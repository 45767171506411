import {
  confirmPasswordReset,
  signInWithEmailAndPassword,
  verifyPasswordResetCode,
} from 'firebase/auth';
import { getUser } from 'shared/services/backend-api/get-user';
import { ResetPasswordErrorTypes } from 'shared/types/reset-password-form';

import { getAuthentication } from './helpers';
export const resetPasswordAndLogin = async (code: string, password: string) => {
  const auth = await getAuthentication();

  try {
    const email = await verifyPasswordResetCode(auth, code);
    await confirmPasswordReset(auth, code, password);
    await signInWithEmailAndPassword(auth, email, password);
    const user = await getUser();

    return user;
  } catch (e) {
    throw ResetPasswordErrorTypes.Unknown;
  }
};
