import { createContext } from 'react';

export type UserSettingsContextProps = {
  openSettings: () => void;
  openChangePassword: () => void;
};

export const UserSettingsContext = createContext<UserSettingsContextProps>({
  openSettings: () => {},
  openChangePassword: () => {},
});
