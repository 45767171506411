import 'shared/lib/react-i18next';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { init as initErrorTracking } from 'shared/services/error-tracking';

import { App } from './app';

initErrorTracking();

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
