import React, { Suspense, useEffect, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { FormInput } from 'shared/components/ui/form-input';
import { Image } from 'shared/types/image';
import { UnsplashImage } from 'shared/types/unsplash-image';

import { Loader } from './loader';
import { NoImagesFound } from './no-images-found';
import * as Styled from './unsplash-image-picker.style';
import { UnsplashImages } from './unsplash-images';

export type UnsplashImagePickerProps = {
  onImage: (image: Image) => void;
};

export const UnsplashImagePicker: React.FC<UnsplashImagePickerProps> = ({
  onImage,
}) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchText, setSearchText] = useState('');

  const changeSearchText = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchText(e.target.value);

  const onImageSelect = (image: UnsplashImage) =>
    onImage({
      id: image.id,
      url: image.urls.small,
      blurHash: image.blur_hash,
      createdAt: new Date(),
      metadata: {
        authorURL: image.user.portfolio_url,
        authorName: image.user.name,
        source: 'unsplash',
        sourceName: 'Unsplash',
        sourceURL: 'https://unsplash.com',
      },
    });

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <Styled.Container>
      <Styled.InputContainer>
        <FormInput
          ref={inputRef}
          onChange={changeSearchText}
          value={searchText}
          placeholder={t(
            'context-menus.image-source-picker.unsplash.search-text.placeholder',
          )}
        />
      </Styled.InputContainer>
      <ErrorBoundary fallback={<NoImagesFound />} resetKeys={[searchText]}>
        <Suspense fallback={<Loader />}>
          <UnsplashImages searchText={searchText} onImage={onImageSelect} />
        </Suspense>
      </ErrorBoundary>
    </Styled.Container>
  );
};
