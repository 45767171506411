import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { SignupFormFields, validationSchema } from 'shared/types/signup-form';

export const useSignupForm = (
  submit: (loginData: SignupFormFields) => void,
) => {
  const { handleSubmit, ...formProps } = useForm<SignupFormFields>({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
  });

  const onSubmit = handleSubmit((data) => submit(data));

  return {
    ...formProps,
    onSubmit,
    errors: formProps.formState.errors,
    isValid: formProps.formState.isValid,
  };
};
