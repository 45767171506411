import { TFunction } from 'i18next';

export type Options = {
  date: Date;
  today: Date;
  t: TFunction;
};

export const formatNumberShort = (value: number) =>
  value >= 1000000
    ? `${(value / 1000000).toFixed(1).replace(/\.0$/, '')}m`
    : value >= 1000
      ? `${(value / 1000).toFixed(1).replace(/\.0$/, '')}k`
      : value.toString();
