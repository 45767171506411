import * as yup from 'yup';

export enum LoginErrorTypes {
  Unknown = 'unknown',
  WrongCombination = 'wrong-combination',
}

export type LoginData = {
  email: string;
  password: string;
};

export const validationSchema: yup.ObjectSchema<LoginData> = yup.object({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

export type LoginFormFields = yup.InferType<typeof validationSchema>;
