import Typography from '@mui/material/Typography';
import { differenceInDays, format, getDate } from 'date-fns';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Timestamp } from 'shared/types/timestamp';

import * as Styled from './rest-of-month-header.style';

export type RestOfMonthHeaderProps = {
  startDate: Timestamp;
  endDate: Timestamp;
};

export const RestOfMonthHeader: React.FC<RestOfMonthHeaderProps> = ({
  startDate,
  endDate,
}) => {
  const { t } = useTranslation();
  const oneDayLeft = useMemo(
    () => differenceInDays(endDate, startDate) <= 0,
    [startDate, endDate],
  );

  return (
    <Styled.Container>
      <Typography variant="h6" component="span">
        {t('general.labels.rest-of-month.title', {
          month: format(startDate, 'LLLL'),
        })}
      </Typography>

      <Typography
        variant="h6"
        component="span"
        color={(theme) => theme.palette.text.secondary}
      >
        {oneDayLeft
          ? format(startDate, 'd MMM')
          : t('general.labels.rest-of-days.title', {
              start: getDate(startDate),
              end: getDate(endDate),
            })}
      </Typography>
    </Styled.Container>
  );
};
