import { format } from 'date-fns';
import React, { useCallback, useMemo, useState } from 'react';
import { DateFormatOptions } from 'shared/types/date-format-options';
import { Timestamp } from 'shared/types/timestamp';

type DateFormatConfig = {
  regex: RegExp;
  order: string[];
  partLengths: number[];
};

const generateFormatConfig = (format: DateFormatOptions): DateFormatConfig => {
  const parts = format.split('-');
  const order = parts.map((part) => part.toLowerCase());
  const regexParts = parts.map((part) => part.replace(/./g, '\\d'));

  const regex = new RegExp(`^(${regexParts.join('-')})$`);
  const partLengths = parts.map((part) => part.length);

  return { regex, order, partLengths };
};

const formatDate = (value: string, config: DateFormatConfig) => {
  // Remove all non-digit characters
  const cleaned = value.replace(/\D/g, '');

  // Dynamically format the value based on the calculated part lengths
  let formattedValue = cleaned;
  const { partLengths } = config;

  for (let i = 0, currentIndex = 0; i < partLengths.length; i++) {
    const partLength = partLengths[i];
    if (cleaned.length > currentIndex + partLength) {
      formattedValue =
        formattedValue.slice(0, currentIndex + partLength + i) +
        '-' +
        formattedValue.slice(currentIndex + partLength + i);
      currentIndex += partLength;
    }
  }

  return formattedValue;
};

export const useDateInput = (
  initialValue: Timestamp | undefined,
  dateFormat: DateFormatOptions,
): [
  value: string,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
] => {
  const [value, setValue] = useState(
    initialValue ? format(initialValue, dateFormat) : '',
  );

  // Memoize the generated config to avoid recalculating on every render
  const formatConfig = useMemo(
    () => generateFormatConfig(dateFormat),
    [dateFormat],
  );

  // Handle input changes
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setValue(formatDate(e.target.value, formatConfig)),
    [formatConfig],
  );

  return [value, onChange];
};
