export enum ActionEvents {
  OnboardingLogin = 'app_onboarding_log_in',
  TaskComplete = 'task_complete',
  HabitComplete = 'habit_completed',
  TaskDelete = 'task_delete',
  TaskCreate = 'task_create',
  GoalComplete = 'goal_complete',
  GoalDelete = 'goal_delete',
  GoalCreate = 'goal_create',
  GoalEdit = 'goal_edit',
  SubGoalCreate = 'subgoal_create',
  SubGoalEdit = 'subgoal_edit',
  LifeAreaCreate = 'life_area_create',
  LifeAreaDelete = 'life_area_delete',
  LifeAreaArchive = 'life_area_archive',
  CancelSubscription = 'cancel_subscription_clicked',
  ShareFeedback = 'share_feedback',
  VisionCreate = 'vision_create',
  VisionEdit = 'vision_edit',
  PushNotificationUpdated = 'push_notifications_updated',
  InitialPushNotificationsQuestion = 'push_notifications_disabled_open_settings',
}
