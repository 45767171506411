import enUS from 'date-fns/locale/en-US';
import nlNL from 'date-fns/locale/nl';
import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { Locales } from 'shared/assets/localization';
import { WeekDays } from 'shared/types/week-days';

// @ts-ignore
registerLocale(Locales.EnUS, enUS);
// @ts-ignore
registerLocale(Locales.NlNL, nlNL);

const weekDaysToNumbers: Record<WeekDays, number> = {
  [WeekDays.Monday]: 1,
  [WeekDays.Tuesday]: 2,
  [WeekDays.Wednesday]: 3,
  [WeekDays.Thursday]: 4,
  [WeekDays.Friday]: 5,
  [WeekDays.Saturday]: 6,
  [WeekDays.Sunday]: 0,
};

export type ReactDatePickerProps = Omit<
  React.ComponentProps<typeof DatePicker>,
  'popperClassName' | 'calendarStartDay' | 'locale'
> & {
  className?: string;
  weekStartsOn?: WeekDays;
};

export const ReactDatePicker: React.FC<ReactDatePickerProps> = ({
  className,
  weekStartsOn,
  ...rest
}) => {
  const { i18n } = useTranslation();
  return (
    <DatePicker
      popperClassName={className}
      calendarStartDay={
        weekStartsOn ? weekDaysToNumbers[weekStartsOn] : undefined
      }
      locale={i18n.language}
      {...rest}
    />
  );
};
