import styled from 'styled-components';

export const Header = styled.div`
  padding: ${({ theme }) => `${theme.spacing(5)} ${theme.spacing(6)}`};
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(8)};

  padding: ${({ theme }) => `0 ${theme.spacing(6)}`};
`;

export const GoalName = styled.span`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`;

export const Interactions = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  justify-content: flex-end;

  padding: ${({ theme }) => `${theme.spacing(5)} ${theme.spacing(6)}`};
`;
