import { Goal } from 'shared/types/goal';
import { ID } from 'shared/types/id';
import { LifeArea } from 'shared/types/life-area';
import { getCompletionPercentage } from 'shared/utils/get-completion-percentage';
import { getGoalColor } from 'shared/utils/get-goal-color';
import { getGoalCompletion } from 'shared/utils/get-goal-completion';

import { RowData } from './types';

export type Options = {
  lifeAreas: LifeArea[];
  goals: Goal[];
  favorites?: ID[];
};

export const mapGoalToRowData = (
  goal: Goal,
  { lifeAreas, goals, favorites }: Options,
): RowData => {
  const { total, completed } = goals.reduce(
    (acc, potentialSubGoal) => {
      if (
        potentialSubGoal.parentIds?.includes(goal.id) &&
        !potentialSubGoal.archivedAt
      ) {
        acc.total += potentialSubGoal.taskCount ?? 0;
        acc.completed += potentialSubGoal.completedTaskCount ?? 0;
      }
      return acc;
    },
    { total: goal.taskCount ?? 0, completed: goal.completedTaskCount ?? 0 },
  );
  const uncompletedTasks = Math.max(0, total - completed);

  const { start, end, current } = getGoalCompletion(goal, {
    goals,
  });
  const completionPercentage = getCompletionPercentage({ start, end, current });

  const color = getGoalColor(goal.id, { goals, lifeAreas });

  const lifeArea = lifeAreas.find(({ id }) => id === goal.lifeAreaId);

  return {
    id: goal.id,
    name: goal.name,
    iconName: goal.iconName,
    image: goal.image,
    deadline: goal.deadline,
    lifeArea: lifeArea?.name,
    color,
    tasks: total,
    completedTasks: completed,
    uncompletedTasks: uncompletedTasks,
    completion: end === Infinity ? null : completionPercentage,
    archivedAt: goal.archivedAt,
    completedAt: goal.completedAt,
    isFavorite: !!favorites?.includes(goal.id),
  };
};
