import { createContext } from 'react';
import { LifeArea } from 'shared/types/life-area';

export type DeleteLifeAreaContextProps = {
  onDeleteLifeArea: (lifeArea: LifeArea, redirectPath?: string) => void;
};

export const DeleteLifeAreaContext = createContext<DeleteLifeAreaContextProps>({
  onDeleteLifeArea: () => {},
});
