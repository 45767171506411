import { ID } from 'shared/types/id';
import { OrderDirection, SortingMode } from 'shared/types/sorting-mode';
import { TaskPriorityOptions } from 'shared/types/task-base';
import { TaskCardTask } from 'shared/types/task-card-task';
import { sortItems } from 'shared/utils/sort-items';

export type Options = {
  customOrder: ID[];
  orderDirection?: OrderDirection;
};

const priorityRanking: Record<TaskPriorityOptions, number> = {
  [TaskPriorityOptions.High]: 0,
  [TaskPriorityOptions.Medium]: 1,
  [TaskPriorityOptions.Low]: 2,
  [TaskPriorityOptions.None]: 3,
};

const sortByCreatedAt = (tasks: TaskCardTask[], { orderDirection }: Options) =>
  tasks.toSorted((taskA, taskB) => {
    const directionTaskA =
      orderDirection === OrderDirection.Asc ? taskA : taskB;
    const directionTaskB =
      orderDirection === OrderDirection.Asc ? taskB : taskA;

    return (
      directionTaskA.raw.task.createdAt.getTime() -
      directionTaskB.raw.task.createdAt.getTime()
    );
  });

const sortByDueDate = (tasks: TaskCardTask[], options: Options) =>
  sortByCreatedAt(tasks, options).sort((taskA, taskB) => {
    const directionTaskA =
      options.orderDirection === OrderDirection.Asc ? taskA : taskB;
    const directionTaskB =
      options.orderDirection === OrderDirection.Asc ? taskB : taskA;

    return (
      (directionTaskA.raw.dueDate?.getTime() ?? 0) -
      (directionTaskB.raw.dueDate?.getTime() ?? 0)
    );
  });

const sortByPriority = (tasks: TaskCardTask[], options: Options) =>
  sortByCreatedAt(tasks, options).sort((taskA, taskB) => {
    const directionTaskA =
      options.orderDirection === OrderDirection.Asc ? taskA : taskB;
    const directionTaskB =
      options.orderDirection === OrderDirection.Asc ? taskB : taskA;

    return (
      priorityRanking[
        directionTaskA.raw.task.priority ?? TaskPriorityOptions.None
      ] -
      priorityRanking[
        directionTaskB.raw.task.priority ?? TaskPriorityOptions.None
      ]
    );
  });

const sortByCustom = (tasks: TaskCardTask[], options: Options) =>
  sortItems(sortByCreatedAt(tasks, options), options.customOrder, 'id');

const sortingModeMap: Record<
  SortingMode,
  (
    tasks: TaskCardTask[],
    options: Omit<Options, 'sortingMode'>,
  ) => TaskCardTask[]
> = {
  [SortingMode.DueDate]: sortByDueDate,
  [SortingMode.CreatedAt]: sortByCreatedAt,
  [SortingMode.Priority]: sortByPriority,
  [SortingMode.Custom]: sortByCustom,
};

export const sortBySortingMode = (
  tasks: TaskCardTask[],
  {
    sortingMode = SortingMode.DueDate,
    orderDirection = OrderDirection.Asc,
    ...options
  }: Options & { sortingMode?: SortingMode },
): TaskCardTask[] => {
  const sorter = sortingModeMap[sortingMode];
  return sorter(tasks, { ...options, orderDirection });
};
