import { createContext } from 'react';
import { Habit } from 'shared/types/habit';
import { RepeatingTask } from 'shared/types/repeating-task';

export type DeleteHabitContextProps = {
  onDeleteHabit: (habit: Habit | RepeatingTask, redirectPath?: string) => void;
};

export const DeleteHabitContext = createContext<DeleteHabitContextProps>({
  onDeleteHabit: () => {},
});
