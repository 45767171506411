import styled, { css } from 'styled-components';

export const Container = styled.label`
  display: inline-flex;
  align-items: center;

  cursor: pointer;

  line-height: 62.5%;
`;

export const Checkbox = styled.span<{
  $isDisabled: boolean;
}>`
  pointer-events: none;
  position: relative;
  display: inline-block;
  width: 1.6rem;
  height: 1.6rem;

  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.background.default};
  border: 0.2rem solid ${({ theme }) => theme.palette.divider};
  transition: all 200ms;

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      opacity: 0.4;
      border-color: ${({ theme }) => theme.palette.divider};
    `};

  :hover {
    background-color: ${({ theme }) => theme.palette.brown['50']};
  }
`;

export const Checkmark = styled.span`
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;

  width: 0.8rem;
  height: 0.8rem;

  transform: translate(-50%, -50%);
  background: ${({ theme }) => theme.palette.background.default};
  border-radius: 50%;
`;

export const Input = styled.input`
  position: absolute;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;

  border: none;
  visibility: hidden;

  &:checked {
    + ${Checkbox} {
      background: ${({ theme }) => theme.palette.primary.main};
      border-color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`;

export const Label = styled.span`
  display: inline-block;
  margin-left: ${({ theme }) => theme.spacing(2)};
`;
