import React from 'react';
import { Lock } from 'shared/assets/icons';
import { GoalSummary } from 'shared/components/ui/goal-summary';
import { Icon } from 'shared/components/ui/icon';

import * as Styled from './goal-header.style';

export type GoalHeaderProps = React.ComponentProps<typeof GoalSummary> & {
  isFrozen?: boolean;
};

export const GoalHeader: React.FC<GoalHeaderProps> = ({
  isFrozen,
  ...rest
}) => (
  <Styled.Container>
    <GoalSummary {...rest} isFrozen={isFrozen} />

    {isFrozen && (
      <Styled.IconContainer>
        <Icon icon={Lock} />
      </Styled.IconContainer>
    )}
  </Styled.Container>
);
