import styled, { css } from 'styled-components';

import { Colors } from './types';

export const Container = styled.button<{
  $selected: boolean;
  $highlight: boolean;
}>`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
  align-items: center;

  margin: 0;
  padding: ${({ theme }) => `0 ${theme.spacing(1.5)}`};
  height: 2.2rem;
  min-width: 0;

  background: transparent;
  border: none;
  border-radius: ${({ theme }) => theme.spacing(4)};
  transition:
    background 200ms,
    color 200ms;

  color: ${({ theme }) => theme.palette.text.secondary};

  ${({ onClick, theme }) =>
    !!onClick &&
    css`
      cursor: pointer;

      &:hover {
        background: ${theme.palette.brown['100']};
        color: ${theme.palette.text.primary};
      }
    `}

  ${({ $highlight, theme }) =>
    $highlight &&
    css`
      color: ${theme.palette.grey['600']};
    `};

  ${({ $selected, theme }) =>
    $selected &&
    css`
      background: ${theme.palette.brown['100']};
      color: ${theme.palette.text.primary};
    `};
`;

export const IconContainer = styled.span<{ $color: Colors }>`
  line-height: 62.5%;
  font-size: 1.2rem;

  color: ${({ $color, theme }) => {
    switch ($color) {
      case Colors.Inherit:
        return 'inherit';
      case Colors.Primary:
        return theme.palette.primary.main;
      case Colors.Secondary:
        return theme.palette.secondary.main;
      case Colors.Info:
        return theme.palette.info.main;
      case Colors.Success:
        return theme.palette.success.main;
      case Colors.Warning:
        return theme.palette.warning.main;
      case Colors.Error:
        return theme.palette.error.main;
    }
  }};
`;

export const Title = styled.span`
  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;
`;
