import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Target } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';

import * as Styled from './new-sub-goal-row.style';

export type NewSubGoalRowProps = {
  onCreateGoal: () => void;
};

export const NewSubGoalRow: React.FC<NewSubGoalRowProps> = ({
  onCreateGoal,
}) => {
  const { t } = useTranslation();

  return (
    <Styled.Container onClick={onCreateGoal}>
      <Styled.IconContainer>
        <Icon icon={Target} />
      </Styled.IconContainer>

      <Typography variant="subtitle2" component="span">
        {t('general.labels.create-sub-goal.label')}
      </Typography>
    </Styled.Container>
  );
};
