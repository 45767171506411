import { useSortable } from '@dnd-kit/sortable';
import React, { useMemo } from 'react';
import { ReorderDotsVertical } from 'shared/assets/icons';
import { LifeAreaContextMenu } from 'shared/components/connected/life-area-context-menu';
import { IconButton } from 'shared/components/ui/icon-button';
import { useContextMenuState } from 'shared/hooks/use-context-menu-state';
import { Paths } from 'shared/routes';
import { ID } from 'shared/types/id';
import { replaceUrlParams } from 'shared/utils/replace-url-params';

import { columnToCellFcMap } from './column-to-cell-fc-map';
import * as Styled from './life-area-data-grid.style';
import { MenuCell } from './menu-cell';
import { Column, ColumnType, RowData } from './types';

type SortableDataRowProps = React.ComponentProps<typeof Styled.DataRow> & {
  id: ID;
};

const SortableDataRow: React.FC<SortableDataRowProps> = ({ id, ...rest }) => {
  const {
    setNodeRef,
    attributes,
    listeners,
    isDragging,
    transform,
    transition,
  } = useSortable({ id });

  return (
    <Styled.DataRow
      {...rest}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      role="presentation"
      $isDragging={isDragging}
      $transform={transform}
      $transition={transition}
    />
  );
};

const Container: React.FC<{
  id: ID;
  isFrozen?: boolean;
  onClick?: (id: ID) => void;
  children: React.ReactNode;
}> = ({ id, isFrozen, onClick, children }) => {
  if (isFrozen || onClick) {
    const _onClick = () => onClick?.(id);
    return (
      <Styled.RowContainer onClick={_onClick}>{children}</Styled.RowContainer>
    );
  }

  return (
    <Styled.RowLink to={replaceUrlParams(Paths.LifeAreaDetail, { id })}>
      {children}
    </Styled.RowLink>
  );
};

export type DataRowProps = {
  columns: Column[];
  data: RowData;
  onLifeArea?: (id: ID) => void;
  onEditLifeArea?: (id: ID) => void;
  onDeleteLifeArea?: (id: ID) => void;
  onArchiveLifeArea?: (id: ID) => void;
  isSortable?: boolean;
  isFrozen?: boolean;
  isArchived?: boolean;
};

export const DataRow: React.FC<DataRowProps> = ({
  isSortable,
  data,
  columns,
  onLifeArea,
  onEditLifeArea,
  onDeleteLifeArea,
  onArchiveLifeArea,
  isFrozen,
  isArchived,
}) => {
  const {
    open: openMenu,
    close: closeMenu,
    position: menuPosition,
  } = useContextMenuState({ disabled: isFrozen });
  const DataRow = isSortable ? SortableDataRow : Styled.DataRow;
  const emptyExcludedColumns = useMemo(
    () => columns.filter(({ type }) => type !== ColumnType.Empty),
    [columns],
  );
  const shouldShowEmptyColumn = emptyExcludedColumns.length < columns.length;

  const onEdit = onEditLifeArea
    ? (e: React.MouseEvent) => {
        e.preventDefault();
        onEditLifeArea(data.id);
        closeMenu();
      }
    : undefined;
  const onArchive = onArchiveLifeArea
    ? (e: React.MouseEvent) => {
        e.preventDefault();
        onArchiveLifeArea(data.id);
        closeMenu();
      }
    : undefined;
  const onDelete =
    data.isDeletable && onDeleteLifeArea
      ? (e: React.MouseEvent) => {
          e.preventDefault();
          onDeleteLifeArea(data.id);
          closeMenu();
        }
      : undefined;

  return (
    <>
      <DataRow
        id={data.id}
        onContextMenu={openMenu}
        $isArchived={!!isArchived}
        $selected={!!menuPosition}
      >
        <Container id={data.id} isFrozen={isFrozen} onClick={onLifeArea}>
          {!!isSortable && (
            <Styled.DragHandle>
              <IconButton icon={ReorderDotsVertical} />
            </Styled.DragHandle>
          )}
          {emptyExcludedColumns.map(({ type }) => {
            const DataCell = columnToCellFcMap[type];

            return (
              <Styled.DataRowItem key={type} $type={type}>
                <DataCell data={data} isFrozen={isFrozen} />
              </Styled.DataRowItem>
            );
          })}

          {shouldShowEmptyColumn && (
            <Styled.DataRowItem $type={ColumnType.Empty}>
              {(onEdit || onDelete || onArchive || isFrozen) && (
                <MenuCell id={data.id} onClick={openMenu} isFrozen={isFrozen} />
              )}
            </Styled.DataRowItem>
          )}
        </Container>
      </DataRow>
      {!!menuPosition && (
        <LifeAreaContextMenu
          lifeArea={data.raw}
          location={menuPosition}
          position="bottom-start"
          onClose={closeMenu}
        />
      )}
    </>
  );
};
