import { useCallback, useState } from 'react';

export enum SortMode {
  Custom = 'custom',
  Asc = 'ascending',
  Desc = 'descending',
}

export const useDataGridSorting = <ColumnType>() => {
  const [sortMode, setSortMode] = useState<SortMode>(SortMode.Custom);
  const [type, setType] = useState<ColumnType>();

  const onSortChange = useCallback(
    (columnType?: ColumnType) => {
      if (!columnType) {
        setSortMode(SortMode.Custom);
        setType(undefined);
        return;
      }

      if (columnType === type) {
        setSortMode(
          sortMode === SortMode.Asc ? SortMode.Desc : SortMode.Custom,
        );
        setType(sortMode === SortMode.Asc ? type : undefined);
        return;
      }

      setType(columnType);
      setSortMode(SortMode.Asc);
    },
    [sortMode, type],
  );

  return {
    sortMode,
    type,
    onSortChange,
  };
};
