import Typography from '@mui/material/Typography';
import React from 'react';
import { ChevronLeft } from 'shared/assets/icons';
import { Collapse } from 'shared/components/ui/collapse';
import {
  IconButton,
  Sizes as IconButtonSizes,
} from 'shared/components/ui/icon-button';
import { useToggle } from 'shared/hooks/use-toggle';

import * as Styled from './section.style';
import { Sizes } from './types';

export type SectionProps = {
  children: React.ReactNode;
  title: string;
  titleCount?: number;
  titleVariant?: React.ComponentProps<typeof Typography>['variant'];
  canCollapse?: boolean;
  initialOpen?: boolean;
  actions?: React.ReactNode[];
  size?: Sizes;
};

export const Section: React.FC<SectionProps> = ({
  title,
  titleCount,
  titleVariant,
  actions = [],
  initialOpen,
  canCollapse,
  size = Sizes.Medium,
  children,
}) => {
  const [open, toggleOpen] = useToggle(initialOpen);
  const CollapseContainer = canCollapse ? Collapse : React.Fragment;
  const collapseContainerProps = canCollapse ? { isOpen: open } : {};
  const titleTypography =
    titleVariant ?? (size === Sizes.Small ? 'caption' : 'h6');

  return (
    <>
      <Styled.Container $size={size} $canCollapse={!!canCollapse}>
        {canCollapse ? (
          <Styled.CollapseButton onClick={toggleOpen}>
            <Styled.IconButtonContainer>
              <IconButton
                icon={ChevronLeft}
                rotation={open ? 270 : 180}
                size={IconButtonSizes.ExtraSmall}
                as="span"
              />
            </Styled.IconButtonContainer>
            <Typography variant={titleTypography} component="span">
              {title}
            </Typography>
            {titleCount !== undefined && (
              <Styled.TitleCount>{titleCount}</Styled.TitleCount>
            )}
          </Styled.CollapseButton>
        ) : (
          <Styled.TitleContainer>
            <Typography variant={titleTypography} component="span">
              {title}
            </Typography>
            {titleCount !== undefined && (
              <Styled.TitleCount>{title}</Styled.TitleCount>
            )}
          </Styled.TitleContainer>
        )}

        {!!actions.length && (
          <Styled.ActionsContainer>
            {actions.map((action, index) => (
              <React.Fragment key={`action-${index}`}>{action}</React.Fragment>
            ))}
          </Styled.ActionsContainer>
        )}
      </Styled.Container>

      <CollapseContainer {...collapseContainerProps}>
        {children}
      </CollapseContainer>
    </>
  );
};
