import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { LoginFormFields, validationSchema } from 'shared/types/login-form';

export const useLoginForm = (submit: (loginData: LoginFormFields) => void) => {
  const { handleSubmit, ...formProps } = useForm<LoginFormFields>({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
  });

  const onSubmit = handleSubmit((data) => submit(data));

  return {
    ...formProps,
    onSubmit,
    errors: formProps.formState.errors,
    isValid: formProps.formState.isValid,
  };
};
