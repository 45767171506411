import React from 'react';
import { IconFormIcons, Target } from 'shared/assets/icons';
import { CircleProgress } from 'shared/components/ui/circle-progress';
import { DataGridNameImageCell } from 'shared/components/ui/data-grid-name-image-cell';
import { TimeLeft } from 'shared/components/ui/time-left';

import * as Styled from './goal-data-grid.style';
import { ColumnType, RowData } from './types';

export const columnToCellFcMap: Record<
  ColumnType,
  React.FC<{ data: RowData; isFrozen?: boolean }>
> = {
  [ColumnType.Name]: ({ data, isFrozen }) => (
    <Styled.DataCell $isFrozen={false}>
      <DataGridNameImageCell
        icon={data.iconName ? IconFormIcons[data.iconName] : Target}
        name={data.name}
        image={data.image}
        isFrozen={isFrozen}
        color={data.color}
        isCompleted={!!data.completedAt}
      />
    </Styled.DataCell>
  ),
  [ColumnType.Tasks]: ({ data, isFrozen }) => (
    <Styled.DataCell $empty={!data.uncompletedTasks} $isFrozen={!!isFrozen}>
      {data.uncompletedTasks || '-'}
    </Styled.DataCell>
  ),
  [ColumnType.Completion]: ({ data, isFrozen }) => (
    <Styled.DataCell $empty={data.completion === null} $isFrozen={!!isFrozen}>
      {data.completion === null ? (
        '-'
      ) : (
        <Styled.ProgressContainer>
          <CircleProgress
            percentage={data.completion}
            isPrimary={!isFrozen}
            isSecondary={!!isFrozen}
          />
        </Styled.ProgressContainer>
      )}
    </Styled.DataCell>
  ),
  [ColumnType.Deadline]: ({ data, isFrozen }) => (
    <Styled.DataCell $empty={!data.deadline} $isFrozen={!!isFrozen}>
      {!data.completedAt && data.deadline ? (
        <TimeLeft date={data.deadline} />
      ) : (
        '-'
      )}
    </Styled.DataCell>
  ),
  [ColumnType.LifeArea]: ({ data, isFrozen }) => (
    <Styled.DataCell $empty={!data.lifeArea} $isFrozen={!!isFrozen}>
      {data.lifeArea ?? '-'}
    </Styled.DataCell>
  ),
  [ColumnType.Empty]: () => <Styled.DataCell $isFrozen={false} />,
};
