import { NewTask } from 'shared/types/task';

import { dateToFirestoreTimestamp } from './converters/date-to-firestore-timestamp';
import { newTaskConverter } from './converters/task';
import { addDocumentWithOwnerRole, CollectionOptions } from './helpers';

export const createTask = async (task: NewTask) => {
  return addDocumentWithOwnerRole(
    CollectionOptions.Tasks,
    dateToFirestoreTimestamp(newTaskConverter(task)),
  );
};
