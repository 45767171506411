import React from 'react';

import * as Styled from './popup-menu.style';

export type PopupMenuListGroupProps = {
  hasBottomBorder?: boolean;
  isScrollable?: boolean;
  children: React.ReactNode;
};
export const PopupMenuListGroup: React.FC<PopupMenuListGroupProps> = ({
  hasBottomBorder,
  isScrollable,
  children,
}) => (
  <Styled.ListGroup
    $hasBottomBorder={!!hasBottomBorder}
    $isScrollable={!!isScrollable}
  >
    {children}
  </Styled.ListGroup>
);
