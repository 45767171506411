import React from 'react';
import { useTranslation } from 'react-i18next';
import { MessageChatModern } from 'shared/assets/icons';
import { Button, Colors, Sizes, Variants } from 'shared/components/ui/button';
import { useOpenFeedbackDialog } from 'shared/contexts/feedback-dialog';

import * as Styled from './side-navigation.style';

export const ShareFeedbackButton: React.FC = () => {
  const { t } = useTranslation();
  const openFeedbackDialog = useOpenFeedbackDialog();

  return (
    <Styled.FeedbackButtonContainer>
      <Button
        size={Sizes.Small}
        color={Colors.Inherit}
        variant={Variants.Contained}
        startIcon={MessageChatModern}
        onClick={openFeedbackDialog}
        alignCenter
      >
        {t('general.menu.navigation.share-feedback')}
      </Button>
    </Styled.FeedbackButtonContainer>
  );
};
