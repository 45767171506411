import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BulletListText,
  BusinessChart,
  Calendar,
  CheckCircle,
  Flag,
  Target,
} from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import {
  PopupMenu,
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
} from 'shared/components/ui/popup-menu';
import { useClickOutside } from 'shared/hooks/use-click-outside';

export type ProgressChartContextMenuProps = Omit<
  React.ComponentProps<typeof PopupMenu>,
  'children' | 'ref'
> & {
  onClose: () => void;
  onEditTarget?: (e: React.MouseEvent) => void;
  onEditStartDate?: (e: React.MouseEvent) => void;
  onEditDeadline?: (e: React.MouseEvent) => void;
  onShowHistory?: (e: React.MouseEvent) => void;
  onUpdateProgress?: (e: React.MouseEvent) => void;
  onComplete?: (e: React.MouseEvent) => void;
  isCompleted?: boolean;
};

export const ProgressChartContextMenu: React.FC<
  ProgressChartContextMenuProps
> = ({
  location,
  referenceElement,
  position,
  onClose,
  onEditTarget,
  onEditStartDate,
  onEditDeadline,
  onShowHistory,
  onUpdateProgress,
  onComplete,
  isCompleted,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  useClickOutside(containerRef, onClose);

  return (
    <PopupMenu
      ref={containerRef}
      location={location}
      position={position}
      referenceElement={referenceElement}
    >
      {(!!onEditTarget || !!onUpdateProgress) && (
        <PopupMenuList>
          {!!onEditTarget && (
            <PopupMenuListItem>
              <PopupMenuButton
                onClick={onEditTarget}
                start={<Icon icon={Target} />}
              >
                {t('context-menus.goal.progress.labels.edit-target')}
              </PopupMenuButton>
            </PopupMenuListItem>
          )}
          {!!onEditStartDate && (
            <PopupMenuListItem>
              <PopupMenuButton
                onClick={onEditStartDate}
                start={<Icon icon={Calendar} />}
              >
                {t('context-menus.goal.progress.labels.edit-start-date')}
              </PopupMenuButton>
            </PopupMenuListItem>
          )}
          {!!onEditDeadline && (
            <PopupMenuListItem>
              <PopupMenuButton
                onClick={onEditDeadline}
                start={<Icon icon={Flag} />}
              >
                {t('context-menus.goal.progress.labels.edit-deadline')}
              </PopupMenuButton>
            </PopupMenuListItem>
          )}
          {!!onUpdateProgress && (
            <PopupMenuListItem>
              <PopupMenuButton
                onClick={onUpdateProgress}
                start={<Icon icon={BusinessChart} />}
              >
                {t('context-menus.goal.progress.labels.update-progress')}
              </PopupMenuButton>
            </PopupMenuListItem>
          )}
          {!!onShowHistory && (
            <PopupMenuListItem>
              <PopupMenuButton
                onClick={onShowHistory}
                start={<Icon icon={BulletListText} />}
              >
                {t('context-menus.goal.progress.labels.show-history')}
              </PopupMenuButton>
            </PopupMenuListItem>
          )}
          {!!onComplete && (
            <PopupMenuListItem>
              <PopupMenuButton
                onClick={onComplete}
                start={<Icon icon={CheckCircle} />}
              >
                {t(
                  isCompleted
                    ? 'context-menus.goal.progress.labels.un-complete'
                    : 'context-menus.goal.progress.labels.complete',
                )}
              </PopupMenuButton>
            </PopupMenuListItem>
          )}
        </PopupMenuList>
      )}
    </PopupMenu>
  );
};
