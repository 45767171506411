import React from 'react';
import { GridContainer } from 'shared/components/ui/grid-container';
import { useTrackPageVisitEvent } from 'shared/hooks/use-track-page-visit-event';
import { PageEvents } from 'shared/services/tracking';

import { GoalsDataGrid } from './components/goals-data-grid';
import { TabPanel } from './components/tab-panel';

export const Goals: React.FC = () => {
  useTrackPageVisitEvent(PageEvents.LifeAreaDetailGoals, undefined);

  return (
    <GridContainer>
      <TabPanel>
        <GoalsDataGrid />
      </TabPanel>
    </GridContainer>
  );
};
