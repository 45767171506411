import React from 'react';
import { Goal } from 'shared/types/goal';
import { LifeArea } from 'shared/types/life-area';

import { LifeAreaDataGridView } from './life-area-data-grid-view';
import { useLifeAreaOrdering } from './use-life-area-ordering';

export type LifeAreaDataGridProps = Omit<
  React.ComponentProps<typeof LifeAreaDataGridView>,
  'rows' | 'onSortChange' | 'sortMode' | 'columnToSort' | 'hasDndRows'
> & {
  goals: Goal[];
  lifeAreas: LifeArea[];
};

export const LifeAreaDataGrid: React.FC<LifeAreaDataGridProps> = ({
  goals,
  lifeAreas,
  columnTypes,
  ...rest
}) => {
  const { rows, sortMode, onSortChange, sortColumn } = useLifeAreaOrdering({
    goals,
    lifeAreas,
    columnTypes,
  });

  return (
    <LifeAreaDataGridView
      rows={rows}
      columnTypes={columnTypes}
      sortMode={sortMode}
      onSortChange={onSortChange}
      columnToSort={sortColumn}
      {...rest}
    />
  );
};
