export const sortItems = <Item>(
  items: Item[],
  order: Item[keyof Item][],
  key: keyof Item,
): Item[] => {
  // Maak een map van waarden naar hun overeenkomstige volgorde-index
  const orderMap = order.reduce<Record<string, number>>((acc, value, index) => {
    acc[value as string] = index;
    return acc;
  }, {});

  // Sorteer de items op basis van de opgegeven sleutel en volgorde
  return items.toSorted((a, b) => {
    const aOrder = orderMap[a[key] as string];
    const bOrder = orderMap[b[key] as string];
    return aOrder - bOrder;
  });
};
