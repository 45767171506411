export const getItemScrollPosition = ({
  itemWidth,
  itemPosition,
  visibleAmount,
}: {
  itemPosition: number;
  visibleAmount: number;
  itemWidth: number;
}): number => {
  const containerWidth = itemWidth * visibleAmount;
  const datePosition = itemPosition * itemWidth;
  return Math.max(0, datePosition - containerWidth);
};
