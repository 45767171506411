import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowRight, Check } from 'shared/assets/icons';
import {
  IconColors,
  InformationBox,
  InformationBoxBullets,
  Item,
} from 'shared/components/ui/information-box';

enum Sections {
  Tips = 'tips',
  Examples = 'examples',
}

export type VisionTipsProps = {
  open?: boolean;
  onClick: () => void;
};

export const VisionTips: React.FC<VisionTipsProps> = ({ open, onClick }) => {
  const { t } = useTranslation();
  const [openSection, setOpenSection] = useState<Sections | undefined>(
    Sections.Tips,
  );

  const onTips = () =>
    setOpenSection((oldSection) =>
      oldSection === Sections.Tips ? undefined : Sections.Tips,
    );

  const onExamples = () =>
    setOpenSection((oldSection) =>
      oldSection === Sections.Examples ? undefined : Sections.Examples,
    );

  const tipItems = useMemo<Item[]>(
    () => [
      {
        icon: Check,
        iconColor: IconColors.Success,
        title: t(
          'pages.life-area-detail.vision.tips-and-examples.tips.tip.1.title',
        ),
        description: t(
          'pages.life-area-detail.vision.tips-and-examples.tips.tip.1.description',
        ),
      },
      {
        icon: Check,
        iconColor: IconColors.Success,
        title: t(
          'pages.life-area-detail.vision.tips-and-examples.tips.tip.2.title',
        ),
        description: t(
          'pages.life-area-detail.vision.tips-and-examples.tips.tip.2.description',
        ),
      },
      {
        icon: Check,
        iconColor: IconColors.Success,
        title: t(
          'pages.life-area-detail.vision.tips-and-examples.tips.tip.3.title',
        ),
        description: t(
          'pages.life-area-detail.vision.tips-and-examples.tips.tip.3.description',
        ),
      },
      {
        icon: Check,
        iconColor: IconColors.Success,
        title: t(
          'pages.life-area-detail.vision.tips-and-examples.tips.tip.4.title',
        ),
        description: t(
          'pages.life-area-detail.vision.tips-and-examples.tips.tip.4.description',
        ),
      },
      {
        icon: Check,
        iconColor: IconColors.Success,
        title: t(
          'pages.life-area-detail.vision.tips-and-examples.tips.tip.5.title',
        ),
        description: t(
          'pages.life-area-detail.vision.tips-and-examples.tips.tip.5.description',
        ),
      },
    ],
    [t],
  );

  const exampleItems = useMemo<Item[]>(
    () => [
      {
        icon: ArrowRight,
        iconColor: IconColors.Info,
        title: t(
          'pages.life-area-detail.vision.tips-and-examples.tips.example.1.title',
        ),
      },
      {
        icon: ArrowRight,
        iconColor: IconColors.Info,
        title: t(
          'pages.life-area-detail.vision.tips-and-examples.tips.example.2.title',
        ),
      },
      {
        icon: ArrowRight,
        iconColor: IconColors.Info,
        title: t(
          'pages.life-area-detail.vision.tips-and-examples.tips.example.3.title',
        ),
      },
      {
        icon: ArrowRight,
        iconColor: IconColors.Info,
        title: t(
          'pages.life-area-detail.vision.tips-and-examples.tips.example.4.title',
        ),
      },
      {
        icon: ArrowRight,
        iconColor: IconColors.Info,
        title: t(
          'pages.life-area-detail.vision.tips-and-examples.tips.example.5.title',
        ),
      },
      {
        icon: ArrowRight,
        iconColor: IconColors.Info,
        title: t(
          'pages.life-area-detail.vision.tips-and-examples.tips.example.6.title',
        ),
      },
      {
        icon: ArrowRight,
        iconColor: IconColors.Info,
        title: t(
          'pages.life-area-detail.vision.tips-and-examples.tips.example.7.title',
        ),
      },
      {
        icon: ArrowRight,
        iconColor: IconColors.Info,
        title: t(
          'pages.life-area-detail.vision.tips-and-examples.tips.example.8.title',
        ),
      },
      {
        icon: ArrowRight,
        iconColor: IconColors.Info,
        title: t(
          'pages.life-area-detail.vision.tips-and-examples.tips.example.9.title',
        ),
      },
      {
        icon: ArrowRight,
        iconColor: IconColors.Info,
        title: t(
          'pages.life-area-detail.vision.tips-and-examples.tips.example.10.title',
        ),
      },
    ],
    [t],
  );

  return (
    <InformationBox
      header={t('pages.life-area-detail.vision.tips-and-examples.header.title')}
      description={t(
        'pages.life-area-detail.vision.tips-and-examples.header.description',
      )}
      onClick={onClick}
      open={open}
    >
      <InformationBoxBullets
        header={t(
          'pages.life-area-detail.vision.tips-and-examples.tips.header.title',
        )}
        items={tipItems}
        onClick={onTips}
        open={openSection === Sections.Tips}
      />
      {/*<InformationBoxBullets*/}
      {/*  header={t(*/}
      {/*    'pages.life-area-detail.vision.tips-and-examples.examples.header.title',*/}
      {/*  )}*/}
      {/*  items={exampleItems}*/}
      {/*  onClick={onExamples}*/}
      {/*  open={openSection === Sections.Examples}*/}
      {/*/>*/}
    </InformationBox>
  );
};
