import { useGoals } from 'features/goals';
import {
  useOverdueHabits,
  useOverdueTasks,
  useTodaysHabits,
  useTodaysTasks,
} from 'features/tasks';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useToday } from 'shared/contexts/today';
import { useUser } from 'shared/hooks/use-user';
import { TodayTypeSections } from 'shared/types/page-sections';
import { TaskType } from 'shared/types/task-base';
import { initialWeekStartsOn } from 'shared/types/week-days';
import { mapTaskToTaskCard } from 'shared/utils/mappers/map-task-to-task-card';
import { sortBySortingMode } from 'shared/utils/sort-by-sorting-mode';

export const useTypeTaskSections = () => {
  const { t } = useTranslation();
  const today = useToday();
  const user = useUser();

  const todos = useTodaysTasks();
  const habits = useTodaysHabits();
  const overdueTodos = useOverdueTasks();
  const overdueHabits = useOverdueHabits();
  const goals = useGoals();

  const tasks = useMemo(
    () => [...overdueTodos, ...overdueHabits, ...todos, ...habits],
    [overdueTodos, overdueHabits, todos, habits],
  );

  const taskCardTasks = useMemo(
    () =>
      tasks.map((task) =>
        mapTaskToTaskCard(task, {
          today,
          goals,
          t,
          showDue: true,
          hideTodayDue: true,
          weekStartsOn: user?.settings?.startOfWeek ?? initialWeekStartsOn,
        }),
      ),
    [goals, t, tasks, today, user?.settings?.startOfWeek],
  );

  const sections = useMemo(
    () => [
      {
        id: TodayTypeSections.Tasks,
        items: sortBySortingMode(
          taskCardTasks.filter(({ raw }) => raw.task.type !== TaskType.Habit),
          {
            sortingMode: user?.settings?.todoSortingMode,
            customOrder:
              user?.settings?.todayGroupSorting?.type?.sorting?.[
                TodayTypeSections.Tasks
              ] ?? [],
          },
        ),
      },
      {
        id: TodayTypeSections.Habits,
        items: sortBySortingMode(
          taskCardTasks.filter(({ raw }) => raw.task.type === TaskType.Habit),
          {
            sortingMode: user?.settings?.todoSortingMode,
            customOrder:
              user?.settings?.todayGroupSorting?.type?.sorting?.[
                TodayTypeSections.Habits
              ] ?? [],
          },
        ),
      },
    ],
    [
      taskCardTasks,
      user?.settings?.todayGroupSorting?.type?.sorting,
      user?.settings?.todoSortingMode,
    ],
  );

  return {
    sections,
    taskCardTasks,
  };
};
