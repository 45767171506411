import { typographyToCss } from 'shared/lib/mui';
import styled from 'styled-components';

export const Container = styled.div<{
  $visibleDates: number;
  $dateWidth: number;
}>`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  height: 100%;
  width: ${({ $visibleDates, $dateWidth }) => $visibleDates * $dateWidth}px;
  margin-left: auto;

  overflow: hidden;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const Week = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  height: 100%;
`;

export const WeekEntry = styled.span<{ $width: number; $completed?: boolean }>`
  ${({ theme }) => typographyToCss(theme.typography.overline)};
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: ${({ $width }) => $width}px;
  color: ${({ $completed, theme }) =>
    $completed ? theme.palette.text.primary : 'inherit'};
`;

export const Divider = styled.span`
  display: inline-block;
  width: 1px;
  height: 100%;
  background: ${({ theme }) => theme.palette.divider};
`;
