import { DragEndEvent, DragOverEvent, DragStartEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ID } from 'shared/types/id';

type MinimalItem = {
  id: ID;
};

type Options<Item> = {
  items?: Item[];
  onDragEnd?: (ids: ID[]) => void;
};

export const useSortableItemsControls = <Item extends MinimalItem>({
  items,
  onDragEnd,
}: Options<Item> = {}) => {
  const [sortedItems, setSortedItems] = useState<Item[]>(items ?? []);
  const [activeId, setActiveId] = useState<ID>();
  const activeItem = useMemo(
    () =>
      activeId ? sortedItems.find(({ id }) => id === activeId) : undefined,
    [activeId, sortedItems],
  );

  useEffect(() => {
    if (items) {
      setSortedItems(items);
    }
  }, [items]);

  const handleDragStart = useCallback(
    ({ active }: DragStartEvent) => setActiveId(active.id.toString()),
    [],
  );

  const handleDragOver = useCallback(
    ({ active, over }: DragOverEvent) => {
      const activeTask = sortedItems.find(
        ({ id }) => id === active.id.toString(),
      );

      // early return when there is no over or active task, or the over and active task are the same. We leave everything as is
      if (!over?.id || over.id === active.id || !activeTask) {
        return;
      }

      const activeIndex = sortedItems.findIndex(({ id }) => id === active.id);
      const overIndex = sortedItems.findIndex(({ id }) => id === over.id);

      // if the task is not moved over a different task or the active task cannot be found, we keep everything as is
      if (activeIndex < 0 || overIndex < 0) {
        return;
      }

      setSortedItems(arrayMove(sortedItems, activeIndex, overIndex));
    },
    [sortedItems],
  );

  const handleDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      setActiveId(undefined);
      const activeTask = sortedItems.find(
        ({ id }) => id === active.id.toString(),
      );

      // early return when there is no over or active task, or the over and active task are the same. We leave everything as is
      if (!over?.id || !activeTask) {
        return;
      }

      const activeIndex = sortedItems.findIndex(({ id }) => id === active.id);
      const overIndex = sortedItems.findIndex(({ id }) => id === over.id);

      // if the task is not moved over a different task or the active task cannot be found, we keep everything as is
      if (activeIndex < 0 || overIndex < 0) {
        return;
      }

      const orderedTaskIds = sortedItems.map(({ id }) => id);

      onDragEnd?.(arrayMove(orderedTaskIds, activeIndex, overIndex));
    },
    [onDragEnd, sortedItems],
  );

  const handleDragCancel = useCallback(() => setActiveId(undefined), []);

  return {
    items: sortedItems,
    activeItem,
    handleDragStart,
    handleDragOver,
    handleDragEnd,
    handleDragCancel,
  };
};
