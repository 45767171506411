import { QueryFunction } from '@tanstack/react-query';
import {
  DocumentReference,
  DocumentSnapshot,
  FirestoreError,
  getDocFromCache,
  onSnapshot,
} from 'firebase/firestore';
import { getRollbar } from 'shared/services/error-tracking';
import { ID } from 'shared/types/id';

import { queryClient } from './query-client';
import { addSubscription } from './subscriptions';

export const createSubscriptionDoc = <T extends { id: ID }>(
  doc: () => Promise<DocumentReference<T>> | DocumentReference<T>,
): QueryFunction<T | undefined, string[]> => {
  let firstRun = true;
  let latestSnapshotData: T;
  // let throttledChanges: FirebaseDocChanges | undefined;

  return (context) => {
    return new Promise(async (resolve, reject) => {
      const docRef = await doc();

      const onSuccess = (response: DocumentSnapshot<T>) => {
        latestSnapshotData = (response.data() as T) ?? null;

        if (firstRun) {
          resolve(latestSnapshotData);
          firstRun = false;
          return;
        }

        queryClient.setQueryData(context.queryKey, latestSnapshotData);
      };

      const onError = (error: FirestoreError) => {
        getRollbar().error('Firestore Doc: Doc request failed', error, {
          custom: {
            queryKey: context.queryKey,
            error,
          },
        });

        if (firstRun) {
          reject(error);
          firstRun = false;
          return;
        }

        queryClient.invalidateQueries({
          queryKey: context.queryKey,
          exact: true,
        });

        // todo: Implement actually setting an error on the query when the fetch has failed.
        //  Reason for this not being possible right now is that the user-query would fail as well, even though the user is logged in
        // const queryToSetErrorOn = queryClient
        //   .getQueryCache()
        //   .getAll()
        //   .find((query) => query.queryKey === context.queryKey);
        //
        // at least invalidate
        // if (!queryToSetErrorOn) {
        //   queryClient.invalidateQueries({
        //     queryKey: context.queryKey,
        //     exact: true,
        //   });
        //   return;
        // }
        //
        // queryToSetErrorOn.setState({
        //   status: 'error',
        //   error,
        //   fetchMeta: {
        //     ...queryToSetErrorOn.state.fetchMeta,
        //   },
        // });
      };

      getDocFromCache(docRef)
        // eslint-disable-next-line promise/always-return -- temp
        .then(onSuccess)
        .catch(() => {
          // this is just cache, do nothing with this error
        });

      addSubscription(onSnapshot(docRef, onSuccess, onError));
    });
  };
};

// activeQuery()!.setState({
//   status: 'error',
//   error,
//   fetchMeta: {
//     ...activeQuery()!.state.fetchMeta,
//     __previousQueryOptions,
//   } as any,
// } as QueryState<unknown, Error>)
