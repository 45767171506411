import {
  Auth,
  browserLocalPersistence,
  connectAuthEmulator,
  getAuth,
  onAuthStateChanged,
  setPersistence,
} from 'firebase/auth';

import { initAppCheck } from './app-check';
import { initApp } from './init-app';

let auth: Auth | undefined;

export const getAuthentication = async () => {
  if (!auth) {
    import.meta.env.MODE !== 'development' && initAppCheck(initApp());
    auth = getAuth(initApp());

    if (
      import.meta.env.MODE === 'development' &&
      import.meta.env.VITE_FIREBASE_EMULATOR_URL
    ) {
      connectAuthEmulator(
        auth,
        `http://${import.meta.env.VITE_FIREBASE_EMULATOR_URL}:9099`,
      );
    }

    await setPersistence(auth, browserLocalPersistence);

    onAuthStateChanged(auth, async (user) => {
      if (import.meta.env.MODE === 'development') {
        // eslint-disable-next-line no-console -- only show on dev
        console.log('user state changed', user?.uid);
      }

      await user?.getIdToken(true);

      if (import.meta.env.MODE === 'development') {
        // eslint-disable-next-line no-console -- only show on dev
        console.log('user state changed - getIdToken called', user?.uid);
      }
    });
  }

  return auth;
};

export const clearAuth = () => {
  auth = undefined;
};
