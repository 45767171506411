import styled from 'styled-components';

export const datePickerWrapperClassName = 'date-picker-wrapper-class';

export const Container = styled.div`
  display: inline-block;
  width: 100%;

  .${datePickerWrapperClassName} {
    width: 100%;
  }
`;
