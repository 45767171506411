import { QueryFunction } from '@tanstack/react-query';
import {
  FirestoreError,
  getDocsFromCache,
  onSnapshot,
  Query,
  QuerySnapshot,
} from 'firebase/firestore';
import { getRollbar } from 'shared/services/error-tracking';
import { ID } from 'shared/types/id';

import { queryClient } from './query-client';
import { addSubscription } from './subscriptions';

export const createSubscriptionQuery = <T extends { id: ID }>(
  queries: (() => Promise<Query<T>>)[],
): QueryFunction<T[] | undefined, string[]> => {
  let firstRun = true;
  const combinedDataMap = new Map<ID, T>();
  const queryIdSets: Set<ID>[] = queries.map(() => new Set<ID>());

  return async (context) => {
    const queryRefs = await Promise.all(queries.map((query) => query()));
    return await new Promise(async (resolve, reject) => {
      const onSuccess = (response: QuerySnapshot<T>, queryIndex: number) => {
        const currentIds = new Set<ID>();
        response.docs.forEach((doc) => {
          const data = doc.data();
          combinedDataMap.set(data.id, data);
          currentIds.add(data.id);
        });

        // clean up old entries that are not present in the query-data anymore
        queryIdSets[queryIndex] = currentIds;
        const allIds = queryIdSets.flatMap((set) => Array.from(set));

        combinedDataMap.forEach((_, id) => {
          if (!allIds.includes(id)) {
            combinedDataMap.delete(id);
          }
        });

        // flatten the data map to an array
        const combinedData = Array.from(combinedDataMap.values());

        if (firstRun) {
          // data should be set when all queries are done, so on the first-run we cannot set the data yet.
          return;
        }

        queryClient.setQueryData(context.queryKey, combinedData);
      };

      const onError = (error: FirestoreError) => {
        getRollbar().error('Firestore Query: query request failed', error);

        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line no-console -- showing error only on development, to see what the error is and how to prevent it
          console.log('error', queryRefs, error);
          debugger;
        }
        if (firstRun) {
          reject(error);
          firstRun = false;
          return;
        }

        queryClient.invalidateQueries({ queryKey: context.queryKey });
      };

      try {
        await Promise.all(
          queryRefs.map(async (queryRef, queryIndex) => {
            try {
              // get from cache first
              const cacheData = await getDocsFromCache(queryRef);
              onSuccess(cacheData, queryIndex);
            } catch {
              // do nothing, since it is probably that there is no cache yet
            }

            addSubscription(
              onSnapshot(
                queryRef,
                (snapshotData) => onSuccess(snapshotData, queryIndex),
                onError,
              ),
            );
          }),
        );

        if (firstRun) {
          const combinedData = Array.from(combinedDataMap.values());
          resolve(combinedData);
          firstRun = false;
        }
      } catch (error) {
        if (firstRun) {
          reject(error);
          firstRun = false;
        }
      }
    });
  };
};
