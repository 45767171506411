import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import {
  GoalTemplateFormFields,
  validationSchema,
} from 'shared/types/goal-template-form';

export const useGoalTemplateForm = (
  submit: (goalData: GoalTemplateFormFields) => void,
  initialValues: GoalTemplateFormFields,
) => {
  const { handleSubmit, ...formProps } = useForm<GoalTemplateFormFields>({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues: initialValues,
  });

  const onSubmit = handleSubmit(
    ({ name, description, iconName, goalId, isPublic }) =>
      submit({ name, description, iconName, goalId, isPublic }),
  );

  return {
    ...formProps,
    onSubmit,
    errors: formProps.formState.errors,
    isValid: formProps.formState.isValid,
  };
};
