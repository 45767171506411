import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Bell, X } from 'shared/assets/icons';
import { Locales } from 'shared/assets/localization';
import { Button, Colors, Sizes, Variants } from 'shared/components/ui/button';
import { Icon } from 'shared/components/ui/icon';
import { IconButton } from 'shared/components/ui/icon-button';

import * as Styled from './new-version-message.style';
import { Version } from './types';

export type NewVersionMessageProps = {
  onCancel: () => void;
  onSubmit: () => void;
  version: Version;
};

export const NewVersionMessage: React.FC<NewVersionMessageProps> = ({
  onCancel,
  onSubmit,
  version,
}) => {
  const { t, i18n } = useTranslation();

  const content = version[i18n.language as Locales] ?? version[Locales.EnUS];

  return (
    <Styled.Container>
      <Styled.CloseButtonContainer>
        <IconButton icon={X} onClick={onCancel} />
      </Styled.CloseButtonContainer>
      <Styled.IconContainer>
        <Icon icon={Bell} />
      </Styled.IconContainer>
      <Styled.Body>
        <Styled.Content>
          <Typography variant="h6" component="span">
            {content.title}
          </Typography>
          <Styled.Description>{content.description}</Styled.Description>
        </Styled.Content>

        <Styled.Interactions>
          {!!content['external-link'] && !!content['external-link-label'] && (
            <Button
              variant={Variants.Text}
              color={Colors.Primary}
              size={Sizes.Small}
              as="a"
              // @ts-ignore
              target="_blank"
              href={content['external-link']}
              onClick={onCancel}
            >
              {content['external-link-label']}
            </Button>
          )}
          <Button
            variant={Variants.Contained}
            color={Colors.Primary}
            size={Sizes.Small}
            onClick={onSubmit}
          >
            {t('messages.version-update.buttons.allow.label')}
          </Button>
        </Styled.Interactions>
      </Styled.Body>
    </Styled.Container>
  );
};
