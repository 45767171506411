import { useMutation } from '@tanstack/react-query';
import { updateTodoDaysOrder } from 'shared/services/backend-api';
import { ID } from 'shared/types/id';
import { Timestamp } from 'shared/types/timestamp';

export type DayOrder = {
  date: Timestamp;
  ids: ID[];
};

export const useUpdateTodoDaysOrderMutation = () =>
  useMutation({
    mutationFn: async (datesOrder: DayOrder[]) =>
      updateTodoDaysOrder(datesOrder),
  });
