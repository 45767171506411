import React from 'react';
import { GridContainer } from 'shared/components/ui/grid-container';
import { useTrackPageVisitEvent } from 'shared/hooks/use-track-page-visit-event';
import { PageEvents } from 'shared/services/tracking';

import { ActionPlan } from './components/action-plan';
import { DetailNavigation } from './components/detail-navigation';
import { GoalDetailHeader } from './components/goal-detail-header';
import { Progress } from './components/progress';

export const GoalDetail: React.FC = () => {
  useTrackPageVisitEvent(PageEvents.GoalsDetail, undefined);

  return (
    <GridContainer>
      <DetailNavigation />
      <GoalDetailHeader />
      <Progress />
      <ActionPlan />
    </GridContainer>
  );
};
