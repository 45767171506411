import { Suspense } from 'react';
import * as React from 'react';

import { LoginView } from './components/login-view';

export const Login: React.FC = () => (
  <Suspense>
    <LoginView />
  </Suspense>
);
