import React from 'react';
import { EmptyGoals } from 'shared/components/ui/empty-goals';
import { useOpenCreateGoalDialog } from 'shared/contexts/goal-form';

import * as Styled from './empty-banner.style';

export const EmptyBanner: React.FC = () => {
  const openCreateDialog = useOpenCreateGoalDialog();

  return (
    <Styled.EmptyContainer>
      <EmptyGoals onAdd={openCreateDialog} />
    </Styled.EmptyContainer>
  );
};
