import { useCallback, useEffect, useRef } from 'react';
import { useCreateLifeAreaMutation } from 'shared/hooks/queries/use-create-life-area-mutation';
import { useTrackEvents } from 'shared/hooks/use-track-events';
import { ActionEvents } from 'shared/services/tracking';
import { NewLifeArea } from 'shared/types/life-area';

export const useCreateLifeArea = () => {
  const newLifeAreaRef = useRef<NewLifeArea>();

  const track = useTrackEvents();
  const { mutate, error, isPending, isSuccess, reset } =
    useCreateLifeAreaMutation();

  const submit = useCallback(
    (lifeArea: NewLifeArea) => {
      const newLifeArea = { ...lifeArea, createdAt: new Date() };
      newLifeAreaRef.current = newLifeArea;
      mutate(newLifeArea);
    },
    [mutate],
  );

  const retry = useCallback(() => {
    if (newLifeAreaRef.current) {
      mutate(newLifeAreaRef.current);
    }
  }, [mutate]);

  const resetAll = useCallback(() => {
    reset();
    newLifeAreaRef.current = undefined;
  }, [reset]);

  useEffect(() => {
    if (isSuccess) {
      track(ActionEvents.LifeAreaCreate, undefined);
    }
  }, [isSuccess, track]);

  return {
    submit,
    retry,
    reset: resetAll,
    error,
    isLoading: isPending,
    isSuccess,
  };
};
