import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Locales } from 'shared/assets/localization';
import { Option } from 'shared/components/ui/form-select';

export const useSupportedLanguageOptions = () => {
  const { t } = useTranslation();

  return useMemo<Option[]>(
    () => [
      {
        value: Locales.EnUS,
        label: t('general.labels.languages.en-us'),
      },
      {
        value: Locales.NlNL,
        label: t('general.labels.languages.nl-nl'),
      },
    ],
    [t],
  );
};
