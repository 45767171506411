import { isSameDay } from 'date-fns';
import { Task } from 'shared/types/task';
import { TaskType } from 'shared/types/task-base';
import { Timestamp } from 'shared/types/timestamp';

export const getTaskCompletedState = (
  { type, completedAt, schedules }: Task,
  today: Timestamp,
  showRepeatingAsTodo?: boolean,
) => {
  if (type === TaskType.Task) {
    return !!completedAt;
  }

  if (!showRepeatingAsTodo) {
    return false;
  }

  const activeSchedule = schedules?.find(({ endDate }) => !endDate);
  if (!activeSchedule || completedAt) {
    return true;
  }

  return !!activeSchedule.completions.find((completion) =>
    isSameDay(today, completion),
  );
};
