import { useMutation, useQueryClient } from '@tanstack/react-query';
import { signOutUser } from 'shared/services/backend-api';

export const useLogoutUserMutation = () => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      const result = await signOutUser();
      client.clear();

      return result;
    },
  });
};
