import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';

import * as Styled from './unsplash-image-picker.style';

export const NoImagesFound: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Styled.ErrorContainer>
      <Icon icon={Image} />
      <Typography variant="subtitle2" component="span">
        {t('context-menus.image-source-picker.unsplash.error.no-images-found')}
      </Typography>
    </Styled.ErrorContainer>
  );
};
