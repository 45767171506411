import { httpsCallable } from 'firebase/functions';

import { getFunctions } from './helpers';

export const createStripeSubscriptionManagementUrl = async (
  stripeCustomerId: string,
) => {
  const functions = getFunctions();
  const createManagementURl = httpsCallable<
    { stripeCustomerId: string },
    string
  >(functions, 'stripe-onCreateManagementUrl');

  const { data } = await createManagementURl({ stripeCustomerId });

  return data;
};
