import { useMutation } from '@tanstack/react-query';
import { updateGoalTaskCount } from 'shared/services/backend-api';
import { ID } from 'shared/types/id';

export type Options = {
  goalId: ID;
  completedTaskCount?: number;
  taskCount?: number;
};

export const useUpdateGoalTaskCountMutation = () =>
  useMutation({
    mutationFn: async ({ goalId, completedTaskCount, taskCount }: Options) =>
      updateGoalTaskCount(goalId, { completedTaskCount, taskCount }),
  });
