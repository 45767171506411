import { NewVision } from 'shared/types/vision';

import { dateToFirestoreTimestamp } from './converters/date-to-firestore-timestamp';
import { newVisionConverter } from './converters/vision';
import { addDocumentWithOwnerRole, CollectionOptions } from './helpers';

export const createVision = async (vision: NewVision) => {
  return addDocumentWithOwnerRole(
    CollectionOptions.Visions,
    dateToFirestoreTimestamp(newVisionConverter(vision)),
  );
};
