import { createContext } from 'react';
import { ID } from 'shared/types/id';
import { LifeAreaFormFields } from 'shared/types/life-area-form';

export type CreateLifeAreaContextProps = {
  onCreateLifeArea: (initialValues?: LifeAreaFormFields) => void;
  onEditLifeArea: (initialValues: LifeAreaFormFields, id: ID) => void;
};

export const LifeAreaFormContext = createContext<CreateLifeAreaContextProps>({
  onCreateLifeArea: () => {},
  onEditLifeArea: () => {},
});
