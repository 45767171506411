import { index, Options } from 'shared/assets/styles';
import { typographyToCss } from 'shared/lib/mui';
import styled, { keyframes } from 'styled-components';

const inAnimation = keyframes`
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const Container = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(3)};

  position: fixed;
  bottom: ${({ theme }) => theme.spacing(4)};
  right: ${({ theme }) => theme.spacing(4)};
  z-index: ${index(Options.PUSH_NOTIFICATION_MESSAGE)};

  width: 31.2rem;
  padding: ${({ theme }) => theme.spacing(4)};

  border-radius: ${({ theme }) => theme.spacing(2)};
  border: 1px solid ${({ theme }) => theme.palette.divider};
  box-shadow: ${({ theme }) => theme.shadowBox.menu};
  background: ${({ theme }) => theme.palette.background.default};

  transform: ${({ theme }) => `translateY(${theme.spacing(12)})`};
  opacity: 0;

  animation: ${inAnimation} 200ms 3000ms linear forwards;
`;

export const IconContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 2.4rem;
  width: 2.4rem;
  min-height: 2.4rem;
  height: 2.4rem;

  background: ${({ theme }) => theme.palette.brown['100']};
  border-radius: ${({ theme }) => theme.spacing(2)};

  color: ${({ theme }) => theme.palette.brown['300']};
  font-size: 1.6rem;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};
`;

export const Content = styled.div``;

export const Description = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.overline)};
  display: inline-block;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const Interactions = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  justify-content: flex-end;
`;
