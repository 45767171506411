import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

export const Label = styled.label<{
  $fullWidth: boolean;
  $isDisabled: boolean;
}>`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};

  opacity: 1;
  transition: opacity 200ms;

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      justify-content: space-between;
      width: 100%;
    `};

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `};
`;

export const Handle = styled.span`
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const PremiumLabel = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.overline)};
  display: block;

  padding: ${({ theme }) => `${theme.spacing(0.5)} ${theme.spacing(2)}`};

  background: ${({ theme }) => theme.palette.info.light};
  border-radius: ${({ theme }) => theme.spacing(6)};

  color: ${({ theme }) => theme.palette.info.main};
`;
