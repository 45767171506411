import { useDownloadBucketImageQuery } from 'shared/hooks/queries/use-download-bucket-image-query';
import { useUser } from 'shared/hooks/use-user';

export const useBucketImage = (src?: string) => {
  const user = useUser();
  const { data, isBucketLink } = useDownloadBucketImageQuery(user?.uid, src);

  return {
    src: data,
    isBucketLink,
  };
};
