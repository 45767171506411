import { typographyToCss } from 'shared/lib/mui';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(4)};

  padding: ${({ theme }) => theme.spacing(6)};

  border-radius: ${({ theme }) => theme.spacing(2)};
  border: 1px solid ${({ theme }) => theme.palette.divider};
  box-shadow: ${({ theme }) => theme.shadowBox.card};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};

  text-align: center;
`;

export const ImageContainer = styled.div`
  margin: 0 auto;

  line-height: 62.5%;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const Description = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.body2)};

  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const Interactions = styled.div`
  margin: 0 auto;
`;
