import styled from 'styled-components';

export const SliderContainer = styled.ul`
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  aspect-ratio: 1;

  border-radius: ${({ theme }) => theme.spacing(2)};
  box-shadow: ${({ theme }) => theme.shadowBox.card};
  overflow: hidden;
  list-style: none;
`;

export const Slide = styled.li<{ $shown: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  opacity: ${({ $shown }) => ($shown ? 1 : 0)};
  transition: opacity 1.5s linear;
`;
