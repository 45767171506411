import { typographyToCss } from 'shared/lib/mui';
import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  max-height: 100%;

  overflow: auto;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(0.5)};

  padding: ${({ theme }) => `${theme.spacing(5)} ${theme.spacing(6)}`};
`;

export const Description = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.overline)};

  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  overflow: auto;
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};

  margin: 0;
  padding: 0;

  overflow: auto;
  list-style: none;
`;

export const ListItem = styled.li``;

export const Section = styled.div`
  display: grid;
  grid-template-columns: 13.6rem 1fr auto;
  gap: ${({ theme }) => theme.spacing(2)};

  padding: ${({ theme }) => `0 ${theme.spacing(6)}`};
`;

export const Divider = styled.span`
  display: block;
  margin: ${({ theme }) => `${theme.spacing(4)} ${theme.spacing(6)}`};

  height: 1px;

  background: ${({ theme }) => theme.palette.divider};
`;

export const Interactions = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  justify-content: flex-end;

  padding: ${({ theme }) => `${theme.spacing(5)} ${theme.spacing(6)}`};
`;
