import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(4)};
  align-items: center;

  margin: 0 auto;
  max-width: 29.8rem;

  color: ${({ theme }) => theme.palette.text.secondary};
  text-align: center;
`;

export const IconContainer = styled.span`
  font-size: 20rem;
  line-height: 62.5%;
`;
