import Typography from '@mui/material/Typography';
import React from 'react';

import * as Styled from './section-header-today.style';

export type SectionHeaderTodayProps = {
  title: string;
  titleCount: number;
  actions?: React.ReactNode[];
};

export const SectionHeaderToday: React.FC<SectionHeaderTodayProps> = ({
  title,
  titleCount,
  actions = [],
}) => (
  <Styled.Container>
    <Styled.TitleContainer>
      <Typography variant="h6" component="span">
        {title}
      </Typography>
      {titleCount !== undefined && (
        <Styled.TitleCount>{titleCount}</Styled.TitleCount>
      )}
    </Styled.TitleContainer>

    {!!actions.length && (
      <Styled.ActionsContainer>
        {actions.map((action, index) => (
          <React.Fragment key={`action-${index}`}>{action}</React.Fragment>
        ))}
      </Styled.ActionsContainer>
    )}
  </Styled.Container>
);
