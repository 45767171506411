import { useQuery } from '@tanstack/react-query';
import { createSubscriptionDoc } from 'shared/lib/@tanstack-query';
import { getLifeAreaById } from 'shared/services/backend-api';
import { ID } from 'shared/types/id';

export const queryKey = 'life-area-by-id';

export const useLifeAreaByIdQuery = (uid: ID = '', id: ID = '') =>
  useQuery({
    queryKey: [queryKey, uid, id],
    queryFn: createSubscriptionDoc(() => getLifeAreaById(id)),
    enabled: !!uid && !!id,
  });
