import { useQuery } from '@tanstack/react-query';
import { getGoalTemplateByGoalId } from 'shared/services/backend-api';
import { ID } from 'shared/types/id';

export const queryKey = 'goal-template-by-goal-id';

export const useGoalTemplateByGoalIdQuery = (goalId?: ID) =>
  useQuery({
    queryKey: [queryKey, goalId],
    queryFn: () => (goalId ? getGoalTemplateByGoalId(goalId) : undefined),
    enabled: !!goalId,
  });
