import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Colors, Sizes, Variants } from 'shared/components/ui/button';
import { FormInput } from 'shared/components/ui/form-input';
import { Paths } from 'shared/routes';
import { LoginErrorTypes, LoginFormFields } from 'shared/types/login-form';

import * as Styled from './login-form.style';
import { useLoginForm } from './use-login-form';

export type LoginFormProps = {
  onSubmit: (loginData: LoginFormFields) => void;
  isLoading: boolean;
  error?: LoginErrorTypes;
};

export const LoginForm: React.FC<LoginFormProps> = ({
  onSubmit,
  isLoading,
  error,
}) => {
  const { t } = useTranslation();
  const { register, onSubmit: submitForm, errors } = useLoginForm(onSubmit);

  const emailErrorMessage = useMemo(() => {
    if (errors.email?.message) {
      return t('forms.login.fields.email.error');
    }

    if (error === LoginErrorTypes.WrongCombination) {
      return t('forms.login.error.incorrect-combination.label');
    }
  }, [error, errors.email?.message, t]);

  const passwordErrorMessage = useMemo(() => {
    if (errors.password?.message) {
      return t('forms.login.fields.password.error');
    }

    if (error === LoginErrorTypes.WrongCombination) {
      return t('forms.login.error.incorrect-combination.label');
    }

    return undefined;
  }, [error, errors.password?.message, t]);

  return (
    <Styled.Form noValidate onSubmit={submitForm}>
      <Styled.Fields>
        <Styled.FieldContainer>
          <FormInput
            {...register('email')}
            id="email"
            placeholder={t('forms.login.fields.email.placeholder')}
            error={emailErrorMessage}
            disabled={isLoading}
          />
          {!!emailErrorMessage && (
            <Styled.ErrorMessage>{emailErrorMessage}</Styled.ErrorMessage>
          )}
        </Styled.FieldContainer>

        <Styled.FieldContainer>
          <FormInput
            {...register('password')}
            id="password"
            type="password"
            placeholder={t('forms.login.fields.password.placeholder')}
            error={passwordErrorMessage}
            disabled={isLoading}
          />
          {!!passwordErrorMessage && (
            <Styled.ErrorMessage>{passwordErrorMessage}</Styled.ErrorMessage>
          )}
        </Styled.FieldContainer>
      </Styled.Fields>

      <Styled.Interactions>
        <Styled.Anchor to={Paths.ForgotPassword}>
          {t('forms.login.buttons.forgot-password.label')}
        </Styled.Anchor>

        <Button
          variant={Variants.Contained}
          color={Colors.Primary}
          size={Sizes.Large}
          type="submit"
          disabled={isLoading}
          fullWidth
          alignCenter
          disabledBackgroundOnly
        >
          {isLoading ? (
            <Styled.Loader color="primary" size={20} />
          ) : (
            t('forms.login.buttons.sign-in.label')
          )}
        </Button>
      </Styled.Interactions>

      <Styled.TermsOfUse>
        {t('forms.login.agreement.1')}
        <Styled.ExternalAnchor href="https://griply.app/eula" target="_blank">
          {t('forms.login.agreement.terms-of-use')}
        </Styled.ExternalAnchor>
        {t('forms.login.agreement.2')}
        <Styled.ExternalAnchor
          href="https://griply.app/privacy-policy"
          target="_blank"
        >
          {t('forms.login.agreement.privacy-policy')}
        </Styled.ExternalAnchor>
        {t('forms.login.agreement.3')}
      </Styled.TermsOfUse>
    </Styled.Form>
  );
};
