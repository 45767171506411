import { useMemo } from 'react';
import { useUser } from 'shared/hooks/use-user';
import { GoalTabSortingOptions } from 'shared/types/user-settings';

export const useGoalSorting = (sortingOption: GoalTabSortingOptions) => {
  const user = useUser();

  return useMemo(
    () => user?.settings?.goalTabSorting?.[sortingOption]?.sorting ?? [],
    [user, sortingOption],
  );
};
