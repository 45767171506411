import { useCallback, useRef } from 'react';
import { useCreateGoalTemplateMutation } from 'shared/hooks/queries/use-create-goal-template-mutation';
import { GoalTemplateFormFields } from 'shared/types/goal-template-form';

export const useCreateGoalTemplate = () => {
  const templateRef = useRef<GoalTemplateFormFields>();

  const { mutateAsync, error, isPending, isSuccess, reset } =
    useCreateGoalTemplateMutation();

  const submit = useCallback(
    async (values: GoalTemplateFormFields) => {
      templateRef.current = values;
      mutateAsync({
        name: values.name,
        description: values.description,
        goalIds: [values.goalId],
        iconName: values.iconName,
        isPublic: values.isPublic,
        tags: [],
      });
    },
    [mutateAsync],
  );

  const retry = useCallback(() => {
    if (templateRef.current) {
      mutateAsync({
        name: templateRef.current.name,
        description: templateRef.current.description,
        goalIds: [templateRef.current.goalId],
        iconName: templateRef.current.iconName,
        isPublic: templateRef.current.isPublic,
        tags: [],
      });
    }
  }, [mutateAsync]);

  const resetAll = useCallback(() => {
    reset();
    templateRef.current = undefined;
  }, [reset]);

  return {
    submit,
    retry,
    reset: resetAll,
    error,
    isLoading: isPending,
    isSuccess,
  };
};
