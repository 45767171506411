import { createContext } from 'react';
import { ThemeModes } from 'shared/types/theme-modes';

export type ThemeInfoContextProps = {
  themeMode: ThemeModes;
  activeTheme: ThemeModes.Dark | ThemeModes.Light;
  setThemeMode: (themeMode: ThemeModes) => void;
  resetThemeMode: () => void;
};

export const ThemeInfoContext = createContext<ThemeInfoContextProps>({
  themeMode: ThemeModes.Auto,
  activeTheme: ThemeModes.Light,
  setThemeMode: () => {},
  resetThemeMode: () => {},
});
