import { createContext } from 'react';
import { ID } from 'shared/types/id';

export type CreateGoalTemplateContextProps = {
  onCreateDialog: (goalId: ID) => void;
};

export const CreateGoalTemplateContext =
  createContext<CreateGoalTemplateContextProps>({
    onCreateDialog: () => {},
  });
