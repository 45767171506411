import { Placement } from '@floating-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image as ImageIcon, Target, Unsplash } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import {
  PopupMenu,
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
  PopupMenuUpload,
} from 'shared/components/ui/popup-menu';

import { defaultPosition, Sources } from './types';

export type SourcePickerMenuProps = {
  referenceElement?: React.RefObject<HTMLElement>;
  position?: Placement;
  sources: Sources[];
  onUpload: (image: File) => void;
  onSource: (source: Sources) => void;
  premiumSources?: Sources[];
  onPremium: () => void;
};

export const SourcePickerMenu: React.FC<SourcePickerMenuProps> = ({
  referenceElement,
  position,
  sources = defaultPosition,
  onUpload,
  onSource,
  premiumSources = [],
  onPremium,
}) => {
  const { t } = useTranslation();

  const onIcon = () => onSource(Sources.Icon);
  const onUnsplash = () => onSource(Sources.Unsplash);

  return (
    <PopupMenu referenceElement={referenceElement} position={position}>
      <PopupMenuList>
        {sources.includes(Sources.Icon) && (
          <PopupMenuListItem>
            <PopupMenuButton
              onClick={
                premiumSources.includes(Sources.Icon) ? onPremium : onIcon
              }
              isPremium={premiumSources.includes(Sources.Icon)}
              start={<Icon icon={Target} />}
            >
              {t('context-menus.image-source-picker.options.icon.label')}
            </PopupMenuButton>
          </PopupMenuListItem>
        )}

        {sources.includes(Sources.Upload) && (
          <PopupMenuListItem>
            {premiumSources.includes(Sources.Upload) ? (
              <PopupMenuButton
                onClick={onPremium}
                start={<Icon icon={ImageIcon} />}
                isPremium
              >
                {t('context-menus.image-source-picker.options.upload.label')}
              </PopupMenuButton>
            ) : (
              <PopupMenuUpload onChange={onUpload} />
            )}
          </PopupMenuListItem>
        )}

        {sources.includes(Sources.Unsplash) && (
          <PopupMenuListItem>
            <PopupMenuButton
              onClick={
                premiumSources.includes(Sources.Unsplash)
                  ? onPremium
                  : onUnsplash
              }
              isPremium={premiumSources.includes(Sources.Unsplash)}
              start={<Icon icon={Unsplash} />}
            >
              {t('context-menus.image-source-picker.options.unsplash.label')}
            </PopupMenuButton>
          </PopupMenuListItem>
        )}
      </PopupMenuList>
    </PopupMenu>
  );
};
