import { rgba } from 'polished';
import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

export const Header = styled.header`
  container-type: inline-size;
  padding: ${({ theme }) => `${theme.spacing(3)} ${theme.spacing(6)} 0`};
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(6)};

  ${({ theme }) =>
    `@container (min-width: ${theme.breakpoints.values.xs + 1}px)`} {
    flex-direction: row;
  }
`;

export const IllustrationContainer = styled.span`
  position: relative;
  display: block;
`;

export const ImageContainer = styled.div<{ $hasImage: boolean }>`
  position: relative;
  display: block;
  width: 8.8rem;
  height: 8.8rem;

  border-radius: ${({ theme }) => theme.spacing(3)};
  box-shadow: ${({ theme }) =>
    `0 ${theme.spacing(2)} ${theme.spacing(4)} -${theme.spacing(2)} ${rgba(theme.palette.common.black, 0.3)}`};
  overflow: hidden;

  font-size: 4rem;

  ${({ $hasImage }) =>
    !$hasImage &&
    css`
      width: 4.8rem;
      height: 4.8rem;
      box-shadow: none;
      margin-top: ${({ theme }) => theme.spacing(4)};
    `};
`;

export const ColorDot = styled.span<{ $color: string }>`
  position: absolute;
  bottom: ${({ theme }) => `-${theme.spacing(0.75)}`};
  right: ${({ theme }) => `-${theme.spacing(0.75)}`};

  display: block;
  height: 1.2rem;
  width: 1.2rem;

  border: ${({ theme }) =>
    `${theme.spacing(0.5)} solid ${theme.palette.background.default};`};
  border-radius: 50%;
  background: ${({ $color }) => $color};
`;

export const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(4)};
`;

export const Content = styled.div<{ $completed: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(0.5)};

  padding-top: ${({ theme }) => theme.spacing(3.5)};

  color: ${({ theme, $completed }) =>
    $completed ? theme.palette.text.secondary : 'inherit'};
`;

export const MetaContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(4)};
`;

export const Deadline = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.overline)};
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};

  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const IconContainer = styled.span`
  font-size: 1.2rem;
  line-height: 62.5%;
`;

export const LifeArea = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.overline)};
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};

  color: ${({ theme }) => theme.palette.text.secondary};
`;
