import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword as authUpdatePassword,
} from 'firebase/auth';
import { ChangePasswordErrorTypes } from 'shared/types/change-password-form';

import { getAuthentication } from './helpers';

export const updatePassword = async (
  oldPassword: string,
  newPassword: string,
) => {
  const auth = await getAuthentication();

  if (!auth.currentUser?.email) {
    return;
  }

  const credentials = EmailAuthProvider.credential(
    auth.currentUser.email,
    oldPassword,
  );

  try {
    await reauthenticateWithCredential(auth.currentUser, credentials);
    await authUpdatePassword(auth.currentUser, newPassword);
  } catch (e) {
    throw ChangePasswordErrorTypes.Unknown;
  }
};
