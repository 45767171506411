import { useGoals } from 'features/goals';
import { useLifeAreaById } from 'features/life-areas';
import { useTasksByLifeAreaId } from 'features/tasks';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useToday } from 'shared/contexts/today';
import { useUser } from 'shared/hooks/use-user';
import { ID } from 'shared/types/id';
import { initialWeekStartsOn } from 'shared/types/week-days';
import { mapTaskToTaskCard } from 'shared/utils/mappers/map-task-to-task-card';
import { sortItems } from 'shared/utils/sort-items';

export const useLifeAreaTasks = (id?: ID) => {
  const { t } = useTranslation();
  const today = useToday();
  const user = useUser();

  const lifeArea = useLifeAreaById(id);
  const tasks = useTasksByLifeAreaId(id, { uncompletedOnly: true });
  const goals = useGoals();

  const taskCardTasks = useMemo(
    () =>
      sortItems(
        tasks.map((task) =>
          mapTaskToTaskCard(task, {
            today,
            goals,
            t,
            showDue: true,
            hideTodayDue: true,
            weekStartsOn: user?.settings?.startOfWeek ?? initialWeekStartsOn,
          }),
        ),
        lifeArea?.taskSorting ?? [],
        'id',
      ),
    [
      goals,
      lifeArea?.taskSorting,
      t,
      tasks,
      today,
      user?.settings?.startOfWeek,
    ],
  );

  return {
    taskCardTasks,
  };
};
