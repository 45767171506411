import { useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import { useCallback, useRef } from 'react';
import { useUpdateGoalMutation } from 'shared/hooks/queries/use-update-goal-mutation';
import { useTrackEvents } from 'shared/hooks/use-track-events';
import {
  ActionEvents,
  goalMetricIdToTrackingMetricTemplate,
  lifeAreaToTrackingLifeAreaType,
} from 'shared/services/tracking';
import { Goal } from 'shared/types/goal';

export const useUpdateGoal = () => {
  const updateGoalRef = useRef<Partial<Goal>>();
  const goals = useGoals();
  const lifeAreas = useLocalizedLifeAreas();

  const track = useTrackEvents();
  const { mutate, error, isPending, isSuccess, reset } =
    useUpdateGoalMutation();

  const submit = useCallback(
    (goal: Partial<Goal>) => {
      const originalGoal = goals.find(({ id }) => goal.id === id);
      const lifeArea = lifeAreas.find(
        ({ id }) =>
          id ===
          ('lifeAreaId' in goal ? goal.lifeAreaId : originalGoal?.lifeAreaId),
      );

      const isSubGoal =
        'parentIds' in goal
          ? !!goal.parentIds?.length
          : !!originalGoal?.parentIds?.length;
      track(isSubGoal ? ActionEvents.SubGoalEdit : ActionEvents.GoalEdit, {
        life_area: lifeAreaToTrackingLifeAreaType(lifeArea),
        image_added: 'image' in goal ? !!goal.image : !!originalGoal?.image,
        image_source:
          'image' in goal
            ? goal.image?.metadata?.source === 'unsplash'
              ? 'unsplash'
              : 'custom'
            : originalGoal?.image?.metadata?.source === 'unsplash'
              ? 'unsplash'
              : 'custom',
        deadline:
          'deadline' in goal ? !!goal.deadline : !!originalGoal?.deadline,
        target: goalMetricIdToTrackingMetricTemplate(
          'metric' in goal ? goal.metric?.id : originalGoal?.metric?.id,
        ),
      });
      updateGoalRef.current = goal;
      mutate({ goal });
    },
    [goals, lifeAreas, mutate, track],
  );

  const retry = useCallback(() => {
    if (updateGoalRef.current) {
      mutate({ goal: updateGoalRef.current });
    }
  }, [mutate]);

  const resetAll = useCallback(() => {
    reset();
    updateGoalRef.current = undefined;
  }, [reset]);

  return {
    submit,
    retry,
    reset: resetAll,
    error,
    isLoading: isPending,
    isSuccess,
  };
};
