import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Locales } from 'shared/assets/localization';
import { signInUser, signupUser } from 'shared/services/backend-api';
import { ID } from 'shared/types/id';
import { SignupErrorTypes } from 'shared/types/signup-form';

import { queryKey as useUserKey } from './use-user-query';

export const useSignupUserMutation = () => {
  const client = useQueryClient();
  return useMutation<
    ID,
    SignupErrorTypes,
    {
      name?: string;
      email: string;
      password: string;
      locale?: Locales;
    }
  >({
    mutationFn: async ({ name, email, password, locale }) => {
      let result: string;
      try {
        result = await signupUser(email, password, name, locale);
      } catch (signupError) {
        if (signupError !== SignupErrorTypes.EmailTaken) {
          throw signupError;
        }

        // if user already exists with email, try signing in
        try {
          const user = await signInUser(email, password);
          result = user?.uid ?? '';
        } catch (signInError) {
          // if login gives an error, we need to throw the emailTaken error
          throw signupError;
        }
      }

      await client.invalidateQueries({ queryKey: [useUserKey] });

      return result;
    },
  });
};
