import { HabitSchedule } from 'shared/types/habit-schedule';
import { ID } from 'shared/types/id';
import { Task } from 'shared/types/task';
import { TaskPriorityOptions, TaskType } from 'shared/types/task-base';
import { Timestamp } from 'shared/types/timestamp';

export enum CheckedTaskState {
  Checked = 'checked',
  Unchecked = 'unchecked',
  RepeatSingleChecked = 'repeat-single-checked',
  RepeatChecked = 'repeat-checked',
}

export type TaskCardTaskFields = {
  name: string;
  type: TaskType;
  schedules?: HabitSchedule[];
  endStrategy: {
    deadline?: Timestamp;
    completionCount?: number;
  };
  reminderTime?: number;
  priority?: TaskPriorityOptions | null;
  goalId?: ID;
};

export type TaskCardTask = {
  id: ID;
  checked: CheckedTaskState;
  strikeThrough: boolean;
  meta: {
    due?: string;
    overdue?: boolean;
    dueToday?: boolean;
    schedule?: string;
    reminderTime?: string;
    goal?: string;
  };
  raw: {
    dueDate: Timestamp | undefined;
    task: Task;
  };
  fields: TaskCardTaskFields;
  isFrozen: boolean;
};

export type TaskCardTaskChanges = Partial<TaskCardTaskFields> & { id: ID };
