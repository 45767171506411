import { Components, Theme } from '@mui/material';

export enum DialogVariantOptions {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export const MuiDialog: Components<Omit<Theme, 'components'>>['MuiDialog'] = {
  styleOverrides: {
    paper: ({ theme }) => ({
      maxWidth: 464,
      borderRadius: 8,
      boxShadow: theme.shadowBox.dialog,
      background: theme.palette.background.default,
      margin: 16,
      width: `calc(100% - (${theme.spacing(4)} * 2))`,
    }),
  },
  variants: [
    {
      props: { variant: DialogVariantOptions.Medium },
      style: {
        '& .MuiDialog-paper': {
          maxWidth: 640,
        },
      },
    },
    {
      props: { variant: DialogVariantOptions.Large },
      style: {
        '& .MuiDialog-paper': {
          maxWidth: 900,
        },
      },
    },
  ],
};

declare module '@mui/material/Dialog' {
  interface DialogProps {
    variant?: DialogVariantOptions;
  }
}
