import Typography from '@mui/material/Typography';
import { useUserHadTrial } from 'features/user';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, RocketPremiumButton } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { IconButton, Sizes } from 'shared/components/ui/icon-button';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { useElectron } from 'shared/hooks/use-is-mac';
import { useUser } from 'shared/hooks/use-user';
import { Entitlements } from 'shared/types/entitlements';

import * as Styled from './top-bar.style';

type AppBarProps = {
  onMenu: () => void;
  displayMenu?: boolean;
  onMobileMenu: () => void;
};

export const TopBar: React.FC<AppBarProps> = ({
  onMenu,
  displayMenu,
  onMobileMenu,
}) => {
  const { t } = useTranslation();
  const user = useUser();
  const userHadTrial = useUserHadTrial();
  const openPremium = useOpenPremiumDialog();
  const { isMac } = useElectron();

  return (
    <Styled.Container $isElectronMac={isMac} $menuDisplayed={!!displayMenu}>
      <Styled.MobileIconButton>
        <IconButton
          aria-label={t('general.menu.labels.open')}
          onClick={onMobileMenu}
          size={Sizes.Large}
          icon={Menu}
          preventDrag={isMac}
        />
      </Styled.MobileIconButton>

      {displayMenu && (
        <Styled.DesktopIconButton>
          <IconButton
            aria-label={t('general.menu.labels.open')}
            onClick={onMenu}
            size={Sizes.Large}
            icon={Menu}
            preventDrag={isMac}
          />
        </Styled.DesktopIconButton>
      )}

      {!user?.entitlements?.includes(Entitlements.Premium) && (
        <Styled.PremiumButton onClick={openPremium} $isElectronMac={isMac}>
          <Icon icon={RocketPremiumButton} />
          <Typography variant="caption" component="span">
            {t(
              userHadTrial
                ? 'general.labels.premium.upgrade'
                : 'general.labels.premium.free',
            )}
          </Typography>
        </Styled.PremiumButton>
      )}
    </Styled.Container>
  );
};
