import { useCallback } from 'react';
import { useOpenDeleteHabitDialog } from 'shared/contexts/delete-habit';
import { useOpenTaskDetail } from 'shared/contexts/task-detail';
import { ID } from 'shared/types/id';
import { Task } from 'shared/types/task';
import { TaskType } from 'shared/types/task-base';

import { useCompleteHabitDate } from './use-complete-habit-date';
import { useCompleteTask } from './use-complete-task';
import { useDeleteTask } from './use-delete-task';
import { useUpdateTask } from './use-update-task';

type Options = {
  parentTaskId?: ID;
};

export const useTaskControls = ({ parentTaskId }: Options = {}) => {
  const { submit: onUpdate } = useUpdateTask();
  const completeTask = useCompleteTask();
  const completeHabit = useCompleteHabitDate();
  const { submit: deleteTask } = useDeleteTask(parentTaskId);

  const deleteHabit = useOpenDeleteHabitDialog();
  const openTaskDetail = useOpenTaskDetail();

  const onComplete = useCallback(
    (task: Task) => {
      task.type === TaskType.Task ? completeTask(task) : completeHabit(task);
      !task.parentIds?.length && openTaskDetail(undefined);
    },
    [completeHabit, completeTask, openTaskDetail],
  );

  const onDelete = useCallback(
    (task: Task) => {
      task.type === TaskType.Task ? deleteTask(task) : deleteHabit(task);
    },
    [deleteHabit, deleteTask],
  );

  return {
    onUpdate,
    onDelete,
    onComplete,
  };
};
