import { CssBaseline } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { darkTheme, GlobalStyles, lightTheme } from 'shared/assets/styles';
import { ThemeModes } from 'shared/types/theme-modes';
import { ThemeProvider as SCThemeProvider } from 'styled-components';

import { ThemeInfoContext, ThemeInfoContextProps } from './theme-info-context';
import { useBrowserPrefersDarkMode } from './use-browser-prefers-dark-mode';

export type ThemeProviderProps = {
  children: React.ReactNode;
};

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const [themeMode, setThemeMode] = useState(ThemeModes.Auto);
  const prefersDarkMode = useBrowserPrefersDarkMode();
  const activeThemeMode = useMemo<ThemeModes.Dark | ThemeModes.Light>(() => {
    if (themeMode === ThemeModes.Auto) {
      return prefersDarkMode ? ThemeModes.Dark : ThemeModes.Light;
    }

    return themeMode;
  }, [prefersDarkMode, themeMode]);

  const theme = useMemo(() => {
    switch (themeMode) {
      case ThemeModes.Auto:
        return prefersDarkMode ? darkTheme : lightTheme;
      case ThemeModes.Dark:
        return darkTheme;
      default:
        return lightTheme;
    }
  }, [prefersDarkMode, themeMode]);

  const value = useMemo<ThemeInfoContextProps>(
    () => ({
      themeMode,
      activeTheme: activeThemeMode,
      setThemeMode,
      resetThemeMode: () => setThemeMode(ThemeModes.Auto),
    }),
    [activeThemeMode, themeMode],
  );

  return (
    <ThemeInfoContext.Provider value={value}>
      <SCThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        <GlobalStyles />
        {children}
      </SCThemeProvider>
    </ThemeInfoContext.Provider>
  );
};
