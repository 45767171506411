import { signOut } from 'firebase/auth';

import { deletePushToken } from './delete-push-token';
import {
  clearApp,
  getAuthentication,
  getPushNotificationToken,
} from './helpers';

export const signOutUser = async () => {
  const auth = await getAuthentication();
  if (auth.currentUser) {
    try {
      const token = getPushNotificationToken(auth.currentUser.uid);
      await deletePushToken(token);
    } catch (e) {
      // nothing to do, if a token can't be deleted it probably means that it doesn't exist
    }
  }

  await signOut(auth);

  // clear database
  await clearApp();
};
