import { useQuery } from '@tanstack/react-query';
import { getGoalMetricTemplates } from 'shared/services/backend-api';

export const queryKey = 'remote-app-config';

export const useRemoteAppConfigQuery = () =>
  useQuery({
    queryKey: [queryKey],
    queryFn: () => getGoalMetricTemplates(),
  });
