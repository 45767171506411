import React, { useMemo, useState } from 'react';
import { TaskCard } from 'shared/components/connected/task-card';
import {
  GoalTaskSection,
  SortableGoalTaskSection,
} from 'shared/components/ui/goal-task-section';
import { SortableContainer } from 'shared/components/ui/sortable-container';
import { DraggableOptions } from 'shared/types/draggable-types';
import { Goal } from 'shared/types/goal';
import { ID } from 'shared/types/id';
import { LifeArea } from 'shared/types/life-area';
import { TaskCardTask } from 'shared/types/task-card-task';
import { getCompletionPercentage } from 'shared/utils/get-completion-percentage';
import { getGoalColor } from 'shared/utils/get-goal-color';
import { getGoalCompletion } from 'shared/utils/get-goal-completion';

export type TaskGoalItemProps = {
  item: Goal | TaskCardTask;
  onTask?: (id?: ID) => void;
  onUnfocus?: (id: ID) => void;
  selectedItem?: ID;
  hideGoal?: boolean;
  showRepeatingAsTodo?: boolean;

  /** All goals */
  goals: Goal[];
  /** All sub-items, not just those of the main goal */
  items: (Goal | TaskCardTask)[];
  lifeAreas: LifeArea[];
  favorites: ID[];
  onEditGoal?: (id: ID) => void;
  onUpdateGoal?: (goal: Partial<Goal>) => void;
  onUpdateGoalProgress: (id: ID) => void;
  onCompleteGoal?: (id: ID) => void;
  onDeleteGoal?: (id: ID) => void;
  onArchiveGoal?: (id: ID) => void;
  onFavoriteGoal?: (id: ID) => void;
  onPremium: () => void;
  imagePlaceholderIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  canCollapse?: boolean;
  initialOpen?: boolean;
  isDragging?: boolean;
  isMoving?: boolean;
  canCreateTask?: boolean;
  isSortingDisabled?: boolean;
  hasDnd?: boolean;
};

export const TaskGoalItem: React.FC<TaskGoalItemProps> = ({
  item,
  onTask,
  onUnfocus,
  selectedItem,
  hideGoal,
  showRepeatingAsTodo,

  items,
  goals,
  favorites,
  lifeAreas,
  onEditGoal,
  onUpdateGoal,
  onUpdateGoalProgress,
  onCompleteGoal,
  onDeleteGoal,
  onArchiveGoal,
  onFavoriteGoal,
  onPremium,
  imagePlaceholderIcon,
  canCollapse,
  initialOpen,
  isDragging,
  isMoving,
  canCreateTask,
  isSortingDisabled,
  hasDnd,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const TaskContainer = hasDnd ? SortableContainer : React.Fragment;
  const taskContainerProps = hasDnd
    ? {
        id: item.id,
        type: DraggableOptions.Task,
        isEditing,
        isDisabled: isSortingDisabled,
      }
    : {};

  const GoalComponent = hasDnd ? SortableGoalTaskSection : GoalTaskSection;

  const completionPercentage = useMemo(() => {
    if ('fields' in item) {
      return 0;
    }

    return getCompletionPercentage(
      getGoalCompletion(item, {
        goals,
      }),
    );
  }, [goals, item]);

  const goalColor = useMemo(() => {
    if ('fields' in item) {
      return;
    }

    return getGoalColor(item.id, { goals, lifeAreas }) ?? undefined;
  }, [goals, item, lifeAreas]);

  return 'fields' in item ? (
    // @ts-ignore
    <TaskContainer {...taskContainerProps}>
      <TaskCard
        task={item.raw.task}
        onUnfocus={onUnfocus}
        onClick={onTask}
        isSelected={selectedItem === item.id}
        hideGoal={hideGoal}
        showRepeatingAsTodo={showRepeatingAsTodo}
        onEditChange={setIsEditing}
      />
    </TaskContainer>
  ) : (
    <GoalComponent
      id={item.id}
      name={item.name}
      color={goalColor}
      progress={completionPercentage}
      isCompleted={!!item.completedAt}
      isArchived={!!item.archivedAt}
      iconName={item.iconName}
      image={item.image}
      deadline={item.deadline}
      parentIds={item.parentIds ?? undefined}
      metric={item.metric}
      isFrozen={!!item.frozenAt}
      isFavorite={favorites.includes(item.id)}
      imagePlaceholderIcon={imagePlaceholderIcon}
      items={items}
      goals={goals}
      favorites={favorites}
      lifeAreas={lifeAreas}
      onTask={onTask}
      onCompleteGoal={onCompleteGoal}
      onUnfocus={onUnfocus}
      hideGoal={hideGoal}
      onEditGoal={onEditGoal}
      onUpdateGoal={onUpdateGoal}
      onUpdateGoalProgress={onUpdateGoalProgress}
      onArchiveGoal={onArchiveGoal}
      onDeleteGoal={onDeleteGoal}
      onFavoriteGoal={onFavoriteGoal}
      onPremium={onPremium}
      selectedItem={selectedItem}
      canCollapse={canCollapse}
      initialOpen={initialOpen}
      isDragging={isDragging}
      isMoving={isMoving}
      canCreateTask={canCreateTask}
      hasDnd={hasDnd}
      isDisabled={isSortingDisabled}
    />
  );
};
