import { useMemo } from 'react';
import { useToday } from 'shared/contexts/today';
import { useTasksQuery } from 'shared/hooks/queries/use-tasks-query';
import { useUser } from 'shared/hooks/use-user';

export const useTodaysCompletedTasks = () => {
  const today = useToday();
  const user = useUser();

  const { data } = useTasksQuery(user?.uid, {
    completedAt: today,
  });

  return useMemo(
    () => data?.filter(({ parentIds }) => !parentIds?.length) ?? [],
    [data],
  );
};
