import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import {
  ForgotPasswordFormFields,
  validationSchema,
} from 'shared/types/forgot-password-form';

export const useForgotPasswordForm = (
  submit: (forgotPasswordData: ForgotPasswordFormFields) => void,
) => {
  const { handleSubmit, ...formProps } = useForm<ForgotPasswordFormFields>({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
  });

  const onSubmit = handleSubmit((data) => submit(data));

  return {
    ...formProps,
    onSubmit,
    errors: formProps.formState.errors,
    isValid: formProps.formState.isValid,
  };
};
