import {
  DocumentReference,
  serverTimestamp,
  setDoc,
  WithFieldValue,
} from 'firebase/firestore';

export const setDocWithTimestamp = <Data extends {}>(
  ref: DocumentReference,
  data: WithFieldValue<Data>,
) => setDoc(ref, { ...data, updatedAt: serverTimestamp() });
