import { useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import { useHabits, useInboxTasks, useRepeatingTasks } from 'features/tasks';
import { useUpdateInboxTaskOrder } from 'features/user';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SelfSortingTaskSection } from 'shared/components/ui/task-section';
import { useActiveTaskId } from 'shared/contexts/task-detail';
import { useToday } from 'shared/contexts/today';
import { useUser } from 'shared/hooks/use-user';
import { TaskType } from 'shared/types/task-base';
import { initialWeekStartsOn } from 'shared/types/week-days';
import { mapTaskToTaskCard } from 'shared/utils/mappers/map-task-to-task-card';
import { orderTaskCardTasksByDate } from 'shared/utils/order-task-card-tasks-by-date';

export const TasksList: React.FC = () => {
  const { t } = useTranslation();
  const user = useUser();
  const today = useToday();
  const selectedTask = useActiveTaskId();

  const goals = useGoals();
  const lifeAreas = useLocalizedLifeAreas();
  const todos = useInboxTasks();
  const repeatingTasks = useRepeatingTasks({
    noGoalsOnly: true,
    uncompletedOnly: true,
    unArchivedOnly: true,
    unFrozenOnly: true,
  });
  const habits = useHabits({
    noGoalsOnly: true,
    uncompletedOnly: true,
    unArchivedOnly: true,
    unFrozenOnly: true,
  });

  const { updateOrder } = useUpdateInboxTaskOrder();

  const tasks = useMemo(
    () =>
      [...todos, ...habits, ...repeatingTasks].filter(
        (task) => task.type !== TaskType.Habit || !task.hideOnTodo,
      ),
    [habits, repeatingTasks, todos],
  );

  const taskCardTasks = useMemo(() => {
    const mappedTasks = tasks.map((task) =>
      mapTaskToTaskCard(task, {
        goals,
        today,
        t,
        showDue: true,
        weekStartsOn: user?.settings?.startOfWeek ?? initialWeekStartsOn,
      }),
    );

    return orderTaskCardTasksByDate(mappedTasks, {
      null: user?.settings?.inboxSorting ?? [],
    });
  }, [
    goals,
    t,
    tasks,
    today,
    user?.settings?.inboxSorting,
    user?.settings?.startOfWeek,
  ]);

  return (
    <SelfSortingTaskSection
      tasks={taskCardTasks}
      goals={goals}
      lifeAreas={lifeAreas}
      today={today}
      onReorder={updateOrder}
      selectedTask={selectedTask}
      canCreateTask
    />
  );
};
