import { doc } from 'firebase/firestore';
import { ID } from 'shared/types/id';

import { getUser, getUserRef } from './get-user';
import {
  CollectionOptions,
  getDatabase,
  updateDocWithTimestamp,
} from './helpers';

export type LifeAreaOrder = {
  lifeAreaId: ID;
  ids: ID[];
};

export const updateLifeAreaGoalOrder = async (
  lifeAreaOrder: LifeAreaOrder[],
) => {
  const user = await getUser();
  // without a user we cannot update anything.
  if (!user) {
    return;
  }

  const userRef = await getUserRef();

  if (!userRef) {
    return;
  }

  lifeAreaOrder.forEach(({ lifeAreaId, ids }) => {
    const lifeAreaRef = doc(
      getDatabase(),
      CollectionOptions.LifeAreas,
      lifeAreaId,
    );
    updateDocWithTimestamp(lifeAreaRef, {
      goalSorting: ids.length ? ids : null,
    });
  });
};
