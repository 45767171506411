import Typography from '@mui/material/Typography';
import {
  useOverdueHabits,
  useOverdueTasks,
  useTodaysHabits,
  useTodaysTasks,
} from 'features/tasks';
import React, { useMemo } from 'react';

import * as Styled from './today-count.style';

export const LoadingTodayCount: React.FC = () => <Styled.Container $loading />;

export const TodayCount: React.FC = () => {
  const todos = useTodaysTasks();
  const habits = useTodaysHabits();
  const overdueTodos = useOverdueTasks();
  const overdueHabits = useOverdueHabits();
  const tasks = useMemo(
    () => [...overdueTodos, ...overdueHabits, ...todos, ...habits],
    [overdueTodos, overdueHabits, todos, habits],
  );

  return !!tasks.length ? (
    <Styled.Container>
      <Typography variant="overline" component="span">
        {tasks.length}
      </Typography>
    </Styled.Container>
  ) : null;
};
