import React from 'react';

import * as UserSelectIcons from './user-select';

export enum IconFormNames {
  ThreeDGlasses = 'user-select-3d-glasses',
  AiChip = 'user-select-ai-chip',
  Apple = 'user-select-apple',
  AsianFood = 'user-select-asian-food',
  Baby = 'user-select-baby',
  Backpack = 'user-select-backpack',
  Bed = 'user-select-bed',
  Bicycle = 'user-select-bicycle',
  Bitcoin = 'user-select-bitcoin',
  Boat = 'user-select-boat',
  Bus = 'user-select-bus',
  Cake = 'user-select-cake',
  Camera = 'user-select-camera',
  Campfire = 'user-select-camp-fire',
  Car = 'user-select-car',
  Cat = 'user-select-cat',
  ChampagneGlasses = 'user-select-champagne-glasses',
  Chart = 'user-select-chart',
  ChefHat = 'user-select-chef-hat',
  ColorPalette = 'user-select-color-palette',
  Computer = 'user-select-computer',
  Croissant = 'user-select-croissant',
  Dog = 'user-select-dog',
  EducationAndLearning = 'user-select-education-and-learning',
  Eggplant = 'user-select-eggplant',
  FamilyAndFriends = 'user-select-family-and-friends',
  Fireworks = 'user-select-fireworks',
  Fish = 'user-select-fish',
  ForkKnife = 'user-select-fork-knife',
  FunAndRelaxation = 'user-select-fun-and-relaxation',
  GameCardsDiamond = 'user-select-game-cards-diamond',
  GameController = 'user-select-game-controller',
  GiftBox = 'user-select-gift-box',
  Glasses = 'user-select-glasses',
  Globe = 'user-select-globe',
  GraduateHat = 'user-select-graduate-hat',
  GroupPeople = 'user-select-group-people',
  Hammer = 'user-select-hammer',
  HatMagician = 'user-select-hat-magician',
  Headphones = 'user-select-headphones',
  LabTube = 'user-select-lab-tube',
  LaptopCode = 'user-select-laptop-code',
  LargeBuilding = 'user-select-large-building',
  Leaf = 'user-select-leaf',
  LoveAndRelationships = 'user-select-love-and-relationships',
  Map = 'user-select-map',
  Meat = 'user-select-meat',
  Microphone = 'user-select-microphone',
  MoneyAndFinance = 'user-select-money-and-finance',
  Movies = 'user-select-movies',
  MusicNote = 'user-select-music-note',
  Package = 'user-select-package',
  Pen = 'user-select-pen',
  PersonalDevelopment = 'user-select-personal-development',
  Phone = 'user-select-phone',
  PlaceholderIcon = 'user-select-placeholder-icon',
  Plane = 'user-select-plane',
  Repeat = 'user-select-repeat',
  RewardCup = 'user-select-reward-cup',
  Rocket = 'user-select-rocket',
  ServingDome = 'user-select-serving-dome',
  ShoppingBag = 'user-select-shopping-bag',
  ShoppingCart = 'user-select-shopping-cart',
  Snow = 'user-select-snow',
  Spirituality = 'user-select-spirituality',
  SportAmericanFootballHelmet = 'user-select-sport-american-football-helmet',
  SportAndHealth = 'user-select-sport-and-health',
  SportBaseball = 'user-select-sport-baseball',
  SportBasketball = 'user-select-sport-basketball',
  SportDumbbell = 'user-select-sport-dumbbell',
  SportFitnessBiceps = 'user-select-sport-fitness-biceps',
  SportGolf = 'user-select-sport-golf',
  SportHockey = 'user-select-sport-hockey',
  SportJumpingRope = 'user-select-sport-jumping-rope',
  SportMartialArtsSwordFencing = 'user-select-sport-martial-arts-sword-fencing',
  SportSkatingShoes = 'user-select-sport-skating-shoes',
  SportSlimWaist = 'user-select-sport-slim-waist',
  SportSneakers = 'user-select-sport-sneakers',
  SportSnowboard = 'user-select-sport-snowboard',
  SportTennisRacquet = 'user-select-sport-tennis-racquet',
  Stars = 'user-select-stars',
  Stethoscope = 'user-select-stethoscope',
  Store = 'user-select-store',
  Sun = 'user-select-sun',
  Sunrise = 'user-select-sunrise',
  Television = 'user-select-television',
  Train = 'user-select-train',
  TravelBag = 'user-select-travel-bag',
  Wallet = 'user-select-wallet',
  WorkAndCareer = 'user-select-work-and-career',
}

export const IconFormIcons: Record<
  IconFormNames,
  React.FunctionComponent<React.SVGProps<SVGSVGElement>>
> = {
  [IconFormNames.PlaceholderIcon]: UserSelectIcons.UserSelectPlaceholderIcon,
  [IconFormNames.WorkAndCareer]: UserSelectIcons.UserSelectWorkAndCareer,
  [IconFormNames.MoneyAndFinance]: UserSelectIcons.UserSelectMoneyAndFinance,
  [IconFormNames.SportAndHealth]: UserSelectIcons.UserSelectSportAndHealth,
  [IconFormNames.EducationAndLearning]:
    UserSelectIcons.UserSelectEducationAndLearning,
  [IconFormNames.LoveAndRelationships]:
    UserSelectIcons.UserSelectLoveAndRelationships,
  [IconFormNames.PersonalDevelopment]:
    UserSelectIcons.UserSelectPersonalDevelopment,
  [IconFormNames.FamilyAndFriends]: UserSelectIcons.UserSelectFamilyAndFriends,
  [IconFormNames.FunAndRelaxation]: UserSelectIcons.UserSelectFunAndRelaxation,
  [IconFormNames.Spirituality]: UserSelectIcons.UserSelectSpirituality,
  [IconFormNames.SportHockey]: UserSelectIcons.UserSelectSportHockey,
  [IconFormNames.SportGolf]: UserSelectIcons.UserSelectSportGolf,
  [IconFormNames.SportBasketball]: UserSelectIcons.UserSelectSportBasketball,
  [IconFormNames.SportBaseball]: UserSelectIcons.UserSelectSportBaseball,
  [IconFormNames.SportDumbbell]: UserSelectIcons.UserSelectSportDumbbell,
  [IconFormNames.SportJumpingRope]: UserSelectIcons.UserSelectSportJumpingRope,
  [IconFormNames.SportFitnessBiceps]:
    UserSelectIcons.UserSelectSportFitnessBiceps,
  [IconFormNames.SportSneakers]: UserSelectIcons.UserSelectSportSneakers,
  [IconFormNames.SportSlimWaist]: UserSelectIcons.UserSelectSportSlimWaist,
  [IconFormNames.SportAmericanFootballHelmet]:
    UserSelectIcons.UserSelectSportAmericanFootballHelmet,
  [IconFormNames.SportMartialArtsSwordFencing]:
    UserSelectIcons.UserSelectSportMartialArtsSwordFencing,
  [IconFormNames.SportSkatingShoes]:
    UserSelectIcons.UserSelectSportSkatingShoes,
  [IconFormNames.SportTennisRacquet]:
    UserSelectIcons.UserSelectSportTennisRacquet,
  [IconFormNames.SportSnowboard]: UserSelectIcons.UserSelectSportSnowboard,
  [IconFormNames.GameController]: UserSelectIcons.UserSelectGameController,
  [IconFormNames.Phone]: UserSelectIcons.UserSelectPhone,
  [IconFormNames.Computer]: UserSelectIcons.UserSelectComputer,
  [IconFormNames.Headphones]: UserSelectIcons.UserSelectHeadphones,
  [IconFormNames.ThreeDGlasses]: UserSelectIcons.UserSelect3DGlasses,
  [IconFormNames.Television]: UserSelectIcons.UserSelectTelevision,
  [IconFormNames.Movies]: UserSelectIcons.UserSelectMovies,
  [IconFormNames.MusicNote]: UserSelectIcons.UserSelectMusicNote,
  [IconFormNames.Bitcoin]: UserSelectIcons.UserSelectBitcoin,
  [IconFormNames.Wallet]: UserSelectIcons.UserSelectWallet,
  [IconFormNames.ColorPalette]: UserSelectIcons.UserSelectColorPalette,
  [IconFormNames.GameCardsDiamond]: UserSelectIcons.UserSelectGameCardsDiamond,
  [IconFormNames.HatMagician]: UserSelectIcons.UserSelectHatMagician,
  [IconFormNames.Pen]: UserSelectIcons.UserSelectPen,
  [IconFormNames.ChefHat]: UserSelectIcons.UserSelectChefHat,
  [IconFormNames.Camera]: UserSelectIcons.UserSelectCamera,
  [IconFormNames.Hammer]: UserSelectIcons.UserSelectHammer,
  [IconFormNames.Campfire]: UserSelectIcons.UserSelectCampFire,
  [IconFormNames.Microphone]: UserSelectIcons.UserSelectMicrophone,
  [IconFormNames.Glasses]: UserSelectIcons.UserSelectGlasses,
  [IconFormNames.Backpack]: UserSelectIcons.UserSelectBackpack,
  [IconFormNames.GraduateHat]: UserSelectIcons.UserSelectGraduateHat,
  [IconFormNames.ShoppingCart]: UserSelectIcons.UserSelectShoppingCart,
  [IconFormNames.ShoppingBag]: UserSelectIcons.UserSelectShoppingBag,
  [IconFormNames.Map]: UserSelectIcons.UserSelectMap,
  [IconFormNames.Sunrise]: UserSelectIcons.UserSelectSunrise,
  [IconFormNames.TravelBag]: UserSelectIcons.UserSelectTravelBag,
  [IconFormNames.Globe]: UserSelectIcons.UserSelectGlobe,
  [IconFormNames.Baby]: UserSelectIcons.UserSelectBaby,
  [IconFormNames.GroupPeople]: UserSelectIcons.UserSelectGroupPeople,
  [IconFormNames.Cat]: UserSelectIcons.UserSelectCat,
  [IconFormNames.Dog]: UserSelectIcons.UserSelectDog,
  [IconFormNames.Bus]: UserSelectIcons.UserSelectBus,
  [IconFormNames.Boat]: UserSelectIcons.UserSelectBoat,
  [IconFormNames.Car]: UserSelectIcons.UserSelectCar,
  [IconFormNames.Bicycle]: UserSelectIcons.UserSelectBicycle,
  [IconFormNames.Train]: UserSelectIcons.UserSelectTrain,
  [IconFormNames.Plane]: UserSelectIcons.UserSelectPlane,
  [IconFormNames.AsianFood]: UserSelectIcons.UserSelectAsianFood,
  [IconFormNames.Cake]: UserSelectIcons.UserSelectCake,
  [IconFormNames.Fish]: UserSelectIcons.UserSelectFish,
  [IconFormNames.Croissant]: UserSelectIcons.UserSelectCroissant,
  [IconFormNames.Meat]: UserSelectIcons.UserSelectMeat,
  [IconFormNames.Apple]: UserSelectIcons.UserSelectApple,
  [IconFormNames.Eggplant]: UserSelectIcons.UserSelectEggplant,
  [IconFormNames.ServingDome]: UserSelectIcons.UserSelectServingDome,
  [IconFormNames.ForkKnife]: UserSelectIcons.UserSelectForkKnife,
  [IconFormNames.ChampagneGlasses]: UserSelectIcons.UserSelectChampagneGlasses,
  [IconFormNames.GiftBox]: UserSelectIcons.UserSelectGiftBox,
  [IconFormNames.Store]: UserSelectIcons.UserSelectStore,
  [IconFormNames.LargeBuilding]: UserSelectIcons.UserSelectLargeBuilding,
  [IconFormNames.Chart]: UserSelectIcons.UserSelectChart,
  [IconFormNames.Package]: UserSelectIcons.UserSelectPackage,
  [IconFormNames.Bed]: UserSelectIcons.UserSelectBed,
  [IconFormNames.Stethoscope]: UserSelectIcons.UserSelectStethoscope,
  [IconFormNames.Fireworks]: UserSelectIcons.UserSelectFireworks,
  [IconFormNames.LaptopCode]: UserSelectIcons.UserSelectLaptopCode,
  [IconFormNames.Leaf]: UserSelectIcons.UserSelectLeaf,
  [IconFormNames.RewardCup]: UserSelectIcons.UserSelectRewardCup,
  [IconFormNames.Stars]: UserSelectIcons.UserSelectStars,
  [IconFormNames.Rocket]: UserSelectIcons.UserSelectRocket,
  [IconFormNames.AiChip]: UserSelectIcons.UserSelectAiChip,
  [IconFormNames.LabTube]: UserSelectIcons.UserSelectLabTube,
  [IconFormNames.Sun]: UserSelectIcons.UserSelectSun,
  [IconFormNames.Snow]: UserSelectIcons.UserSelectSnow,
  [IconFormNames.Repeat]: UserSelectIcons.UserSelectRepeat,
};
