import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Archive,
  ArrowDownRight,
  ArrowUpRight,
  BusinessChart,
  Check,
  Edit3,
  Star,
  Trash2,
  Upload,
} from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { MoveGoalContextMenu } from 'shared/components/ui/move-goal-context-menu';
import {
  Colors,
  PopupMenu,
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
} from 'shared/components/ui/popup-menu';
import { useClickOutside } from 'shared/hooks/use-click-outside';
import { useOpenMenu } from 'shared/hooks/use-open-menu';
import { Goal } from 'shared/types/goal';
import { ID } from 'shared/types/id';

export type GoalContextMenuProps = Omit<
  React.ComponentProps<typeof PopupMenu>,
  'children' | 'ref'
> & {
  onClose: () => void;
  goals?: Goal[];
  parentIds?: ID[];
  onEditGoal?: (e: React.MouseEvent) => void;
  isCompleted?: boolean;
  onCompleteGoal?: (e: React.MouseEvent) => void;
  isArchived?: boolean;
  onFavorite?: (e: React.MouseEvent) => void;
  isFavorite?: boolean;
  onArchiveGoal?: (e: React.MouseEvent) => void;
  onDeleteGoal?: (e: React.MouseEvent) => void;
  onUpdateProgress?: (e: React.MouseEvent) => void;
  onCreateTemplate?: (e: React.MouseEvent) => void;
  onMoveGoal?: (parentIds: ID[]) => void;
  onGoalToMain?: (e: React.MouseEvent) => void;
};

export const GoalContextMenu: React.FC<GoalContextMenuProps> = ({
  onClose,
  goals,
  parentIds,
  position,
  location,
  referenceElement,
  onEditGoal,
  isCompleted,
  onCompleteGoal,
  isArchived,
  onFavorite,
  isFavorite,
  onArchiveGoal,
  onDeleteGoal,
  onUpdateProgress,
  onCreateTemplate,
  onMoveGoal,
  onGoalToMain,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const { menuOpen: goalSelectOpen, openMenu: openGoalSelect } = useOpenMenu();

  const onOpenGoalSelect = (e: React.MouseEvent) => {
    e.preventDefault();
    openGoalSelect();
  };

  useClickOutside(containerRef, () => {
    if (!goalSelectOpen) {
      onClose();
    }
  });

  return (
    <>
      {!goalSelectOpen ? (
        <PopupMenu
          ref={containerRef}
          location={location}
          position={position}
          referenceElement={referenceElement}
        >
          {(!!onEditGoal || !!onFavorite) && (
            <PopupMenuList hasBottomBorder>
              {!!onEditGoal && (
                <PopupMenuListItem>
                  <PopupMenuButton
                    onClick={onEditGoal}
                    start={<Icon icon={Edit3} />}
                  >
                    {t('context-menus.goal.labels.edit')}
                  </PopupMenuButton>
                </PopupMenuListItem>
              )}
              {!!onFavorite && (
                <PopupMenuListItem>
                  <PopupMenuButton
                    onClick={onFavorite}
                    start={<Icon icon={Star} />}
                  >
                    {t(
                      isFavorite
                        ? 'context-menus.goal.labels.remove-favorite'
                        : 'context-menus.goal.labels.add-favorite',
                    )}
                  </PopupMenuButton>
                </PopupMenuListItem>
              )}
            </PopupMenuList>
          )}
          {!!onCompleteGoal && (
            <PopupMenuList hasBottomBorder>
              <PopupMenuListItem>
                <PopupMenuButton
                  onClick={onCompleteGoal}
                  start={<Icon icon={Check} />}
                >
                  {t(
                    isCompleted
                      ? 'context-menus.goal.labels.un-complete'
                      : 'context-menus.goal.labels.complete',
                  )}
                </PopupMenuButton>
              </PopupMenuListItem>
            </PopupMenuList>
          )}
          {!!onUpdateProgress && (
            <PopupMenuList hasBottomBorder>
              <PopupMenuListItem>
                <PopupMenuButton
                  onClick={onUpdateProgress}
                  start={<Icon icon={BusinessChart} />}
                >
                  {t('context-menus.goal.labels.update-progress')}
                </PopupMenuButton>
              </PopupMenuListItem>
            </PopupMenuList>
          )}
          {(!!(onMoveGoal && !!goals) || !!(onGoalToMain && !!parentIds)) && (
            <PopupMenuList hasBottomBorder>
              {onMoveGoal && (
                <PopupMenuListItem>
                  <PopupMenuButton
                    onClick={onOpenGoalSelect}
                    start={<Icon icon={ArrowDownRight} />}
                  >
                    {t('context-menus.goal.labels.move-goal')}
                  </PopupMenuButton>
                </PopupMenuListItem>
              )}
              {onGoalToMain && (
                <PopupMenuListItem>
                  <PopupMenuButton
                    onClick={onGoalToMain}
                    start={<Icon icon={ArrowUpRight} />}
                  >
                    {t('context-menus.goal.labels.make-main')}
                  </PopupMenuButton>
                </PopupMenuListItem>
              )}
            </PopupMenuList>
          )}
          {!!(onCreateTemplate || onArchiveGoal || onDeleteGoal) && (
            <PopupMenuList>
              {!!onCreateTemplate && (
                <PopupMenuListItem>
                  <PopupMenuButton
                    onClick={onCreateTemplate}
                    startColor={Colors.Info}
                    start={<Icon icon={Upload} />}
                    textColor={Colors.Info}
                  >
                    {t('context-menus.goal.labels.create-template')}
                  </PopupMenuButton>
                </PopupMenuListItem>
              )}
              {!!onArchiveGoal && (
                <PopupMenuListItem>
                  <PopupMenuButton
                    onClick={onArchiveGoal}
                    start={<Icon icon={Archive} />}
                  >
                    {t(
                      isArchived
                        ? 'context-menus.goal.labels.de-archive'
                        : 'context-menus.goal.labels.archive',
                    )}
                  </PopupMenuButton>
                </PopupMenuListItem>
              )}
              {!!onDeleteGoal && (
                <PopupMenuListItem>
                  <PopupMenuButton
                    onClick={onDeleteGoal}
                    start={<Icon icon={Trash2} />}
                  >
                    {t('context-menus.goal.labels.delete')}
                  </PopupMenuButton>
                </PopupMenuListItem>
              )}
            </PopupMenuList>
          )}
        </PopupMenu>
      ) : (
        <MoveGoalContextMenu
          location={location}
          position={position}
          referenceElement={referenceElement}
          goals={goals!}
          selectedGoal={parentIds?.[parentIds.length - 1]}
          onGoal={onMoveGoal!}
          onClose={onClose}
        />
      )}
    </>
  );
};
