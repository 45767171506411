import { useArchiveLifeArea } from 'features/life-areas';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Archive, Edit3, Trash2 } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import {
  PopupMenu,
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
} from 'shared/components/ui/popup-menu';
import { useOpenDeleteLifeAreaDialog } from 'shared/contexts/delete-life-area';
import { useOpenEditLifeAreaDialog } from 'shared/contexts/life-area-form';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { useClickOutside } from 'shared/hooks/use-click-outside';
import { usePremiumFeatureAllowed } from 'shared/hooks/use-premium-feature-allowed';
import { useUser } from 'shared/hooks/use-user';
import { LifeArea } from 'shared/types/life-area';
import { PremiumFeature } from 'shared/types/premium-feature';
import { getLocalizedStringValue } from 'shared/utils/get-localized-string-value';

export type LifeAreaContextMenuProps = Omit<
  React.ComponentProps<typeof PopupMenu>,
  'children' | 'ref'
> & {
  lifeArea: LifeArea;
  onClose: () => void;
};

export const LifeAreaContextMenu: React.FC<LifeAreaContextMenuProps> = ({
  lifeArea,
  onClose,
  location,
  position,
  referenceElement,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { t, i18n } = useTranslation();
  const user = useUser();

  const openEditDialog = useOpenEditLifeAreaDialog();
  const openDeleteDialog = useOpenDeleteLifeAreaDialog();
  const archiveLifeArea = useArchiveLifeArea();

  const openPremiumDialog = useOpenPremiumDialog();
  const editPredefinedLifeAreasAllowed = usePremiumFeatureAllowed(
    PremiumFeature.CustomizeTemplateLifeAreas,
    { user },
  );

  const onEdit = () => {
    if (!!lifeArea.templateId && !editPredefinedLifeAreasAllowed) {
      openPremiumDialog();
      onClose();
      return;
    }

    openEditDialog(
      {
        ...lifeArea,
        name: getLocalizedStringValue(lifeArea.name, i18n.language),
      },
      lifeArea.id,
    );
    onClose();
  };

  const onArchive = () => {
    archiveLifeArea(lifeArea);
    onClose();
  };

  const onDelete = () => {
    openDeleteDialog(lifeArea);
    onClose();
  };

  useClickOutside(containerRef, onClose);

  return (
    <PopupMenu
      ref={containerRef}
      location={location}
      position={position}
      referenceElement={referenceElement}
    >
      {!lifeArea.archivedAt && (
        <PopupMenuList hasBottomBorder>
          <PopupMenuListItem>
            <PopupMenuButton
              onClick={onEdit}
              start={<Icon icon={Edit3} />}
              isPremium={
                !!lifeArea.templateId && !editPredefinedLifeAreasAllowed
              }
            >
              {t('context-menus.life-area.labels.edit')}
            </PopupMenuButton>
          </PopupMenuListItem>
        </PopupMenuList>
      )}
      <PopupMenuList>
        <PopupMenuListItem>
          <PopupMenuButton onClick={onArchive} start={<Icon icon={Archive} />}>
            {t(
              !!lifeArea.archivedAt
                ? 'context-menus.life-area.labels.de-archive'
                : 'context-menus.life-area.labels.archive',
            )}
          </PopupMenuButton>
        </PopupMenuListItem>
        {!lifeArea.templateId && (
          <PopupMenuListItem>
            <PopupMenuButton onClick={onDelete} start={<Icon icon={Trash2} />}>
              {t('context-menus.life-area.labels.delete')}
            </PopupMenuButton>
          </PopupMenuListItem>
        )}
      </PopupMenuList>
    </PopupMenu>
  );
};
