import { useMutation } from '@tanstack/react-query';
import { forgotPassword } from 'shared/services/backend-api';
import { ForgotPasswordErrorTypes } from 'shared/types/forgot-password-form';

export const useForgotPasswordMutation = () =>
  useMutation<void, ForgotPasswordErrorTypes, { email: string }>({
    mutationFn: async ({ email }) => {
      await forgotPassword(email);
    },
  });
