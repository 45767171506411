import {
  addYears,
  endOfYear,
  isAfter,
  isBefore,
  isSameDay,
  startOfYear,
} from 'date-fns';
import { Goal } from 'shared/types/goal';
import { Timestamp } from 'shared/types/timestamp';

export enum GoalsFilterOptions {
  Active = 'active',
  All = 'all',
  Frozen = 'frozen',
  Completed = 'completed',
  Archived = 'archived',
  ThisYear = 'this-year',
  NextYear = 'next-year',
}

export const goalsFilters: Record<
  GoalsFilterOptions,
  (goals: Goal[], today: Timestamp) => Goal[]
> = {
  [GoalsFilterOptions.All]: (goals) =>
    goals.filter(({ completedAt, archivedAt }) => !archivedAt && !completedAt),
  [GoalsFilterOptions.Active]: (goals, today) =>
    goals.filter(
      ({ completedAt, archivedAt, startDate }) =>
        !archivedAt &&
        !completedAt &&
        !!startDate &&
        (isBefore(startDate, today) || isSameDay(startDate, today)),
    ),
  [GoalsFilterOptions.Frozen]: (goals) =>
    goals.filter(
      ({ completedAt, archivedAt, frozenAt }) =>
        !completedAt && !archivedAt && !!frozenAt,
    ),
  [GoalsFilterOptions.Completed]: (goals) =>
    goals.filter(({ archivedAt, completedAt }) => !archivedAt && !!completedAt),
  [GoalsFilterOptions.Archived]: (goals) =>
    goals.filter(({ archivedAt }) => !!archivedAt),
  [GoalsFilterOptions.ThisYear]: (goals, today) =>
    goals.filter(
      ({ archivedAt, completedAt, startDate, deadline }) =>
        !completedAt &&
        !archivedAt &&
        ((startDate &&
          deadline &&
          isBefore(startDate, endOfYear(today)) &&
          isAfter(deadline, startOfYear(today))) ||
          (startDate && !deadline && isBefore(startDate, endOfYear(today))) ||
          (!startDate && deadline && isAfter(deadline, startOfYear(today)))),
    ),
  [GoalsFilterOptions.NextYear]: (goals, today) =>
    goals.filter(
      ({ archivedAt, completedAt, startDate, deadline }) =>
        !completedAt &&
        !archivedAt &&
        ((startDate &&
          deadline &&
          isAfter(startDate, endOfYear(today)) &&
          isAfter(deadline, addYears(startOfYear(today), 1))) ||
          (startDate &&
            !deadline &&
            isBefore(startDate, addYears(endOfYear(today), 1))) ||
          (!startDate &&
            deadline &&
            isAfter(deadline, addYears(startOfYear(today), 1)))),
    ),
};
