import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Styled from './popup-menu.style';
import { Colors } from './types';

export type PopupMenuButtonProps = React.ComponentProps<'button'> & {
  start?: React.ReactNode;
  end?: React.ReactNode;
  startColor?: Colors;
  endColor?: Colors;
  textColor?: Colors;
  isPremium?: boolean;
  isSelected?: boolean;
  hasIndentation?: boolean;
  overflowEllipsis?: boolean;
  as?: string;
};

export const PopupMenuButton: React.FC<PopupMenuButtonProps> = ({
  start,
  end,
  children,
  startColor,
  endColor,
  textColor,
  isPremium,
  isSelected,
  hasIndentation,
  overflowEllipsis,
  ...rest
}) => {
  const { t } = useTranslation();
  return (
    <Styled.Button
      $color={textColor}
      $isSelected={!!isSelected}
      $hasIndentation={!!hasIndentation}
      {...rest}
    >
      {!!start && (
        <Styled.AffixContainer $isSelected={!!isSelected} $color={startColor}>
          {start}
        </Styled.AffixContainer>
      )}
      <Styled.ButtonContent $overflowEllipsis={!!overflowEllipsis}>
        {children}
      </Styled.ButtonContent>
      {isPremium && (
        <Styled.PremiumLabel>{t('general.labels.premium')}</Styled.PremiumLabel>
      )}
      {!!end && (
        <Styled.AffixContainer $isSelected={!!isSelected} $color={endColor}>
          {end}
        </Styled.AffixContainer>
      )}
    </Styled.Button>
  );
};
