import { doc, FieldValue, increment } from 'firebase/firestore';
import { ID } from 'shared/types/id';

import {
  CollectionOptions,
  getDatabase,
  updateDocWithTimestamp,
} from './helpers';

export const updateGoalTaskCount = (
  goalId: ID,
  {
    taskCount,
    completedTaskCount,
  }: { taskCount?: number; completedTaskCount?: number },
) => {
  const goalRef = doc(getDatabase(), CollectionOptions.Goals, goalId);
  const data: {
    taskCount?: FieldValue;
    completedTaskCount?: FieldValue;
  } = {};

  if (taskCount) {
    data.taskCount = increment(taskCount);
  }
  if (completedTaskCount) {
    data.completedTaskCount = increment(completedTaskCount);
  }

  updateDocWithTimestamp(goalRef, data);
};
