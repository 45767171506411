import { useCallback } from 'react';
import { useUpdateGoalTaskCountMutation } from 'shared/hooks/queries/use-update-goal-task-count-mutation';
import { useUpdateTaskMutation } from 'shared/hooks/queries/use-update-task-mutation';
import { useTrackEvents } from 'shared/hooks/use-track-events';
import {
  ActionEvents,
  taskTypeToTrackingTaskType,
} from 'shared/services/tracking';
import { Task } from 'shared/types/task';
import { TaskType } from 'shared/types/task-base';
import { Timestamp } from 'shared/types/timestamp';

export const useCompleteTask = () => {
  const { mutate: updateTask } = useUpdateTaskMutation();
  const { mutate: updateGoalTaskCount } = useUpdateGoalTaskCountMutation();
  const track = useTrackEvents();

  return useCallback(
    (task: Task, date: Timestamp = new Date()) => {
      const taskToUpdate = { ...task };
      taskToUpdate.completedAt = taskToUpdate.completedAt ? null : date;
      taskToUpdate.completedAt &&
        ([TaskType.Habit, TaskType.Repeating].includes(taskToUpdate.type)
          ? track(ActionEvents.HabitComplete, undefined)
          : track(ActionEvents.TaskComplete, {
              type: taskTypeToTrackingTaskType[taskToUpdate.type],
            }));

      updateTask(taskToUpdate);

      if (taskToUpdate.goalId) {
        updateGoalTaskCount({
          goalId: taskToUpdate.goalId,
          completedTaskCount: taskToUpdate.completedAt ? 1 : -1,
        });
      }

      return taskToUpdate;
    },
    [track, updateTask, updateGoalTaskCount],
  );
};
