import { isAfter, isBefore, isSameDay } from 'date-fns';
import { ID } from 'shared/types/id';
import { Timestamp } from 'shared/types/timestamp';

export const getCustomOrderBetweenDates = (
  orderByDate: Record<string, ID[]>,
  startDate: Timestamp,
  endDate: Timestamp,
) => {
  return (
    Object.entries(orderByDate)
      // only take entries within the date range
      .filter(([key]) => {
        const keyDate = new Date(key);
        return (
          (isSameDay(keyDate, endDate) || isBefore(keyDate, endDate)) &&
          (isSameDay(keyDate, endDate) || isAfter(keyDate, startDate))
        );
      })
      // sort the entries on date
      .sort(
        ([keyA], [keyB]) => new Date(keyA).getTime() - new Date(keyB).getTime(),
      )
      // combine the entries into 1 single list
      .reduce<ID[]>((acc, [, order]) => {
        acc.push(...order);
        return acc;
      }, [])
  );
};
