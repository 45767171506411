import React from 'react';

import * as Styled from './popup-menu.style';

export type PopupMenuListProps = {
  hasBottomBorder?: boolean;
  isScrollable?: boolean;
  children: React.ReactNode;
};

export const PopupMenuList: React.FC<PopupMenuListProps> = ({
  hasBottomBorder,
  isScrollable,
  children,
}) => (
  <Styled.List
    $hasBottomBorder={!!hasBottomBorder}
    $isScrollable={!!isScrollable}
  >
    {children}
  </Styled.List>
);
