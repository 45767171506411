import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Colors, Sizes, Variants } from 'shared/components/ui/button';
import { FormInput } from 'shared/components/ui/form-input';
import {
  ForgotPasswordErrorTypes,
  ForgotPasswordFormFields,
} from 'shared/types/forgot-password-form';

import * as Styled from './forgot-password-form.style';
import { useForgotPasswordForm } from './use-forgot-password-form';

export type ForgotPasswordFormProps = {
  onSubmit: (loginData: ForgotPasswordFormFields) => void;
  isLoading: boolean;
  error?: ForgotPasswordErrorTypes;
};

export const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({
  onSubmit,
  isLoading,
  error,
}) => {
  const { t } = useTranslation();

  const {
    register,
    onSubmit: submitForm,
    errors,
  } = useForgotPasswordForm(onSubmit);

  const emailErrorMessage = useMemo(() => {
    if (errors.email?.message) {
      return t('forms.forgot-password.fields.email.error');
    }

    if (error === ForgotPasswordErrorTypes.UnknownEmail) {
      return t('forms.forgot-password.error.unknown-email');
    }

    if (error === ForgotPasswordErrorTypes.Unknown) {
      return t('forms.forgot-password.error.unknown');
    }
  }, [error, errors.email?.message, t]);

  return (
    <Styled.Form noValidate onSubmit={submitForm}>
      <Styled.Fields>
        <Styled.FieldContainer>
          <Typography variant="body2">
            {t('forms.forgot-password.description')}
          </Typography>
        </Styled.FieldContainer>
        <Styled.Content>
          <Styled.FieldContainer>
            <FormInput
              {...register('email')}
              id="email"
              placeholder={t('forms.forgot-password.fields.email.placeholder')}
              error={emailErrorMessage}
              disabled={isLoading}
            />
            {!!emailErrorMessage && (
              <Styled.ErrorMessage>{emailErrorMessage}</Styled.ErrorMessage>
            )}
          </Styled.FieldContainer>

          <Styled.Interactions>
            <Button
              variant={Variants.Contained}
              color={Colors.Primary}
              size={Sizes.Large}
              type="submit"
              disabled={isLoading}
              fullWidth
              alignCenter
              disabledBackgroundOnly
            >
              {isLoading ? (
                <Styled.Loader color="primary" size={20} />
              ) : (
                t('forms.forgot-password.buttons.reset-password.label')
              )}
            </Button>
          </Styled.Interactions>
        </Styled.Content>
      </Styled.Fields>
    </Styled.Form>
  );
};
