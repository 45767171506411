import React from 'react';
import { useTranslation } from 'react-i18next';
import { useUnsplashImages } from 'shared/hooks/use-unsplash-images';
import { UnsplashImage } from 'shared/types/unsplash-image';

import * as Styled from './unsplash-image-picker.style';

export type UnsplashImagesProps = {
  searchText: string;
  onImage: (image: UnsplashImage) => void;
};

export const UnsplashImages: React.FC<UnsplashImagesProps> = ({
  searchText,
  onImage,
}) => {
  const { t } = useTranslation();
  const { images } = useUnsplashImages(searchText);

  const onImageSelect = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const imageId = e.currentTarget.getAttribute('data-id');
    const image = images.find(({ id }) => id === imageId);

    if (!image) {
      return;
    }

    onImage(image);
  };

  return (
    <Styled.List>
      {images.map((image) => (
        <Styled.ListItem key={image.id}>
          <Styled.Figure>
            <Styled.Image
              src={image.urls.small}
              alt={image.description}
              onClick={onImageSelect}
              data-id={image.id}
            />
            <Styled.ImageLabel>
              {t('context-menus.image-source-picker.unsplash.image-author.by')}{' '}
              <Styled.Anchor href={image.user.portfolio_url} target="_blank">
                {image.user.name}
              </Styled.Anchor>
            </Styled.ImageLabel>
          </Styled.Figure>
        </Styled.ListItem>
      ))}
    </Styled.List>
  );
};
