import Typography from '@mui/material/Typography';
import { useVerifyEmail } from 'features/user';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LoginHeader } from 'shared/components/ui/login-header';
import { useDelayedCallback } from 'shared/hooks/use-delayed-callback';
import { QueryParamOptions, useQueryParam } from 'shared/lib/react-router-dom';
import { Paths } from 'shared/routes';

import * as Styled from './verify-email.style';

export const VerifyEmail: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [code] = useQueryParam(QueryParamOptions.OobCode);
  const { verifyEmail, isLoading, error, isSuccess } = useVerifyEmail();
  const redirectToLogin = useDelayedCallback(() => navigate(Paths.Home), 5000);

  useEffect(() => {
    verifyEmail(code);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on first render
  }, []);

  useEffect(() => {
    if (isSuccess) {
      redirectToLogin();
    }
  }, [isSuccess, redirectToLogin]);

  const content = useMemo(() => {
    if (isLoading) {
      return <Styled.Loader size={40} />;
    }

    if (error) {
      return (
        <Typography variant="overline" textAlign="center">
          {t('forms.verify-email.error.message')}
        </Typography>
      );
    }

    if (isSuccess) {
      return (
        <>
          <Typography variant="overline" textAlign="center">
            {t('forms.verify-email.success.message')}
          </Typography>
          <Styled.Anchor to={Paths.Home}>
            {t('forms.verify-email.success.redirect.label')}
          </Styled.Anchor>
        </>
      );
    }
  }, [error, isLoading, isSuccess, t]);

  return (
    <>
      <LoginHeader />
      <Styled.Container>
        <Styled.Body>
          <Typography variant="h1" textAlign="center">
            {t('forms.verify-email.title')}
          </Typography>

          {content}
        </Styled.Body>
      </Styled.Container>
    </>
  );
};
