export enum Variants {
  Filled = 'filled',
  Outlined = 'outlined',
}

export enum Colors {
  Default = 'default',
  Primary = 'primary',
  Warning = 'warning',
  Info = 'info',
  Success = 'success',
}

export enum Sizes {
  Small = 'small',
  Medium = 'medium',
}
