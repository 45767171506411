import { ID } from 'shared/types/id';
import { TaskCardTask } from 'shared/types/task-card-task';

export const orderTaskCardTasksByDate = (
  taskCardTasks: TaskCardTask[],
  order: { [key: string]: string[] },
): TaskCardTask[] => {
  // first make sure we only have unique tasks to prevent errors on double entries
  const uniqueTaskCardTasks = Object.values(
    taskCardTasks.reduce<Record<ID, TaskCardTask>>(
      (acc, taskCardTask) => ({ ...acc, [taskCardTask.id]: taskCardTask }),
      {},
    ),
  );

  // sort the tasks to get them in order of how they should be, if there is no order.
  const sortedTaskCardTasks = [
    // first the task-card-tasks with a due-date
    ...uniqueTaskCardTasks
      .filter(({ raw }) => !!raw.dueDate)
      .sort(
        ({ raw: rawA }, { raw: rawB }) =>
          new Date(rawA.dueDate!).getTime() - new Date(rawB.dueDate!).getTime(),
      ),
    // after the task-card-tasks without due-date
    ...uniqueTaskCardTasks
      .filter(({ raw }) => !raw.dueDate)
      .sort(
        ({ raw: rawA }, { raw: rawB }) =>
          new Date(rawA.task.createdAt).getTime() -
          new Date(rawB.task.createdAt).getTime(),
      ),
  ];

  // order the tasks based on the order entries
  const orderedTasksCardTasks = Object.entries(order)
    .sort(([keyA], [keyB]) =>
      keyA === 'null'
        ? -1
        : keyB === 'null'
          ? 1
          : new Date(keyA).getTime() - new Date(keyB).getTime(),
    )
    .reduce<TaskCardTask[]>((acc, [, value]) => {
      value.forEach((taskId) => {
        const sortedTasksIndex = sortedTaskCardTasks.findIndex(
          ({ id }) => id === taskId,
        );
        if (sortedTasksIndex >= 0) {
          // actually removing the task-card-tasks from the sortedTaskCardTasks to prevent double entries.
          const [taskCardTask] = sortedTaskCardTasks.splice(
            sortedTasksIndex,
            1,
          );
          acc.push(taskCardTask);
        }
      });

      return acc;
    }, []);

  return [...orderedTasksCardTasks, ...sortedTaskCardTasks];
};
