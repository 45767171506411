import React, { useCallback, useState } from 'react';
import { getPositionRelativeToContainContext } from 'shared/utils/get-position-relative-to-contain-context';

type Options = {
  onOpen?: (e: React.MouseEvent) => void;
  onClose?: () => void;
  disabled?: boolean;
  basedOnWindow?: boolean;
};
export const useContextMenuState = ({
  onOpen,
  onClose,
  disabled,
  basedOnWindow,
}: Options = {}) => {
  const [position, setPosition] = useState<{ left: number; top: number }>();

  const close = useCallback(() => {
    if (disabled) {
      return;
    }

    setPosition(undefined);
    onClose?.();
  }, [disabled, onClose]);

  const open = useCallback(
    (e: React.MouseEvent) => {
      if (disabled) {
        return;
      }

      e.preventDefault();
      e.stopPropagation();

      const containContextPosition = basedOnWindow
        ? {
            top: e.clientY,
            right: e.clientX,
            bottom: e.clientY,
            left: e.clientX,
          }
        : getPositionRelativeToContainContext(e.currentTarget as HTMLElement, {
            top: e.clientY,
            right: e.clientX,
            bottom: e.clientY,
            left: e.clientX,
          });

      setPosition({
        left: containContextPosition.left,
        top: containContextPosition.top,
      });
      onOpen?.(e);
    },
    [basedOnWindow, disabled, onOpen],
  );

  return {
    position,
    open,
    close,
  };
};
