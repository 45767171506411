import {
  addDays,
  differenceInDays,
  endOfMonth,
  isFirstDayOfMonth,
} from 'date-fns';
import { useMemo } from 'react';
import { useToday } from 'shared/contexts/today';

export const useShouldHaveRestOfMonth = () => {
  const today = useToday();
  const restOfMonthStartDate = useMemo(() => addDays(today, 10), [today]);
  const restOfMonthEndDate = useMemo(
    () => endOfMonth(restOfMonthStartDate),
    [restOfMonthStartDate],
  );

  const shouldHaveRestOfMonth = useMemo(
    () =>
      differenceInDays(restOfMonthEndDate, restOfMonthStartDate) >= 0 &&
      !isFirstDayOfMonth(restOfMonthStartDate),
    [restOfMonthEndDate, restOfMonthStartDate],
  );

  return {
    restOfMonthStartDate,
    restOfMonthEndDate,
    shouldHaveRestOfMonth,
  };
};
