import { useMemo } from 'react';
import { useUser } from 'shared/hooks/use-user';

export const useFavoriteIds = () => {
  const user = useUser();

  return useMemo(
    () => user?.settings?.favorites?.map(({ id }) => id) ?? [],
    [user],
  );
};
