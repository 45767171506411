import { addDays, endOfDay, startOfDay } from 'date-fns';
import { useMemo } from 'react';
import { useToday } from 'shared/contexts/today';
import {
  DateSection,
  DateSectionOptions,
  overdueSectionID,
} from 'shared/types/orderable-section';
import { formatDateKey } from 'shared/utils/format-date-key';

export const useTodaySections = (): DateSection[] => {
  const today = useToday();

  return useMemo(
    () => [
      {
        // overdue
        id: overdueSectionID,
        type: DateSectionOptions.Day,
        startDate: new Date(0),
        endDate: endOfDay(addDays(today, -1)),
        deadline: endOfDay(addDays(today, -1)),
        items: [],
      },
      {
        // today
        id: formatDateKey(endOfDay(today)),
        type: DateSectionOptions.Day,
        startDate: startOfDay(today),
        endDate: endOfDay(today),
        deadline: endOfDay(today),
        items: [],
      },
    ],
    [today],
  );
};
