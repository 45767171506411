import { useLocalizedLifeAreas } from 'features/life-areas';
import { useMemo } from 'react';
import { ID } from 'shared/types/id';
export const useLifeAreaById = (id?: ID) => {
  const lifeAreas = useLocalizedLifeAreas(!!id);
  return useMemo(
    () => lifeAreas.find((lifeArea) => lifeArea.id === id),
    [id, lifeAreas],
  );
};
