import { addDays, endOfDay } from 'date-fns';
import { useMemo } from 'react';
import { useToday } from 'shared/contexts/today';
import { useTasksQuery } from 'shared/hooks/queries/use-tasks-query';
import { useUser } from 'shared/hooks/use-user';

export const useOverdueTasks = () => {
  const today = useToday();
  const user = useUser();

  const { data: tasks } = useTasksQuery(user?.uid, {
    deadlineEndDate: endOfDay(addDays(today, -1)),
    unFrozenOnly: true,
    uncompletedOnly: true,
    noScheduleOnly: true,
  });

  return useMemo(() => tasks ?? [], [tasks]);
};
