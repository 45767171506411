import { endOfDay } from 'date-fns';
import { FirestoreDataConverter } from 'firebase/firestore';
import { Goal, NewGoal } from 'shared/types/goal';

import { firebaseTimestampToDate } from './firebase-timestamp-to-date';

export const goalConverter: FirestoreDataConverter<Goal> = {
  fromFirestore: (snapshot) => {
    const data = snapshot.data();

    return {
      ...data,
      startDate: firebaseTimestampToDate(data.startDate),
      deadline: firebaseTimestampToDate(data.deadline),
      completedAt: firebaseTimestampToDate(data.completedAt),
      createdAt: firebaseTimestampToDate(data.createdAt),
      archivedAt: firebaseTimestampToDate(data.archivedAt),
      frozenAt: firebaseTimestampToDate(data.frozenAt),
      iconName: data.iconName ?? null,
      image: data.image
        ? {
            ...data.image,
            createdAt: firebaseTimestampToDate(data.image.createdAt),
          }
        : null,
      metric: data.metric
        ? {
            ...data.metric,
            startValue:
              typeof data.metric.startValue === 'number'
                ? Math.round(data.metric.startValue * 100) / 100
                : null,
            targetValue:
              typeof data.metric.targetValue === 'number'
                ? Math.round(data.metric.targetValue * 100) / 100
                : null,
            entries:
              // @ts-ignore -- createdAt type not recognized
              data.metric.entries?.map(({ date, value, ...entry }) => ({
                ...entry,
                date: firebaseTimestampToDate(date),
                value: Math.round(value * 100) / 100,
              })) ?? null,
          }
        : null,
    } as Goal;
  },
  toFirestore: (goal) => goal,
};

export const newGoalConverter = (goal: NewGoal) => ({
  ...goal,
  createdAt: new Date(),
  image: goal.image ?? null,
  lifeAreaId: goal.lifeAreaId ?? null,
  frozenAt: null,
  startDate: goal.startDate ?? null,
  deadline: goal.deadline ? endOfDay(goal.deadline) : null,
  parentIds: goal.parentIds ?? null,
  completedAt: null,
  archivedAt: null,
});
