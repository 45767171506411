import { useCallback } from 'react';
import { useUpdateLifeAreaGoalOrderMutation } from 'shared/hooks/queries/use-update-life-area-goal-order-mutation';
import { Goal } from 'shared/types/goal';
import { ID } from 'shared/types/id';
import { LifeArea } from 'shared/types/life-area';

export const useUpdateLifeAreaGoalOrder = (lifeArea?: LifeArea) => {
  const { mutate: updateOrders } = useUpdateLifeAreaGoalOrderMutation();

  const goalReorder = useCallback(
    (goal: Goal, options: { delete?: boolean } = {}) => {
      if (!lifeArea) {
        return;
      }

      if (
        goal.completedAt ||
        goal.lifeAreaId !== lifeArea.id ||
        options.delete
      ) {
        updateOrders([
          {
            lifeAreaId: lifeArea.id,
            ids: lifeArea.goalSorting?.filter((id) => id !== goal.id) ?? [],
          },
        ]);
        return;
      }

      if (!lifeArea.goalSorting?.includes(goal.id)) {
        updateOrders([
          {
            lifeAreaId: lifeArea.id,
            ids: [...(lifeArea?.goalSorting ?? []), goal.id],
          },
        ]);
      }
    },
    [lifeArea, updateOrders],
  );

  const updateOrder = useCallback(
    (ids: ID[]) => {
      if (!lifeArea) {
        return;
      }

      updateOrders([{ lifeAreaId: lifeArea.id, ids }]);
    },
    [lifeArea, updateOrders],
  );

  return {
    goalReorder,
    updateOrder,
  };
};
