import { Currency } from 'shared/types/currency';

// todo: actually fetch currencies
export const getPremiumCurrencies = (): Currency[] => [
  {
    id: 'eur',
    label: 'EUR',
    sign: '€',
    options: [
      {
        type: 'year',
        amount: '7,49',
        totalAmount: '89,99',
        discountPercentage: 25,
      },
      {
        type: 'month',
        amount: '9,99',
      },
    ],
  },
  {
    id: 'usd',
    label: 'USD',
    sign: '$',
    options: [
      {
        type: 'year',
        amount: '7.49',
        totalAmount: '89.99',
        discountPercentage: 25,
      },
      {
        type: 'month',
        amount: '9.99',
      },
    ],
  },
  {
    id: 'gbp',
    label: 'GBP',
    sign: '£',
    options: [
      {
        type: 'year',
        amount: '5.83',
        totalAmount: '69.99',
        discountPercentage: 27,
      },
      {
        type: 'month',
        amount: '7.99',
      },
    ],
  },
];
