import { useMemo } from 'react';
import {
  GetGoalsOptions,
  useGoalsQuery,
} from 'shared/hooks/queries/use-goals-query';
import { useUser } from 'shared/hooks/use-user';

export const useGoals = (options?: GetGoalsOptions) => {
  const user = useUser();
  const { data } = useGoalsQuery(user?.uid, options);

  return useMemo(() => data ?? [], [data]);
};
