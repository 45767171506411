import { useMemo } from 'react';
import { useTasksQuery } from 'shared/hooks/queries/use-tasks-query';
import { useUser } from 'shared/hooks/use-user';
import { GetTaskOptions } from 'shared/services/backend-api';

export const useTasks = (options: GetTaskOptions = {}, enabled = true) => {
  const user = useUser();
  const { data } = useTasksQuery(
    user?.uid,
    {
      ...options,
    },
    enabled,
  );

  return useMemo(() => (enabled ? data ?? [] : []), [data, enabled]);
};
