import { useLogout, useUserHadTrial } from 'features/user';
import React from 'react';
import { useOpenFeedbackDialog } from 'shared/contexts/feedback-dialog';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { useOpenSettings } from 'shared/contexts/user-settings';
import { useNewVersionAvailable } from 'shared/contexts/version';
import { useUser } from 'shared/hooks/use-user';
import { Entitlements } from 'shared/types/entitlements';

import { UserButtonView } from './user-button-view';

export type UserButtonProps = {
  onCloseSideNavigation?: () => void;
};

export const UserButton: React.FC<UserButtonProps> = ({
  onCloseSideNavigation,
}) => {
  const user = useUser();
  const userHadTrial = useUserHadTrial();
  const newVersionAvailable = useNewVersionAvailable();

  const openPremiumDialog = useOpenPremiumDialog();
  const openFeedbackDialog = useOpenFeedbackDialog();
  const onLogout = useLogout();
  const onSettings = useOpenSettings();

  if (!user) {
    return null;
  }

  return (
    <UserButtonView
      name={user.name}
      onLogout={onLogout}
      onSettings={onSettings}
      whatsappLink="https://chat.whatsapp.com/GSri97x51ubEMEIwccPICr"
      onFeedback={openFeedbackDialog}
      onCloseSideNavigation={onCloseSideNavigation}
      userHadTrial={userHadTrial}
      newVersionAvailable={newVersionAvailable}
      onIAP={
        !user.entitlements?.includes(Entitlements.Premium)
          ? openPremiumDialog
          : undefined
      }
    />
  );
};
