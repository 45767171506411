import { typographyToCss } from 'shared/lib/mui';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2.5)};

  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(6)} 0`};
`;

export const Illustration = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 4.8rem;
  height: 4.8rem;
  margin-top: ${({ theme }) => theme.spacing(1)};

  background: ${({ theme }) => theme.palette.brown['100']};
  border-radius: ${({ theme }) => theme.spacing(3)};

  color: ${({ theme }) => theme.palette.brown['300']};
  font-size: 2.4rem;
`;

export const ColorDot = styled.span<{ $color: string }>`
  position: absolute;
  bottom: ${({ theme }) => `-${theme.spacing(0.75)}`};
  right: ${({ theme }) => `-${theme.spacing(0.75)}`};

  display: block;
  height: 1.2rem;
  width: 1.2rem;

  border: ${({ theme }) =>
    `${theme.spacing(0.5)} solid ${theme.palette.background.default};`};
  border-radius: 50%;
  background: ${({ $color }) => $color};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(0.5)};
`;

export const Title = styled.h2`
  ${({ theme }) => typographyToCss(theme.typography.h2)};
  margin: 0;
  padding: ${({ theme }) => `0 0 0 ${theme.spacing(1.5)}`};
`;

export const MetaContainer = styled.div`
  display: flex;
`;

export const MetaTag = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.overline)};
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};

  padding: ${({ theme }) => `${theme.spacing(0.5)} ${theme.spacing(1.5)}`};

  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const MetaIcon = styled.span`
  font-size: 1.2rem;
  line-height: 62.5%;
`;
