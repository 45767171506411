import { useArchiveTask, useDeleteTask } from 'features/tasks';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Archive, Trash2 } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import {
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
} from 'shared/components/ui/popup-menu';
import { useOpenDeleteHabitDialog } from 'shared/contexts/delete-habit';
import { Habit } from 'shared/types/habit';
import { Task } from 'shared/types/task';
import { TaskType } from 'shared/types/task-base';

export type RemoveListProps = {
  task: Task;
  onClose: () => void;
  hasListBelow: boolean;
};

export const RemoveList: React.FC<RemoveListProps> = ({
  task,
  onClose,
  hasListBelow,
}) => {
  const { t } = useTranslation();
  const archiveTask = useArchiveTask();

  const parentTaskId = useMemo(
    () => task.parentIds?.[task.parentIds.length - 1],
    [task],
  );

  const { submit: deleteTask } = useDeleteTask(parentTaskId);
  const deleteHabit = useOpenDeleteHabitDialog();

  const isHabit = [TaskType.Habit, TaskType.Repeating].includes(task.type);

  const onArchive = () => {
    archiveTask(task);
    onClose();
  };

  const onDelete = () => {
    if (isHabit) {
      deleteHabit(task as Habit);
      return;
    }

    deleteTask(task);
    onClose();
  };

  return (
    <PopupMenuList hasBottomBorder={hasListBelow}>
      {isHabit && (
        <PopupMenuListItem>
          <PopupMenuButton onClick={onArchive} start={<Icon icon={Archive} />}>
            {t(
              task.archivedAt
                ? 'context-menus.task.labels.de-archive'
                : 'context-menus.task.labels.archive',
            )}
          </PopupMenuButton>
        </PopupMenuListItem>
      )}
      <PopupMenuListItem>
        <PopupMenuButton onClick={onDelete} start={<Icon icon={Trash2} />}>
          {t('context-menus.task.labels.delete')}
        </PopupMenuButton>
      </PopupMenuListItem>
    </PopupMenuList>
  );
};
