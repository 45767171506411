import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { enUS, Locales, nlNL } from 'shared/assets/localization';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: Locales.EnUS,
    resources: {
      [Locales.EnUS]: { translation: enUS },
      [Locales.NlNL]: { translation: nlNL },
    },
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['navigator'],
    },
  });

export default i18n;
