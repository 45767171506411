import React, { useState } from 'react';
import { TaskCard } from 'shared/components/connected/task-card';
import { SortableContainer } from 'shared/components/ui/sortable-container';
import { DraggableOptions } from 'shared/types/draggable-types';
import { ID } from 'shared/types/id';
import { Task } from 'shared/types/task';
import { DueDisplayOptions } from 'shared/types/task-base';
import { TaskCardTask } from 'shared/types/task-card-task';

import * as Styled from './tasks-list.style';

type TasksListItemProps = {
  task: Task;
  onTask?: (id?: ID) => void;
  onUnfocus?: (id: ID) => void;
  displayDue?: DueDisplayOptions[];
  selectedTask?: ID;
  showRepeatingAsTodo?: boolean;
  hideMeta?: boolean;
  hideGoal?: boolean;
  hideLifeArea?: boolean;
  showDetailOnSelect?: boolean;
  tasksDisabled?: boolean;
  hasDnd?: boolean;
  taskIsMoving?: boolean;
};

const TaskListItem: React.FC<TasksListItemProps> = ({
  task,
  onTask,
  onUnfocus,
  displayDue,
  selectedTask,
  showRepeatingAsTodo,
  hideMeta,
  hideGoal,
  hideLifeArea,
  showDetailOnSelect,
  tasksDisabled,
  hasDnd,
  taskIsMoving,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const TaskContainer =
    hasDnd && !tasksDisabled ? SortableContainer : React.Fragment;
  const taskContainerProps =
    hasDnd && !tasksDisabled
      ? {
          id: task.id,
          type: DraggableOptions.Task,
          isEditing,
        }
      : {};

  return (
    // @ts-ignore
    <TaskContainer {...taskContainerProps}>
      <TaskCard
        task={task}
        onUnfocus={onUnfocus}
        onClick={onTask}
        onEditChange={setIsEditing}
        displayDue={displayDue}
        showRepeatingAsTodo={showRepeatingAsTodo}
        isSelected={selectedTask === task.id}
        hideMeta={hideMeta}
        hideGoal={hideGoal}
        hideLifeArea={hideLifeArea}
        showDetailOnSelect={showDetailOnSelect}
        isDisabled={tasksDisabled}
        noHover={taskIsMoving}
      />
    </TaskContainer>
  );
};

export type TasksListViewProps = {
  tasks: TaskCardTask[];
  onTask?: (id?: ID) => void;
  onUnfocus?: (id: ID) => void;
  selectedTask?: ID;
  showRepeatingAsTodo?: boolean;
  displayDue?: DueDisplayOptions[];
  hideMeta?: boolean;
  hideGoal?: boolean;
  hideLifeArea?: boolean;
  showDetailOnSelect?: boolean;
  tasksDisabled?: boolean;
  hasDnd?: boolean;
  taskIsMoving?: boolean;
};

export const TasksListView: React.FC<TasksListViewProps> = ({
  tasks,
  onTask,
  onUnfocus,
  selectedTask,
  showRepeatingAsTodo,
  displayDue,
  hideMeta,
  hideGoal,
  hideLifeArea,
  showDetailOnSelect,
  tasksDisabled,
  hasDnd,
  taskIsMoving,
}) => (
  <Styled.List>
    {tasks.map((task) => (
      <Styled.ListItem key={task.id}>
        <TaskListItem
          task={task.raw.task}
          onUnfocus={onUnfocus}
          onTask={onTask}
          showRepeatingAsTodo={showRepeatingAsTodo}
          selectedTask={selectedTask}
          displayDue={displayDue}
          hideMeta={hideMeta}
          hideGoal={hideGoal}
          hideLifeArea={hideLifeArea}
          showDetailOnSelect={showDetailOnSelect}
          hasDnd={hasDnd}
          tasksDisabled={tasksDisabled}
          taskIsMoving={taskIsMoving}
        />
      </Styled.ListItem>
    ))}
  </Styled.List>
);
