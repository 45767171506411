import Typography from '@mui/material/Typography';
import React from 'react';

import * as Styled from './goal-template-preview-dialog-header.style';

export type GoalTemplatePreviewHeaderProps = {
  name: string;
};

export const GoalTemplatePreviewDialogHeader: React.FC<
  GoalTemplatePreviewHeaderProps
> = ({ name }) => {
  return (
    <Styled.Container>
      <Typography variant="h5">{name}</Typography>
    </Styled.Container>
  );
};
