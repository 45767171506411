import styled, { css } from 'styled-components';

export const Container = styled.div<{ $hasFlex1: boolean }>`
  max-width: 87.2rem;
  width: 100%;
  margin: 0 auto;

  ${({ $hasFlex1 }) =>
    $hasFlex1 &&
    css`
      flex: 1;
      display: flex;
    `};
`;

export const Row = styled.div<{ $hasFlex1: boolean }>`
  margin: ${({ theme }) => `0 ${theme.spacing(3)}`};

  ${({ $hasFlex1 }) =>
    $hasFlex1 &&
    css`
      flex: 1;
    `};
`;
