import React, { forwardRef, useEffect, useState } from 'react';
import { useForwardedRef } from 'shared/hooks/use-forwarded-ref';
import { useKeysDown } from 'shared/hooks/use-keys-down';

import * as Styled from './editable-text-field.style';

export enum Variants {
  H1 = 'h1',
  H2 = 'h2',
  H5 = 'h5',
  Body2 = 'body2',
}

export type EditableTitleProps = {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  variant?: Variants;
  placeholder?: string;
  isStrikeThrough?: boolean;
  allowMultiline?: boolean;
};

export const EditableTextField = forwardRef<
  HTMLInputElement,
  EditableTitleProps
>(
  (
    {
      value,
      onChange,
      disabled,
      variant = Variants.H1,
      placeholder,
      isStrikeThrough,
      allowMultiline,
    },
    ref,
  ) => {
    const [isEditing, setIsEditing] = useState(false);
    const inputRef = useForwardedRef<HTMLInputElement>(ref);
    const [tempTitle, setTempTitle] = useState(value);

    const updateTitle = (e: React.FormEvent<HTMLHeadingElement>) => {
      setTempTitle(e.currentTarget.innerText);
    };

    const _onFocus = () => {
      setIsEditing(true);
    };

    const submitChange = (e: React.FocusEvent<HTMLHeadingElement>) => {
      setIsEditing(false);
      const newTitle = e.currentTarget.innerText;
      if (!newTitle || tempTitle === value) {
        return;
      }

      onChange(tempTitle);
    };

    useEffect(() => {
      setTempTitle(value);
    }, [value]);

    useKeysDown(['Enter'], (e) => {
      if (document.activeElement === inputRef.current && !allowMultiline) {
        e.preventDefault();
      }
    });

    const handleKeyPress = (e: React.KeyboardEvent) => {
      if (
        document.activeElement === inputRef.current &&
        e.key === 'Enter' &&
        !allowMultiline
      ) {
        e.preventDefault();
        inputRef.current?.blur();
      }
    };

    return (
      <Styled.Typography
        ref={inputRef}
        variant={variant}
        contentEditable={!disabled}
        suppressContentEditableWarning
        onInput={updateTitle}
        onFocus={_onFocus}
        onBlur={submitChange}
        onKeyDown={handleKeyPress}
        $placeholder={placeholder}
        $isStrikeThrough={!isEditing && isStrikeThrough}
      >
        {value}
      </Styled.Typography>
    );
  },
);
