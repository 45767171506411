import { addDays, endOfDay, getWeek, startOfWeek, subWeeks } from 'date-fns';
import { useMemo } from 'react';
import { weekdaysNumberMap } from 'shared/constants';
import { useToday } from 'shared/contexts/today';
import { WeekDays } from 'shared/types/week-days';

import { Week } from '../types/week';

export type Options = {
  amountOfWeeksBack?: number;
};

export const useLastWeeks = (
  weekStartsOn: WeekDays,
  { amountOfWeeksBack = 4 }: Options = {},
): Week[] => {
  const today = useToday();

  return useMemo(
    () =>
      Array.from({ length: amountOfWeeksBack }, (_, weekIndex) => {
        const weekStart = subWeeks(
          startOfWeek(today, { weekStartsOn: weekdaysNumberMap[weekStartsOn] }),
          amountOfWeeksBack - 1 - weekIndex,
        );

        return {
          weekNumber: getWeek(weekStart),
          dates: Object.keys(WeekDays).map((_, dayIndex) => ({
            date: endOfDay(addDays(weekStart, dayIndex)),
          })),
        };
      }),
    [today, amountOfWeeksBack, weekStartsOn],
  );
};
