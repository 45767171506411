import { Active, Over } from '@dnd-kit/core';
import { ID } from 'shared/types/id';

type ItemBase = {
  id: ID;
};

type SectionBase<Item extends ItemBase> = {
  id: ID;
  items: Item[];
  // this means that it does not sort the items, just adds it to the bottom of the section
  isDroppableOnly?: boolean;
};

export const handleSectionItemMovement = <
  Item extends ItemBase,
  Section extends SectionBase<Item>,
>(
  sections: Section[],
  active: Active,
  over: Over,
): { sections: Section[]; activeItem?: Item } => {
  const activeId = active.id.toString();
  const overId = over.id.toString();

  const newSections = structuredClone(sections);
  const activeSection = newSections.find(({ items }) =>
    items.find(({ id }) => id === activeId),
  );
  const overSection = newSections.find(
    ({ items, id }) => overId === id || items.find(({ id }) => id === overId),
  );

  if (!activeSection || !overSection) {
    return { sections };
  }

  const activeItem = activeSection.items.find(({ id }) => id === activeId)!;

  // if the activeId and overId are the same, we do not need to move the item.
  if (activeId === overId) {
    return { sections, activeItem };
  }

  // when hovering over a section (or the overSection is droppable only), add the active task to the end
  if (overId === overSection.id || overSection.isDroppableOnly) {
    // remove the active item from the active section
    activeSection.items = activeSection.items.filter(
      ({ id }) => id !== activeId,
    );

    // add the item to the end on the overSection
    overSection.items.push(activeItem);
    return { sections: newSections, activeItem };
  }

  // we'll need to know if the item was over half of the over item, to know where to place it. On top or below
  const isBelowOverItem =
    over &&
    !!active.rect.current.translated &&
    active.rect.current.translated.top > over.rect.top + over.rect.height / 2;

  const modifier = isBelowOverItem ? 1 : 0;

  // if moving in the same section, we need update the active section
  if (activeSection.id === overSection.id) {
    const filteredItems = overSection.items.filter(({ id }) => id !== activeId);
    const overIndex = overSection.items.findIndex(({ id }) => id === overId);
    const newIndex =
      overIndex >= 0 ? overIndex + modifier : filteredItems.length + 1;
    overSection.items = [
      ...filteredItems.slice(0, newIndex),
      activeItem,
      ...filteredItems.slice(newIndex, overSection.items.length),
    ];
    return { sections: newSections, activeItem };
  }

  // if not moving in the same section, we can just filter out the item of the activeSection and add it to the overSection
  const overIndex = overSection.items.findIndex(({ id }) => id === overId);
  const newIndex =
    overIndex >= 0 ? overIndex + modifier : overSection.items.length + 1;

  activeSection.items = activeSection.items.filter(({ id }) => id !== activeId);
  overSection.items = [
    ...overSection.items.slice(0, newIndex),
    activeItem,
    ...overSection.items.slice(newIndex, overSection.items.length),
  ];

  return { sections: newSections, activeItem };
};
