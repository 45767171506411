import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(2)}`};

  color: ${({ theme }) => theme.palette.grey['500']};
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
`;
