import { Icon } from 'shared/components/ui/icon';
import styled from 'styled-components';

export const Image = styled.img`
  display: block;

  width: 100%;
  height: 100%;

  object-fit: cover;
`;

export const PlaceholderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  background: ${({ theme }) => theme.palette.brown['50']};
`;

export const PlaceholderIcon = styled(Icon)`
  max-width: 5.6rem;
  max-height: 5.6rem;
  width: 50%;
  aspect-ratio: 1;

  color: ${({ theme }) => theme.palette.brown['200']};
`;
