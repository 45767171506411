import { isSameDay } from 'date-fns';
import { useCallback } from 'react';
import { useUpdateTaskMutation } from 'shared/hooks/queries/use-update-task-mutation';
import { useTrackEvents } from 'shared/hooks/use-track-events';
import {
  ActionEvents,
  taskTypeToTrackingTaskType,
} from 'shared/services/tracking';
import { Habit } from 'shared/types/habit';
import { RepeatingTask } from 'shared/types/repeating-task';
import { Timestamp } from 'shared/types/timestamp';

export const useCompleteHabitDate = () => {
  const { mutate: updateTask } = useUpdateTaskMutation();
  const track = useTrackEvents();

  return useCallback(
    (habit: Habit | RepeatingTask, date: Timestamp = new Date()) => {
      const activeSchedule = habit.schedules.find(({ endDate }) => !endDate);
      if (!activeSchedule) {
        return habit;
      }

      const filteredCompletions = activeSchedule.completions.filter(
        (completion) => !isSameDay(completion, date),
      );
      if (filteredCompletions.length === activeSchedule.completions.length) {
        filteredCompletions.push(date);

        // Uncomplete sub-tasks when completing a habit, so these tasks can be completed again in the future
        habit.childIds?.forEach((childId) => {
          updateTask({
            id: childId,
            completedAt: null,
          });
        });
      }

      activeSchedule.completions = filteredCompletions;

      track(ActionEvents.TaskComplete, {
        type: taskTypeToTrackingTaskType[habit.type],
      });

      updateTask(habit);
      return habit;
    },
    [track, updateTask],
  );
};
