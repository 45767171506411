import { useMutation } from '@tanstack/react-query';
import { createTask } from 'shared/services/backend-api';
import { NewTask } from 'shared/types/task';
import { TaskType } from 'shared/types/task-base';

export const useCreateTaskMutation = () =>
  useMutation({
    mutationFn: async (task: NewTask) => {
      const taskToCreate = {
        ...task,
        endStrategy: task.endStrategy
          ? {
              deadline:
                task.endStrategy.deadline && !task.schedules?.length
                  ? task.endStrategy.deadline
                  : null,
              completionCount: task.endStrategy.completionCount ?? null,
            }
          : null,
      };

      if ('hideOnTodo' in task) {
        taskToCreate.hideOnTodo =
          task.type === TaskType.Habit ? task.hideOnTodo : null;
      }

      return createTask(taskToCreate);
    },
  });
