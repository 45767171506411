export enum PushNotificationMessageRequestState {
  Asked = 'asked',
  NotAsked = 'not-asked',
}

const pushNotificationMessageKey = 'push-notification-message';

export const getPushNotificationMessage =
  (): PushNotificationMessageRequestState => {
    const result = window.localStorage.getItem(pushNotificationMessageKey) as
      | PushNotificationMessageRequestState
      | undefined;
    return result ?? PushNotificationMessageRequestState.NotAsked;
  };

export const setPushNotificationMessage = (
  state: PushNotificationMessageRequestState,
) => {
  window.localStorage.setItem(pushNotificationMessageKey, state);
};
