import { ID } from './id';

export enum RoleOption {
  Owner = 'owner',
  All = 'all',
}

export type Roles = {
  [RoleOption.Owner]: ID;
  [RoleOption.All]: ID[];
};
