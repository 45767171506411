import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataGridCreateRow } from 'shared/components/ui/data-grid-create-row';
import { SortMode } from 'shared/hooks/use-data-grid-sorting';
import { ID } from 'shared/types/id';

import { DataHeaderRow } from './data-header-row';
import { DataRow } from './data-row';
import * as Styled from './goal-data-grid.style';
import { ColumnType, RowData } from './types';
import { useColumns } from './use-columns';

export type GoalDataGridViewProps = {
  rows: RowData[];
  columnTypes?: ColumnType[];
  sortMode: SortMode;
  columnToSort?: ColumnType;
  onSortChange: (columnType: ColumnType) => void;
  onGoal?: (id: ID) => void;
  onEditGoal?: (id: ID) => void;
  onCompleteGoal?: (id: ID) => void;
  onArchiveGoal?: (id: ID) => void;
  isArchived?: boolean;
  onFavoriteGoal?: (id: ID) => void;
  onDeleteGoal?: (id: ID) => void;
  onCreateGoal?: () => void;
  hasDndRows?: boolean;
  showPremiumLabel?: boolean;
  hideHeader?: boolean;
  isFrozen?: boolean;
};

export const GoalDataGridView: React.FC<GoalDataGridViewProps> = ({
  rows,
  columnTypes,
  sortMode,
  columnToSort,
  onSortChange,
  onGoal,
  onEditGoal,
  onCompleteGoal,
  onArchiveGoal,
  isArchived,
  onFavoriteGoal,
  onDeleteGoal,
  onCreateGoal,
  showPremiumLabel,
  hasDndRows,
  hideHeader,
  isFrozen,
}) => {
  const { t } = useTranslation();
  const columns = useColumns(columnTypes);

  return (
    <Styled.Container>
      {!hideHeader && (
        <DataHeaderRow
          columns={columns}
          sortMode={sortMode}
          onSortChange={onSortChange}
          columnToSort={columnToSort}
        />
      )}

      <Styled.Body>
        {rows.map((data) => (
          <Styled.BodyItem key={data.id}>
            <DataRow
              columns={columns}
              data={data}
              onGoal={onGoal}
              onEditGoal={onEditGoal}
              onCompleteGoal={onCompleteGoal}
              onArchiveGoal={onArchiveGoal}
              onDeleteGoal={onDeleteGoal}
              onFavoriteGoal={onFavoriteGoal}
              isFavorite={data.isFavorite}
              isSortable={!!hasDndRows}
              isFrozen={isFrozen}
              isArchived={isArchived}
            />
          </Styled.BodyItem>
        ))}

        {!!onCreateGoal && (
          <DataGridCreateRow
            label={t('general.labels.goal-table.footer.create.label')}
            onClick={onCreateGoal}
            showPremiumLabel={showPremiumLabel}
          />
        )}
      </Styled.Body>
    </Styled.Container>
  );
};
