import { useCallback } from 'react';
import { useUpdateUserMutation } from 'shared/hooks/queries/use-update-user-mutation';
import { useUser } from 'shared/hooks/use-user';
import { ID } from 'shared/types/id';
import { FavoriteType } from 'shared/types/user-settings';

export const useToggleFavorite = () => {
  const { mutate } = useUpdateUserMutation();
  const user = useUser();

  return useCallback(
    (id: ID, type: FavoriteType) => {
      const favorites = user?.settings?.favorites ?? [];
      const newFavorites = favorites.filter(
        (favorite) => !(favorite.type === type && favorite.id === id),
      );
      if (newFavorites.length === favorites.length) {
        newFavorites.push({ id, type });
      }
      mutate({ favorites: newFavorites });
    },
    [mutate, user?.settings?.favorites],
  );
};
