import Dialog from '@mui/material/Dialog';
import React, { useMemo } from 'react';
import { GoalProgressForm } from 'shared/components/ui/goal-progress-form';
import { DateFormatOptions } from 'shared/types/date-format-options';
import { MetricEntry, MetricUnit } from 'shared/types/goal-metric';
import { GoalProgressFormFields } from 'shared/types/goal-progress-form';
import { WeekDays } from 'shared/types/week-days';

import { GoalProgressError } from './goal-progress-error';
import { GoalProgressLoading } from './goal-progress-loading';

export type GoalProgressDialogProps = {
  open?: boolean;
  initialValues?: GoalProgressFormFields;
  unit?: MetricUnit;
  target?: number;
  dateFormat: DateFormatOptions;
  weekStartsOn: WeekDays;
  onClose: () => void;
  onSubmit: (entries: GoalProgressFormFields['entries']) => void;
  onRetry: () => void;
  isLoading?: boolean;
  isError?: boolean;
};

const defaultInitialValues: { entries: MetricEntry[] } = {
  entries: [],
};

export const GoalProgressDialog: React.FunctionComponent<
  GoalProgressDialogProps
> = ({
  initialValues = defaultInitialValues,
  unit,
  target,
  dateFormat,
  weekStartsOn,
  open,
  onClose,
  onSubmit,
  onRetry,
  isLoading,
  isError,
}) => {
  const content = useMemo(() => {
    if (isError) {
      return <GoalProgressError onRetry={onRetry} onCancel={onClose} />;
    }

    if (isLoading) {
      return <GoalProgressLoading />;
    }

    return (
      <GoalProgressForm
        entries={initialValues.entries}
        unit={unit}
        target={target}
        dateFormat={dateFormat}
        weekStartsOn={weekStartsOn}
        onSubmit={onSubmit}
        onCancel={onClose}
      />
    );
  }, [
    isError,
    isLoading,
    initialValues.entries,
    unit,
    target,
    dateFormat,
    weekStartsOn,
    onSubmit,
    onClose,
    onRetry,
  ]);

  return (
    <Dialog open={!!open} fullWidth>
      {content}
    </Dialog>
  );
};
