import styled from 'styled-components';

export const Container = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;

export const ToggleLabel = styled.label`
  display: inline-block;
  position: relative;
  width: 3.2rem;
  height: 2rem;

  border-radius: ${({ theme }) => theme.spacing(14)};
  background-color: ${({ theme }) => theme.palette.brown['200']};
  transition: background-color 200ms ease-in-out;
  pointer-events: none;

  &:after {
    content: '';
    position: absolute;
    left: ${({ theme }) => theme.spacing(1)};
    top: 50%;

    width: 1.2rem;
    height: 1.2rem;

    transform: translateY(-50%);
    border-radius: 50%;
    background: ${({ theme }) => theme.palette.primary.contrastText};
    transition: left 200ms ease-in-out;
  }
`;

export const Input = styled.input`
  position: absolute;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;

  border: none;
  visibility: hidden;

  &:checked {
    + ${ToggleLabel} {
      background: ${({ theme }) => theme.palette.primary.main};

      &:after {
        left: calc(100% - 1.2rem - ${({ theme }) => theme.spacing(1)});
      }
    }
  }
`;
