import React, { useRef } from 'react';
import { MoreHorizontal } from 'shared/assets/icons';
import { IconButton, Sizes } from 'shared/components/ui/icon-button';
import { ID } from 'shared/types/id';

import * as Styled from './life-area-data-grid.style';

export type MenuCellProps = {
  id: ID;
  onClick: (event: React.MouseEvent) => void;
  isFrozen?: boolean;
};

export const MenuCell: React.FC<MenuCellProps> = ({ onClick, isFrozen }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const onButton = (e: React.MouseEvent) => {
    e.preventDefault();
    onClick(e);
  };

  return (
    <Styled.DataCell $isFrozen={!!isFrozen} $hasButton>
      <IconButton
        ref={buttonRef}
        icon={MoreHorizontal}
        onClick={!isFrozen ? onButton : undefined}
        size={Sizes.Small}
      />
    </Styled.DataCell>
  );
};
