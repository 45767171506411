import { QueryNavLink } from 'shared/components/connected/query-nav-link';
import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

export const Link = styled(QueryNavLink)<{
  $active: boolean;
  $isMoving: boolean;
  $isFrozen: boolean;
}>`
  ${({ theme }) => typographyToCss(theme.typography.overline)};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};

  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(3)}`};

  border-radius: ${({ theme }) => theme.spacing(2)};
  transition:
    background 200ms,
    color 200ms;

  text-decoration: none;
  color: inherit;

  ${({ theme, $active }) =>
    $active
      ? css`
          background: ${theme.palette.primaryExtended
            .opacityBackgroundBackground};
          color: ${theme.palette.primaryExtended.opacityBackgroundText};
        `
      : css`
          &:hover {
            background: ${theme.palette.brown['50']};
          }
        `};

  ${({ theme, $isMoving }) =>
    $isMoving &&
    css`
      background: ${theme.palette.background.default};

      &:hover {
        background: ${theme.palette.background.default};
      }
    `};

  ${({ $isFrozen }) =>
    $isFrozen &&
    css`
      opacity: 0.5;
    `};
`;

export const IconContainer = styled.span<{ $active: boolean }>`
  display: flex;
  align-items: center;
  transition: color 200ms;

  font-size: 1.4rem;
  color: ${({ theme, $active }) =>
    $active ? 'inherit' : theme.palette.grey['400']};
`;

export const Title = styled.span<{ $completed: boolean }>`
  ${({ theme }) => typographyToCss(theme.typography.body2)}
  display: block;
  flex: 1;

  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ $completed }) =>
    $completed &&
    css`
      text-decoration: line-through;
    `};
`;

export const TimeLeftContainer = styled.span<{ $active: boolean }>`
  color: ${({ theme, $active }) =>
    $active ? 'inherit' : theme.palette.text.secondary};
`;
