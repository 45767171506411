import React, { forwardRef } from 'react';
import { ForwardChevrons } from 'shared/assets/icons';
import { IconButton, Sizes } from 'shared/components/ui/icon-button';

import * as Styled from './detail-column-container.style';
import { ResizableContainer } from './resizable-container';

export type DetailColumnContainerProps = {
  open: boolean;
  onClose: () => void;
  actions?: React.ReactNode[];
  children: React.ReactNode;
};

export const DetailColumnContainer = forwardRef<
  HTMLDivElement,
  DetailColumnContainerProps
>(({ open, onClose, actions = [], children }, ref) => {
  return (
    <ResizableContainer ref={ref} open={open}>
      <Styled.Header>
        <IconButton
          icon={ForwardChevrons}
          size={Sizes.Large}
          onClick={onClose}
        />

        {!!actions.length && (
          <Styled.ActionsContainer>
            {actions.map((action, index) => (
              <React.Fragment key={`action-${index}`}>{action}</React.Fragment>
            ))}
          </Styled.ActionsContainer>
        )}
      </Styled.Header>
      {children}
    </ResizableContainer>
  );
});
