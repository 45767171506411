import styled from 'styled-components';

export const Container = styled.div`
  width: 46.4rem;
`;

export const Header = styled.div`
  padding: ${({ theme }) => `${theme.spacing(5)} ${theme.spacing(6)}`};
`;

export const Body = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacing(6)} ${theme.spacing(5)}`};
`;
