import { isBefore } from 'date-fns';
import {
  useOpenSubscriptionManagement,
  useUpdatePassword,
  useUpdateUser,
} from 'features/user';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Locales } from 'shared/assets/localization';
import { useToday } from 'shared/contexts/today';
import { ChangePasswordDialog } from 'shared/dialogs/change-password';
import { UserSettingsDialog } from 'shared/dialogs/user-settings';
import { useDelayedCallback } from 'shared/hooks/use-delayed-callback';
import { useToggle } from 'shared/hooks/use-toggle';
import { useUser } from 'shared/hooks/use-user';
import {
  DateFormatOptions,
  initialDateFormat,
} from 'shared/types/date-format-options';
import { initialThemeMode, ThemeModes } from 'shared/types/theme-modes';
import { Notifications } from 'shared/types/user-settings';
import { initialWeekStartsOn, WeekDays } from 'shared/types/week-days';

import {
  UserSettingsContext,
  UserSettingsContextProps,
} from './user-settings-context';

export type UserSettingsProviderProps = {
  children: React.ReactNode;
};

export const UserSettingsProvider: React.FC<UserSettingsProviderProps> = ({
  children,
}) => {
  const today = useToday();
  const [dialogOpen, toggleDialog] = useToggle(false);
  const [passwordDialogOpen, togglePasswordDialog, forcePasswordDialog] =
    useToggle(false);
  const user = useUser();
  const { submit: updateUser } = useUpdateUser();
  const {
    updatePassword,
    isLoading: passwordLoading,
    error: passwordError,
    isSuccess: passwordSuccess,
  } = useUpdatePassword();
  const {
    submit: openSubscriptionManagement,
    isLoading: manageSubscriptionLoading,
  } = useOpenSubscriptionManagement();

  const updateUserName = useDelayedCallback(
    useCallback(
      (name: string) => !!name && updateUser({ name }, user),
      [updateUser, user],
    ),
    1000,
  );

  const updateUserLanguage = useCallback(
    (language: Locales) => updateUser({ language }, user),
    [updateUser, user],
  );

  const updateUserDateFormat = useCallback(
    (dateFormat: DateFormatOptions) => updateUser({ dateFormat }, user),
    [updateUser, user],
  );

  const updateWeekStart = useCallback(
    (weekDay: WeekDays) => updateUser({ startOfWeek: weekDay }, user),
    [updateUser, user],
  );

  const updateNotifications = useCallback(
    (notifications: Notifications) => updateUser({ notifications }, user),
    [updateUser, user],
  );

  const updateUserThemePreference = useCallback(
    (themePreference: ThemeModes) => updateUser({ themePreference }, user),
    [updateUser, user],
  );

  const activeSubscription = useMemo(
    () =>
      user?.subscriptions?.find(({ expiresAt }) => isBefore(today, expiresAt)),
    [today, user?.subscriptions],
  );

  const value = useMemo<UserSettingsContextProps>(
    () => ({
      openSettings: toggleDialog,
      openChangePassword: togglePasswordDialog,
    }),
    [toggleDialog, togglePasswordDialog],
  );

  useEffect(() => {
    if (passwordSuccess) {
      forcePasswordDialog(false);
    }
  }, [forcePasswordDialog, passwordSuccess]);

  return (
    <UserSettingsContext.Provider value={value}>
      {children}
      {!!user && (
        <>
          <UserSettingsDialog
            open={dialogOpen}
            onClose={toggleDialog}
            userName={user.name}
            updateUserName={updateUserName}
            language={user.settings?.language}
            onChangeLanguage={updateUserLanguage}
            email={user.email}
            isSignedInWithProvider={user.isSiwa}
            dateFormat={user.settings?.dateFormat ?? initialDateFormat}
            onChangeDateFormat={updateUserDateFormat}
            weekStartsOn={user.settings?.startOfWeek ?? initialWeekStartsOn}
            onChangeWeekStart={updateWeekStart}
            onChangePassword={togglePasswordDialog}
            notifications={user.settings?.notifications}
            onChangeNotifications={updateNotifications}
            themePreference={
              user.settings?.themePreference?.desktop ?? initialThemeMode
            }
            onChangeThemePreference={updateUserThemePreference}
            activeSubscription={activeSubscription}
            onManageSubscription={openSubscriptionManagement}
            manageSubscriptionLoading={manageSubscriptionLoading}
          />
          <ChangePasswordDialog
            open={passwordDialogOpen}
            onSubmit={updatePassword}
            error={passwordError ?? undefined}
            isLoading={passwordLoading}
            onClose={togglePasswordDialog}
          />
        </>
      )}
    </UserSettingsContext.Provider>
  );
};
