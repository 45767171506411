import { useQuery } from '@tanstack/react-query';
import { createSubscriptionQuery } from 'shared/lib/@tanstack-query';
import { getLifeAreas, GetLifeAreasOptions } from 'shared/services/backend-api';
import { ID } from 'shared/types/id';

export const queryKey = 'life-areas';

export const useLifeAreasQuery = (
  uid?: ID,
  options: GetLifeAreasOptions = {},
  enabled = true,
) =>
  useQuery({
    queryKey: [
      queryKey,
      uid ?? '',
      ...Object.entries(options)
        .filter(([, value]) => value !== undefined)
        .map(
          ([key, value]) =>
            `${key}: ${
              value.toSorted().join(',')
              // value instanceof Date
              //   ? value.getTime().toString()
              //   : Array.isArray(value)
              //   ? value.join(',')
              //   : value?.toString()
            }`,
        ),
    ],
    queryFn: createSubscriptionQuery([() => getLifeAreas()]),
    enabled: !!uid && enabled,
  });
