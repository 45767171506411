import { useMutation } from '@tanstack/react-query';
import { endOfDay } from 'date-fns';
import { createGoal } from 'shared/services/backend-api';
import { NewGoal } from 'shared/types/goal';

export const useCreateGoalMutation = () =>
  useMutation({
    mutationFn: async (goal: NewGoal) =>
      createGoal({
        ...goal,
        deadline: goal.deadline ? endOfDay(goal.deadline) : null,
      }),
  });
