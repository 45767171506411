import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Colors, Sizes, Variants } from 'shared/components/ui/button';
import { FormInput } from 'shared/components/ui/form-input';
import {
  ResetPasswordErrorTypes,
  ResetPasswordFormFields,
} from 'shared/types/reset-password-form';

import * as Styled from './reset-password-form.style';
import { useResetPasswordForm } from './use-reset-password-form';

export type ResetPasswordFormProps = {
  onSubmit: (loginData: ResetPasswordFormFields) => void;
  isLoading: boolean;
  error?: ResetPasswordErrorTypes;
};

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
  onSubmit,
  isLoading,
  error,
}) => {
  const { t } = useTranslation();

  const {
    register,
    onSubmit: submitForm,
    errors,
    watch,
  } = useResetPasswordForm(onSubmit);

  const [passwordValue, passwordConfirmationValue] = watch([
    'password',
    'passwordConfirmation',
  ]);
  const submitDisabled =
    isLoading || !passwordValue || !passwordConfirmationValue;

  const passwordErrorMessage = useMemo(() => {
    if (errors.password?.message || errors.passwordConfirmation?.message) {
      return t('forms.reset-password.fields.password.error');
    }
  }, [errors.password?.message, errors.passwordConfirmation?.message, t]);

  const passwordConfirmationErrorMessage = useMemo(() => {
    if (errors.passwordConfirmation?.message) {
      return t('forms.reset-password.fields.password-confirm.error');
    }
  }, [errors.passwordConfirmation?.message, t]);

  return (
    <Styled.Form noValidate onSubmit={submitForm}>
      <Styled.Fields>
        <Styled.FieldContainer>
          <Styled.Label htmlFor="password">
            {t('forms.reset-password.fields.password.label')}
          </Styled.Label>
          <FormInput
            {...register('password')}
            id="password"
            type="password"
            placeholder={t('forms.reset-password.fields.password.placeholder')}
            error={passwordErrorMessage}
            disabled={isLoading}
          />
          {!!passwordErrorMessage && (
            <Styled.ErrorMessage>{passwordErrorMessage}</Styled.ErrorMessage>
          )}
        </Styled.FieldContainer>

        <Styled.FieldContainer>
          <Styled.Label htmlFor="passwordConfirmation">
            {t('forms.reset-password.fields.password-confirmation.label')}
          </Styled.Label>
          <FormInput
            {...register('passwordConfirmation')}
            id="passwordConfirmation"
            type="password"
            placeholder={t(
              'forms.reset-password.fields.password-confirmation.placeholder',
            )}
            error={passwordConfirmationErrorMessage}
            disabled={isLoading}
          />
          {!!passwordConfirmationErrorMessage && (
            <Styled.ErrorMessage>
              {passwordConfirmationErrorMessage}
            </Styled.ErrorMessage>
          )}
          {error === ResetPasswordErrorTypes.Unknown && (
            <Styled.ErrorMessage>
              {t('forms.reset-password.error.unknown')}
            </Styled.ErrorMessage>
          )}
        </Styled.FieldContainer>

        <Styled.Interactions>
          <Button
            variant={Variants.Contained}
            color={Colors.Primary}
            size={Sizes.Large}
            type="submit"
            disabled={submitDisabled}
            fullWidth
            alignCenter
            disabledBackgroundOnly={isLoading}
          >
            {isLoading ? (
              <Styled.Loader color="primary" size={20} />
            ) : (
              t('forms.reset-password.buttons.submit.label')
            )}
          </Button>
        </Styled.Interactions>
      </Styled.Fields>
    </Styled.Form>
  );
};
