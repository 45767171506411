import Dialog from '@mui/material/Dialog';
import React, { useMemo } from 'react';
import { LifeAreaForm } from 'shared/components/ui/life-area-form';
import { LifeAreaFormFields } from 'shared/types/life-area-form';

import { LifeAreaFormError } from './life-area-form-error';
import { LifeAreaFormLoading } from './life-area-form-loading';

export type LifeAreaFormDialogProps = {
  initialValues?: LifeAreaFormFields;
  open?: boolean;
  onClose: () => void;
  onSubmit: (lifeArea: LifeAreaFormFields) => void;
  onRetry: () => void;
  isLoading?: boolean;
  isError?: boolean;
  isEdit?: boolean;
  isCustomIconAllowed?: boolean;
  onPremium?: () => void;
};

export const LifeAreaFormDialog: React.FC<LifeAreaFormDialogProps> = ({
  initialValues,
  open,
  onClose,
  onSubmit,
  onRetry,
  isLoading,
  isError,
  isEdit,
  isCustomIconAllowed,
  onPremium,
}) => {
  const content = useMemo(() => {
    if (isError) {
      return (
        <LifeAreaFormError
          onRetry={onRetry}
          onCancel={onClose}
          isEdit={isEdit}
        />
      );
    }
    if (isLoading) {
      return <LifeAreaFormLoading />;
    }

    return (
      <LifeAreaForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        onCancel={onClose}
        isEdit={isEdit}
        isCustomIconAllowed={isCustomIconAllowed}
        onPremium={onPremium}
      />
    );
  }, [
    initialValues,
    isCustomIconAllowed,
    isEdit,
    isError,
    isLoading,
    onClose,
    onPremium,
    onRetry,
    onSubmit,
  ]);

  return (
    <Dialog open={!!open} fullWidth onClose={!isLoading ? onClose : undefined}>
      {content}
    </Dialog>
  );
};
