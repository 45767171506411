import { createContext } from 'react';
import { ID } from 'shared/types/id';

export type TaskDetailContextProps = {
  openTaskDetail: (task: ID | undefined) => void;
  activeTaskId: ID | undefined;
};

export const TaskDetailContext = createContext<TaskDetailContextProps>({
  openTaskDetail: () => {},
  activeTaskId: undefined,
});
