import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(6)};

  padding: ${({ theme }) => `${theme.spacing(5)} ${theme.spacing(6)}`};
  min-height: 32rem;
`;
