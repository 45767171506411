import styled from 'styled-components';

export const Container = styled.button`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  align-items: center;

  margin: 0;
  padding: ${({ theme }) => `0 ${theme.spacing(1)}`};
  height: 3rem;
  width: 100%;

  border: none;
  background: transparent;
  cursor: pointer;

  color: ${({ theme }) => theme.palette.text.secondary};
  line-height: 62.5%;
`;

export const CheckboxContainer = styled.span`
  line-height: 62.5%;
`;
