import React from 'react';
import { GridContainer } from 'shared/components/ui/grid-container';
import { useTrackPageVisitEvent } from 'shared/hooks/use-track-page-visit-event';
import { PageEvents } from 'shared/services/tracking';

import { TabPanel } from './components/tab-panel';
import { VisionDetail } from './components/vision-detail';

export const Vision: React.FC = () => {
  useTrackPageVisitEvent(PageEvents.LifeAreaDetailVision, undefined);

  return (
    <GridContainer hasFlex1>
      <TabPanel>
        <VisionDetail />
      </TabPanel>
    </GridContainer>
  );
};
