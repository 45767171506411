import styled from 'styled-components';

export const Container = styled.button`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(4)};
  margin: 0;
  width: 100%;
  height: 100%;

  cursor: pointer;
  background: ${({ theme }) => theme.palette.background.default};
  border-radius: ${({ theme }) => theme.spacing(2)};
  border: 1px solid ${({ theme }) => theme.palette.divider};
  box-shadow: ${({ theme }) => theme.shadowBox.card};

  text-align: left;
`;

export const Illustration = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  width: 4rem;

  border-radius: ${({ theme }) => theme.spacing(2)};
  background: ${({ theme }) => theme.palette.warning.light};
  aspect-ratio: 1;

  color: ${({ theme }) => theme.palette.warning.dark};
  font-size: 2rem;
`;

export const Content = styled.div``;

export const Description = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const MetaContent = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  max-width: 100%;
`;
