import { generateNonce } from 'shared/utils/generate-nonce';

import { set } from './set';
import { Nonce } from './types';

export const create = async () => {
  const generated = await generateNonce();

  const nonce: Nonce = { raw: generated.nonce, hashed: generated.hashNonce };
  set(nonce);

  return nonce;
};
