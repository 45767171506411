import React from 'react';
import { X } from 'shared/assets/icons';
import { IconButton, Sizes, Variants } from 'shared/components/ui/icon-button';
import { useActiveTheme } from 'shared/lib/mui';
import { ThemeModes } from 'shared/types/theme-modes';

import * as Styled from './header.style';

export type HeaderProps = {
  onClose: () => void;
};

export const Header: React.FC<HeaderProps> = ({ onClose }) => {
  const [themeMode] = useActiveTheme();

  return (
    <Styled.Container>
      {themeMode === ThemeModes.Dark ? (
        <Styled.DesktopImage
          src="/images/premium/success/premium-dialog-success-header-graphic-dark.png"
          // eslint-disable-next-line max-len -- long file names
          srcSet="/images/premium/success/premium-dialog-success-header-graphic-dark@2x.png, /images/premium/success/premium-dialog-success-header-graphic-dark@3x.png"
        />
      ) : (
        <Styled.DesktopImage
          src="/images/premium/success/premium-dialog-success-header-graphic-light.png"
          // eslint-disable-next-line max-len -- long file names
          srcSet="/images/premium/success/premium-dialog-success-header-graphic-light@2x.png, /images/premium/success/premium-dialog-success-header-graphic-light@3x.png"
        />
      )}

      <Styled.CloseButtonContainer>
        <IconButton
          icon={X}
          onClick={onClose}
          variant={Variants.Contained}
          size={Sizes.Large}
        />
      </Styled.CloseButtonContainer>
    </Styled.Container>
  );
};
