import React from 'react';

import * as Styled from './overlay-component-wrapper.style';

export type OverlayComponentWrapperProps = {
  children: React.ReactNode;
};

export const OverlayComponentWrapper: React.FC<
  OverlayComponentWrapperProps
> = ({ children }) => <Styled.OverlayWrapper>{children}</Styled.OverlayWrapper>;
