import {
  Analytics,
  getAnalytics as getFBAnalytics,
  setAnalyticsCollectionEnabled,
} from 'firebase/analytics';
import { FirebaseApp } from 'firebase/app';

let analytics: Analytics | undefined;

export const getAnalytics = (app: FirebaseApp) => {
  if (!analytics) {
    analytics = getFBAnalytics(app);
  }
  return analytics;
};

export const clearAnalytics = () => {
  if (analytics) {
    setAnalyticsCollectionEnabled(analytics, false);
  }
  analytics = undefined;
};
