import { createContext } from 'react';
import { Habit } from 'shared/types/habit';

export type RemoveHabitScheduleContextProps = {
  onRemoveHabitSchedule: (habit: Habit) => void;
};

export const RemoveHabitScheduleContext =
  createContext<RemoveHabitScheduleContextProps>({
    onRemoveHabitSchedule: () => {},
  });
