import { LifeArea, LocalizedLifeArea } from 'shared/types/life-area';

import { LifeAreaTrackTypes } from './models/life-area-track-types';

const lifeAreaTemplateIdToLifeAreaTrackTypesMap: Record<
  string,
  LifeAreaTrackTypes
> = {
  education_and_learning: LifeAreaTrackTypes.EducationAndLearning,
  family_and_friends: LifeAreaTrackTypes.FamilyAndFriends,
  fun_and_relaxation: LifeAreaTrackTypes.FunAndRelaxation,
  love_and_relationships: LifeAreaTrackTypes.LoveAndRelationships,
  money_and_finance: LifeAreaTrackTypes.MoneyAndFinances,
  personal_development: LifeAreaTrackTypes.PersonalDevelopment,
  spirituality: LifeAreaTrackTypes.Spirituality,
  sport_and_health: LifeAreaTrackTypes.SportAndHealth,
  work_and_career: LifeAreaTrackTypes.WorkAndCareer,
};

export const lifeAreaToTrackingLifeAreaType = (
  lifeArea?: LifeArea | LocalizedLifeArea,
): LifeAreaTrackTypes | undefined => {
  if (!lifeArea) {
    return;
  }

  return (
    lifeAreaTemplateIdToLifeAreaTrackTypesMap[lifeArea.templateId!] ??
    LifeAreaTrackTypes.Custom
  );
};
