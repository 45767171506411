import { IconFormNames } from 'shared/assets/icons';
import {
  FrequencyUnit,
  HabitSchedule,
  RruleReferenceDate,
} from 'shared/types/habit-schedule';
import { ID } from 'shared/types/id';
import { TaskPriorityOptions, TaskType } from 'shared/types/task-base';
import { Timestamp } from 'shared/types/timestamp';
import * as yup from 'yup';

export type NewHabit = {
  name: string;
  iconName: IconFormNames | null;
  type: TaskType.Habit | TaskType.Repeating;
  hideOnTodo?: boolean | null;
  goalId?: ID | null;
  lifeAreaId?: ID | null;
  priority?: TaskPriorityOptions | null;
  deadline?: Timestamp | null;
  reminderTime?: number | null;
  schedules?: HabitSchedule[];
};

export const validationSchema: yup.ObjectSchema<NewHabit> = yup.object({
  name: yup.string().required(),
  type: yup.string().oneOf([TaskType.Habit, TaskType.Repeating]).required(),
  hideOnTodo: yup.bool().nullable(),
  iconName: yup
    .string<IconFormNames>()
    .oneOf(Object.values(IconFormNames))
    .required(),
  goalId: yup.string().nullable(),
  lifeAreaId: yup.string().nullable(),
  deadline: yup.date().nullable(),
  reminderTime: yup.number().nullable(),
  priority: yup.string().oneOf(Object.values(TaskPriorityOptions)).nullable(),
  schedules: yup
    .array(
      yup.object({
        startDate: yup.date().required(),
        rrule: yup
          .object({
            format: yup.string().required(),
            referenceDate: yup
              .string<RruleReferenceDate>()
              .oneOf(Object.values(RruleReferenceDate))
              .required(),
          })
          .required(),
        frequency: yup
          .object({
            count: yup.number().required(),
            unit: yup
              .string<FrequencyUnit>()
              .oneOf(Object.values(FrequencyUnit))
              .required(),
          })
          .nullable(),
        completions: yup.array().required(),
        endDate: yup.date().nullable(),
      }),
    )
    .required(),
});

export type HabitFormFields = yup.InferType<typeof validationSchema>;
