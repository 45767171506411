import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image as ImageIcon } from 'shared/assets/icons';
import { PopupMenuInput } from 'shared/components/ui/popup-menu';

export type ImageUploadProps = {
  onChange: (image: File) => void;
};

export const PopupMenuUpload: React.FC<ImageUploadProps> = ({ onChange }) => {
  const { t } = useTranslation();
  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.[0]) {
      return;
    }

    onChange(e.target.files[0]);
  };

  return (
    <PopupMenuInput
      startIcon={ImageIcon}
      type="file"
      accept="image/*"
      onChange={onFileChange}
      inputHidden
    >
      {t('context-menus.image-source-picker.options.upload.label')}
    </PopupMenuInput>
  );
};
