import { getDocs, query, where } from 'firebase/firestore';
import { ID } from 'shared/types/id';

import { goalTemplateConverter } from './converters/goal-template';
import { CollectionOptions, getCollection } from './helpers';

export const getGoalTemplateByGoalId = async (goalId: ID) => {
  const result = await getDocs(
    query(
      getCollection(CollectionOptions.GoalTemplates).withConverter(
        goalTemplateConverter,
      ),
      where(`originGoalIdsMap.${goalId}`, '==', null),
    ),
  );

  return result.docs[0]?.data() ?? null;
};
