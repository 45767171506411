import { useMemo } from 'react';
import { useTasksQuery } from 'shared/hooks/queries/use-tasks-query';
import { useUser } from 'shared/hooks/use-user';
import { GetTaskOptions } from 'shared/services/backend-api';
import { ID } from 'shared/types/id';

type Options = Omit<GetTaskOptions, 'goals'>;

export const useTasksByGoalId = (id: ID = '', options: Options = {}) => {
  const user = useUser();
  const { data } = useTasksQuery(
    user?.uid,
    {
      ...options,
      goals: [id],
    },
    !!id,
  );

  return useMemo(() => data || [], [data]);
};
