import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useResetPasswordAndLoginMutation } from 'shared/hooks/queries/use-reset-password-and-login-mutation';
import { Paths } from 'shared/routes';

export const useResetPasswordAndLogin = () => {
  const navigate = useNavigate();
  const { mutateAsync, error, reset, isPending, isSuccess } =
    useResetPasswordAndLoginMutation();

  const resetPasswordAndLogin = useCallback(
    async (
      oobCode: string,
      { password, name }: { password: string; name?: string },
    ) => {
      const user = await mutateAsync({ code: oobCode, password, name });
      if (user) {
        navigate(Paths.Home);
      }
    },
    [mutateAsync, navigate],
  );

  return {
    resetPasswordAndLogin,
    error,
    isLoading: isPending,
    isSuccess,
    reset,
  };
};
