import { useMutation } from '@tanstack/react-query';
import {
  createEntriesFromGoalTemplate,
  CreateEntriesFromGoalTemplateOptions,
} from 'shared/services/backend-api';

export const useCreateEntriesFromGoalTemplateMutation = () =>
  useMutation({
    mutationFn: (options: CreateEntriesFromGoalTemplateOptions) =>
      createEntriesFromGoalTemplate(options),
  });
