import { useCallback } from 'react';
import { useUpdateGoalMutation } from 'shared/hooks/queries/use-update-goal-mutation';
import { useTrackEvents } from 'shared/hooks/use-track-events';
import { ActionEvents } from 'shared/services/tracking';
import { Goal } from 'shared/types/goal';

export const useCompleteGoal = () => {
  const { mutate: updateGoal } = useUpdateGoalMutation();
  const track = useTrackEvents();

  return useCallback(
    (goal: Goal) => {
      const goalToUpdate = {
        ...goal,
        completedAt: goal.completedAt ? null : new Date(),
      };

      updateGoal({ goal: goalToUpdate, forceValues: true });
      goalToUpdate.completedAt && track(ActionEvents.GoalComplete, undefined);

      return goalToUpdate;
    },
    [track, updateGoal],
  );
};
