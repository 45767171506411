import { doc } from 'firebase/firestore';
import { Vision } from 'shared/types/vision';

import { dateToFirestoreTimestamp } from './converters/date-to-firestore-timestamp';
import {
  CollectionOptions,
  getCollection,
  updateDocWithTimestamp,
} from './helpers';

export const updateVision = async (vision: Vision) => {
  const visionCollection = getCollection(CollectionOptions.Visions);
  const docRef = doc(visionCollection, vision.id);
  updateDocWithTimestamp(docRef, dateToFirestoreTimestamp(vision));
};
