// component
import React, { useEffect, useRef } from 'react';

import * as Styled from './auto-size-text-area.style';

export type AutoSizeTextAreaProps = Omit<
  React.ComponentProps<'textarea'>,
  'value'
> & {
  value: string;
};

export const AutoSizeTextArea: React.FC<AutoSizeTextAreaProps> = ({
  value,
  onChange,
  ...rest
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.setAttribute(
        'data-replicated-value',
        value ? value + '\u00A0' : '',
      );
    }
  }, [value]);

  const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (containerRef.current) {
      containerRef.current.setAttribute(
        'data-replicated-value',
        e.target.value + '\u00A0',
      );
    }
    onChange && onChange(e);
  };

  return (
    <Styled.Container ref={containerRef}>
      <Styled.TextArea
        ref={textAreaRef}
        value={value}
        onChange={handleInput}
        rows={1}
        {...rest}
      />
    </Styled.Container>
  );
};
