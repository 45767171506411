import { doc } from 'firebase/firestore';
import { ID } from 'shared/types/id';
import { RoleOption } from 'shared/types/roles';

import { CollectionOptions } from './collection-options';
import { getAuthentication } from './get-authentication';
import { getCollection } from './get-collection';
import { setDocWithTimestamp } from './set-doc-with-timestamp';

type ReturnType<T> = T & { id: ID };

export const addDocumentWithOwnerRole = async <T extends {}>(
  collection: CollectionOptions,
  data: T,
): Promise<ReturnType<T>> => {
  // Get the currently authenticated user
  const user = (await getAuthentication()).currentUser;

  if (!user) {
    throw Error('unauthenticated');
  }

  // Create a new reference to the Firestore collection
  const newDoc = doc(getCollection(collection));

  const newDocData = {
    ...data,
    id: newDoc.id,
    roles: { [RoleOption.Owner]: user.uid, [RoleOption.All]: [user.uid] },
  } as ReturnType<T>;

  // Add the document with user's role to Firestore
  // specifically no await, to support offline usage of the app
  setDocWithTimestamp(newDoc, newDocData);

  return newDocData;
};
