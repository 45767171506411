import React from 'react';

import { AppleButton } from './apple-button';
import * as Styled from './third-party-signup-buttons.style';

export type ThirdPartySignupButtonsProps = {
  isLoading?: boolean;
  onAppleLogin: () => void;
};

export const ThirdPartySignupButtons: React.FC<
  ThirdPartySignupButtonsProps
> = ({ isLoading, onAppleLogin }) => {
  return (
    <Styled.List>
      <Styled.ListItem>
        <AppleButton isLoading={isLoading} onClick={onAppleLogin} />
      </Styled.ListItem>
    </Styled.List>
  );
};
