import { useMutation } from '@tanstack/react-query';
import { updateTask } from 'shared/services/backend-api';
import { UpdateTask } from 'shared/types/task';
import { TaskType } from 'shared/types/task-base';

export const useUpdateTaskMutation = () =>
  useMutation({
    mutationFn: async (task: UpdateTask) => {
      let taskToUpdate = { ...task };

      if ('endStrategy' in task) {
        taskToUpdate.endStrategy = task.endStrategy
          ? {
              deadline:
                task.endStrategy?.deadline && !task.schedules?.length
                  ? task.endStrategy.deadline
                  : null,
              completionCount: task.endStrategy.completionCount ?? null,
            }
          : null;
      }

      if ('reminderTime' in task) {
        taskToUpdate.reminderTime = task.reminderTime ?? null;
      }

      if ('hideOnTodo' in task) {
        taskToUpdate.hideOnTodo =
          task.type === TaskType.Habit ? task.hideOnTodo : null;
      }

      return updateTask(taskToUpdate);
    },
  });
