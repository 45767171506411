export enum PageEvents {
  Login = 'log_in',
  Signup = 'sign_up',
  CompleteSignup = 'complete_signup',
  Today = 'today',
  Inbox = 'inbox',
  Upcoming = 'upcoming',
  AllTasks = 'all_tasks',
  Habits = 'habits',
  LifeAreas = 'life_areas',
  LifeAreaDetailOverview = 'life_area_detail_overview',
  LifeAreaDetailVision = 'life_area_detail_vision',
  LifeAreaDetailGoals = 'life_area_detail_goals',
  LifeAreaDetailTasks = 'life_area_detail_tasks',
  LifeAreaDetailAchievements = 'life_area_detail_achievements',
  Goals = 'goals',
  GoalsDetail = 'goal_detail',
  GoalTemplatesOverview = 'goal_templates_overview',
  Journal = 'journal',
}
