import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};
`;

const GridStyling = css`
  display: grid;
  grid-template-columns: ${({ theme }) =>
    `1fr ${theme.spacing(18)} ${theme.spacing(18)}`};
  grid-row-gap: ${({ theme }) => theme.spacing(3)};
  align-items: center;
`;

export const Header = styled.div`
  ${GridStyling};

  padding: ${({ theme }) => `${theme.spacing(2)} 0`};

  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};

  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const Body = styled.div`
  ${GridStyling};
  justify-items: center;
`;

export const Label = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};

  width: 100%;
`;

export const Description = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.overline)};

  color: ${({ theme }) => theme.palette.text.secondary};
`;
