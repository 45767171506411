import React, { forwardRef, useEffect } from 'react';
import { ChevronLeft } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { useForwardedRef } from 'shared/hooks/use-forwarded-ref';

import * as Styled from './form-select.style';

export type Option = {
  value: string | number;
  label: string | number;
};

export type FormSelectProps = React.ComponentProps<'select'> & {
  options: Option[];
  label?: React.ReactNode;
  error?: string;
  isSmall?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
};

export const FormSelect = forwardRef<HTMLSelectElement, FormSelectProps>(
  (
    {
      label,
      options,
      value,
      id,
      disabled,
      error,
      isSmall,
      fullWidth,
      placeholder,
      onChange,
      ...rest
    },
    ref,
  ) => {
    const selectRef = useForwardedRef(ref);

    const _onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      e.target.setCustomValidity(e.target.value ? '' : ' ');
      onChange?.(e);
    };

    useEffect(() => {
      const element = selectRef.current;
      if (!placeholder || element?.value) {
        return;
      }

      element?.setCustomValidity(' ');
    }, [placeholder, selectRef]);

    return (
      <Styled.Container>
        {!!label && (
          <Styled.Label htmlFor={id} $disabled={!!disabled}>
            {label}
          </Styled.Label>
        )}
        <Styled.SelectContainer $fullWidth={!!fullWidth}>
          <Styled.Select
            value={value}
            onChange={_onChange}
            id={id}
            disabled={disabled}
            {...rest}
            $hasError={!!error}
            $isSmall={!!isSmall}
            ref={selectRef}
          >
            {!!placeholder && (
              <Styled.Option value="">{placeholder}</Styled.Option>
            )}
            {options.map(({ value, label }) => (
              <Styled.Option key={value} value={value}>
                {label}
              </Styled.Option>
            ))}
          </Styled.Select>

          <Styled.ChevronContainer>
            <Icon icon={ChevronLeft} />
          </Styled.ChevronContainer>
        </Styled.SelectContainer>
      </Styled.Container>
    );
  },
);
