import Typography from '@mui/material/Typography';
import React from 'react';

import * as Styled from './chip.style';
import { Colors, Sizes, Variants } from './types';

export type ChipProps = {
  variant?: Variants;
  color?: Colors;
  size?: Sizes;
  label: string;
};

export const Chip: React.FC<ChipProps> = ({
  variant = Variants.Filled,
  size = Sizes.Medium,
  color = Colors.Default,
  label,
}) => (
  <Styled.Container $size={size} $color={color} $variant={variant}>
    <Typography variant={size === Sizes.Medium ? 'subtitle2' : 'buttonSmall'}>
      {label}
    </Typography>
  </Styled.Container>
);
