import React from 'react';
import { GridContainer } from 'shared/components/ui/grid-container';
import { useTrackPageVisitEvent } from 'shared/hooks/use-track-page-visit-event';
import { PageEvents } from 'shared/services/tracking';

import { Header } from './components/header';
import { Overview } from './components/overview';

export const LifeAreaOverview: React.FC = () => {
  useTrackPageVisitEvent(PageEvents.LifeAreas, undefined);

  return (
    <GridContainer>
      <Header />
      <Overview />
    </GridContainer>
  );
};
