import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;

  height: 19rem;
  padding: ${({ theme }) => theme.spacing(6)};

  background: ${({ theme }) => theme.gradients.premium};
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.up(theme.breakpoints.values.sm)} {
    padding: ${({ theme }) => theme.spacing(10)};
    height: 20.8rem;
  }
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacing(3.5)};
  right: ${({ theme }) => theme.spacing(3.5)};
  color: ${({ theme }) => theme.palette.common.white};
`;

export const DesktopImage = styled.img`
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  bottom: 0;
  height: 100%;

  transform: translateX(-50%);
`;
