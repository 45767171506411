import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Locales } from 'shared/assets/localization';
import { useToday } from 'shared/contexts/today';
import { useCreateEntriesFromGoalTemplateMutation } from 'shared/hooks/queries/use-create-entries-from-goal-template-mutation';
import { Paths } from 'shared/routes';
import { ID } from 'shared/types/id';
import { replaceUrlParams } from 'shared/utils/replace-url-params';

export const useCreateEntriesFromGoalTemplate = () => {
  const templateIdRef = useRef<ID>();

  const { i18n } = useTranslation();
  const { mutateAsync, isSuccess, isPending, error, reset } =
    useCreateEntriesFromGoalTemplateMutation();
  const today = useToday();
  const navigate = useNavigate();

  const submit = useCallback(
    async (id: ID) => {
      templateIdRef.current = id;

      const { mainGoalId } = await mutateAsync({
        id,
        locale: i18n.language as Locales,
        startDate: today,
      });

      navigate(replaceUrlParams(Paths.GoalDetail, { id: mainGoalId }));
    },
    [i18n.language, mutateAsync, navigate, today],
  );

  const retry = useCallback(async () => {
    const id = templateIdRef.current;
    if (!id) {
      return;
    }

    const { mainGoalId } = await mutateAsync({
      id,
      locale: i18n.language as Locales,
      startDate: today,
    });

    navigate(replaceUrlParams(Paths.GoalDetail, { id: mainGoalId }));
  }, [i18n.language, mutateAsync, navigate, today]);

  const resetAll = useCallback(() => {
    reset();
    templateIdRef.current = undefined;
  }, [reset]);

  return {
    submit,
    retry,
    reset: resetAll,
    error,
    isLoading: isPending,
    isSuccess,
  };
};
