import { useCallback, useRef } from 'react';
import { useCreateGoalMutation } from 'shared/hooks/queries/use-create-goal-mutation';
import { useTrackEvents } from 'shared/hooks/use-track-events';
import {
  ActionEvents,
  goalMetricIdToTrackingMetricTemplate,
  lifeAreaToTrackingLifeAreaType,
} from 'shared/services/tracking';
import { NewGoal } from 'shared/types/goal';
import { LifeArea } from 'shared/types/life-area';

export const useCreateGoal = () => {
  const newGoalRef = useRef<NewGoal>();

  const track = useTrackEvents();
  const { mutateAsync, error, isPending, isSuccess, reset } =
    useCreateGoalMutation();

  const submit = useCallback(
    async (goal: NewGoal, { lifeArea }: { lifeArea: LifeArea }) => {
      const isSubGoal = !!goal.parentIds?.length;

      track(isSubGoal ? ActionEvents.SubGoalCreate : ActionEvents.GoalCreate, {
        life_area: lifeAreaToTrackingLifeAreaType(lifeArea),
        image_added: !!goal.image,
        image_source: goal.image
          ? goal.image?.metadata?.source === 'unsplash'
            ? 'unsplash'
            : 'custom'
          : undefined,
        deadline: !!goal.deadline,
        target: goalMetricIdToTrackingMetricTemplate(goal.metric?.id),
      });

      newGoalRef.current = goal;
      return mutateAsync(goal);
    },
    [mutateAsync, track],
  );

  const retry = useCallback(() => {
    if (newGoalRef.current) {
      mutateAsync(newGoalRef.current);
    }
  }, [mutateAsync]);

  const resetAll = useCallback(() => {
    reset();
    newGoalRef.current = undefined;
  }, [reset]);

  return {
    submit,
    retry,
    reset: resetAll,
    error,
    isLoading: isPending,
    isSuccess,
  };
};
