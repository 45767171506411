import { useUpdateTodayGroupBy, useUpdateTodoSortMode } from 'features/user';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from 'shared/components/ui/page-header';
import { useUser } from 'shared/hooks/use-user';

import { HeaderInteractions } from '../header-interactions';

export const Header: React.FC = () => {
  const { t } = useTranslation();
  const setSortMode = useUpdateTodoSortMode();
  const setGroupBy = useUpdateTodayGroupBy();
  const user = useUser();

  return (
    <PageHeader
      title={t('pages.today.title')}
      interactions={
        <HeaderInteractions
          sort={user?.settings?.todoSortingMode}
          groupBy={user?.settings?.todayGroupBy}
          onSort={setSortMode}
          onGroupBy={setGroupBy}
        />
      }
    />
  );
};
