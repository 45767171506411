import { BlurHashImage } from 'shared/components/connected/blur-hash-image';
import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

export const Container = styled.span`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
  width: 100%;
`;

export const IllustrationContainer = styled.span`
  position: relative;
  display: block;
`;

export const ImageContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 2.4rem;
  width: 2.4rem;
  min-width: 2.4rem;

  border-radius: ${({ theme }) => theme.spacing(2)};
  background: ${({ theme }) => theme.palette.brown['100']};
  overflow: hidden;

  font-size: 1.6rem;
  line-height: 62.5%;
  color: ${({ theme }) => theme.palette.brown['300']};
`;

export const CompletedContainer = styled.span`
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 1.2rem;
  height: 1.2rem;

  transform: translate(33%, -33%);
  background: ${({ theme }) => theme.palette.success.main};
  border-radius: 50%;

  font-size: 0.8rem;
  color: ${({ theme }) => theme.palette.success.contrastText};
`;

export const ColorDot = styled.span<{ $color: string }>`
  position: absolute;
  bottom: ${({ theme }) => `-${theme.spacing(0.75)}`};
  right: ${({ theme }) => `-${theme.spacing(0.75)}`};

  display: block;
  height: 1rem;
  width: 1rem;

  border: ${({ theme }) =>
    `${theme.spacing(0.5)} solid ${theme.palette.background.default};`};
  border-radius: 50%;
  background: ${({ $color }) => $color};
`;

export const Image = styled(BlurHashImage)`
  display: block;
  width: 100%;
  height: 100%;

  object-fit: cover;
`;

export const Title = styled.span<{ $isFrozen: boolean; $isCompleted: boolean }>`
  ${({ theme }) => typographyToCss(theme.typography.subtitle2)};

  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ $isFrozen, theme }) =>
    $isFrozen &&
    css`
      color: ${theme.palette.text.secondary};
    `};

  ${({ $isCompleted }) =>
    $isCompleted &&
    css`
      text-decoration: line-through;
    `};
`;

export const IconContainer = styled.span`
  display: inline-block;

  font-size: 1.6rem;
  line-height: 62.5%;
  color: ${({ theme }) => theme.palette.text.secondary};
`;
