import { useCallback } from 'react';
import { useUpdateLifeAreaMutation } from 'shared/hooks/queries/use-update-life-area-mutation';
import { useTrackEvents } from 'shared/hooks/use-track-events';
import {
  ActionEvents,
  lifeAreaToTrackingLifeAreaType,
} from 'shared/services/tracking';
import { LifeArea, LocalizedLifeArea } from 'shared/types/life-area';

export const useArchiveLifeArea = () => {
  const { mutate: updateLifeArea } = useUpdateLifeAreaMutation();
  const track = useTrackEvents();

  return useCallback(
    (lifeArea: LifeArea | LocalizedLifeArea) => {
      const lifeAreaToUpdate = {
        ...lifeArea,
        archivedAt: lifeArea.archivedAt ? null : new Date(),
      };
      track(ActionEvents.LifeAreaArchive, {
        life_area: lifeAreaToTrackingLifeAreaType(lifeArea)!,
      });

      updateLifeArea(lifeAreaToUpdate);

      return lifeAreaToUpdate;
    },
    [track, updateLifeArea],
  );
};
