import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronLeft } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { PopupMenu } from 'shared/components/ui/popup-menu';
import { RepeatRruleForm } from 'shared/components/ui/repeat-rrule-form';
import { useToday } from 'shared/contexts/today';
import { useClickOutside } from 'shared/hooks/use-click-outside';
import { useContextMenuState } from 'shared/hooks/use-context-menu-state';
import { HabitSchedule } from 'shared/types/habit-schedule';
import { TaskType } from 'shared/types/task-base';
import { WeekDays } from 'shared/types/week-days';
import { getScheduleTranslationKey } from 'shared/utils/get-schedule-translation-key';

import * as Styled from './repeat-form-input.style';

export type RepeatFormInputProps = {
  onChange: (schedules: HabitSchedule[] | null) => void;
  onChangeType: (type: TaskType.Habit | TaskType.Repeating) => void;
  value: HabitSchedule[];
  type?: TaskType.Habit | TaskType.Repeating;
  createHabitAllowed: boolean;
  weekStartsOn: WeekDays;
  error?: string;
};

export const RepeatFormInput: React.FC<RepeatFormInputProps> = ({
  onChange,
  onChangeType,
  value,
  type,
  weekStartsOn,
  createHabitAllowed,
  error,
}) => {
  const { t } = useTranslation();
  const today = useToday();
  const shouldReplaceRef = useRef(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const {
    open: openOptions,
    close: closeOptions,
    position: optionsPosition,
  } = useContextMenuState();

  const _onChange = (schedule: HabitSchedule | null) => {
    if (!schedule) {
      onChange(null);
      closeOptions();
      return;
    }

    const endedSchedules = value.filter(({ endDate }) => !!endDate);
    const updateSchedules = [];

    if (!shouldReplaceRef.current) {
      const scheduleToEnd = value.find(({ endDate }) => !endDate);
      !!scheduleToEnd &&
        updateSchedules.push({ ...scheduleToEnd, endDate: today });
      shouldReplaceRef.current = true;
    }

    updateSchedules.push(schedule);

    onChange([...endedSchedules, ...updateSchedules]);
    closeOptions();
  };

  const val = useMemo(() => {
    const activeSchedule = value.find(({ endDate }) => !endDate);
    return activeSchedule ?? value[value.length - 1];
  }, [value]);

  useClickOutside(containerRef, closeOptions);

  return (
    <Styled.Container ref={containerRef}>
      <Styled.Button
        $placeholder={!val}
        onClick={openOptions}
        $hasError={!!error}
        type="button"
      >
        <Styled.Label>
          {val
            ? getScheduleTranslationKey(val, t)
            : t('forms.habit.fields.repeat.placeholder')}
        </Styled.Label>

        <Styled.ChevronContainer>
          <Icon icon={ChevronLeft} />
        </Styled.ChevronContainer>
      </Styled.Button>

      {optionsPosition && (
        <PopupMenu referenceElement={containerRef} position="bottom-start">
          <RepeatRruleForm
            initialRrule={val?.rrule?.format}
            initialCount={val?.frequency?.count ?? null}
            weekStartsOn={weekStartsOn}
            type={type!}
            onChange={_onChange}
            onChangeType={onChangeType}
            createHabitPremium={!createHabitAllowed}
          />
        </PopupMenu>
      )}
    </Styled.Container>
  );
};
