import { useMutation } from '@tanstack/react-query';
import { updatePassword } from 'shared/services/backend-api';
import { ChangePasswordErrorTypes } from 'shared/types/change-password-form';

export const useUpdatePasswordMutation = () =>
  useMutation<
    void,
    ChangePasswordErrorTypes,
    {
      oldPassword: string;
      newPassword: string;
    }
  >({
    mutationFn: async ({ oldPassword, newPassword }) =>
      updatePassword(oldPassword, newPassword),
  });
