import { typographyToCss } from 'shared/lib/mui';
import styled from 'styled-components';

export const Container = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacing(6)}`};
`;

export const Badge = styled.span<{ $active: boolean }>`
  ${({ theme }) => typographyToCss(theme.typography.overline)};

  display: inline-block;

  border-radius: ${({ theme }) => theme.spacing(1.5)};
`;
