import { useGoalById, useSubGoals } from 'features/goals';
import { useMemo } from 'react';
import { ID } from 'shared/types/id';
import { getGoalCompletionCount } from 'shared/utils/get-goal-completion-count';

export type Progress = {
  end: number;
  completed: number;
};

export const useGoalCompletionCount = (id?: ID) => {
  const goal = useGoalById(id);
  const subGoals = useSubGoals(id);

  return useMemo<Progress>(() => {
    if (!goal) {
      return { completed: 0, end: 0 };
    }

    const { current, end } = getGoalCompletionCount(goal, {
      goals: subGoals,
    });

    return { completed: current, end };
  }, [goal, subGoals]);
};
