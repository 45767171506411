export enum LifeAreaTrackTypes {
  EducationAndLearning = 'education_and_learning',
  WorkAndCareer = 'work_and_career',
  MoneyAndFinances = 'money_and_finances',
  SportAndHealth = 'sport_and_health',
  LoveAndRelationships = 'love_and_relationships',
  PersonalDevelopment = 'personal_development',
  FamilyAndFriends = 'family_and_friends',
  FunAndRelaxation = 'fun_and_relaxation',
  Spirituality = 'spirituality',
  Custom = 'custom',
}
