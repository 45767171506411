export enum DateFormatOptions {
  DayMonthYear = 'dd-MM-yyyy',
  MonthDayYear = 'MM-dd-yyyy',
}

export const dateFormatOptionToLabelMap: Record<DateFormatOptions, string> = {
  [DateFormatOptions.DayMonthYear]: 'DD-MM-YYYY',
  [DateFormatOptions.MonthDayYear]: 'MM-DD-YYYY',
};

export const initialDateFormat = DateFormatOptions.DayMonthYear;
