import { createNonce } from 'shared/services/local-storage';

import { getAppleId } from './get-apple-id';

export const getAppleToken = async () => {
  const appleId = await getAppleId();

  if (!appleId) {
    // eslint-disable-next-line no-console -- todo: should be something with logging this error to the backend, not just in the console
    console.error('no apple');
  }

  const { hashed } = await createNonce();

  const authOptions = {
    nonce: hashed,
    scope: 'email name',
    clientId: import.meta.env.VITE_APPLE_CLIENT_ID,
    redirectURI: import.meta.env.VITE_APPLE_REDIRECT_URI,
  };

  appleId.auth.init(authOptions);

  await appleId.auth.signIn();
};
