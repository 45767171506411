import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Column, ColumnType } from './types';

const allColumnTypes = Object.values(ColumnType);

const columnTypeTranslationsMap: Record<ColumnType, string | undefined> = {
  [ColumnType.Name]: 'general.labels.goal-table.headers.name.title',
  [ColumnType.Deadline]: 'general.labels.goal-table.headers.deadline.title',
  [ColumnType.Completion]: 'general.labels.goal-table.headers.completion.title',
  [ColumnType.Tasks]: 'general.labels.goal-table.headers.tasks.title',
  [ColumnType.LifeArea]: 'general.labels.goal-table.headers.life-area.title',
  [ColumnType.Empty]: undefined,
};

const columnTypeSortableMap: Record<ColumnType, boolean> = {
  [ColumnType.Name]: true,
  [ColumnType.Deadline]: true,
  [ColumnType.Completion]: true,
  [ColumnType.Tasks]: true,
  [ColumnType.LifeArea]: true,
  [ColumnType.Empty]: false,
};

export const useColumns = (
  columnTypes: ColumnType[] = allColumnTypes,
): Column[] => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      columnTypes.map((columnType) => {
        const translationKey = columnTypeTranslationsMap[columnType];
        return {
          type: columnType,
          label: translationKey ? t(translationKey) : undefined,
          sortable: columnTypeSortableMap[columnType],
        };
      }),
    [columnTypes, t],
  );
};
