import styled from 'styled-components';

export const Container = styled.div`
  margin: ${({ theme }) => `${theme.spacing(6)} 0`};
`;

export const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: ${({ theme }) => theme.spacing(3)};

  margin: 0;
  padding: ${({ theme }) => `0 ${theme.spacing(3)} 0 ${theme.spacing(6)}`};
  width: 100%;

  list-style: none;
`;

export const ListItem = styled.li`
  min-width: 0;
`;
