import React, { ComponentProps } from 'react';

interface IconProps extends ComponentProps<'svg'> {
  width?: string;
  height?: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export const Icon: React.FC<IconProps> = ({
  width = '1em',
  height = '1em',
  icon: IconComponent,
  ...props
}) => <IconComponent width={width} height={height} {...props} />;
