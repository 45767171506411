import { Placement } from '@floating-ui/react';
import React, { forwardRef, useMemo } from 'react';
import { ChevronLeft, X } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { LinkedItemSearchMenu } from 'shared/components/ui/linked-item-search-menu';
import { useClickOutside } from 'shared/hooks/use-click-outside';
import { useForwardedRef } from 'shared/hooks/use-forwarded-ref';
import { useOpenMenu } from 'shared/hooks/use-open-menu';
import { Goal } from 'shared/types/goal';
import { ID } from 'shared/types/id';
import { LifeArea } from 'shared/types/life-area';
import { LinkOptions } from 'shared/types/task';

import * as Styled from './form-linked-item-search.style';

const menuFallbackPositions: Placement[] = ['top', 'right', 'left'];

export type FormLinkedItemSearchProps = {
  value?: ID | null;
  goals: Goal[];
  lifeAreas: LifeArea[];
  onChange: (link: { id: ID; type: LinkOptions } | null) => void;
  placeholder?: string;
};

export const FormLinkedItemSearch = forwardRef<
  HTMLDivElement,
  FormLinkedItemSearchProps
>(({ value, goals, lifeAreas, onChange, placeholder }, ref) => {
  const containerRef = useForwardedRef<HTMLDivElement>(ref);

  const { menuOpen, openMenu, closeMenu } = useOpenMenu();

  const onClear = (e: React.MouseEvent) => {
    e.preventDefault();
    onChange(null);
    closeMenu();
  };

  const _onChange = (link: { id: ID; type: LinkOptions } | null) => {
    onChange(link);
    closeMenu();
  };

  const selectedOption = useMemo(
    () => goals.find((goal) => goal.id === value),
    [goals, value],
  );

  useClickOutside(containerRef, closeMenu);

  return (
    <Styled.InputContainer ref={containerRef}>
      <Styled.Button
        $isPlaceholder={!selectedOption}
        onClick={openMenu}
        type="button"
      >
        {selectedOption ? selectedOption.name : placeholder}
      </Styled.Button>

      <Styled.InteractionContainer>
        {selectedOption ? (
          <Styled.ClearButton onClick={onClear} type="button" tabIndex={-1}>
            <Icon icon={X} />
          </Styled.ClearButton>
        ) : (
          <Styled.IconContainer>
            <Icon icon={ChevronLeft} />
          </Styled.IconContainer>
        )}
      </Styled.InteractionContainer>

      {menuOpen && (
        <LinkedItemSearchMenu
          goals={goals}
          lifeAreas={lifeAreas}
          onChange={_onChange}
          value={value}
          referenceElement={containerRef}
          position="bottom"
          fallbackPositions={menuFallbackPositions}
        />
      )}
    </Styled.InputContainer>
  );
});
