import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  overflow: auto;
  padding: ${({ theme }) => `0 ${theme.spacing(3)}`};
`;

export const ArchivedContainer = styled.div`
  padding-top: ${({ theme }) => theme.spacing(2)};
`;
