import { useLocalizedLifeAreas } from 'features/life-areas';
import { useMemo } from 'react';

export const useCustomLifeAreas = () => {
  const lifeAreas = useLocalizedLifeAreas();

  return useMemo(
    () => lifeAreas.filter(({ templateId }) => !templateId),
    [lifeAreas],
  );
};
