import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckCircle, IconFormIcons, Repeat } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { GoalTemplate } from 'shared/types/goal-template';
import { ID } from 'shared/types/id';
import { TaskType } from 'shared/types/task-base';
import { getLocalizedStringValue } from 'shared/utils/get-localized-string-value';

import * as Styled from './goal-template-card.style';
import { MetaTag } from './meta-tag';

export type GoalTemplateCardProps = {
  template: GoalTemplate;
  onClick: (id: ID) => void;
};

export const GoalTemplateCard: React.FC<GoalTemplateCardProps> = ({
  template,
  onClick,
}) => {
  const { t, i18n } = useTranslation();

  const _onClick = () => onClick(template.id);

  const habits = useMemo(
    () => template.tasks.filter(({ type }) => type === TaskType.Habit),
    [template.tasks],
  );
  const tasks = useMemo(
    () => template.tasks.filter(({ type }) => type !== TaskType.Habit),
    [template.tasks],
  );

  return (
    <Styled.Container onClick={_onClick}>
      <Styled.Illustration>
        <Icon icon={IconFormIcons[template.iconName]} />
      </Styled.Illustration>

      <Styled.Content>
        <Typography variant="subtitle2">
          {getLocalizedStringValue(template.name, i18n.language)}
        </Typography>
        {!!template.description && (
          <Styled.Description>
            {getLocalizedStringValue(template.description, i18n.language)}
          </Styled.Description>
        )}
      </Styled.Content>

      {(!!habits.length || !!tasks.length) && (
        <Styled.MetaContent>
          {!!habits.length && (
            <MetaTag
              icon={Repeat}
              name={t('goal-template.meta.habits.label', {
                count: habits.length,
              })}
              amount={habits.length}
            />
          )}
          {!!tasks.length && (
            <MetaTag
              icon={CheckCircle}
              name={t('goal-template.meta.tasks.label', {
                count: tasks.length,
              })}
              amount={tasks.length}
            />
          )}
        </Styled.MetaContent>
      )}
    </Styled.Container>
  );
};
