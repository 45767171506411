import { IconFormNames } from 'shared/assets/icons';
import { ID } from 'shared/types/id';
import * as yup from 'yup';

export type NewLifeArea = {
  name?: string;
  color?: string | null;
  iconName: IconFormNames;
  templateId?: ID | null;
};

export const validationSchema: yup.ObjectSchema<NewLifeArea> = yup.object({
  name: yup.string().required(),
  color: yup.string().nullable(),
  iconName: yup
    .string<IconFormNames>()
    .oneOf(Object.values(IconFormNames))
    .required(),
  templateId: yup.string().nullable(),
});

export type LifeAreaFormFields = yup.InferType<typeof validationSchema>;
