import { isSameDay } from 'date-fns';
import { useHabits, useRepeatingTasks } from 'features/tasks';
import { useMemo } from 'react';
import { useToday } from 'shared/contexts/today';

export const useTodaysCompletedHabits = () => {
  const repeatingTasks = useRepeatingTasks();
  const habits = useHabits();
  const today = useToday();

  return useMemo(
    () =>
      [...habits, ...repeatingTasks].filter(({ schedules }) =>
        schedules.find(
          ({ endDate, completions }) =>
            !endDate &&
            completions.find((completionDate) =>
              isSameDay(completionDate, today),
            ),
        ),
      ),
    [habits, repeatingTasks, today],
  );
};
