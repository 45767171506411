import { useCallback } from 'react';
import { useUpdateTaskMutation } from 'shared/hooks/queries/use-update-task-mutation';
import { Task } from 'shared/types/task';
import { Timestamp } from 'shared/types/timestamp';

export const useArchiveTask = () => {
  const { mutate: updateTask } = useUpdateTaskMutation();

  return useCallback(
    (task: Task, date: Timestamp = new Date()) => {
      const taskToUpdate = {
        ...task,
        archivedAt: task.archivedAt ? null : date,
      };

      updateTask(taskToUpdate);

      return taskToUpdate;
    },
    [updateTask],
  );
};
