import {
  useLocalizedLifeAreaById,
  useUpdateLifeArea,
} from 'features/life-areas';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import {
  EditableTextField,
  Variants,
} from 'shared/components/ui/editable-text-field';
import { getLocalizedStringValue } from 'shared/utils/get-localized-string-value';

import * as Styled from './detail-header.style';

export const DetailHeader: React.FC = () => {
  const { i18n } = useTranslation();
  const { id } = useParams();
  const lifeArea = useLocalizedLifeAreaById(id);
  const { submit: updateLifeArea } = useUpdateLifeArea();

  if (!lifeArea) {
    return null;
  }

  const onChangeTitle = (title: string) => {
    updateLifeArea({ ...lifeArea, name: title });
  };

  return (
    <Styled.Header>
      <EditableTextField
        disabled={!!lifeArea.templateId}
        value={getLocalizedStringValue(lifeArea.name, i18n.language)}
        variant={Variants.H1}
        onChange={onChangeTitle}
      />
    </Styled.Header>
  );
};
