import { NotificationTypes } from './models/notification-types';

const notificationToNotificationTrackingTypeMap: Record<
  string,
  NotificationTypes
> = {
  taskReminder: NotificationTypes.TaskReminder,
  dailyReflection: NotificationTypes.DailyReflection,
  weeklyReflection: NotificationTypes.WeeklyReflection,
};

export const notificationsToTrackingNotificationType = (
  notificationKey: string,
) => notificationToNotificationTrackingTypeMap[notificationKey];
