import { Frequency, RRule } from 'rrule';
import { workWeek } from 'shared/types/week-days';
import { getRruleWeekDays } from 'shared/utils/get-rrule-week-days';

export enum FormUnit {
  Weeks = 'weeks',
  Months = 'months',
}

export enum DayType {
  Day = 'day',
  WorkDay = 'work-day',
  WeekendDay = 'weekend',
}

export const formUnits = Object.values(FormUnit);

export const formUnitToRruleFreqMap: Record<FormUnit, Frequency> = {
  [FormUnit.Weeks]: Frequency.WEEKLY,
  [FormUnit.Months]: Frequency.MONTHLY,
};

export const defaultRrule = new RRule({
  freq: Frequency.WEEKLY,
  byweekday: getRruleWeekDays(workWeek),
}).toString();
