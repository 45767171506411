import { useGoals } from 'features/goals';
import { useCallback, useEffect, useRef } from 'react';
import { useDeleteGoalsMutation } from 'shared/hooks/queries/use-delete-goals-mutation';
import { useDeleteFavorites } from 'shared/hooks/use-delete-favorites';
import { useTrackEvents } from 'shared/hooks/use-track-events';
import { ActionEvents } from 'shared/services/tracking';
import { Goal } from 'shared/types/goal';

export const useDeleteGoal = () => {
  const deleteGoalRef = useRef<Goal>();
  const goals = useGoals();

  const track = useTrackEvents();
  const { mutate, isSuccess, reset, error, isPending } =
    useDeleteGoalsMutation();
  const removeFavorite = useDeleteFavorites();

  const submit = useCallback(
    (goal: Goal) => {
      deleteGoalRef.current = goal;
      const subGoals = goals
        .filter(({ parentIds }) => parentIds?.includes(goal.id))
        .map(({ id }) => id);
      mutate([goal.id, ...subGoals]);
      removeFavorite([goal.id, ...subGoals]);
    },
    [goals, mutate, removeFavorite],
  );

  const retry = useCallback(() => {
    const goal = deleteGoalRef.current;
    if (goal) {
      const subGoals = goals
        .filter(({ parentIds }) => parentIds?.includes(goal.id))
        .map(({ id }) => id);
      mutate([goal.id, ...subGoals]);
      removeFavorite([goal.id, ...subGoals]);
    }
  }, [goals, mutate, removeFavorite]);

  const resetAll = useCallback(() => {
    reset();
    deleteGoalRef.current = undefined;
  }, [reset]);

  useEffect(() => {
    if (isSuccess) {
      track(ActionEvents.GoalDelete, undefined);
    }
  }, [isSuccess, track]);

  return {
    submit,
    retry,
    reset: resetAll,
    error,
    isLoading: isPending,
    isSuccess,
  };
};
