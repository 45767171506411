import { httpsCallable } from 'firebase/functions';

import { getFunctions } from './helpers';

export const sendFeedback = async (message: string) => {
  const functions = getFunctions();
  const send = httpsCallable(functions, 'users-sendFeedback');

  await send({ message });
};
