import { useMutation } from '@tanstack/react-query';
import { resetPassword } from 'shared/services/backend-api';
import { ResetPasswordErrorTypes } from 'shared/types/reset-password-form';

export const useResetPasswordMutation = () =>
  useMutation<
    void,
    ResetPasswordErrorTypes,
    {
      password: string;
      code: string;
    }
  >({
    mutationFn: async ({ password, code }) => resetPassword(code, password),
  });
