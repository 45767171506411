import React, { useRef } from 'react';
import { MoreHorizontal } from 'shared/assets/icons';
import { GoalContextMenu } from 'shared/components/ui/goal-context-menu';
import { IconButton, Sizes } from 'shared/components/ui/icon-button';
import { useOpenMenu } from 'shared/hooks/use-open-menu';
import { ID } from 'shared/types/id';
import { Timestamp } from 'shared/types/timestamp';

import * as Styled from './goal-data-grid.style';

export type MenuCellProps = {
  id: ID;
  onEditGoal?: (id: ID) => void;
  onCompleteGoal?: (id: ID) => void;
  completedAt?: Timestamp | null;
  onDeleteGoal?: (id: ID) => void;
  archivedAt?: Timestamp | null;
  onArchiveGoal?: (id: ID) => void;
  onFavoriteGoal?: (id: ID) => void;
  isFavorite?: boolean;
  isFrozen?: boolean;
};

export const MenuCell: React.FC<MenuCellProps> = ({
  id,
  onEditGoal,
  archivedAt,
  onArchiveGoal,
  onDeleteGoal,
  completedAt,
  onCompleteGoal,
  onFavoriteGoal,
  isFavorite,
  isFrozen,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { menuOpen, openMenu, closeMenu } = useOpenMenu();

  const onButton = (e: React.MouseEvent) => {
    e.preventDefault();
    openMenu();
  };

  const onEdit = onEditGoal
    ? (e: React.MouseEvent) => {
        e.preventDefault();
        onEditGoal(id);
        closeMenu();
      }
    : undefined;
  const onComplete = onCompleteGoal
    ? (e: React.MouseEvent) => {
        e.preventDefault();
        onCompleteGoal(id);
        closeMenu();
      }
    : undefined;
  const onArchive = onArchiveGoal
    ? (e: React.MouseEvent) => {
        e.preventDefault();
        onArchiveGoal(id);
        closeMenu();
      }
    : undefined;
  const onDelete = onDeleteGoal
    ? (e: React.MouseEvent) => {
        e.preventDefault();
        onDeleteGoal(id);
        closeMenu();
      }
    : undefined;
  const onFavorite = onFavoriteGoal
    ? (e: React.MouseEvent) => {
        e.preventDefault();
        onFavoriteGoal(id);
        closeMenu();
      }
    : undefined;

  return (
    <Styled.DataCell $isFrozen={!!isFrozen} $hasButton>
      <IconButton
        ref={buttonRef}
        icon={MoreHorizontal}
        onClick={!isFrozen ? onButton : undefined}
        size={Sizes.Small}
      />

      {menuOpen && (
        <GoalContextMenu
          referenceElement={buttonRef}
          onClose={closeMenu}
          onEditGoal={onEdit}
          isCompleted={!!completedAt}
          onCompleteGoal={onComplete}
          isArchived={!!archivedAt}
          onArchiveGoal={onArchive}
          onDeleteGoal={onDelete}
          onFavorite={onFavorite}
          isFavorite={isFavorite}
        />
      )}
    </Styled.DataCell>
  );
};
