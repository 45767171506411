import { isBefore } from 'date-fns';
import { useCallback } from 'react';
import { useToday } from 'shared/contexts/today';
// eslint-disable-next-line max-len -- long hook name
import { useCreateStripeSubscriptionManagementUrlMutation } from 'shared/hooks/queries/use-create-stripe-subscription-management-url-mutation';
import { useTrackEvents } from 'shared/hooks/use-track-events';
import { useUser } from 'shared/hooks/use-user';
import { ActionEvents } from 'shared/services/tracking';

export const useOpenSubscriptionManagement = () => {
  const today = useToday();
  const user = useUser();
  const track = useTrackEvents();
  const { mutateAsync, isPending } =
    useCreateStripeSubscriptionManagementUrlMutation();

  const submit = useCallback(async () => {
    track(ActionEvents.CancelSubscription, undefined);
    const subscription = user?.subscriptions?.find(({ expiresAt }) =>
      isBefore(today, expiresAt),
    );

    const url = subscription?.stripeCustomerId
      ? await mutateAsync(subscription.stripeCustomerId)
      : subscription?.managementUrl;
    if (url) {
      window.open(url, '_blank');
    }
  }, [mutateAsync, today, track, user?.subscriptions]);

  return {
    submit,
    isLoading: isPending,
  };
};
