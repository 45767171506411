import { useHabits } from 'features/tasks';
import React from 'react';
import { GridContainer } from 'shared/components/ui/grid-container';

import { ActiveHabitsList } from './components/active-habits-list';
import { ArchivedHabitsList } from './components/archived-habits-list';
import { EmptyHabitsList } from './components/empty-habits-list';
import { TabPanel } from './components/tab-panel';

export const Active: React.FC = () => {
  const habits = useHabits({
    uncompletedOnly: true,
    unArchivedOnly: true,
  });

  return (
    <GridContainer hasFlex1>
      <TabPanel>
        {!habits.length ? (
          <EmptyHabitsList />
        ) : (
          <>
            <ActiveHabitsList />
            <ArchivedHabitsList />
          </>
        )}
      </TabPanel>
    </GridContainer>
  );
};
