import { useCompleteGoal } from 'features/goals';
import React, { Suspense, useCallback, useMemo, useState } from 'react';
import { Goal } from 'shared/types/goal';

import {
  CompleteGoalContext,
  CompleteGoalContextProps,
} from './complete-goal-context';

const CompleteGoalDialog = React.lazy(async () => ({
  default: (await import('shared/dialogs/complete-goal')).CompleteGoalDialog,
}));

export type CompleteGoalProviderProps = {
  children: React.ReactNode;
};

export const CompleteGoalProvider: React.FC<CompleteGoalProviderProps> = ({
  children,
}) => {
  const [goal, setGoal] = useState<Goal>();
  const completeGoal = useCompleteGoal();

  const openDialog = useCallback(
    (goal: Goal) => {
      completeGoal(goal);
      !goal.completedAt && setGoal(goal);
    },
    [completeGoal],
  );

  const closeDialog = useCallback(() => {
    setGoal(undefined);
  }, []);

  const value = useMemo<CompleteGoalContextProps>(
    () => ({
      onCompleteDialog: openDialog,
    }),
    [openDialog],
  );

  return (
    <CompleteGoalContext.Provider value={value}>
      {children}
      <Suspense>
        <CompleteGoalDialog open={!!goal} onClose={closeDialog} />
      </Suspense>
    </CompleteGoalContext.Provider>
  );
};
