import { useQuery } from '@tanstack/react-query';
import { createSubscriptionDoc } from 'shared/lib/@tanstack-query';
import { getTaskById } from 'shared/services/backend-api';
import { ID } from 'shared/types/id';

export const queryKey = 'task-by-id';

export const useTaskByIdQuery = (uid: ID = '', id: ID = '') =>
  useQuery({
    queryKey: [queryKey, uid, id],
    queryFn: createSubscriptionDoc(() => getTaskById(id)),
    enabled: !!uid && !!id,
  });
