import { useMemo } from 'react';
import { useGoalsQuery } from 'shared/hooks/queries/use-goals-query';
import { useUser } from 'shared/hooks/use-user';

import { useLocalizedLifeAreas } from './use-localized-life-areas';

export type Options = {
  uncompletedGoalsOnly?: boolean;
};

export const useLifeAreasWithGoals = (options: Options = {}) => {
  const user = useUser();
  const lifeAreas = useLocalizedLifeAreas();

  const lifeAreaIds = useMemo(
    () => lifeAreas?.map(({ id }) => id),
    [lifeAreas],
  );

  const { data: goals } = useGoalsQuery(user?.uid, {
    lifeAreas: lifeAreaIds,
    uncompletedOnly: options.uncompletedGoalsOnly,
  });

  return useMemo(
    () => ({ goals: goals ?? [], lifeAreas: lifeAreas ?? [] }),
    [goals, lifeAreas],
  );
};
