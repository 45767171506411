import { useLocalizedLifeAreaById } from 'features/life-areas';
import { useIsFavorite, useToggleFavorite } from 'features/user';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { ArrowLeft, MoreHorizontal, Star } from 'shared/assets/icons';
import { LifeAreaContextMenu } from 'shared/components/connected/life-area-context-menu';
import { Button, Colors, Sizes, Variants } from 'shared/components/ui/button';
import { IconButton } from 'shared/components/ui/icon-button';
import { useBackFunction } from 'shared/contexts/history';
import { useOpenMenu } from 'shared/hooks/use-open-menu';
import { Paths } from 'shared/routes';
import { FavoriteType } from 'shared/types/user-settings';

import * as Styled from './detail-navigation.style';

export const DetailNavigation: React.FC = () => {
  const interactionsRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { menuOpen, openMenu, closeMenu } = useOpenMenu();
  const { id } = useParams();
  const lifeArea = useLocalizedLifeAreaById(id);
  const isFavorite = useIsFavorite(id);

  const backFunction = useBackFunction(Paths.LifeAreas);
  const toggleFavorite = useToggleFavorite();

  const onFavorite = () => {
    !!lifeArea && toggleFavorite(lifeArea.id, FavoriteType.LifeArea);
  };

  return (
    <Styled.Container>
      <Button
        onClick={backFunction}
        startIcon={ArrowLeft}
        size={Sizes.Medium}
        variant={Variants.Text}
        color={Colors.Inherit}
      >
        {t('general.labels.navigation.back.title')}
      </Button>

      <Styled.Interactions ref={interactionsRef}>
        <Styled.FavoriteButtonContainer $isFavorite={isFavorite}>
          <IconButton icon={Star} size={Sizes.Large} onClick={onFavorite} />
        </Styled.FavoriteButtonContainer>

        <IconButton icon={MoreHorizontal} onClick={openMenu} />
        {menuOpen && !!lifeArea && (
          <LifeAreaContextMenu
            lifeArea={lifeArea}
            onClose={closeMenu}
            position="bottom-end"
            referenceElement={interactionsRef}
          />
        )}
      </Styled.Interactions>
    </Styled.Container>
  );
};
