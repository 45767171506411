import { index, Options } from 'shared/assets/styles';
import { typographyToCss } from 'shared/lib/mui';
import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${index(Options.IOS_BANNER)};

  padding: ${({ theme }) => `${theme.spacing(10)} ${theme.spacing(8)} 0`};

  background: ${({ theme }) => theme.palette.background.paper};
`;

export const Content = styled.div``;

export const IconContainer = styled.span`
  display: block;
  font-size: 5.6rem;
  text-align: center;
  line-height: 62.5%;
`;

export const Title = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.h4)};
  display: block;

  margin: ${({ theme }) => `${theme.spacing(6)} auto 0`};

  text-align: center;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const Description = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.h1)};
  display: block;

  margin: ${({ theme }) => `${theme.spacing(3)} auto 0`};

  text-align: center;
`;

export const AppleLink = styled.a`
  display: block;

  border-radius: ${({ theme }) => theme.spacing(1)};

  margin-top: ${({ theme }) => theme.spacing(8)};
  text-align: center;
  text-decoration: none;
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing(7.5)};
`;

export const ImageLink = styled.a``;

export const Image = styled.img`
  display: block;
  max-width: 100%;
`;
