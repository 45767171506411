import {
  addDays,
  addWeeks,
  isBefore,
  isSameDay,
  isSameMonth,
  isSameWeek,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { Frequency, RRule } from 'rrule';
import { weekdaysNumberMap } from 'shared/constants';
import { HabitSchedule } from 'shared/types/habit-schedule';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';

export type Options = {
  today: Timestamp;
  weekStartsOn: WeekDays;
};

export const getFrequencyDue = (
  schedule: HabitSchedule,
  { today, weekStartsOn }: Options,
) => {
  const completionsThisWeek =
    schedule.completions
      ?.filter((completion) =>
        isSameWeek(today, completion, {
          weekStartsOn: weekdaysNumberMap[weekStartsOn],
        }),
      )
      .toSorted(
        (completionA, completionB) =>
          completionB.getTime() - completionA.getTime(),
      ) ?? [];

  const completedToday = completionsThisWeek.find((completion) =>
    isSameDay(completion, today),
  );

  const nextCompletionDate =
    completionsThisWeek.length < (schedule.frequency?.count ?? 0)
      ? addDays(today, completedToday ? 1 : 0)
      : addWeeks(
          startOfWeek(today, {
            weekStartsOn: weekdaysNumberMap[weekStartsOn],
          }),
          1,
        );

  const lastSkip = schedule.skips?.[schedule.skips.length - 1];

  return [nextCompletionDate, lastSkip ? addDays(lastSkip, 1) : undefined]
    .filter(Boolean)
    .sort(
      (nextDueDateA, nextDueDateB) =>
        nextDueDateB!.getTime() - nextDueDateA!.getTime(),
    )[0];
};

export const getScheduleDue = (
  schedule: HabitSchedule,
  { today, weekStartsOn }: Options,
) => {
  if (schedule.frequency?.count) {
    return getFrequencyDue(schedule, { today, weekStartsOn });
  }

  const rrule = RRule.fromString(schedule.rrule.format);

  const weekOrMonthStart =
    rrule.options.freq === Frequency.MONTHLY
      ? startOfMonth(today)
      : startOfWeek(today, {
          weekStartsOn: weekdaysNumberMap[weekStartsOn],
        });

  const lastCompletion = (
    rrule.options.freq === Frequency.MONTHLY
      ? schedule.completions
          ?.filter((completion) => isSameMonth(today, completion))
          .toSorted(
            (completionA, completionB) =>
              completionB.getTime() - completionA.getTime(),
          ) ?? []
      : schedule.completions?.filter((completion) =>
          isSameWeek(today, completion, {
            weekStartsOn: weekdaysNumberMap[weekStartsOn],
          }),
        ) ?? []
  ).sort(
    (completionA, completionB) => completionB.getTime() - completionA.getTime(),
  )[0];

  const lastSkip = schedule.skips?.[schedule.skips?.length - 1];
  const skipDueDate = lastSkip ? addDays(lastSkip, 1) : undefined;

  // get the dtstart based on last skip, last completion and the weekOrMonthStart
  rrule.options.dtstart = [
    weekOrMonthStart,
    lastCompletion ? addDays(lastCompletion, 1) : undefined,
    schedule.startDate,
  ]
    .filter(Boolean)
    // by sorting them we use the latest date as starting point, since we don't need to know anything earlier than that
    .sort((dateA, dateB) => dateB!.getTime() - dateA!.getTime())[0] as Date;

  const previousOccurrence = rrule.before(today);

  // if there is a previous occurrence, take that or the last skip, depending on what is last
  if (previousOccurrence) {
    return skipDueDate && isBefore(previousOccurrence, skipDueDate)
      ? skipDueDate
      : previousOccurrence;
  }

  const nextOccurrence = rrule.after(today, true);

  if (!nextOccurrence) {
    return undefined;
  }

  return skipDueDate && isBefore(nextOccurrence, skipDueDate)
    ? skipDueDate
    : nextOccurrence;
};
