import { useCallback, useRef } from 'react';
import { useUpdateTaskMutation } from 'shared/hooks/queries/use-update-task-mutation';
import { Task } from 'shared/types/task';
import { TaskType } from 'shared/types/task-base';

export const useForceUpdateTask = () => {
  const updateTaskRef = useRef<Task>();
  const {
    mutate: updateTask,
    error,
    isPending,
    isSuccess,
    reset,
  } = useUpdateTaskMutation();

  const submit = useCallback(
    (task: Task) => {
      updateTaskRef.current = task;
      const type = task.schedules?.length ? TaskType.Habit : TaskType.Task;

      updateTask({ ...task, type } as Task);
    },
    [updateTask],
  );

  const retry = useCallback(() => {
    const task = updateTaskRef.current;

    if (task) {
      const type = task.schedules?.length ? TaskType.Habit : TaskType.Task;

      updateTask({ ...task, type } as Task);
    }
  }, [updateTask]);

  const resetAll = useCallback(() => {
    reset();
    updateTaskRef.current = undefined;
  }, [reset]);

  return {
    submit,
    retry,
    reset: resetAll,
    error,
    isLoading: isPending,
    isSuccess,
  };
};
