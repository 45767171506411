import { useMemo } from 'react';
import {
  SortMode,
  useDataGridSorting,
} from 'shared/hooks/use-data-grid-sorting';
import { Goal } from 'shared/types/goal';
import { ID } from 'shared/types/id';
import { LifeArea } from 'shared/types/life-area';

import {
  columnTypeOrderAscendingMap,
  columnTypeOrderDescendingMap,
} from './column-type-to-sorting-function-map';
import { mapGoalToRowData } from './map-goal-to-row-data';
import { ColumnType } from './types';
import { useColumns } from './use-columns';

export type Input = {
  columnTypes?: ColumnType[];
  goals: Goal[];
  lifeAreas: LifeArea[];
  favorites: ID[];
  allGoals: Goal[];
};
export const useGoalOrdering = ({
  columnTypes,
  goals,
  lifeAreas,
  favorites,
  allGoals,
}: Input) => {
  const columns = useColumns(columnTypes);
  const {
    sortMode,
    onSortChange,
    type: sortColumn,
  } = useDataGridSorting<ColumnType>();

  const sortedDataRows = useMemo(() => {
    const dataRows = goals.map((goal) =>
      mapGoalToRowData(goal, { lifeAreas, goals: allGoals, favorites }),
    );
    const colToSort = columns.find(({ type }) => type === sortColumn);

    if (!colToSort) {
      return dataRows;
    }

    const sortFunction =
      sortMode === SortMode.Asc
        ? columnTypeOrderAscendingMap[colToSort.type]
        : columnTypeOrderDescendingMap[colToSort.type];
    return dataRows.toSorted(sortFunction);
  }, [allGoals, columns, favorites, goals, lifeAreas, sortColumn, sortMode]);

  return {
    rows: sortedDataRows,
    sortMode,
    onSortChange,
    sortColumn,
  };
};
