import styled from 'styled-components';

export const Container = styled.h2`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
  margin: 0;
  padding: ${({ theme }) =>
    `${theme.spacing(4)} ${theme.spacing(1.5)}
    ${theme.spacing(1)} 0`};
`;
