import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Colors, Sizes, Variants } from 'shared/components/ui/button';

import * as Styled from './repeat-rrule-form.style';

export type FooterProps = {
  onClear: () => void;
  onSubmit: () => void;
  isValid: boolean;
};

export const Footer: React.FC<FooterProps> = ({
  onClear,
  onSubmit,
  isValid,
}) => {
  const { t } = useTranslation();

  return (
    <Styled.FooterContainer>
      <Button
        variant={Variants.Outlined}
        size={Sizes.Small}
        color={Colors.Inherit}
        onClick={onClear}
        type="button"
        fullWidth
        alignCenter
      >
        {t('forms.habit.fields.repeat.footer.clear')}
      </Button>
      <Button
        variant={Variants.Contained}
        size={Sizes.Small}
        onClick={onSubmit}
        disabled={!isValid}
        fullWidth
        alignCenter
      >
        {t('forms.habit.fields.repeat.footer.submit')}
      </Button>
    </Styled.FooterContainer>
  );
};
