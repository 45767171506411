import { FirebaseApp } from 'firebase/app';
import { FirebasePerformance, getPerformance } from 'firebase/performance';

let performance: FirebasePerformance | undefined;

export const getPerformanceMonitoring = (app: FirebaseApp) => {
  if (!performance) {
    performance = getPerformance(app);
  }

  return performance;
};

export const clearPerformanceMonitoring = async () => {
  if (performance) {
    performance.dataCollectionEnabled = false;
    performance.instrumentationEnabled = false;
  }
  performance = undefined;
};
