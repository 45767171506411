import { isBefore } from 'date-fns';
import { useHabits, useRepeatingTasks } from 'features/tasks';
import { useMemo } from 'react';
import { useToday } from 'shared/contexts/today';
import { TaskType } from 'shared/types/task-base';
import { initialWeekStartsOn } from 'shared/types/week-days';
import { getTaskDueDate } from 'shared/utils/get-task-due';

export const useOverdueHabits = (weekStartsOn = initialWeekStartsOn) => {
  const today = useToday();
  const repeatingTasks = useRepeatingTasks({
    uncompletedOnly: true,
    unArchivedOnly: true,
    unFrozenOnly: true,
  });
  const habits = useHabits({
    uncompletedOnly: true,
    unArchivedOnly: true,
    unFrozenOnly: true,
  });

  return useMemo(
    () =>
      [...habits, ...repeatingTasks].filter((habit) => {
        if (habit.type === TaskType.Habit && habit.hideOnTodo) {
          return false;
        }

        const dueDate = getTaskDueDate(habit, { today, weekStartsOn });

        if (!dueDate) {
          return false;
        }

        const activeSchedule = habit.schedules.find(({ endDate }) => !endDate);
        if (!activeSchedule) {
          return false;
        }

        return isBefore(dueDate, today);
      }),
    [habits, repeatingTasks, today, weekStartsOn],
  );
};
