import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Colors, Sizes, Variants } from 'shared/components/ui/button';
import { FormInput } from 'shared/components/ui/form-input';
import {
  ChangePasswordErrorTypes,
  ChangePasswordFormFields,
} from 'shared/types/change-password-form';

import * as Styled from './change-password-form.style';
import { useChangePasswordForm } from './use-change-password-form';

export type ChangePasswordFormProps = {
  onSubmit: (oldPassword: string, newPassword: string) => void;
  onCancel: () => void;
  isLoading: boolean;
  error?: ChangePasswordErrorTypes;
};

export const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({
  onSubmit,
  onCancel,
  isLoading,
  error,
}) => {
  const { t } = useTranslation();

  const submit = useCallback(
    (data: ChangePasswordFormFields) =>
      onSubmit(data.oldPassword, data.newPassword),
    [onSubmit],
  );

  const {
    register,
    onSubmit: submitForm,
    errors,
    watch,
  } = useChangePasswordForm(submit);

  const [oldPasswordValue, newPasswordValue, passwordConfirmationValue] = watch(
    ['oldPassword', 'newPassword', 'passwordConfirmation'],
  );

  const submitDisabled =
    isLoading ||
    !oldPasswordValue ||
    !newPasswordValue ||
    !passwordConfirmationValue;

  const oldPasswordErrorMessage = useMemo(() => {
    if (errors.oldPassword?.message) {
      return t('forms.change-password.fields.old-password.error');
    }
  }, [errors.oldPassword?.message, t]);

  const newPasswordErrorMessage = useMemo(() => {
    if (errors.newPassword?.message || errors.passwordConfirmation?.message) {
      return t('forms.change-password.fields.new-password.error');
    }
  }, [errors.newPassword?.message, errors.passwordConfirmation?.message, t]);

  const passwordConfirmationErrorMessage = useMemo(() => {
    if (errors.passwordConfirmation?.message) {
      return t('forms.change-password.fields.password-confirm.error');
    }
  }, [errors.passwordConfirmation?.message, t]);

  return (
    <Styled.Form noValidate onSubmit={submitForm}>
      <Styled.Fields>
        <Styled.FieldContainer>
          <Styled.Label htmlFor="oldPassword">
            {t('forms.change-password.fields.old-password.label')}
          </Styled.Label>
          <FormInput
            {...register('oldPassword')}
            id="oldPassword"
            type="password"
            placeholder={t(
              'forms.change-password.fields.old-password.placeholder',
            )}
            error={oldPasswordErrorMessage}
            disabled={isLoading}
          />
          {!!oldPasswordErrorMessage && (
            <Styled.ErrorMessage>{oldPasswordErrorMessage}</Styled.ErrorMessage>
          )}
        </Styled.FieldContainer>

        <Styled.FieldContainer>
          <Styled.Label htmlFor="newPassword">
            {t('forms.change-password.fields.new-password.label')}
          </Styled.Label>
          <FormInput
            {...register('newPassword')}
            id="newPassword"
            type="password"
            placeholder={t(
              'forms.change-password.fields.new-password.placeholder',
            )}
            error={newPasswordErrorMessage}
            disabled={isLoading}
          />
          {!!newPasswordErrorMessage && (
            <Styled.ErrorMessage>{newPasswordErrorMessage}</Styled.ErrorMessage>
          )}
        </Styled.FieldContainer>

        <Styled.FieldContainer>
          <Styled.Label htmlFor="passwordConfirmation">
            {t('forms.change-password.fields.password-confirmation.label')}
          </Styled.Label>
          <FormInput
            {...register('passwordConfirmation')}
            id="passwordConfirmation"
            type="password"
            placeholder={t(
              'forms.change-password.fields.password-confirmation.placeholder',
            )}
            error={passwordConfirmationErrorMessage}
            disabled={isLoading}
          />
          {!!passwordConfirmationErrorMessage && (
            <Styled.ErrorMessage>
              {passwordConfirmationErrorMessage}
            </Styled.ErrorMessage>
          )}
          {error === ChangePasswordErrorTypes.Unknown && (
            <Styled.ErrorMessage>
              {t('forms.change-password.error.unknown')}
            </Styled.ErrorMessage>
          )}
        </Styled.FieldContainer>

        <Styled.Interactions>
          <Button
            variant={Variants.Outlined}
            size={Sizes.Medium}
            color={Colors.Inherit}
            onClick={onCancel}
            disabled={isLoading}
            type="button"
          >
            {t('forms.change-password.buttons.cancel.label')}
          </Button>
          <Button
            variant={Variants.Contained}
            color={Colors.Primary}
            size={Sizes.Medium}
            type="submit"
            disabled={submitDisabled}
            disabledBackgroundOnly={isLoading}
          >
            {isLoading ? (
              <Styled.Loader color="primary" size={14} />
            ) : (
              t('forms.change-password.buttons.submit.label')
            )}
          </Button>
        </Styled.Interactions>
      </Styled.Fields>
    </Styled.Form>
  );
};
