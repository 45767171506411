import { useMutation } from '@tanstack/react-query';
import { updateGoalOrder } from 'shared/services/backend-api';
import { ID } from 'shared/types/id';
import { GoalTabSortingOptions } from 'shared/types/user-settings';

export const useUpdateGoalOrderMutation = () =>
  useMutation({
    mutationFn: async ({
      newOrder,
      option,
    }: {
      newOrder: ID[];
      option: GoalTabSortingOptions;
    }) => updateGoalOrder(newOrder, option),
  });
