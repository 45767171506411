import { httpsCallable } from 'firebase/functions';
import { Locales } from 'shared/assets/localization';
import { ID } from 'shared/types/id';
import { Timestamp } from 'shared/types/timestamp';

import { getFunctions } from './helpers';

export type CreateOptions = {
  id: ID;
  locale: Locales;
  startDate: Timestamp;
  timezoneOffset?: number;
};

export const createEntriesFromGoalTemplate = async (options: CreateOptions) => {
  const functions = getFunctions();
  const createGoalTemplate = httpsCallable<CreateOptions, { mainGoalId: ID }>(
    functions,
    'templates-createDocumentsByTemplate',
  );

  const { data } = await createGoalTemplate({
    ...options,
    timezoneOffset: options.timezoneOffset ?? new Date().getTimezoneOffset(),
  });

  return data;
};
