import Typography from '@mui/material/Typography';
import React from 'react';
import { ChevronLeft } from 'shared/assets/icons';
import { Collapse } from 'shared/components/ui/collapse';
import { Icon } from 'shared/components/ui/icon';
import { useToggle } from 'shared/hooks/use-toggle';

import * as Styled from './titled-collapse.style';

export type TitledCollapseProps = {
  title: string;
  titleCount?: number;
  children: React.ReactNode;
  initialOpen?: boolean;
  titleVariant?: React.ComponentProps<typeof Typography>['variant'];
  titleUnderlined?: boolean;
  titleHighlighted?: boolean;
};

export const TitledCollapse: React.FC<TitledCollapseProps> = ({
  title,
  titleCount,
  children,
  initialOpen,
  titleVariant = 'caption',
  titleUnderlined,
  titleHighlighted,
}) => {
  const [open, toggleOpen] = useToggle(!!initialOpen);

  return (
    <>
      <Styled.Button onClick={toggleOpen}>
        <Styled.ButtonContent
          $underlined={!!titleUnderlined}
          $highlighted={!!titleHighlighted}
        >
          <Styled.Title>
            <Typography variant={titleVariant} component="span">
              {title}
            </Typography>
            {titleCount !== undefined && (
              <Styled.TitleCount>{titleCount}</Styled.TitleCount>
            )}
          </Styled.Title>

          <Styled.IconContainer>
            <Styled.IconRotation $rotate={open}>
              <Icon icon={ChevronLeft} />
            </Styled.IconRotation>
          </Styled.IconContainer>
        </Styled.ButtonContent>
      </Styled.Button>

      <Collapse isOpen={open}>{open ? children : null}</Collapse>
    </>
  );
};
