import { useMemo } from 'react';
import { useUser } from 'shared/hooks/use-user';
import { ID } from 'shared/types/id';

export const useIsFavorite = (id?: ID) => {
  const user = useUser();

  return useMemo(
    () => !!user?.settings?.favorites?.find((favorite) => favorite.id === id),
    [user, id],
  );
};
