import styled, { css, DefaultTheme } from 'styled-components';

import { Colors } from './types';

const getColor = (color: Colors, theme: DefaultTheme) => {
  switch (color) {
    case Colors.Info:
      return theme.palette.info.main;
    case Colors.Warning:
      return theme.palette.warning.main;
    case Colors.Error:
      return theme.palette.error.dark;
    case Colors.Success:
      return theme.palette.success.main;
    default:
      return theme.palette.divider;
  }
};

export const Container = styled.label`
  line-height: 62.5%;
  display: inline-block;
  cursor: pointer;
`;

export const Checkbox = styled.span<{
  $color: Colors;
  $isPlaceholder: boolean;
  $isRecommended: boolean;
  $isDisabled: boolean;
}>`
  pointer-events: none;
  position: relative;
  display: inline-block;
  width: 1.6rem;
  height: 1.6rem;
  margin: ${({ theme }) => theme.spacing(1)};

  border-radius: ${({ theme }) => theme.spacing(1.5)};
  background-color: ${({ theme }) => theme.palette.background.default};
  border: 0.2rem solid ${({ $color, theme }) => getColor($color, theme)};
  transition: all 200ms;

  ${({ $isPlaceholder }) =>
    $isPlaceholder &&
    css`
      border-style: dotted;
    `}

  ${({ $isRecommended, theme }) =>
    $isRecommended &&
    css`
      border-color: ${theme.palette.brown['200']};
    `};

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      opacity: 0.4;
      border-color: ${({ theme }) => theme.palette.divider};
    `};

  :hover {
    background-color: ${({ theme }) => theme.palette.brown['50']};
  }
`;

export const Checkmark = styled.span<{ $isSmall: boolean }>`
  pointer-events: none;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
  transition: opacity 200ms;

  font-size: ${({ $isSmall }) => ($isSmall ? '1rem' : '1.2rem')};
  line-height: 62.5%;
  color: ${({ theme }) => theme.palette.primary.contrastText};
`;

export const Input = styled.input`
  position: absolute;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;

  border: none;
  visibility: hidden;

  &:checked {
    + ${Checkbox} {
      background: ${({ theme }) => theme.palette.primary.main};
      border-color: ${({ theme }) => theme.palette.primary.main};

      ${Checkmark} {
        opacity: 1;
      }
    }
  }
`;

export const Label = styled.span`
  display: inline-block;
  margin-left: ${({ theme }) => theme.spacing(2)};
`;
