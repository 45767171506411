import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(5)};

  width: 100dvw;
  height: 100dvh;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(4)};

  text-align: center;
`;

export const ImageContainer = styled.span`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 6.4rem;
  line-height: 62.5%;
`;
