import { Placement } from '@floating-ui/react';
import { index, Options } from 'shared/assets/styles';
import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

import { Positions } from './types';

const placementOptions = Object.values(Positions);

const verticalPlacements = [Positions.Top, Positions.Bottom];
const horizontalPlacements = [Positions.Left, Positions.Right];

export const positioningOffset = 24;

export const Container = styled.span<{
  $placement: Placement;
  $isVisible: boolean;
}>`
  position: relative;
  padding: ${({ theme }) => `${theme.spacing(0.5)} ${theme.spacing(2)}`};

  z-index: ${index(Options.TOOLTIP)};
  border-radius: ${({ theme }) => theme.spacing(1)};
  background: ${({ theme }) => theme.palette.grey['900']};
  pointer-events: none;
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  transition: opacity 0ms;
  transition-delay: ${({ $isVisible }) => ($isVisible ? '300ms' : '0ms')};

  color: ${({ theme }) => theme.palette.background.default};

  &::before {
    content: ${({ $placement }) =>
      !placementOptions.includes($placement as Positions) ? '' : '""'};
    position: absolute;

    width: 0.8rem;
    height: 0.8rem;

    border-radius: ${({ theme }) => theme.spacing(0.5)};
    background: ${({ theme }) => theme.palette.grey['900']};

    top: ${({ $placement }) =>
      $placement === Positions.Top
        ? 'unset'
        : $placement === Positions.Bottom
          ? '1px'
          : '50%'};
    left: ${({ $placement }) =>
      $placement === Positions.Left
        ? 'unset'
        : $placement === Positions.Right
          ? '1px'
          : '50%'};
    right: ${({ $placement }) =>
      $placement === Positions.Left ? '1px' : 'unset'};
    bottom: ${({ $placement }) =>
      $placement === Positions.Top ? '1px' : 'unset'};

    transform: translate(
        ${({ $placement }) =>
          verticalPlacements.includes($placement as Positions)
            ? '-50%'
            : $placement === Positions.Left
              ? '50%'
              : '-50%'},
        ${({ $placement }) =>
          horizontalPlacements.includes($placement as Positions)
            ? '-50%'
            : $placement === Positions.Top
              ? '50%'
              : '-50%'}
      )
      rotate(45deg);
  }

  ${({ $isVisible }) =>
    $isVisible &&
    css`
      transition-delay: 300ms;
    `}
`;

export const Label = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.overline)};
  position: relative;
`;
