import Fuse from 'fuse.js';

export const getAutoCompleteSuggestions = <Item extends {}>(
  input: string | undefined,
  values: Item[],
  key: keyof Item,
) => {
  if (!input) {
    return values;
  }
  const fuse = new Fuse(values, { keys: [key.toString()], threshold: 0.4 });

  const result = fuse.search(input);
  return result.map(({ item }) => item);
};
