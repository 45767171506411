import Typography from '@mui/material/Typography';
import { useForgotPassword } from 'features/user';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoginHeader } from 'shared/components/ui/login-header';
import { Paths } from 'shared/routes';

import { ForgotPasswordForm } from './components/forgot-password-form';
import * as Styled from './forgot-password.style';

export const ForgotPassword: React.FC = () => {
  const { t } = useTranslation();
  const { forgotPassword, isLoading, error, isSuccess } = useForgotPassword();

  return (
    <>
      <LoginHeader />
      <Styled.Container>
        <Styled.Body>
          <Typography variant="h1" textAlign="center">
            {t('forms.forgot-password.title')}
          </Typography>

          {isSuccess ? (
            <Typography variant="body2">
              {t('forms.forgot-password.success.message')}
            </Typography>
          ) : (
            <ForgotPasswordForm
              onSubmit={forgotPassword}
              isLoading={isLoading}
              error={error ?? undefined}
            />
          )}

          <Styled.Anchor to={Paths.Login}>
            {t('forms.forgot-password.back-to-login.label')}
          </Styled.Anchor>
        </Styled.Body>
      </Styled.Container>
    </>
  );
};
