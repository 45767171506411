import {
  useGoalById,
  useGoalCompletion,
  useGoalCompletionCount,
} from 'features/goals';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { ProgressCountSection } from 'shared/components/ui/progress-count-section';
import { useCompleteGoal } from 'shared/contexts/complete-goal';
import { useOpenEditGoalDialog } from 'shared/contexts/goal-form';
import {
  DefaultMetricOptions,
  metricUnitFormatBase,
} from 'shared/types/metric-template';
import { getLocalizedStringValue } from 'shared/utils/get-localized-string-value';

export const CountChart: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const goal = useGoalById(id);
  const { completed, end } = useGoalCompletionCount(id);
  const { completed: completedPercentage, end: endPercentage } =
    useGoalCompletion(id);

  const openEditGoalDialog = useOpenEditGoalDialog();
  const completeGoal = useCompleteGoal();

  const unitFormat = useMemo(() => {
    if (goal?.metric?.unit?.format) {
      return (
        goal.metric.unit.format ??
        `${metricUnitFormatBase} ${getLocalizedStringValue(goal.metric.unit.name, i18n.language)}`
      );
    }

    if (goal?.metric?.id === DefaultMetricOptions.CompletedTasks) {
      return `${metricUnitFormatBase} ${t('general.labels.goal-progress.unit-format.tasks.label')}`;
    }

    if (goal?.metric?.id === DefaultMetricOptions.SubGoalProgress) {
      return `${metricUnitFormatBase} ${t('general.labels.goal-progress.unit-format.sub-goals.label')}`;
    }

    return undefined;
  }, [goal?.metric?.id, goal?.metric?.unit, i18n.language, t]);

  const onCompleteGoal = () => !!goal && completeGoal(goal);

  const onEditGoal = () => {
    if (!goal) {
      return;
    }

    openEditGoalDialog(goal, goal.id);
  };

  return (
    <ProgressCountSection
      target={end}
      lastEntryValue={completed}
      targetFormat={
        unitFormat
          ? getLocalizedStringValue(unitFormat, i18n.language)
          : undefined
      }
      onEditTarget={onEditGoal}
      onEditStartDate={onEditGoal}
      onEditDeadline={onEditGoal}
      onComplete={onCompleteGoal}
      isCompleted={!!goal?.completedAt}
      total={endPercentage}
      completed={completedPercentage}
    />
  );
};
