import {
  and,
  orderBy,
  query,
  QueryFieldFilterConstraint,
  where,
} from 'firebase/firestore';
import { ID } from 'shared/types/id';
import { Timestamp } from 'shared/types/timestamp';

import { goalConverter } from './converters/goal';
import { CollectionOptions, getAuthentication, getCollection } from './helpers';

export type Options = {
  ids?: ID[];
  lifeAreas?: ID[];
  uncompletedOnly?: boolean;
  completedAt?: Timestamp;
};

export const getGoals = async (options: Options = {}) => {
  const auth = await getAuthentication();

  const conditions = [
    where('roles.all', 'array-contains', auth.currentUser?.uid),

    !!options.ids?.length && where('id', 'in', options.ids),
    !!options.lifeAreas?.length && where('lifeAreaId', 'in', options.lifeAreas),
    !!options.uncompletedOnly && where('completedAt', '==', null),
    !!options.completedAt && where('completedAt', '>=', options.completedAt),
  ].filter(Boolean) as QueryFieldFilterConstraint[];

  return query(
    getCollection(CollectionOptions.Goals).withConverter(goalConverter),
    and(...conditions),
    orderBy('createdAt', 'asc'),
  );
};
