import { useMemo } from 'react';
import {
  SortMode,
  useDataGridSorting,
} from 'shared/hooks/use-data-grid-sorting';
import { Goal } from 'shared/types/goal';
import { LifeArea } from 'shared/types/life-area';

import {
  columnTypeOrderAscendingMap,
  columnTypeOrderDescendingMap,
} from './column-type-to-sorting-function-map';
import { mapLifeAreaToRowData } from './map-life-area-to-row-data';
import { ColumnType } from './types';
import { useColumns } from './use-columns';

export type Input = {
  columnTypes?: ColumnType[];
  goals: Goal[];
  lifeAreas: LifeArea[];
};
export const useLifeAreaOrdering = ({
  columnTypes,
  goals,
  lifeAreas,
}: Input) => {
  const columns = useColumns(columnTypes);
  const {
    sortMode,
    onSortChange,
    type: sortColumn,
  } = useDataGridSorting<ColumnType>();

  const sortedDataRows = useMemo(() => {
    const dataRows = lifeAreas.map((lifeArea) =>
      mapLifeAreaToRowData(lifeArea, goals),
    );
    const colToSort = columns.find(({ type }) => type === sortColumn);

    if (!colToSort) {
      return dataRows;
    }

    const sortFunction =
      sortMode === SortMode.Asc
        ? columnTypeOrderAscendingMap[colToSort.type]
        : columnTypeOrderDescendingMap[colToSort.type];
    return dataRows.toSorted(sortFunction);
  }, [columns, goals, lifeAreas, sortColumn, sortMode]);

  return {
    rows: sortedDataRows,
    sortMode,
    onSortChange,
    sortColumn,
  };
};
