import { useEffect } from 'react';
import { EventProps, PageEvents } from 'shared/services/tracking';

import { useTrackEvents } from './use-track-events';

export const useTrackPageVisitEvent = <TrackEvent extends PageEvents>(
  event: TrackEvent,
  eventProps: EventProps<TrackEvent>,
) => {
  const track = useTrackEvents();

  useEffect(() => {
    track(event, eventProps);
  }, [event, eventProps, track]);
};
