import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Info } from 'shared/assets/icons';
import { Alert, Colors as AlertColors } from 'shared/components/ui/alert';
import { Button, Colors, Sizes, Variants } from 'shared/components/ui/button';
import { Toggle } from 'shared/components/ui/toggle';
import { useNotificationPermission } from 'shared/hooks/use-notification-permission';
import { Notifications } from 'shared/types/user-settings';

import * as Styled from './notifications-table.style';

const initialNotifications: Notifications = {
  taskReminder: { desktop: false, mobile: false },
};

export type NotificationsTableProps = {
  notifications?: Notifications | null;
  onChange?: (notifications: Notifications) => void;
};

export const NotificationsTable: React.FC<NotificationsTableProps> = ({
  notifications,
  onChange,
}) => {
  const { t } = useTranslation();
  const [permissions, requestNotificationPermission] =
    useNotificationPermission();

  const values = useMemo(
    () => notifications ?? initialNotifications,
    [notifications],
  );

  const onChangeNotification = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    const categoryKey = target.name as keyof Notifications;
    const categoryOption = target.value as keyof Notifications['taskReminder'];
    const category = values[categoryKey] ?? {};
    const oldValue = category?.[categoryOption] ?? false;

    onChange?.({
      ...values,
      [categoryKey]: {
        ...category,
        [categoryOption]: !oldValue,
      },
    });
  };

  return (
    <Styled.Container>
      {permissions === 'prompt' && (
        <Alert
          startIcon={Info}
          color={AlertColors.Warning}
          action={
            <Button
              variant={Variants.Outlined}
              color={Colors.Warning}
              size={Sizes.Small}
              onClick={requestNotificationPermission}
            >
              {t(
                'forms.user-settings.sections.notifications.warning.undecided.activate-button.label',
              )}
            </Button>
          }
        >
          <Typography variant="overline" component="span">
            {t(
              'forms.user-settings.sections.notifications.warning.undecided.message.label',
            )}
          </Typography>
        </Alert>
      )}
      {permissions === 'denied' && (
        <Alert startIcon={Info} color={AlertColors.Warning}>
          <Typography variant="overline" component="span">
            {t(
              'forms.user-settings.sections.notifications.warning.denied.message.label',
            )}
          </Typography>
        </Alert>
      )}

      <Styled.TableContainer>
        <Styled.Header>
          <Typography variant="overline">
            {t(
              'forms.user-settings.sections.notifications.header.notification.label',
            )}
          </Typography>
          <Typography variant="overline" textAlign="center">
            {t(
              'forms.user-settings.sections.notifications.header.desktop.label',
            )}
          </Typography>
          <Typography variant="overline" textAlign="center">
            {t(
              'forms.user-settings.sections.notifications.header.mobile.label',
            )}
          </Typography>
        </Styled.Header>
        <Styled.Body>
          <Styled.Label>
            <Typography variant="body2">
              {t(
                'forms.user-settings.sections.notifications.fields.task-reminder.label',
              )}
            </Typography>
            <Styled.Description>
              {t(
                'forms.user-settings.sections.notifications.fields.task-reminder.description',
              )}
            </Styled.Description>
          </Styled.Label>
          <Toggle
            name="taskReminder"
            value="desktop"
            checked={!!values?.taskReminder?.desktop}
            onChange={onChangeNotification}
          />
          <Toggle
            name="taskReminder"
            value="mobile"
            checked={!!values?.taskReminder?.mobile}
            onChange={onChangeNotification}
          />
        </Styled.Body>
      </Styled.TableContainer>
    </Styled.Container>
  );
};
