import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { ID } from 'shared/types/id';

import { getAuthentication } from './get-authentication';
import { initApp } from './init-app';

export enum ImageLocations {
  Goals = 'uploads/goal',
  Vision = 'uploads/vision',
}

export const uploadFile = async (
  imageLocation: ImageLocations,
  file: File,
  id: ID,
) => {
  const storage = getStorage(initApp());
  const auth = await getAuthentication();

  if (!auth.currentUser) {
    return '';
  }

  const storageRef = ref(storage, `${imageLocation}/${file.name}`);

  await uploadBytes(storageRef, file, {
    customMetadata: {
      id,
      uid: auth.currentUser.uid,
    },
  });

  return `gs://${storageRef.bucket}/${storageRef.fullPath}`;
};
