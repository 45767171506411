import { addDays } from 'date-fns';
import { useCreateUnlimitedGoalsAllowed, useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import { useCreateHabitAllowed, useUpdateTask } from 'features/tasks';
import React, { memo, useMemo } from 'react';
import { Subtask } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { TaskMetaInteraction } from 'shared/components/ui/task-meta-interaction';
import { TaskMetaInteractionDueDate } from 'shared/components/ui/task-meta-interaction-due-date';
import { TaskMetaInteractionLinkedItem } from 'shared/components/ui/task-meta-interaction-linked-item';
import { TaskMetaInteractionPriority } from 'shared/components/ui/task-meta-interaction-priority';
import { TaskMetaInteractionReminderTime } from 'shared/components/ui/task-meta-interaction-reminder-time';
import { TaskMetaInteractionRepeat } from 'shared/components/ui/task-meta-interaction-repeat';
import { useOpenCreateGoalDialog } from 'shared/contexts/goal-form';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { useRemoveHabitScheduleDialog } from 'shared/contexts/remove-habit-schedule';
import { useToday } from 'shared/contexts/today';
import { useUser } from 'shared/hooks/use-user';
import { initialDateFormat } from 'shared/types/date-format-options';
import { Habit } from 'shared/types/habit';
import { HabitSchedule } from 'shared/types/habit-schedule';
import { ID } from 'shared/types/id';
import { LinkOptions, Task } from 'shared/types/task';
import {
  DueDisplayOptions,
  TaskPriorityOptions,
  TaskType,
} from 'shared/types/task-base';
import { Timestamp } from 'shared/types/timestamp';
import { initialWeekStartsOn } from 'shared/types/week-days';
import { getTaskDueDate } from 'shared/utils/get-task-due';
import { shouldDueBeShown } from 'shared/utils/should-due-be-shown';

import * as Styled from './task-meta-interactions.style';

const allDueDisplayOptions = Object.values(DueDisplayOptions);

export type TaskMetaInteractionsProps = {
  task: Task;
  displayDue?: DueDisplayOptions[];
  hasTooltips?: boolean;
  hideEmpty?: boolean;
  hideGoal?: boolean;
  hideLifeArea?: boolean;
  showGoalColor?: boolean;
  highlight?: boolean;
};

export const TaskMetaInteractions: React.FC<TaskMetaInteractionsProps> = memo(
  ({
    task,
    displayDue = allDueDisplayOptions,
    hideEmpty,
    hideGoal,
    hideLifeArea,
    showGoalColor,
    highlight,
    hasTooltips,
  }) => {
    const user = useUser();
    const today = useToday();
    const goals = useGoals();
    const lifeAreas = useLocalizedLifeAreas();

    const isCreateGoalAllowed = useCreateUnlimitedGoalsAllowed();
    const isCreateHabitAllowed = useCreateHabitAllowed();
    const openPremiumDialog = useOpenPremiumDialog();
    const openCreateGoalDialog = useOpenCreateGoalDialog();

    const { submit: updateTask } = useUpdateTask();
    const openRemoveScheduleDialog = useRemoveHabitScheduleDialog();

    const onCreateGoal = (name?: string) =>
      isCreateGoalAllowed
        ? openCreateGoalDialog({ name })
        : openPremiumDialog();

    // prevent edit mode on the task-card
    const onContainer = (e: React.MouseEvent) => {
      e.stopPropagation();
    };

    const updateDeadline = (date?: Timestamp) =>
      updateTask({
        ...task,
        endStrategy: {
          deadline: date ?? null,
          completionCount: task.endStrategy?.completionCount ?? null,
        },
      });

    const updateSkip = (date?: Timestamp) => {
      if (!date) {
        return;
      }

      const schedules = structuredClone((task as Habit).schedules ?? []);

      const activeSchedule = schedules.find(({ endDate }) => !endDate);
      if (!activeSchedule) {
        return;
      }

      // skip the day before the newly set date to make it due on the selected date.
      activeSchedule.skips = [
        ...(activeSchedule.skips ?? []),
        addDays(date, -1),
      ];

      updateTask({ id: task.id, schedules });
    };

    const updateSchedule = (changes: {
      schedules: HabitSchedule[] | null;
      type: TaskType;
    }) => {
      if (!changes.schedules) {
        openRemoveScheduleDialog(task as Habit);
        return;
      }

      updateTask({
        ...task,
        ...changes,
      } as Task);
    };

    const onChangeGoal = (link: { id: ID; type: LinkOptions } | null) =>
      updateTask({
        ...task,
        lifeAreaId: link?.type === LinkOptions.LifeArea ? link.id : null,
        goalId: link?.type === LinkOptions.Goal ? link.id : null,
      });

    const onChangeReminder = (reminderTime: number | undefined) =>
      updateTask({ ...task, reminderTime: reminderTime ?? null });

    const onChangePriority = (priority: TaskPriorityOptions | null) =>
      updateTask({ ...task, priority });

    const dateFormat = user?.settings?.dateFormat ?? initialDateFormat;
    const weekStartsOn = user?.settings?.startOfWeek ?? initialWeekStartsOn;
    const changeToHabitAllowed =
      isCreateHabitAllowed || task.type === TaskType.Habit;

    const dueDate = useMemo(
      () => getTaskDueDate(task, { today, weekStartsOn }),
      [task, today, weekStartsOn],
    );

    const hideDueDate = useMemo(
      () => !dueDate || !shouldDueBeShown(dueDate, displayDue, today),
      [displayDue, dueDate, today],
    );

    return (
      <Styled.List onClick={onContainer}>
        {!!task.childIds?.length && (
          <Styled.ListItem $hide={false} $hasValue>
            <TaskMetaInteraction
              start={<Icon icon={Subtask} />}
              label={`${task.childIds.length}`}
            />
          </Styled.ListItem>
        )}

        <Styled.ListItem
          $hasValue={!!dueDate}
          $hide={!!hideEmpty && hideDueDate}
        >
          <TaskMetaInteractionDueDate
            dateFormat={dateFormat}
            weekStartsOn={weekStartsOn}
            onChange={task.schedules ? updateSkip : updateDeadline}
            value={dueDate}
            highlight={highlight}
            hasTooltip={hasTooltips}
            isDeadline={!task.schedules}
          />
        </Styled.ListItem>
        <Styled.ListItem
          $hasValue={!!task.schedules}
          $hide={!!hideEmpty && !task.schedules}
        >
          <TaskMetaInteractionRepeat
            value={task.schedules}
            type={task.type}
            onChange={updateSchedule}
            weekStartsOn={weekStartsOn}
            changeToHabitAllowed={changeToHabitAllowed}
            onPremium={openPremiumDialog}
            highlight={highlight}
            hasTooltip={hasTooltips}
          />
        </Styled.ListItem>
        <Styled.ListItem
          $hasValue={!!task.goalId || !!task.lifeAreaId}
          $hide={
            (!!hideEmpty && !task.goalId && !task.lifeAreaId) ||
            (!!hideGoal && !!task.goalId) ||
            (!!hideLifeArea && !!task.lifeAreaId)
          }
        >
          <TaskMetaInteractionLinkedItem
            goals={goals}
            lifeAreas={lifeAreas}
            value={task.goalId ?? task.lifeAreaId}
            onChange={onChangeGoal}
            onCreate={onCreateGoal}
            showGoalColor={showGoalColor}
            highlight={highlight}
            hasTooltip={hasTooltips}
          />
        </Styled.ListItem>
        <Styled.ListItem
          $hasValue={!!task.reminderTime}
          $hide={!!hideEmpty && !task.reminderTime}
        >
          <TaskMetaInteractionReminderTime
            onChange={onChangeReminder}
            value={task.reminderTime}
            highlight={highlight}
            hasTooltip={hasTooltips}
          />
        </Styled.ListItem>
        <Styled.ListItem $hasValue={!!task.priority} $hide={!!hideEmpty}>
          <TaskMetaInteractionPriority
            value={task.priority}
            onChange={onChangePriority}
            highlight={highlight}
            hasTooltip={hasTooltips}
          />
        </Styled.ListItem>
      </Styled.List>
    );
  },
);
