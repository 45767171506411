import { NewLifeArea } from 'shared/types/life-area';

import { dateToFirestoreTimestamp } from './converters/date-to-firestore-timestamp';
import { newLifeAreaConverter } from './converters/life-area';
import { addDocumentWithOwnerRole, CollectionOptions } from './helpers';

export const createLifeArea = async (lifeArea: NewLifeArea) => {
  return addDocumentWithOwnerRole(
    CollectionOptions.LifeAreas,
    dateToFirestoreTimestamp(newLifeAreaConverter(lifeArea)),
  );
};
