import { useVerifyEmailMutation } from 'shared/hooks/queries/use-verify-email-mutation';

export const useVerifyEmail = () => {
  const { mutate, isPending, isSuccess, error } = useVerifyEmailMutation();

  return {
    verifyEmail: mutate,
    isLoading: isPending,
    isSuccess,
    error,
  };
};
