import { TFunction } from 'i18next';
import { Frequency, RRule } from 'rrule';
import { TaskTemplateSchedule } from 'shared/types/goal-template';
import {
  HabitSchedule,
  HabitScheduleTimesPer,
} from 'shared/types/habit-schedule';
import { WeekDays, weekendDays, workWeek } from 'shared/types/week-days';
import { formatRruleWeekDays } from 'shared/utils/format-rrule-week-days';

export const getScheduleTranslationKey = (
  schedule: HabitSchedule | TaskTemplateSchedule,
  t: TFunction,
) => {
  const rrule = RRule.fromString(schedule.rrule.format);
  const frequency = (schedule as HabitScheduleTimesPer).frequency;
  const weekDays = formatRruleWeekDays(schedule.rrule.format);

  if (rrule.origOptions.freq === Frequency.MONTHLY) {
    if (frequency) {
      return t('general.labels.habit.repeat.value.time-per-month.label', {
        count: frequency.count,
      });
    }

    if (rrule.origOptions.bysetpos) {
      let dayLabel = 'day';
      if (weekDays.length === 1) {
        dayLabel = weekDays[0];
      }

      if (
        weekDays.length === workWeek.length &&
        workWeek.every((weekDay) => weekDays.includes(weekDay))
      ) {
        dayLabel = 'work-day';
      }

      if (
        weekDays.length === weekendDays.length &&
        weekendDays.every((weekendDay) => weekDays.includes(weekendDay))
      ) {
        dayLabel = 'weekend';
      }

      return t(
        'general.labels.habit.repeat.value.specific-day.per-month.label',
        {
          ordinalNumber: t(
            `general.labels.habit.repeat.value.ordinal.specific-day.${rrule.origOptions.bysetpos}.label`,
          ),
          day: t(
            `general.labels.habit.repeat.specific-days.days.${dayLabel}.label`,
          ),
        },
      );
    }

    if (rrule.origOptions.bymonthday) {
      return [
        ...(Array.isArray(rrule.origOptions.bymonthday)
          ? rrule.origOptions.bymonthday
          : [rrule.origOptions.bymonthday]),
      ]
        .sort((monthDayA, monthDayB) => monthDayA - monthDayB)
        .map((monthDay) => `${monthDay}e`)
        .join(', ');
    }
  }

  if (frequency) {
    return t('general.labels.habit.repeat.value.time-per-week.label', {
      count: frequency.count,
    });
  }

  // check if it is set on every day
  if (weekDays.length === Object.keys(WeekDays).length) {
    return t(
      'general.labels.habit.repeat.value.specific-days.days.every-day.label',
    );
  }

  // check if it is a workweek
  if (
    workWeek.length === weekDays.length &&
    weekDays
      .toSorted()
      .every((val, index) => val === workWeek.toSorted()[index])
  ) {
    return t(
      'general.labels.habit.repeat.value.specific-days.days.week-days.label',
    );
  }

  // check if it is set on a single day
  if (weekDays.length === 1) {
    return t(
      'general.labels.habit.repeat.value.specific-days.days.single-day.label',
      {
        day: t(
          `general.labels.habit.repeat.value.specific-days.days.${weekDays[0]}.label`,
        ),
      },
    );
  }

  // map all days to specific format
  return weekDays
    .map((weekday) =>
      t(
        `general.labels.habit.repeat.value.specific-days.days.${weekday}.label`,
      ),
    )
    .join(', ');
};
