import {
  connectFirestoreEmulator,
  Firestore,
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
} from 'firebase/firestore';

import { getAnalytics } from './get-analytics';
import { getPerformanceMonitoring } from './get-performance-monitoring';
import { initApp } from './init-app';

let database: Firestore | undefined;

export const getDatabase = () => {
  if (!database) {
    database = initializeFirestore(initApp(), {
      localCache: persistentLocalCache(
        /*settings*/ { tabManager: persistentMultipleTabManager() },
      ),
    });

    if (
      import.meta.env.MODE === 'development' &&
      import.meta.env.VITE_FIREBASE_EMULATOR_URL
    ) {
      connectFirestoreEmulator(
        database,
        import.meta.env.VITE_FIREBASE_EMULATOR_URL,
        8080,
      );
    }

    getAnalytics(initApp());
    getPerformanceMonitoring(initApp());
  }

  return database;
};

export const clearDatabase = () => {
  database = undefined;
};
