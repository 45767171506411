import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChangePasswordForm } from 'shared/components/ui/change-password-form';
import { ChangePasswordErrorTypes } from 'shared/types/change-password-form';

import * as Styled from './change-password.style';

export type ChangePasswordDialogProps = {
  open?: boolean;
  isLoading?: boolean;
  error?: ChangePasswordErrorTypes;
  onSubmit: (oldPassword: string, newPassword: string) => void;
  onClose: () => void;
};

export const ChangePasswordDialog: React.FC<ChangePasswordDialogProps> = ({
  open,
  isLoading,
  error,
  onSubmit,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={!!open} onClose={!isLoading ? onClose : undefined}>
      <Styled.Container>
        <Styled.Header>
          <Typography variant="h5" component="h1">
            {t('forms.change-password.title')}
          </Typography>
        </Styled.Header>

        <Styled.Body>
          <ChangePasswordForm
            onSubmit={onSubmit}
            onCancel={onClose}
            isLoading={!!isLoading}
            error={error}
          />
        </Styled.Body>
      </Styled.Container>
    </Dialog>
  );
};
