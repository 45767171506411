import { createContext } from 'react';
import { Paths } from 'shared/routes';

export type HistoryContextProps = {
  historyStack: Paths[];
};

export const HistoryContext = createContext<HistoryContextProps>({
  historyStack: [],
});
