import {
  AuthError,
  AuthErrorCodes,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import { Locales } from 'shared/assets/localization';
import { SignupErrorTypes } from 'shared/types/signup-form';

import { getAuthentication } from './helpers';
import { setUserCountry } from './set-user-country';
import { updateUser } from './update-user';

export const signupUser = async (
  email: string,
  password: string,
  name?: string,
  locale?: Locales,
) => {
  const auth = await getAuthentication();

  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password,
    );

    // todo: electron should be added to the source as well, once implemented
    await updateUser({
      name,
      language: locale,
      metaSource: 'web_app',
      notifications: {
        taskReminder: { desktop: true, mobile: true },
      },
    });

    try {
      await setUserCountry();
    } catch (e) {
      // do nothing, eventually send error to sentry or something like that
    }

    return userCredential.user.uid;
  } catch (e) {
    if ((e as AuthError).message.includes(AuthErrorCodes.EMAIL_EXISTS)) {
      throw SignupErrorTypes.EmailTaken;
    }

    switch ((e as AuthError).code) {
      case AuthErrorCodes.EMAIL_EXISTS:
        throw SignupErrorTypes.EmailTaken;
      default:
        throw SignupErrorTypes.Unknown;
    }
  }
};
