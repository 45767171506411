import { doc } from 'firebase/firestore';
import { ID } from 'shared/types/id';

import { taskConverter } from './converters/task';
import { CollectionOptions, getDatabase } from './helpers';

export const getTaskById = async (id: ID) => {
  return doc(getDatabase(), CollectionOptions.Tasks, id).withConverter(
    taskConverter,
  );
};
