import React, { useEffect, useState } from 'react';
import { BlurHashImage } from 'shared/components/connected/blur-hash-image';
import { Image } from 'shared/types/image';

import * as Styled from './image-cross-fade.style';

interface ImageSliderProps {
  items: Image[];
  interval?: number;
}

export const ImageCrossFade: React.FC<ImageSliderProps> = ({
  items,
  interval = 4000,
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalRef = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === items.length - 1 ? 0 : prevIndex + 1,
      );
    }, interval);

    return () => clearInterval(intervalRef);
  }, [items.length, interval]);

  return (
    <Styled.SliderContainer>
      {items.map(({ url, blurHash }, index) => (
        <Styled.Slide key={index} $shown={index === currentImageIndex}>
          <BlurHashImage src={url} hash={blurHash} />
        </Styled.Slide>
      ))}
    </Styled.SliderContainer>
  );
};
