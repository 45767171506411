import React from 'react';
import { SelfSortingTasksList } from 'shared/components/ui/tasks-list';

import { TaskSectionLayout } from './task-section-layout';

export type SelfSortingTaskSectionProps = Omit<
  React.ComponentProps<typeof TaskSectionLayout>,
  'children' | 'onCreateGoal'
> &
  React.ComponentProps<typeof SelfSortingTasksList>;

export const SelfSortingTaskSection: React.FC<SelfSortingTaskSectionProps> = ({
  tasks,
  displayDue,
  onReorder,
  selectedTask,
  hasOwnSelectedState,
  showRepeatingAsTodo,
  showDetailOnSelect,
  hideMeta,
  hideGoal,
  hideLifeArea,

  goals,
  lifeAreas,
  today,
  date,
  priority,
  goalId,
  lifeAreaId,
  parentIds,
  parentChildIds,
  headerToDisplay,
  headerIsLink,
  customHeader,
  newTaskInputRef,
  headerHasImage,
  canCreateTask,
  canCreateHabit,
  canCollapse,
  isFrozen,
  tasksDisabled,
}) => (
  <>
    <TaskSectionLayout
      goals={goals}
      lifeAreas={lifeAreas}
      today={today}
      date={date}
      priority={priority}
      goalId={goalId}
      lifeAreaId={lifeAreaId}
      parentIds={parentIds}
      parentChildIds={parentChildIds}
      headerToDisplay={headerToDisplay}
      headerIsLink={headerIsLink}
      customHeader={customHeader}
      newTaskInputRef={newTaskInputRef}
      canCollapse={canCollapse}
      headerHasImage={headerHasImage}
      canCreateTask={canCreateTask}
      canCreateHabit={canCreateHabit}
      hideMeta={hideMeta}
      isFrozen={isFrozen}
    >
      <SelfSortingTasksList
        tasks={tasks}
        displayDue={displayDue}
        onReorder={onReorder}
        selectedTask={selectedTask}
        hasOwnSelectedState={hasOwnSelectedState}
        showRepeatingAsTodo={showRepeatingAsTodo}
        showDetailOnSelect={showDetailOnSelect}
        hideMeta={hideMeta}
        hideGoal={hideGoal}
        hideLifeArea={hideLifeArea}
        tasksDisabled={tasksDisabled}
      />
    </TaskSectionLayout>
  </>
);
