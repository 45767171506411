import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLifeAreaByIdQuery } from 'shared/hooks/queries/use-life-area-by-id-query';
import { useUser } from 'shared/hooks/use-user';
import { ID } from 'shared/types/id';
import { LifeArea } from 'shared/types/life-area';
import { getLocalizedStringValue } from 'shared/utils/get-localized-string-value';

export const useLocalizedLifeAreaById = (id?: ID) => {
  const { i18n } = useTranslation();
  const user = useUser();
  const { data } = useLifeAreaByIdQuery(user?.uid, id);

  return useMemo<LifeArea | undefined>(
    () =>
      data
        ? { ...data, name: getLocalizedStringValue(data?.name, i18n.language) }
        : undefined,
    [data, i18n.language],
  );
};
