import { typographyToCss } from 'shared/lib/mui';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 32rem;
  height: 32rem;
`;

export const InputContainer = styled.div`
  padding: ${({ theme }) => `${theme.spacing(3)} ${theme.spacing(3)} 0`};
`;

export const List = styled.ul`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: ${({ theme }) => theme.spacing(3)};

  padding: ${({ theme }) => theme.spacing(3)};
  margin: 0;

  overflow: auto;
  list-style: none;
`;

export const ListItem = styled.li`
  min-width: 0;
`;

export const Figure = styled.figure`
  margin: 0;
  padding: 0;
  width: 100%;
`;

export const Image = styled.img`
  display: block;

  width: 100%;
  aspect-ratio: 1;
  border-radius: ${({ theme }) => theme.spacing(2)};
  cursor: pointer;

  object-fit: cover;
`;

export const ImageLabel = styled.figcaption`
  ${({ theme }) => typographyToCss(theme.typography.overline)};

  overflow: hidden;

  font-size: 1rem;
  line-height: 1.4rem;
  color: ${({ theme }) => theme.palette.text.secondary};
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Anchor = styled.a`
  color: inherit;
`;

export const LoaderContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ErrorContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};

  padding: ${({ theme }) => theme.spacing(3)};

  font-size: 2.4rem;
  color: ${({ theme }) => theme.palette.text.secondary};
`;
