import { useMutation } from '@tanstack/react-query';
import { updateGoal } from 'shared/services/backend-api';
import { Goal } from 'shared/types/goal';

export const useUpdateGoalMutation = () =>
  useMutation({
    mutationFn: async ({
      goal,
      forceValues,
    }: {
      goal: Partial<Goal>;
      forceValues?: boolean;
    }) => updateGoal(goal, forceValues),
  });
