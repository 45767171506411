import { useDroppable } from '@dnd-kit/core';
import React from 'react';
import { TasksList } from 'shared/components/ui/tasks-list';

import * as Styled from './task-section.style';
import { TaskSectionLayout } from './task-section-layout';

export type TaskSectionProps = Omit<
  React.ComponentProps<typeof TaskSectionLayout>,
  'children'
> &
  Omit<React.ComponentProps<typeof TasksList>, 'canDragTasks'> & {
    droppableId?: string;
    canReorder?: boolean;
    isFrozen?: boolean;
  };

export const TaskSection: React.FC<TaskSectionProps> = ({
  goalId,
  date,
  priority,
  tasks,
  selectedTask,
  showRepeatingAsTodo,
  displayDue,
  onTask,
  onUnfocus,
  canCreateTask,
  goals,
  lifeAreas,
  today,
  customHeader,
  headerToDisplay,
  headerIsLink,
  hideGoal,
  hideMeta,
  parentChildIds,
  parentIds,
  onHeader,
  droppableId,
  canReorder,
  canCollapse,
  isFrozen,
  taskIsMoving,
}) => {
  const { setNodeRef } = useDroppable({
    id: droppableId ?? '',
    disabled: !droppableId || !!tasks.length,
  });

  const containerProps = droppableId ? { ref: setNodeRef } : {};

  return (
    <Styled.Container {...containerProps}>
      <TaskSectionLayout
        goalId={goalId}
        canCreateTask={canCreateTask}
        goals={goals}
        lifeAreas={lifeAreas}
        date={date}
        today={today}
        priority={priority}
        headerToDisplay={headerToDisplay}
        onHeader={onHeader}
        customHeader={customHeader}
        canCollapse={canCollapse}
        isFrozen={isFrozen}
        parentIds={parentIds}
        parentChildIds={parentChildIds}
        hideMeta={hideMeta}
        headerIsLink={headerIsLink}
      >
        <TasksList
          tasks={tasks}
          onTask={onTask}
          onUnfocus={onUnfocus}
          selectedTask={selectedTask}
          showRepeatingAsTodo={showRepeatingAsTodo}
          displayDue={displayDue}
          canReorder={canReorder}
          canDragTasks={!!droppableId}
          hideMeta={hideMeta}
          hideGoal={hideGoal}
          taskIsMoving={taskIsMoving}
        />
      </TaskSectionLayout>
    </Styled.Container>
  );
};
