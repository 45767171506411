import { DndContext } from '@dnd-kit/core';
import { useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RescheduleButton } from 'shared/components/connected/reschedule-button';
import { TaskCard } from 'shared/components/connected/task-card';
import { OverlayComponentWrapper } from 'shared/components/ui/overlay-component-wrapper';
import { SortableOverlay } from 'shared/components/ui/sortable-overlay';
import { TaskSection } from 'shared/components/ui/task-section';
import { useActiveTaskId } from 'shared/contexts/task-detail';
import { useToday } from 'shared/contexts/today';
import { useDroppableDatesControls } from 'shared/hooks/use-droppable-dates-controls';
import { useHandleDateSectionDrop } from 'shared/hooks/use-handle-date-section-drop';
import { useSortableSensors } from 'shared/hooks/use-sortable-sensors';
import {
  DateSectionOptions,
  overdueSectionID,
} from 'shared/types/orderable-section';
import { DueDisplayOptions } from 'shared/types/task-base';

import { useUpcomingTaskSections } from '../../hooks/use-upcoming-task-sections';
import { sectionTypeToHeaderMap } from '../../types/date-section-options';
import { RestOfMonthHeader } from '../rest-of-month-header';
import { SectionHeaderOverdue } from '../section-header-overdue';

export const TasksOverview: React.FC = () => {
  const { t } = useTranslation();
  const today = useToday();
  const { taskCardTasks, sections: initialSections } =
    useUpcomingTaskSections();
  const onDragEnd = useHandleDateSectionDrop(initialSections);
  const {
    handleDragStart,
    handleDragOver,
    handleDragEnd,
    handleDragCancel,
    activeId,
    sections,
  } = useDroppableDatesControls({ initialSections, onDragEnd });
  const sensors = useSortableSensors();

  const selectedTask = useActiveTaskId();

  const goals = useGoals();
  const lifeAreas = useLocalizedLifeAreas();

  const activeItem = useMemo(
    () =>
      activeId ? taskCardTasks.find(({ id }) => id === activeId) : undefined,
    [activeId, taskCardTasks],
  );

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
      sensors={sensors}
    >
      {sections.map(({ id, items, deadline, startDate, endDate, type }) => {
        const headerToDisplay = sectionTypeToHeaderMap[type];
        const customHeader =
          type === DateSectionOptions.RestOfMonth ? (
            <RestOfMonthHeader startDate={startDate} endDate={endDate} />
          ) : undefined;

        if (id === overdueSectionID) {
          return items.length ? (
            <TaskSection
              key={id}
              date={deadline}
              tasks={items}
              displayDue={[DueDisplayOptions.Past, DueDisplayOptions.Future]}
              selectedTask={selectedTask}
              goals={goals}
              lifeAreas={lifeAreas}
              today={today}
              headerToDisplay={headerToDisplay}
              customHeader={
                <SectionHeaderOverdue
                  title={t('general.sections.overdue.title')}
                  titleCount={items.length}
                  actions={[
                    <RescheduleButton
                      tasks={items.map(({ raw }) => raw.task)}
                    />,
                  ]}
                />
              }
              droppableId={id}
              canCollapse
              canReorder
            />
          ) : null;
        }

        return (
          <TaskSection
            key={id}
            date={deadline}
            tasks={items}
            displayDue={
              type === DateSectionOptions.Day
                ? []
                : [DueDisplayOptions.Past, DueDisplayOptions.Future]
            }
            selectedTask={selectedTask}
            goals={goals}
            lifeAreas={lifeAreas}
            today={today}
            headerToDisplay={headerToDisplay}
            customHeader={customHeader}
            droppableId={id}
            canCollapse={id === overdueSectionID}
            canCreateTask={id !== overdueSectionID}
            canReorder
          />
        );
      })}

      <SortableOverlay>
        {!!activeItem && (
          <OverlayComponentWrapper>
            <TaskCard
              task={activeItem.raw.task}
              displayDue={[DueDisplayOptions.Past, DueDisplayOptions.Future]}
              noHover
            />
          </OverlayComponentWrapper>
        )}
      </SortableOverlay>
    </DndContext>
  );
};
