export const elementHasAttribute = (
  element: Element,
  key: string,
  value: string,
): boolean => {
  const hasAttribute = element.getAttribute(key) === value;

  if (hasAttribute) {
    return true;
  }

  if (element.parentElement) {
    return elementHasAttribute(element.parentElement, key, value);
  }

  return false;
};
