import React from 'react';
import { GridContainer } from 'shared/components/ui/grid-container';
import { useTrackPageVisitEvent } from 'shared/hooks/use-track-page-visit-event';
import { PageEvents } from 'shared/services/tracking';

import { Header } from './components/header';
import { Overview } from './components/overview';
import * as Styled from './goals-templates-overview.style';

export const GoalTemplatesOverview: React.FC = () => {
  useTrackPageVisitEvent(PageEvents.GoalTemplatesOverview, undefined);

  return (
    <Styled.Container>
      <GridContainer>
        <Header />
        <Overview />
      </GridContainer>
    </Styled.Container>
  );
};
