import { useCallback, useState } from 'react';

export const useOpenMenu = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const openMenu = useCallback(() => setMenuOpen(true), []);
  const closeMenu = useCallback(() => setMenuOpen(false), []);

  return {
    menuOpen,
    openMenu,
    closeMenu,
  };
};
