import React, { Suspense } from 'react';
import { SideNavigation } from 'shared/components/connected/side-navigation';
import { useActivePath } from 'shared/hooks/use-active-path';
import { useToggle } from 'shared/hooks/use-toggle';

import { Loader } from '../loader';
import * as Styled from './pages-layout.style';
import { TopBar } from './top-bar';

type PageLayoutProps = {
  children: React.ReactNode;
};

export const PagesLayout: React.FC<PageLayoutProps> = ({ children }) => {
  const activePath = useActivePath({ level: 2 });
  const [menuOpen, toggleMenuOpen] = useToggle(true);
  const [mobileMenuOpen, toggleMobileMenuOpen, forceMobileMenuOpen] =
    useToggle();

  const onNavItem = () => forceMobileMenuOpen(false);

  return (
    <Styled.Container>
      <SideNavigation
        open={menuOpen}
        toggleOpen={toggleMenuOpen}
        mobileOpen={mobileMenuOpen}
        toggleMobileOpen={toggleMobileMenuOpen}
        onNavigationItem={onNavItem}
        activePath={activePath}
      />

      <Styled.Body>
        <Suspense>
          <TopBar
            onMenu={toggleMenuOpen}
            onMobileMenu={toggleMobileMenuOpen}
            displayMenu={!menuOpen}
          />
        </Suspense>

        <Suspense fallback={<Loader />}>
          <Styled.Content>{children}</Styled.Content>
        </Suspense>
      </Styled.Body>
    </Styled.Container>
  );
};
