import Dialog from '@mui/material/Dialog';
import React from 'react';
import { X } from 'shared/assets/icons';
import { DialogVariantOptions } from 'shared/assets/styles';
import { IconButton, Sizes, Variants } from 'shared/components/ui/icon-button';
import { useNetworkStatus } from 'shared/hooks/use-network-status';

import * as Styled from './feedback-dialog.style';
import { FeedbackForm } from './feedback-form';
import { OfflineMessage } from './offline-message';

export type FeedbackDialogProps = {
  open: boolean;
  userName?: string;
  onSubmit: (fields: { message: string }) => void;
  onClose: () => void;
};

export const FeedbackDialog: React.FC<FeedbackDialogProps> = ({
  open,
  userName,
  onSubmit,
  onClose,
}) => {
  const { isOnline } = useNetworkStatus();
  const _onSubmit = (fields: { message: string }) => {
    onSubmit(fields);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} variant={DialogVariantOptions.Medium}>
      <Styled.CloseButton>
        <IconButton
          icon={X}
          variant={Variants.Contained}
          size={Sizes.Large}
          onClick={onClose}
        />
      </Styled.CloseButton>

      {isOnline ? (
        <FeedbackForm userName={userName} onSubmit={_onSubmit} />
      ) : (
        <OfflineMessage onClose={onClose} />
      )}
    </Dialog>
  );
};
