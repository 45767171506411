import React from 'react';
import { X } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { IconButton, Sizes } from 'shared/components/ui/icon-button';

import * as Styled from './alert.style';
import { Colors } from './types';

export type AlertProps = {
  startIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  color?: Colors;
  action?: React.ReactNode;
  onClose?: () => void;
  children: React.ReactNode;
};

export const Alert: React.FC<AlertProps> = ({
  startIcon,
  color = Colors.Info,
  action,
  onClose,
  children,
}) => (
  <Styled.Container $color={color}>
    {!!startIcon && (
      <Styled.IconContainer>
        <Icon icon={startIcon} />
      </Styled.IconContainer>
    )}

    <Styled.Content>{children}</Styled.Content>

    {!!action && <Styled.ActionContainer>{action}</Styled.ActionContainer>}

    {!!onClose && <IconButton icon={X} size={Sizes.Small} onClick={onClose} />}
  </Styled.Container>
);
