import { useCallback } from 'react';
import { useUpdateTodayLifeAreaTaskOrderMutation } from 'shared/hooks/queries/use-update-today-life-area-task-order-mutation';
import { ID } from 'shared/types/id';

export const useLifeAreaTodayReorder = () => {
  const { mutate } = useUpdateTodayLifeAreaTaskOrderMutation();

  return useCallback(
    (id: ID, taskIds: ID[]) => mutate({ id, taskIds }),
    [mutate],
  );
};
