// styling
import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

const textBase = css`
  ${({ theme }) => typographyToCss(theme.typography.body2)};
  display: block;
  grid-area: 1 / 1 / 2 / 2;

  padding: 0;
  margin: 0;

  border: none;
  outline: none;
`;

export const Container = styled.div`
  display: grid;

  &::after {
    ${textBase};
    content: attr(data-replicated-value) ' ';
    white-space: pre-wrap;
    visibility: hidden;
  }
`;

export const TextArea = styled.textarea`
  ${textBase};

  resize: none;
  overflow: hidden;

  color: inherit;
  background: transparent;
`;
