import { typographyToCss } from 'shared/lib/mui';
import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;

  height: 3.2rem;
  color: inherit;
`;

export const TitleContainer = styled.span`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
  align-items: center;
`;

export const TitleCount = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.body2)};

  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
`;
