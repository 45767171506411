import { Unsubscribe } from 'firebase/firestore';

const subscriptions: Unsubscribe[] = [];

export const clearSubscriptions = () => {
  while (subscriptions.length > 0) {
    const unsubscribe = subscriptions.pop();
    unsubscribe?.();
  }
};

export const addSubscription = (unsubscribe: Unsubscribe) => {
  subscriptions.push(unsubscribe);
};
