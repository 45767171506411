import { useCreateGoalTemplate } from 'features/goal-templates';
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { CreateGoalTemplateDialog } from 'shared/dialogs/create-goal-template';
import { GoalTemplateFormFields } from 'shared/types/goal-template-form';
import { ID } from 'shared/types/id';

import {
  CreateGoalTemplateContext,
  CreateGoalTemplateContextProps,
} from './create-goal-template-context';

export type CreateGoalTemplateProviderProps = {
  children: React.ReactNode;
};

export const CreateGoalTemplateProvider: React.FC<
  CreateGoalTemplateProviderProps
> = ({ children }) => {
  const [goalId, setGoalId] = useState<ID>();
  const { submit, retry, isLoading, isSuccess, error, reset } =
    useCreateGoalTemplate();

  const closeDialog = useCallback(() => {
    reset();
    setGoalId(undefined);
  }, [reset]);

  const value = useMemo<CreateGoalTemplateContextProps>(
    () => ({
      onCreateDialog: setGoalId,
    }),
    [],
  );

  const submitForm = useCallback(
    (values: GoalTemplateFormFields) => {
      submit(values);
    },
    [submit],
  );

  useEffect(() => {
    if (isSuccess) {
      closeDialog();
    }
  }, [closeDialog, isSuccess]);

  return (
    <CreateGoalTemplateContext.Provider value={value}>
      {children}

      {!!goalId && (
        <Suspense>
          <CreateGoalTemplateDialog
            goalId={goalId}
            onSubmit={submitForm}
            onRetry={retry}
            onClose={closeDialog}
            isLoading={isLoading}
            isError={!!error}
          />
        </Suspense>
      )}
    </CreateGoalTemplateContext.Provider>
  );
};
