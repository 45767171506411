import React from 'react';
import { useTranslation } from 'react-i18next';
import { GridContainer } from 'shared/components/ui/grid-container';
import { PageHeader } from 'shared/components/ui/page-header';
import { useTrackPageVisitEvent } from 'shared/hooks/use-track-page-visit-event';
import { PageEvents } from 'shared/services/tracking';

import { TasksOverview } from './components/tasks-overview';

export const Upcoming: React.FC = () => {
  const { t } = useTranslation();
  useTrackPageVisitEvent(PageEvents.Upcoming, undefined);

  return (
    <GridContainer>
      <PageHeader title={t('pages.upcoming.title')} />
      <TasksOverview />
    </GridContainer>
  );
};
