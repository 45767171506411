import { Loader } from 'pages/root/components/loader';
import React, { Suspense } from 'react';
import { Outlet } from 'react-router';
import { GridContainer } from 'shared/components/ui/grid-container';

import { DetailHeader } from './components/detail-header';
import { DetailNavigation } from './components/detail-navigation';
import { DetailNavigationTabs } from './components/detail-navigation-tabs';
import * as Styled from './life-area-detail.style';

export const LifeAreaDetail: React.FC = () => (
  <Styled.Container>
    <GridContainer>
      <DetailNavigation />
      <DetailHeader />
      <DetailNavigationTabs />
    </GridContainer>

    <Suspense fallback={<Loader />}>
      <Outlet />
    </Suspense>
  </Styled.Container>
);
