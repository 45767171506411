import Dialog from '@mui/material/Dialog';
import { useGoalTemplateByGoalId } from 'features/goal-templates';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GoalTemplateForm } from 'shared/components/ui/goal-template-form';
import { GoalTemplateFormFields } from 'shared/types/goal-template-form';
import { ID } from 'shared/types/id';
import { getLocalizedStringValue } from 'shared/utils/get-localized-string-value';

import { GoalTemplateFormError } from './goal-template-form-error';
import { GoalTemplateFormLoading } from './goal-template-form-loading';

export type CreateGoalTemplateDialogProps = {
  goalId: ID;
  onSubmit: (values: GoalTemplateFormFields) => void;
  onRetry: () => void;
  onClose: () => void;
  isLoading?: boolean;
  isError?: boolean;
};

export const CreateGoalTemplateDialog: React.FC<
  CreateGoalTemplateDialogProps
> = ({ goalId, onSubmit, onRetry, onClose, isLoading, isError }) => {
  const { i18n } = useTranslation();
  const template = useGoalTemplateByGoalId(goalId);
  const initialValues = useMemo(
    () => ({
      name: template?.name
        ? getLocalizedStringValue(template?.name, i18n.language)
        : undefined,
      description: template?.description
        ? getLocalizedStringValue(template?.description, i18n.language)
        : undefined,
      iconName: template?.iconName,
      isPublic: template?.isPublic ?? false,
      goalId: goalId,
    }),
    [
      goalId,
      i18n.language,
      template?.description,
      template?.iconName,
      template?.isPublic,
      template?.name,
    ],
  );

  const content = useMemo(() => {
    if (isError) {
      return <GoalTemplateFormError onRetry={onRetry} onCancel={onClose} />;
    }

    if (isLoading) {
      return <GoalTemplateFormLoading />;
    }

    return (
      <GoalTemplateForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        onCancel={onClose}
      />
    );
  }, [initialValues, isError, isLoading, onClose, onRetry, onSubmit]);

  return (
    <Dialog open fullWidth>
      {content}
    </Dialog>
  );
};
