import {
  connectFunctionsEmulator,
  Functions,
  getFunctions as getFirebaseFunctions,
} from 'firebase/functions';

import { initApp } from './init-app';

let functions: Functions | undefined;

export const getFunctions = () => {
  if (!functions) {
    functions = getFirebaseFunctions(initApp(), 'europe-west1');

    if (
      import.meta.env.MODE === 'development' &&
      import.meta.env.VITE_FIREBASE_EMULATOR_URL
    ) {
      connectFunctionsEmulator(
        functions,
        import.meta.env.VITE_FIREBASE_EMULATOR_URL,
        5001,
      );
    }
  }

  return functions;
};

export const clearFunctions = () => {
  functions = undefined;
};
