import { createContext } from 'react';
import { GoalTemplate } from 'shared/types/goal-template';

export type GoalTemplatePreviewContextProps = {
  openGoalTemplatePreview: (template: GoalTemplate) => void;
};

export const GoalTemplatePreviewContext =
  createContext<GoalTemplatePreviewContextProps>({
    openGoalTemplatePreview: () => {},
  });
