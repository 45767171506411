import { differenceInCalendarDays, differenceInHours, isAfter } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'shared/components/ui/icon';
import { useToday } from 'shared/contexts/today';
import { Timestamp } from 'shared/types/timestamp';

import * as Styled from './time-left.style';

export type TimeLeftProps = {
  startIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  date: Timestamp;
  isShort?: boolean;
};

export const TimeLeft: React.FC<TimeLeftProps> = ({
  date,
  startIcon,
  isShort,
}) => {
  const today = useToday();
  const { t } = useTranslation();
  const isOverdue = isAfter(today, date);
  const hoursLeft = differenceInHours(date, today);
  const daysLeft = isOverdue
    ? differenceInCalendarDays(date, today)
    : Math.ceil(hoursLeft / 24);
  const weeksLeft = Math.floor(daysLeft / 7);
  const daysLeftInWeek = daysLeft % 7;

  const overdueLabel = isShort
    ? 'general.labels.days-ago.short'
    : 'general.labels.days-ago';
  const weekLeftLabel = isShort
    ? 'general.labels.weeks-left.short.title'
    : 'general.labels.weeks-left.title';
  const weekDaysLabel = isShort
    ? 'general.labels.time-left.left.short'
    : 'general.labels.time-left.left';
  const daysLeftLabel = isShort
    ? 'general.labels.days-left.short.title'
    : 'general.labels.days-left.title';

  const isLastWeek = daysLeft <= 7;

  return (
    <Styled.Container $overdue={isOverdue} $lastWeek={isLastWeek}>
      {!!startIcon && (
        <Styled.IconContainer>
          <Icon icon={startIcon} />
        </Styled.IconContainer>
      )}

      <Styled.Label>
        {t(
          isOverdue
            ? overdueLabel
            : daysLeft > 7
              ? daysLeftInWeek
                ? weekDaysLabel
                : weekLeftLabel
              : daysLeftLabel,
          {
            count: isOverdue
              ? isShort
                ? daysLeft
                : Math.abs(daysLeft)
              : daysLeft > 7
                ? weeksLeft
                : daysLeft,
            time: t('general.labels.time-left.week-days', {
              weeks: weeksLeft,
              days: daysLeftInWeek,
            }),
          },
        )}
      </Styled.Label>
    </Styled.Container>
  );
};
