import { useCallback } from 'react';
import { useUpdateTaskMutation } from 'shared/hooks/queries/use-update-task-mutation';
import { useUpdateTodayPriorityTaskOrderMutation } from 'shared/hooks/queries/use-update-today-priority-task-order-mutation';
import { SectionBase } from 'shared/types/orderable-section';
import { TaskPriorityOptions } from 'shared/types/task-base';
import { TaskCardTask } from 'shared/types/task-card-task';

export const usePriorityTodayReorder = (
  initialSections: SectionBase<TaskCardTask>[],
) => {
  const { mutate: updatePrioritySorting } =
    useUpdateTodayPriorityTaskOrderMutation();
  const { mutate: updateTask } = useUpdateTaskMutation();

  return useCallback(
    (sections: SectionBase<TaskCardTask>[], movedItem: TaskCardTask) => {
      const newSection = sections.find(({ items }) =>
        items.find(({ id }) => id === movedItem.id),
      );
      const oldSection = initialSections.find(({ items }) =>
        items.find(({ id }) => id === movedItem.id),
      );

      if (!newSection || !oldSection) {
        return;
      }

      const prioritiesToUpdate = [
        {
          priority: newSection.id as TaskPriorityOptions,
          taskIds: newSection.items.map(({ id }) => id),
        },
      ];

      // if the section changed, get the new oldSection variant and add it to the prioritiesToUpdate list
      if (newSection.id !== oldSection.id) {
        const newOldSection = sections.find(({ id }) => id === oldSection.id);

        if (!newOldSection) {
          // should not be possible, just check for type-safety
          return;
        }

        prioritiesToUpdate.push({
          priority: newOldSection.id as TaskPriorityOptions,
          taskIds: newOldSection.items.map(({ id }) => id),
        });

        updateTask({
          id: movedItem.id,
          priority: newSection.id as TaskPriorityOptions,
        });
      }

      updatePrioritySorting(prioritiesToUpdate);
    },
    [initialSections, updatePrioritySorting, updateTask],
  );
};
