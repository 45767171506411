import styled from 'styled-components';

export const Container = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacing(1)}`};
`;

export const List = styled.ul`
  margin: 0;
  padding: 0;

  list-style: none;
`;

export const ListItem = styled.li`
  padding: ${({ theme }) => `0 0 0 ${theme.spacing(4)}`};
`;
