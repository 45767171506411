import { typographyToCss } from 'shared/lib/mui';
import styled from 'styled-components';

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => `${theme.spacing(6)} 0 0`};
  overflow: auto;

  ${({ theme }) => theme.breakpoints.up(theme.breakpoints.values.sm)} {
    gap: ${({ theme }) => theme.spacing(4)};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};

  margin: 0 auto;
  padding: ${({ theme }) => `0 ${theme.spacing(6)}`};
  max-width: 43.2rem;

  text-align: center;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const Title = styled.h2`
  ${({ theme }) => typographyToCss(theme.typography.h4)};
  margin: 0;
  padding: 0;

  color: ${({ theme }) => theme.palette.text.primary};
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(5)};
`;

export const Interactions = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  padding: ${({ theme }) => theme.spacing(4)};

  background: ${({ theme }) => theme.palette.background.default};

  ${({ theme }) => theme.breakpoints.up(theme.breakpoints.values.sm)} {
    position: initial;
    bottom: initial;
    left: initial;
    right: initial;

    margin: 0 auto;
    max-width: 31.6rem;
    width: 100%;
    padding: ${({ theme }) => `0 ${theme.spacing(6)} ${theme.spacing(6)}`};

    border-top: none;
  }
`;
