import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import React, { useMemo } from 'react';
import { NewTaskRow } from 'shared/components/connected/new-task-row';
import { NewSubGoalRow } from 'shared/components/ui/new-sub-goal-row';
import { Goal } from 'shared/types/goal';
import { ID } from 'shared/types/id';
import { TaskCardTask } from 'shared/types/task-card-task';
import { sortItems } from 'shared/utils/sort-items';

import * as Styled from './task-and-goal-list.style';
import { TaskGoalItem } from './task-goal-item';

export type TaskAndGoalListProps = Omit<
  React.ComponentProps<typeof TaskGoalItem>,
  'item' | 'isDragging' | 'isMoving'
> & {
  id: ID;
  onCreateSubGoal?: () => void;
  newTaskInputRef?: React.RefObject<HTMLTextAreaElement>;
};

export const TaskAndGoalList: React.FC<TaskAndGoalListProps> = ({
  id,
  items,
  goals,
  onTask,
  onCompleteGoal,
  onUnfocus,
  onDeleteGoal,
  onEditGoal,
  onUpdateGoal,
  onUpdateGoalProgress,
  onCreateSubGoal,
  onArchiveGoal,
  onFavoriteGoal,
  favorites,
  lifeAreas,
  onPremium,
  imagePlaceholderIcon,
  selectedItem,
  canCollapse,
  initialOpen,
  canCreateTask,
  newTaskInputRef,
  hasDnd,
  isSortingDisabled,
  hideGoal,
  showRepeatingAsTodo,
}) => {
  const SortableContainer = hasDnd ? SortableContext : React.Fragment;
  const item = useMemo(
    () => items.find((item) => item.id === id) as Goal | undefined,
    [id, items],
  );

  const { goalItems, taskItems } = useMemo(() => {
    const [own, otherItems] = items.reduce<
      [(Goal | TaskCardTask)[], (Goal | TaskCardTask)[]]
    >(
      (acc, item) => {
        const itemParentId =
          (item as TaskCardTask).fields?.goalId ??
          (item as Goal).parentIds?.[
            ((item as Goal).parentIds?.length ?? 0) - 1
          ];

        if (itemParentId === id) {
          acc[0].push(item);
          return acc;
        }

        acc[1].push(item);
        return acc;
      },
      [[], []],
    );

    return {
      goalItems: sortItems(
        own.filter((item) => !('fields' in item)) as Goal[],
        item?.goalSorting ?? [],
        'id',
      ),
      taskItems: sortItems(
        own.filter((item) => 'fields' in item) as TaskCardTask[],
        item?.taskSorting ?? [],
        'id',
      ),
      otherItems: otherItems,
    };
  }, [id, item?.goalSorting, item?.taskSorting, items]);

  const sortableGoalProps = hasDnd
    ? {
        items: goalItems.map(({ id }) => id),
        strategy: verticalListSortingStrategy,
      }
    : ({} as React.ComponentProps<typeof SortableContext>);

  const sortableTaskProps = hasDnd
    ? {
        items: taskItems.map(({ id }) => id),
        strategy: verticalListSortingStrategy,
      }
    : ({} as React.ComponentProps<typeof SortableContext>);

  return (
    <Styled.List>
      <SortableContainer {...sortableGoalProps}>
        {goalItems.map((item) => (
          <Styled.ListItem key={item.id}>
            <TaskGoalItem
              item={item}
              items={items}
              goals={goals}
              favorites={favorites}
              lifeAreas={lifeAreas}
              onTask={onTask}
              onCompleteGoal={onCompleteGoal}
              onFavoriteGoal={onFavoriteGoal}
              onUnfocus={onUnfocus}
              onDeleteGoal={onDeleteGoal}
              onEditGoal={onEditGoal}
              onUpdateGoal={onUpdateGoal}
              onUpdateGoalProgress={onUpdateGoalProgress}
              canCreateTask={canCreateTask}
              onArchiveGoal={onArchiveGoal}
              onPremium={onPremium}
              imagePlaceholderIcon={imagePlaceholderIcon}
              selectedItem={selectedItem}
              canCollapse={canCollapse}
              initialOpen={initialOpen}
              hasDnd={hasDnd}
              hideGoal={hideGoal}
              showRepeatingAsTodo={showRepeatingAsTodo}
              isSortingDisabled={isSortingDisabled}
            />
          </Styled.ListItem>
        ))}
      </SortableContainer>
      <SortableContainer {...sortableTaskProps}>
        {taskItems.map((item) => (
          <Styled.ListItem key={item.id} $isTask>
            <TaskGoalItem
              item={item}
              items={items}
              goals={goals}
              onTask={onTask}
              favorites={favorites}
              lifeAreas={lifeAreas}
              onCompleteGoal={onCompleteGoal}
              onFavoriteGoal={onFavoriteGoal}
              onUnfocus={onUnfocus}
              onDeleteGoal={onDeleteGoal}
              onEditGoal={onEditGoal}
              onUpdateGoal={onUpdateGoal}
              onUpdateGoalProgress={onUpdateGoalProgress}
              canCreateTask={canCreateTask}
              onArchiveGoal={onArchiveGoal}
              onPremium={onPremium}
              imagePlaceholderIcon={imagePlaceholderIcon}
              selectedItem={selectedItem}
              canCollapse={canCollapse}
              initialOpen={initialOpen}
              hasDnd={hasDnd}
              hideGoal={hideGoal}
              showRepeatingAsTodo={showRepeatingAsTodo}
              isSortingDisabled={isSortingDisabled}
            />
          </Styled.ListItem>
        ))}
      </SortableContainer>

      {(!!onCreateSubGoal || !!canCreateTask) && (
        <Styled.DroppableArea>
          {!!canCreateTask && (
            <Styled.NewTaskContainer>
              <NewTaskRow goal={id} newTaskInputRef={newTaskInputRef} />
            </Styled.NewTaskContainer>
          )}
          {!!onCreateSubGoal && (
            <Styled.NewTaskContainer>
              <NewSubGoalRow onCreateGoal={onCreateSubGoal} />
            </Styled.NewTaskContainer>
          )}
        </Styled.DroppableArea>
      )}
    </Styled.List>
  );
};
