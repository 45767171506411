import Dialog from '@mui/material/Dialog';
import React from 'react';
import { Locales } from 'shared/assets/localization';
import { DialogVariantOptions } from 'shared/assets/styles';
import { DateFormatOptions } from 'shared/types/date-format-options';
import { ThemeModes } from 'shared/types/theme-modes';
import { Notifications } from 'shared/types/user-settings';
import { UserSubscription } from 'shared/types/user-subscription';
import { WeekDays } from 'shared/types/week-days';

import { SettingsForm } from './settings-form';

export type UserSettingsDialogProps = {
  open: boolean;
  onClose: () => void;
  userName: string;
  updateUserName: (name: string) => void;
  language?: Locales;
  onChangeLanguage: (locale: Locales) => void;
  email: string;
  isSignedInWithProvider: boolean;
  dateFormat: DateFormatOptions;
  onChangeDateFormat: (dateFormat: DateFormatOptions) => void;
  weekStartsOn: WeekDays;
  onChangeWeekStart: (weekDay: WeekDays) => void;
  onChangePassword: () => void;
  notifications?: Notifications;
  onChangeNotifications: (notifications: Notifications) => void;
  themePreference: ThemeModes;
  onChangeThemePreference: (themeMode: ThemeModes) => void;
  activeSubscription?: UserSubscription;
  onManageSubscription: () => void;
  manageSubscriptionLoading?: boolean;
};

export const UserSettingsDialog: React.FC<UserSettingsDialogProps> = ({
  open,
  onClose,
  userName,
  updateUserName,
  language,
  onChangeLanguage,
  email,
  isSignedInWithProvider,
  dateFormat,
  onChangeDateFormat,
  weekStartsOn,
  onChangeWeekStart,
  onChangePassword,
  notifications,
  onChangeNotifications,
  themePreference,
  onChangeThemePreference,
  activeSubscription,
  onManageSubscription,
  manageSubscriptionLoading,
}) => {
  return (
    <Dialog
      open={open}
      fullWidth
      onClose={onClose}
      variant={DialogVariantOptions.Medium}
    >
      <SettingsForm
        onClose={onClose}
        name={userName}
        onChangeName={updateUserName}
        language={language}
        onChangeLanguage={onChangeLanguage}
        email={email}
        isSignedInWithProvider={isSignedInWithProvider}
        dateFormat={dateFormat}
        onChangeDateFormat={onChangeDateFormat}
        weekStartsOn={weekStartsOn}
        onChangeWeekStart={onChangeWeekStart}
        onChangePassword={onChangePassword}
        notifications={notifications}
        onChangeNotifications={onChangeNotifications}
        themePreference={themePreference}
        onChangeThemePreference={onChangeThemePreference}
        activeSubscription={activeSubscription}
        onManageSubscription={onManageSubscription}
        manageSubscriptionLoading={manageSubscriptionLoading}
      />
    </Dialog>
  );
};
