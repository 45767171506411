import { RRule } from 'rrule';

export type Options = {
  format: string;
  startDate: Date;
  endDate: Date;
};

export const getRruleFrequency = ({
  format,
  startDate,
  endDate,
}: Options): number => {
  const rule = RRule.fromString(format);
  // weirdly we need to set a start and end date on the options for this to work..
  rule.options.dtstart = startDate;
  rule.options.until = endDate;

  const dates = rule.between(startDate, endDate, true);

  return dates.length;
};
