import { deleteDoc, doc } from 'firebase/firestore';
import { ID } from 'shared/types/id';

import { CollectionOptions, getCollection } from './helpers';

export const deleteTask = (id: ID) => {
  const taskCollection = getCollection(CollectionOptions.Tasks);
  const docRef = doc(taskCollection, id);

  deleteDoc(docRef);
};
