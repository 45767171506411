import MuiTab from '@mui/material/Tab';
import MuiTabs from '@mui/material/Tabs';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { QueryNavLink } from 'shared/components/connected/query-nav-link';
import { useActivePath } from 'shared/hooks/use-active-path';
import { Paths } from 'shared/routes';
import { replaceUrlParams } from 'shared/utils/replace-url-params';

// import { useCompletedLifeAreaTasks } from '../../hooks/use-completed-life-area-tasks';
// import { useLifeAreaTasks } from '../../hooks/use-life-area-tasks';
import * as Styled from './detail-navigation-tabs.style';

const navigationTabs = [
  {
    label: 'pages.life-area-detail.tabs.vision.label',
    path: Paths.LifeAreaDetail,
  },
  {
    label: 'pages.life-area-detail.tabs.goals.label',
    path: Paths.LifeAreaDetailGoals,
  },
  {
    label: 'pages.life-area-detail.tabs.tasks.label',
    path: Paths.LifeAreaDetailTasks,
  },
];

export const DetailNavigationTabs: React.FC = () => {
  const { t } = useTranslation();
  const activePath = useActivePath();
  const { id } = useParams();
  // const { taskCardTasks: tasks } = useLifeAreaTasks(id);
  // const { taskCardTasks: completedTasks } = useCompletedLifeAreaTasks(id);

  // const tabs = useMemo(
  //   () =>
  //     tasks.length || completedTasks.length
  //       ? navigationTabs
  //       : navigationTabs.filter(
  //           ({ path }) => path !== Paths.LifeAreaDetailTasks,
  //         ),
  //   [completedTasks, tasks],
  // );

  const activeIndex = useMemo(
    () => navigationTabs.findIndex(({ path }) => path === activePath),
    [activePath],
  );

  if (!id) {
    return null;
  }

  return (
    <Styled.Container>
      <MuiTabs value={activeIndex}>
        {navigationTabs.map(({ label, path }) => (
          <MuiTab
            key={path}
            label={t(label)}
            component={QueryNavLink}
            to={replaceUrlParams(path, { id })}
            replace
          />
        ))}
      </MuiTabs>
    </Styled.Container>
  );
};
