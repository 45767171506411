import { useTaskByIdQuery } from 'shared/hooks/queries/use-task-by-id-query';
import { useUser } from 'shared/hooks/use-user';
import { ID } from 'shared/types/id';

export const useTaskById = (id?: ID) => {
  const user = useUser();
  const { data: task } = useTaskByIdQuery(user?.uid, id);

  return task;
};
