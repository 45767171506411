import { FirestoreDataConverter } from 'firebase/firestore';
import { FirebaseImage } from 'shared/types/image';
import { NewVision, Vision } from 'shared/types/vision';

import { firebaseTimestampToDate } from './firebase-timestamp-to-date';

export const visionConverter: FirestoreDataConverter<Vision> = {
  fromFirestore: (snapshot) => {
    const data = snapshot.data();
    return {
      ...data,
      images:
        data.images?.map((image: FirebaseImage) => ({
          ...image,
          createdAt: firebaseTimestampToDate(image.createdAt),
        })) ?? [],
      createdAt: firebaseTimestampToDate(data.createdAt),
      frozenAt: firebaseTimestampToDate(data.frozenAt),
    } as Vision;
  },
  toFirestore: (vision) => vision,
};

export const newVisionConverter = (vision: NewVision) => ({
  ...vision,
  createdAt: new Date(),
  lifeAreaId: vision.lifeAreaId ?? null,
  frozenAt: null,
});
