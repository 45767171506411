import { useCallback, useState } from 'react';

export const useToggle = (
  initialValue: boolean = false,
): [
  value: boolean,
  toggle: () => void,
  toggleForce: (value: boolean) => void,
] => {
  const [value, setValue] = useState(initialValue);

  const toggle = useCallback(() => {
    setValue((oldValue) => !oldValue);
  }, []);

  return [value, toggle, setValue];
};
