import {
  TypographyOptions,
  TypographyStyleOptions,
} from '@mui/material/styles/createTypography';

export const characterWidth = 7.1;

export const typography: TypographyOptions = {
  fontFamily: '"Poppins", sans-serif',
  fontWeightBold: 600,
  h1: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 600,
    fontSize: '2.4rem',
    lineHeight: 1.4,
  },
  h2: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 600,
    fontSize: '2.2rem',
    lineHeight: 1.4,
  },
  h3: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 600,
    fontSize: '2rem',
    lineHeight: 1.4,
  },
  h4: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 600,
    fontSize: '1.8rem',
    lineHeight: 1.4,
  },
  h5: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 600,
    fontSize: '1.6rem',
    lineHeight: 1.5,
  },
  h6: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 600,
    fontSize: '1.4rem',
    lineHeight: 1.4,
  },
  subtitle1: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 500,
    fontSize: '1.6rem',
    lineHeight: 1.5,
  },
  subtitle2: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 500,
    fontSize: '1.4rem',
    lineHeight: 1.4,
  },
  body1: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 400,
    fontSize: '1.6rem',
    lineHeight: 1.5,
  },
  body2: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 400,
    fontSize: '1.4rem',
    lineHeight: 1.4,
  },
  button: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 500,
    fontSize: '1.4rem',
    lineHeight: 1.4,
  },
  buttonSmall: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 500,
    fontSize: '1.2rem',
    lineHeight: 1.5,
  },
  buttonLarge: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 500,
    fontSize: '1.4rem',
    lineHeight: 1.4,
  },
  overline: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 400,
    fontSize: '1.2rem',
    lineHeight: 1.5,
    textTransform: 'initial',
  },
  caption: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 600,
    fontSize: '1.2rem',
    lineHeight: 1.5,
  },
};

declare module '@mui/material/styles' {
  interface TypographyVariants {
    buttonSmall: TypographyStyleOptions;
    buttonLarge: TypographyStyleOptions;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    buttonSmall: TypographyStyleOptions;
    buttonLarge: TypographyStyleOptions;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    buttonSmall: true;
    buttonLarge: true;
  }
}
