import {
  useArchiveLifeArea,
  useCustomLifeAreas,
  useLifeAreasWithGoals,
} from 'features/life-areas';
import { useUpdateLifeAreaOrder, useUserHadTrial } from 'features/user';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  LifeAreaDataGrid,
  SelfSortingLifeAreaDataGrid,
} from 'shared/components/ui/life-area-data-grid';
import { PremiumBanner } from 'shared/components/ui/premium-banner';
import { TitledCollapse } from 'shared/components/ui/titled-collapse';
import { useOpenDeleteLifeAreaDialog } from 'shared/contexts/delete-life-area';
import {
  useOpenCreateLifeAreaDialog,
  useOpenEditLifeAreaDialog,
} from 'shared/contexts/life-area-form';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { usePremiumFeatureAllowed } from 'shared/hooks/use-premium-feature-allowed';
import { useUser } from 'shared/hooks/use-user';
import { ID } from 'shared/types/id';
import { PremiumFeature } from 'shared/types/premium-feature';
import { sortItems } from 'shared/utils/sort-items';

import * as Styled from './overview.style';

export const Overview: React.FC = () => {
  const { t } = useTranslation();
  const { goals, lifeAreas } = useLifeAreasWithGoals();
  const customLifeAreas = useCustomLifeAreas();
  const user = useUser();
  const userHadTrial = useUserHadTrial();

  const updateOrder = useUpdateLifeAreaOrder();
  const openEditDialog = useOpenEditLifeAreaDialog();
  const openCreateDialog = useOpenCreateLifeAreaDialog();
  const openDeleteDialog = useOpenDeleteLifeAreaDialog();
  const archiveLifeArea = useArchiveLifeArea();

  const openPremiumDialog = useOpenPremiumDialog();
  const createLifeAreaAllowed = usePremiumFeatureAllowed(
    PremiumFeature.UnlimitedLifeAreas,
    { totalAmountOfCustomLifeAreas: customLifeAreas.length },
  );

  const onEdit = (id: ID) => {
    const lifeArea = lifeAreas.find((lifeArea) => lifeArea.id === id);
    !!lifeArea && openEditDialog(lifeArea, id);
  };

  const onDelete = (id: ID) => {
    const lifeArea = lifeAreas.find((lifeArea) => lifeArea.id === id);
    !!lifeArea && openDeleteDialog(lifeArea);
  };

  const onArchive = (id: ID) => {
    const lifeArea = lifeAreas.find((lifeArea) => lifeArea.id === id);
    !!lifeArea && archiveLifeArea(lifeArea);
  };

  const activeLifeAreas = useMemo(
    () =>
      sortItems(
        lifeAreas.filter(
          ({ frozenAt, archivedAt }) => !frozenAt && !archivedAt,
        ),
        user?.settings?.lifeAreaSorting ?? [],
        'id',
      ),
    [lifeAreas, user?.settings?.lifeAreaSorting],
  );
  const archivedLifeAreas = useMemo(
    () =>
      sortItems(
        lifeAreas.filter(({ archivedAt }) => !!archivedAt),
        user?.settings?.lifeAreaSorting ?? [],
        'id',
      ),
    [lifeAreas, user?.settings?.lifeAreaSorting],
  );

  const frozenLifeAreas = useMemo(
    () => lifeAreas.filter(({ frozenAt }) => !!frozenAt),
    [lifeAreas],
  );

  return (
    <Styled.Container>
      <SelfSortingLifeAreaDataGrid
        lifeAreas={activeLifeAreas}
        goals={goals}
        onReorder={updateOrder}
        onEditLifeArea={onEdit}
        onDeleteLifeArea={onDelete}
        onArchiveLifeArea={onArchive}
        onCreateLifeArea={createLifeAreaAllowed ? openCreateDialog : undefined}
      />
      {!createLifeAreaAllowed && (
        <>
          <PremiumBanner
            title={t('pages.life-areas.premium-banner.title')}
            description={t(
              userHadTrial
                ? 'pages.life-areas.premium-banner.description'
                : 'pages.life-areas.premium-banner.try-for-free.description',
            )}
            onClick={openPremiumDialog}
            isTrial={!userHadTrial}
          />
          <LifeAreaDataGrid
            goals={goals}
            lifeAreas={frozenLifeAreas}
            onCreateLifeArea={openPremiumDialog}
            onLifeArea={openPremiumDialog}
            isFrozen
            hideHeader
            showPremiumLabel
          />
        </>
      )}

      {!!archivedLifeAreas.length && (
        <Styled.ArchivedContainer>
          <TitledCollapse
            title={t('pages.life-areas.sections.archived.title')}
            titleCount={archivedLifeAreas.length}
          >
            <LifeAreaDataGrid
              goals={goals}
              lifeAreas={archivedLifeAreas}
              onArchiveLifeArea={onArchive}
              onEditLifeArea={onEdit}
              onDeleteLifeArea={onDelete}
              hideHeader
              isArchived
            />
          </TitledCollapse>
        </Styled.ArchivedContainer>
      )}
    </Styled.Container>
  );
};
