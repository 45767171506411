import { useMemo } from 'react';
import { ID } from 'shared/types/id';

import { useGoals } from './use-goals';

export const useSubGoals = (id?: ID) => {
  const goals = useGoals();

  return useMemo(
    () => (id ? goals.filter(({ parentIds }) => parentIds?.includes(id)) : []),
    [goals, id],
  );
};
