import React, { forwardRef } from 'react';
import { ID } from 'shared/types/id';
import { Task } from 'shared/types/task';

import * as Styled from './task-card-title.style';

export type TaskCardTitleProps = {
  taskId: ID;
  value: string;
  isEditing?: boolean;
  completed?: boolean;
  placeholder?: string;
  onClick?: () => void;
  onChange: (updateTask: Pick<Task, 'id' | 'name'>) => void;
  onFocus?: (e: React.FocusEvent) => void;
  fullWidth?: boolean;
};

export const TaskCardTitle = forwardRef<
  HTMLTextAreaElement,
  TaskCardTitleProps
>(
  (
    {
      taskId,
      value,
      isEditing,
      completed,
      placeholder,
      onClick,
      onChange,
      onFocus,
      fullWidth,
    },
    ref,
  ) => {
    const onChangeTitle = ({
      target,
    }: React.ChangeEvent<HTMLTextAreaElement>) =>
      onChange({ id: taskId, name: target.value });

    return (
      <Styled.Container $fullWidth={!!fullWidth}>
        <Styled.SizingSpan $isStrikeThrough={!isEditing && !!completed}>
          {value || placeholder}
        </Styled.SizingSpan>
        <Styled.NameInput
          ref={ref}
          value={value}
          placeholder={placeholder}
          onChange={onChangeTitle}
          onClick={onClick}
          onFocus={onFocus}
          $isStrikeThrough={!isEditing && !!completed}
          $enabled={!!isEditing}
        ></Styled.NameInput>
      </Styled.Container>
    );
  },
);
