import * as yup from 'yup';

export enum SignupErrorTypes {
  EmailTaken = 'email-taken',
  Unknown = 'unknown',
}

export type SignupData = {
  name: string;
  email: string;
  password: string;
};

export const validationSchema: yup.ObjectSchema<SignupData> = yup.object({
  name: yup.string().required(),
  email: yup.string().email().required(),
  password: yup.string().required(),
});

export type SignupFormFields = yup.InferType<typeof validationSchema>;
