import { useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import {
  useCreateHabitAllowed,
  useCreateTask,
  useUpdateTask,
} from 'features/tasks';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NewTaskCard } from 'shared/components/ui/task-card';
import { useOpenCreateGoalDialog } from 'shared/contexts/goal-form';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { useClickOutside } from 'shared/hooks/use-click-outside';
import { useKeysDown } from 'shared/hooks/use-keys-down';
import { useNewTaskState } from 'shared/hooks/use-new-task-state';
import { useUser } from 'shared/hooks/use-user';
import { initialDateFormat } from 'shared/types/date-format-options';
import { ID } from 'shared/types/id';
import { NewTask } from 'shared/types/task';
import { TaskPriorityOptions } from 'shared/types/task-base';
import { Timestamp } from 'shared/types/timestamp';
import { initialWeekStartsOn } from 'shared/types/week-days';

export type NewTaskRowProps = {
  date?: Timestamp;
  goal?: ID;
  lifeArea?: ID;
  parentIds?: ID[] | null;
  parentChildIds?: ID[] | null;
  priority?: TaskPriorityOptions;
  newTaskInputRef?: React.RefObject<HTMLTextAreaElement>;
  hideMeta?: boolean;
};

export const NewTaskRow: React.FC<NewTaskRowProps> = ({
  date,
  goal,
  lifeArea,
  parentIds,
  parentChildIds,
  priority,
  newTaskInputRef,
  hideMeta,
}) => {
  const { t } = useTranslation();
  const taskRef = useRef<HTMLDivElement>(null);
  const [isSelected, setIsSelected] = useState(false);

  const user = useUser();
  const goals = useGoals();
  const lifeAreas = useLocalizedLifeAreas();
  const isCreateHabitAllowed = useCreateHabitAllowed();
  const openPremiumDialog = useOpenPremiumDialog();
  const openCreateGoalDialog = useOpenCreateGoalDialog();
  const { submit: createTask } = useCreateTask();
  const { submit: updateTask } = useUpdateTask();

  const onCreateTask = async (newTask: NewTask) => {
    const task = await createTask(newTask);
    const parentId = parentIds?.[parentIds.length - 1];

    if (parentId) {
      updateTask({
        id: parentId,
        childIds: [...(parentChildIds ?? []), task.id],
      });
    }
  };

  const onCreateGoal = (name: string) => openCreateGoalDialog({ name });

  const { submit, updateNewTask, newTask } = useNewTaskState(onCreateTask, {
    endStrategy: { deadline: date },
    goalId: goal ?? null,
    lifeAreaId: lifeArea ?? null,
    parentIds: parentIds ?? null,
    priority: priority,
  });

  const submitTask = () => {
    if (newTask.name) {
      submit();
      return;
    }

    setIsSelected(false);
  };

  const onFocus = () => setIsSelected(true);

  useClickOutside(taskRef, submitTask);
  useKeysDown(['Enter'], (e) => {
    if (isSelected) {
      // prevent the enter value from entering the text-area
      e.preventDefault();

      submitTask();
    }
  });

  return (
    <NewTaskCard
      nameInputRef={newTaskInputRef}
      ref={taskRef}
      name={newTask.name}
      type={newTask.type}
      createHabitAllowed={isCreateHabitAllowed}
      onPremium={openPremiumDialog}
      placeholder={t(
        parentIds?.length
          ? 'forms.new-task.sub-task.title.placeholder'
          : 'forms.new-task.title.placeholder',
      )}
      goalId={newTask.goalId}
      lifeAreaId={newTask.lifeAreaId}
      goals={goals}
      lifeAreas={lifeAreas}
      deadline={newTask.endStrategy?.deadline ?? null}
      priority={newTask.priority}
      dateFormat={user?.settings?.dateFormat ?? initialDateFormat}
      weekStartsOn={user?.settings?.startOfWeek ?? initialWeekStartsOn}
      schedules={newTask.schedules ?? null}
      reminderTime={newTask.reminderTime}
      onChange={updateNewTask}
      isSelected={isSelected}
      onClick={onFocus}
      onCreateGoal={onCreateGoal}
      noMeta={hideMeta}
    />
  );
};
