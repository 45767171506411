import { useCallback } from 'react';
import { useUpdateGoalMutation } from 'shared/hooks/queries/use-update-goal-mutation';
import { Goal } from 'shared/types/goal';

export const useArchiveGoal = () => {
  const { mutate: updateGoal } = useUpdateGoalMutation();

  return useCallback(
    (goal: Goal) => {
      const archivedAt = goal.archivedAt ? null : new Date();
      const goalToUpdate = {
        ...goal,
        archivedAt,
      };

      updateGoal({ goal: { id: goal.id, archivedAt } });

      return goalToUpdate;
    },
    [updateGoal],
  );
};
