import styled from 'styled-components';

export const Container = styled.section``;

export const SortIconContainer = styled.div`
  color: ${({ theme }) => theme.palette.grey['500']};
`;

export const SectionContainer = styled.section`
  padding-left: ${({ theme }) => theme.spacing(1)};
`;
