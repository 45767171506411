import { CircularProgress } from '@mui/material';
import React from 'react';

import * as Styled from './goal-template-preview-loading.style';

export const GoalTemplatePreviewLoading: React.FC = () => (
  <Styled.Container>
    <CircularProgress />
  </Styled.Container>
);
