import { useCallback } from 'react';
import { useRevalidator } from 'react-router-dom';
import { useLogoutUserMutation } from 'shared/hooks/queries/use-logout-user-mutation';

export const useLogout = () => {
  const { mutateAsync } = useLogoutUserMutation();
  const { revalidate } = useRevalidator();

  return useCallback(async () => {
    await mutateAsync();
    revalidate();
  }, [mutateAsync, revalidate]);
};
