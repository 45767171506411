import { createContext } from 'react';
import { Goal } from 'shared/types/goal';
import { ID } from 'shared/types/id';

export type GoalFormContextProps = {
  onCreateGoal: (initialValues?: Partial<Goal>) => void;
  onEditGoal: (initialValues: Partial<Goal>, id: ID) => void;
};

export const GoalFormContext = createContext<GoalFormContextProps>({
  onCreateGoal: () => {},
  onEditGoal: () => {},
});
