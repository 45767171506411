import React from 'react';
import { IconFormIcons } from 'shared/assets/icons';
import { DataGridNameImageCell } from 'shared/components/ui/data-grid-name-image-cell';

import * as Styled from './life-area-data-grid.style';
import { ColumnType, RowData } from './types';

export const columnToCellFcMap: Record<
  ColumnType,
  React.FC<{ data: RowData; isFrozen?: boolean }>
> = {
  [ColumnType.Name]: ({ data, isFrozen }) => (
    <Styled.DataCell $isFrozen={false}>
      <DataGridNameImageCell
        name={data.name}
        image={data.image}
        icon={IconFormIcons[data.iconName]}
        color={data.color}
        isFrozen={isFrozen}
      />
    </Styled.DataCell>
  ),
  [ColumnType.Vision]: ({ data, isFrozen }) => (
    <Styled.DataCell $empty={!data.visionDescription} $isFrozen={!!isFrozen}>
      {data.visionDescription || '-'}
    </Styled.DataCell>
  ),
  [ColumnType.Score]: ({ data, isFrozen }) => (
    <Styled.DataCell $empty={!data.lifeScore} $isFrozen={!!isFrozen}>
      {data.lifeScore || '-'}
    </Styled.DataCell>
  ),
  [ColumnType.ActiveGoals]: ({ data, isFrozen }) => (
    <Styled.DataCell $empty={!data.activeGoals} $isFrozen={!!isFrozen}>
      {data.activeGoals || '-'}
    </Styled.DataCell>
  ),
  [ColumnType.CompletedGoals]: ({ data, isFrozen }) => (
    <Styled.DataCell $empty={!data.completedGoals} $isFrozen={!!isFrozen}>
      {data.completedGoals || '-'}
    </Styled.DataCell>
  ),
  [ColumnType.Empty]: () => <Styled.DataCell $isFrozen={false} />,
};
