import * as yup from 'yup';

export enum ChangePasswordErrorTypes {
  Unknown = 'unknown',
}

export type ChangePasswordData = {
  oldPassword: string;
  newPassword: string;
  passwordConfirmation: string;
};

export const validationSchema: yup.ObjectSchema<ChangePasswordData> =
  yup.object({
    oldPassword: yup.string().required(),
    newPassword: yup.string().required(),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref('newPassword')])
      .required(),
  });

export type ChangePasswordFormFields = yup.InferType<typeof validationSchema>;
