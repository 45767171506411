import React from 'react';
import { ChevronLeft } from 'shared/assets/icons';
import { Collapse } from 'shared/components/ui/collapse';
import { IconButton, Sizes } from 'shared/components/ui/icon-button';
import { sideNavigationHoverClass } from 'shared/constants';
import { useToggle } from 'shared/hooks/use-toggle';

import * as Styled from './navigation-section-layout.style';

export type NavigationSectionLayoutProps = {
  label: string;
  children: React.ReactNode;
  isFirst?: boolean;
  initialOpen?: boolean;
};

export const NavigationSectionLayout: React.FC<
  NavigationSectionLayoutProps
> = ({ label, children, isFirst, initialOpen }) => {
  const [open, toggleOpen] = useToggle(initialOpen);
  return (
    <Styled.NavigationSectionContainer>
      <Styled.NavigationSectionHeader $isFirst={!!isFirst} onClick={toggleOpen}>
        <Styled.NavigationSectionButton>
          <Styled.NavigationSectionTitle>{label}</Styled.NavigationSectionTitle>

          <IconButton
            size={Sizes.Small}
            icon={ChevronLeft}
            rotation={open ? 270 : 90}
            className={sideNavigationHoverClass}
            as="span"
          />
        </Styled.NavigationSectionButton>
      </Styled.NavigationSectionHeader>

      <Collapse isOpen={open}>{children}</Collapse>
    </Styled.NavigationSectionContainer>
  );
};
