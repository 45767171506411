import { clearIndexedDbPersistence, terminate } from 'firebase/firestore';
import { clearSubscriptions } from 'shared/lib/@tanstack-query';

import { clearAppCheck } from './app-check';
import { clearAnalytics } from './get-analytics';
import { clearAuth } from './get-authentication';
import { clearDatabase, getDatabase } from './get-database';
import { clearFunctions } from './get-functions';
import { clearPerformanceMonitoring } from './get-performance-monitoring';
import { clearRemoteAppConfig } from './get-remote-app-config';
import { clearApp as clearFBApp } from './init-app';
import { clearPushNotificationsSubscriptions } from './init-push-notifications';
import { clearPushNotificationTokens } from './push-notifications-storage';

export const clearApp = async () => {
  const database = getDatabase();
  clearSubscriptions();
  await terminate(database);
  await clearIndexedDbPersistence(database);

  // clear all singletons so the app doesn't connect with the wrong entries
  clearPerformanceMonitoring();
  clearAnalytics();
  clearRemoteAppConfig();
  await clearFBApp();
  clearPushNotificationTokens();
  clearPushNotificationsSubscriptions();
  clearAppCheck();
  clearAuth();
  clearDatabase();
  clearFunctions();
};
