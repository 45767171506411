import { typographyToCss } from 'shared/lib/mui';
import styled from 'styled-components';

export const Textarea = styled.textarea`
  ${({ theme }) => typographyToCss(theme.typography.body2)};
  flex: 1;
  width: 100%;

  padding: 0;
  margin: 0;

  border: none;
  background: transparent;
  outline: none;
  resize: none;

  color: inherit;

  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  &::-moz-placeholder {
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  &:-ms-input-placeholder {
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;
