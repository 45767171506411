import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Logo, Star, X } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { IconButton, Sizes, Variants } from 'shared/components/ui/icon-button';
import { useActiveTheme } from 'shared/lib/mui';
import { ThemeModes } from 'shared/types/theme-modes';

import * as Styled from './header.style';

export type HeaderProps = {
  onClose: () => void;
};

export const Header: React.FC<HeaderProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const [themeMode] = useActiveTheme();

  return (
    <Styled.Container>
      {themeMode === ThemeModes.Dark ? (
        <Styled.Image
          src="/images/premium/purchase/premium-dialog-header-graphic-dark.png"
          // eslint-disable-next-line max-len -- long file names
          srcSet="/images/premium/purchase/premium-dialog-header-graphic-dark@2x.png, /images/premium/purchase/premium-dialog-header-graphic-dark@3x.png"
        />
      ) : (
        <Styled.Image
          src="/images/premium/purchase/premium-dialog-header-graphic-light.png"
          // eslint-disable-next-line max-len -- long file names
          srcSet="/images/premium/purchase/premium-dialog-header-graphic-light@2x.png, /images/premium/purchase/premium-dialog-header-graphic-light@3x.png"
        />
      )}

      <Styled.CloseButtonContainer>
        <IconButton
          icon={X}
          onClick={onClose}
          variant={Variants.Contained}
          size={Sizes.Large}
        />
      </Styled.CloseButtonContainer>

      <Styled.Body>
        <Styled.LogoContainer>
          <Icon icon={Logo} />

          <Styled.PremiumBatch>
            <Icon icon={Star} />
          </Styled.PremiumBatch>
        </Styled.LogoContainer>

        <Styled.Content>
          <Typography variant="h3" component="h1">
            {t('dialogs.premium.title')}
          </Typography>
          <Styled.Description>{t('dialogs.premium.slogan')}</Styled.Description>
        </Styled.Content>
      </Styled.Body>
    </Styled.Container>
  );
};
