import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { HabitCard, Plus } from 'shared/assets/icons';
import { Button, Colors, Sizes, Variants } from 'shared/components/ui/button';
import { useOpenCreateHabitDialog } from 'shared/contexts/habit-form';

import * as Styled from './empty-habits-list.style';

export const EmptyHabitsList: React.FC = () => {
  const { t } = useTranslation();
  const openDialog = useOpenCreateHabitDialog();

  const onAdd = () => openDialog();

  return (
    <Styled.Container>
      <Styled.Content>
        <Styled.IconContainer>
          <HabitCard />
        </Styled.IconContainer>
        <Typography variant="body2">
          {t('pages.habits.empty.description')}
        </Typography>
        <Button
          variant={Variants.Contained}
          size={Sizes.Medium}
          color={Colors.Primary}
          startIcon={Plus}
          tabIndex={0}
          onClick={onAdd}
        >
          {t('pages.habits.empty.button.add.label')}
        </Button>
      </Styled.Content>
    </Styled.Container>
  );
};
