import Typography from '@mui/material/Typography';
import React from 'react';
import { IconFormIcons } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { Paths } from 'shared/routes';
import { LifeArea } from 'shared/types/life-area';
import { replaceUrlParams } from 'shared/utils/replace-url-params';

import * as Styled from './favorite-life-area.style';

export type FavoriteLifeAreaProps = {
  lifeArea: LifeArea;
  currentPath?: string;
  isMoving?: boolean;
};

export const FavoriteLifeArea: React.FC<FavoriteLifeAreaProps> = ({
  lifeArea,
  currentPath,
  isMoving,
}) => {
  const url = replaceUrlParams(Paths.LifeAreaDetail, { id: lifeArea.id });
  const isActive = !!currentPath && currentPath.includes(url);

  return (
    <Styled.Link
      to={url}
      $active={isActive}
      as={lifeArea.frozenAt ? 'span' : undefined}
      $isFrozen={!!lifeArea.frozenAt}
      $isMoving={!!isMoving}
    >
      <Styled.IconContainer $active={isActive}>
        <Icon icon={IconFormIcons[lifeArea.iconName]} />
      </Styled.IconContainer>
      <Styled.Title>
        <Typography variant="body2" component="span">
          {lifeArea.name}
        </Typography>
      </Styled.Title>
    </Styled.Link>
  );
};
