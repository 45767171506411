import * as yup from 'yup';

export enum CompleteSignupErrorTypes {
  Unknown = 'unknown',
}

export type CompleteSignupData = {
  name: string;
  password: string;
};

export const validationSchema: yup.ObjectSchema<CompleteSignupData> =
  yup.object({
    name: yup.string().required(),
    password: yup.string().required(),
  });

export type CompleteSignupFormFields = yup.InferType<typeof validationSchema>;
