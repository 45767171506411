import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { getPathByString, Options } from 'shared/utils/get-path-by-string';

export const useActivePath = (options?: Options) => {
  const { pathname: currentPathname } = useLocation();

  return useMemo(
    () => getPathByString(currentPathname, options),
    [currentPathname, options],
  );
};
