import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Repeat } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { PopupMenu } from 'shared/components/ui/popup-menu';
import { RepeatRruleForm } from 'shared/components/ui/repeat-rrule-form';
import { TaskMetaInteraction } from 'shared/components/ui/task-meta-interaction';
import { useToday } from 'shared/contexts/today';
import { useClickOutside } from 'shared/hooks/use-click-outside';
import { useOpenMenu } from 'shared/hooks/use-open-menu';
import { HabitSchedule } from 'shared/types/habit-schedule';
import { TaskType } from 'shared/types/task-base';
import { WeekDays } from 'shared/types/week-days';
import { getScheduleTranslationKey } from 'shared/utils/get-schedule-translation-key';

import * as Styled from './task-meta-interaction-repeat.style';

export type TaskMetaInteractionRepeatProps = Omit<
  React.ComponentProps<typeof TaskMetaInteraction>,
  'start' | 'onClick' | 'label' | 'selected' | 'tooltipLabel'
> & {
  value: HabitSchedule[] | null;
  type: TaskType;
  onChange: (changes: {
    type: TaskType;
    schedules: HabitSchedule[] | null;
  }) => void;
  weekStartsOn: WeekDays;
  changeToHabitAllowed: boolean;
  onPremium: () => void;
};

export const TaskMetaInteractionRepeat: React.FC<
  TaskMetaInteractionRepeatProps
> = ({
  value,
  type,
  onChange,
  changeToHabitAllowed,
  weekStartsOn,
  onPremium,

  ...rest
}) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const shouldReplaceScheduleRef = useRef(false);
  const today = useToday();

  const [adjustedType, setAdjustedType] = useState<
    TaskType.Habit | TaskType.Repeating
  >(
    type === TaskType.Task
      ? changeToHabitAllowed
        ? TaskType.Habit
        : TaskType.Repeating
      : type,
  );
  const { menuOpen, openMenu, closeMenu } = useOpenMenu();

  const _onChangeType = (type: TaskType.Habit | TaskType.Repeating) => {
    if (type === TaskType.Habit && !changeToHabitAllowed) {
      onPremium();
      return;
    }

    setAdjustedType(type);
  };

  const _clear = () => onChange({ type: TaskType.Task, schedules: null });

  const _onChange = (schedule: HabitSchedule | null) => {
    if (!schedule) {
      _clear();
      closeMenu();
      return;
    }

    const endedSchedules = value?.filter(({ endDate }) => !!endDate) ?? [];
    const updateSchedules = [];

    if (!shouldReplaceScheduleRef.current) {
      const scheduleToEnd = value?.find(({ endDate }) => !endDate);
      !!scheduleToEnd &&
        updateSchedules.push({ ...scheduleToEnd, endDate: today });
      shouldReplaceScheduleRef.current = true;
    }

    updateSchedules.push(schedule);

    onChange({
      type: adjustedType,
      schedules: [...endedSchedules, ...updateSchedules],
    });
    closeMenu();
  };

  const val = value?.[value?.length - 1];

  useClickOutside(containerRef, closeMenu);

  return (
    <Styled.Container ref={containerRef}>
      <TaskMetaInteraction
        start={<Icon icon={Repeat} />}
        onClick={openMenu}
        label={val ? getScheduleTranslationKey(val, t) : undefined}
        selected={menuOpen}
        tooltipLabel={t(
          val
            ? 'task.repeat.tooltip.edit.label'
            : 'task.repeat.tooltip.add.label',
        )}
        {...rest}
      />

      {menuOpen && (
        <PopupMenu referenceElement={containerRef}>
          <RepeatRruleForm
            initialRrule={val?.rrule?.format}
            initialCount={val?.frequency?.count ?? null}
            weekStartsOn={weekStartsOn}
            type={adjustedType}
            onChange={_onChange}
            onChangeType={_onChangeType}
            createHabitPremium={!changeToHabitAllowed}
          />
        </PopupMenu>
      )}
    </Styled.Container>
  );
};
