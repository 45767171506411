import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Option } from 'shared/components/ui/form-select';
import { ThemeModes } from 'shared/types/theme-modes';

export const useThemeModeOptions = () => {
  const { t } = useTranslation();

  return useMemo<Option[]>(
    () =>
      Object.values(ThemeModes).map((themeMode) => ({
        label: t(`general.labels.theme-modes.${themeMode}`),
        value: themeMode,
      })),
    [t],
  );
};
