import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogVariantOptions } from 'shared/assets/styles';
import { Button, Colors, Sizes, Variants } from 'shared/components/ui/button';

import { Header } from './header';
import * as Styled from './premium-purchased.style';

export type PremiumPurchasedProps = {
  open?: boolean;
  onClose: () => void;
};

export const PremiumPurchased: React.FC<PremiumPurchasedProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={!!open}
      fullWidth
      onClose={onClose}
      variant={DialogVariantOptions.Medium}
    >
      <Header onClose={onClose} />

      <Styled.Body>
        <Styled.Content>
          <Styled.Title>
            {t('dialogs.premium-purchased.content.title')}
          </Styled.Title>

          <Styled.DescriptionContainer>
            <Typography variant="body2" component="p">
              {t('dialogs.premium-purchased.content.description.1')}
            </Typography>
            <Typography variant="body2" component="p">
              {t('dialogs.premium-purchased.content.description.2')}
            </Typography>
          </Styled.DescriptionContainer>
        </Styled.Content>

        <Styled.Interactions>
          <Button
            variant={Variants.Contained}
            size={Sizes.Large}
            color={Colors.Primary}
            fullWidth
            alignCenter
            onClick={onClose}
          >
            {t('dialogs.premium-purchased.interactions.confirm')}
          </Button>
        </Styled.Interactions>
      </Styled.Body>
    </Dialog>
  );
};
