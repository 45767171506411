type Position = {
  top: number;
  right: number;
  bottom: number;
  left: number;
};

export const getPositionRelativeToContainContext = (
  element: HTMLElement,
  position?: Position,
) => {
  const rect = position ?? element.getBoundingClientRect();
  let parent = element.parentElement;

  while (parent) {
    const style = window.getComputedStyle(parent);

    if (style.containerType === 'inline-size') {
      const parentRect = parent.getBoundingClientRect();

      return {
        top: rect.top - parentRect.top,
        left: rect.left - parentRect.left,
        bottom: rect.bottom - parentRect.bottom,
        right: rect.right - parentRect.left,
      };
    }

    parent = parent.parentElement;
  }

  return rect;
};
