import {
  FrequencyUnit,
  HabitSchedule,
  RruleReferenceDate,
} from 'shared/types/habit-schedule';

import { parseFormUnitFromRrule } from './parse-rrule';
import { FormUnit } from './types';

export const parseValueAndCountToSchedule = (
  value: string,
  count: number | null,
  today: Date,
): HabitSchedule => {
  const formUnit = parseFormUnitFromRrule(value);

  return {
    startDate: today,
    frequency: count
      ? {
          count,
          unit:
            formUnit === FormUnit.Weeks
              ? FrequencyUnit.Week
              : FrequencyUnit.Month,
        }
      : null,
    rrule: {
      format: value,
      referenceDate: RruleReferenceDate.StartDate,
    },
    completions: [],
  };
};
