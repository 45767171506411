import { createContext } from 'react';
import { Timestamp } from 'shared/types/timestamp';

export type TodayContextProps = {
  today: Timestamp;
};

export const TodayContext = createContext<TodayContextProps>({
  today: new Date(),
});
