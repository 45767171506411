import { index, Options } from 'shared/assets/styles';
import styled, { css } from 'styled-components';

export const minContainerWidth = 264;
export const initialContainerWidth = 440;
export const maxContainerWidth = 560;

export const ResizableContainer = styled.article<{
  $open: boolean;
  $width: number;
  $isResizing: boolean;
}>`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;

  background: ${({ theme }) => theme.palette.background.default};
  z-index: ${index(Options.DETAIL_COLUMN)};
  overflow: hidden;
  transition:
    right 200ms,
    width 200ms;

  ${({ $open, $width }) =>
    $open
      ? css`
          width: ${`${$width}px`};
          box-shadow: ${({ theme }) => theme.shadowBox.column};

          border-left: 1px solid ${({ theme }) => theme.palette.divider};
        `
      : css`
          width: 0;
        `};
`;

export const ResizeHandle = styled.span<{ $isResizing: boolean }>`
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 4px;
  cursor: col-resize;
  background: transparent;

  transition: background 200ms;

  &:hover {
    background: ${({ theme }) => theme.palette.divider};
  }

  ${({ theme }) => theme.breakpoints.up(theme.breakpoints.values.xs)} {
    display: block;
  }

  ${({ theme, $isResizing }) =>
    $isResizing &&
    css`
      background: ${theme.palette.divider};
    `};
`;
