import React from 'react';
import { Outlet } from 'react-router';
import { GridContainer } from 'shared/components/ui/grid-container';
import { useTrackPageVisitEvent } from 'shared/hooks/use-track-page-visit-event';
import { PageEvents } from 'shared/services/tracking';

import { Header } from './components/header';
import { OverviewNavigationTabs } from './components/overview-navigation-tabs';
import * as Styled from './goals-overview.style';

export const GoalsOverview: React.FC = () => {
  useTrackPageVisitEvent(PageEvents.Goals, undefined);

  return (
    <Styled.Container>
      <GridContainer>
        <Header />
        <OverviewNavigationTabs />
      </GridContainer>

      <Outlet />
    </Styled.Container>
  );
};
