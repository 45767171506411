import MuiTypography from '@mui/material/Typography';
import styled, { css } from 'styled-components';

export const Typography = styled(MuiTypography)<{
  $placeholder?: string;
  $isStrikeThrough?: boolean;
}>`
  max-width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;

  &:focus {
    outline: none;
  }

  ${({ $placeholder }) =>
    $placeholder &&
    css`
      &:empty::before {
        content: '${$placeholder}';
        opacity: 0.4;
      }
    `};

  ${({ $isStrikeThrough }) =>
    $isStrikeThrough &&
    css`
      text-decoration: line-through;
    `};
`;
