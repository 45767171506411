import { useDeleteGoal } from 'features/goals';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DeleteGoalDialog } from 'shared/dialogs/delete-goal';
import { Goal } from 'shared/types/goal';

import {
  DeleteGoalContext,
  DeleteGoalContextProps,
} from './delete-goal-context';

export type DeleteGoalProviderProps = {
  children: React.ReactNode;
};

export const DeleteGoalProvider: React.FC<DeleteGoalProviderProps> = ({
  children,
}) => {
  const navigate = useNavigate();
  const [goal, setGoal] = useState<Goal>();
  const [redirectPath, setRedirectPath] = useState<string>();
  const {
    submit: deleteGoal,
    error,
    isSuccess,
    isLoading,
    retry,
    reset,
  } = useDeleteGoal();

  const openDialog = useCallback((goal: Goal, path?: string) => {
    setGoal(goal);
    setRedirectPath(path);
  }, []);

  const closeDialog = useCallback(() => {
    reset();
    setGoal(undefined);
    setRedirectPath(undefined);
  }, [reset]);

  const submit = useCallback(() => {
    !!goal && deleteGoal(goal);
  }, [deleteGoal, goal]);

  const value = useMemo<DeleteGoalContextProps>(
    () => ({ onDeleteGoal: openDialog }),
    [openDialog],
  );

  useEffect(() => {
    if (isSuccess) {
      redirectPath && navigate(redirectPath);
      closeDialog();
    }
  }, [closeDialog, isSuccess, navigate, redirectPath]);

  return (
    <DeleteGoalContext.Provider value={value}>
      {children}
      <DeleteGoalDialog
        open={!!goal}
        name={goal?.name!}
        onClose={closeDialog}
        onSubmit={submit}
        onRetry={retry}
        isLoading={isLoading}
        isError={!!error}
      />
    </DeleteGoalContext.Provider>
  );
};
