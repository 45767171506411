import { useCallback, useRef } from 'react';
import { useCreateTaskMutation } from 'shared/hooks/queries/use-create-task-mutation';
import { useUpdateGoalTaskCountMutation } from 'shared/hooks/queries/use-update-goal-task-count-mutation';
import { useTrackEvents } from 'shared/hooks/use-track-events';
import { ActionEvents } from 'shared/services/tracking';
import { taskTypeToTrackingTaskType } from 'shared/services/tracking/task-type-to-tracking-task-type';
import { goalInboxID } from 'shared/types/orderable-section';
import { NewTask, Task } from 'shared/types/task';

export const useCreateTask = () => {
  const track = useTrackEvents();
  const createTaskRef = useRef<NewTask>();
  const {
    mutateAsync: createTask,
    error,
    isPending,
    isSuccess,
    reset,
  } = useCreateTaskMutation();
  const { mutate: updateGoalTaskCount } = useUpdateGoalTaskCountMutation();

  const submit = useCallback(
    async (task: NewTask): Promise<Task> => {
      // filter out inbox ID
      task.goalId = task.goalId === goalInboxID ? null : task.goalId;
      createTaskRef.current = task;
      const { id } = await createTask(task);

      if (task.goalId) {
        updateGoalTaskCount({ goalId: task.goalId, taskCount: 1 });
      }

      track(ActionEvents.TaskCreate, {
        type: taskTypeToTrackingTaskType[task.type],
        deadline_added: !!task.endStrategy?.deadline,
        linked_goal: !!task.goalId,
      });

      return {
        ...task,
        id,
      } as Task;
    },
    [createTask, track, updateGoalTaskCount],
  );

  const retry = useCallback(async () => {
    const task = createTaskRef.current;

    if (task) {
      const { id } = await createTask(task);

      track(ActionEvents.TaskCreate, {
        type: taskTypeToTrackingTaskType[task.type],
        deadline_added: !!task.endStrategy?.deadline,
        linked_goal: !!task.goalId,
      });

      return {
        ...task,
        id,
      } as Task;
    }
  }, [createTask, track]);

  const resetAll = useCallback(() => {
    reset();
    createTaskRef.current = undefined;
  }, [reset]);

  return {
    submit,
    retry,
    reset: resetAll,
    error,
    isLoading: isPending,
    isSuccess,
  };
};
