import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export enum QueryParamOptions {
  OobCode = 'oobCode',
  Premium = 'premium',
}

export const globalQueryParamOptions: QueryParamOptions[] = [];

export const useQueryParam = (
  key: QueryParamOptions,
  initialValue?: string | number,
): [string | undefined, (value?: string | number) => void] => {
  const [queryParams, setQueryParams] = useSearchParams();
  const queryParam = queryParams.get(key) ?? undefined;

  const setQueryParam = useCallback(
    (value?: string | number, replace?: boolean) =>
      setQueryParams(
        (oldQueryParams) => {
          oldQueryParams.set(key, String(value));

          if (!value) {
            oldQueryParams.delete(key);
          }

          return oldQueryParams;
        },
        { replace },
      ),
    [key, setQueryParams],
  );

  useEffect(() => {
    if (!queryParam && typeof initialValue !== 'undefined') {
      setQueryParam(initialValue, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on the first render the initialValue should be set.
  }, []);

  return [queryParam, setQueryParam];
};

export const useQueryParamArray = (
  key: QueryParamOptions,
): [string[], (value?: string[] | number[]) => void] => {
  const [queryParams, setQueryParams] = useSearchParams();
  const queryParam = queryParams.getAll(key);

  const setQueryParam = useCallback(
    (value?: string[] | number[]) =>
      setQueryParams((oldQueryParams) => {
        oldQueryParams.delete(key);
        value?.forEach((val) => {
          oldQueryParams.append(key, String(val));
        });

        if (!value) {
          oldQueryParams.delete(key);
        }

        return oldQueryParams;
      }),
    [key, setQueryParams],
  );

  return [queryParam, setQueryParam];
};
