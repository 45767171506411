import Typography from '@mui/material/Typography';
import { format, isSameDay } from 'date-fns';
import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronLeft } from 'shared/assets/icons';
import { IconButton, Sizes } from 'shared/components/ui/icon-button';
import { useToday } from 'shared/contexts/today';

import {
  dateCheckboxWidth,
  initialVisibleDates,
  weekLength,
} from '../../constants';
import { useScrollSmoothToPosition } from '../../hooks/use-scroll-smooth-to-position';
import { Week } from '../../types/week';
import * as Styled from './habits-header.style';

export type ActiveHabitsHeaderProps = {
  onPrevious?: () => void;
  onNext?: () => void;
  scrollToDate?: number;
  dateWidth?: number;
  visibleDates?: number;
  onContainerWidthChange?: (width: number) => void;
  weeks?: Week[];
};

export const HabitsHeader: React.FC<ActiveHabitsHeaderProps> = ({
  onPrevious,
  onNext,
  scrollToDate,
  dateWidth = dateCheckboxWidth,
  visibleDates = initialVisibleDates,
  onContainerWidthChange,
  weeks,
}) => {
  const today = useToday();
  const { t } = useTranslation();
  const resizeContainerRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const scrollDate = useMemo(
    () => scrollToDate ?? (weeks?.length ?? 1) * weekLength - 1,
    [scrollToDate, weeks],
  );

  const maxDates = useMemo(
    () => (weeks?.length ?? 1) * weekLength - 1,
    [weeks],
  );

  useScrollSmoothToPosition({
    ref: scrollContainerRef,
    scrollPosition: scrollToDate,
    itemWidth: dateWidth,
    visibleAmount: visibleDates,
    totalAmount: (weeks?.length ?? 1) * weekLength - 1,
  });

  useEffect(() => {
    const container = resizeContainerRef.current;
    if (!container) {
      return;
    }

    const resizeObserver = new ResizeObserver((entries) =>
      entries.forEach((entry) =>
        onContainerWidthChange?.(entry.contentRect.width),
      ),
    );
    resizeObserver.observe(container);

    return () => resizeObserver.unobserve(container);
  }, [onContainerWidthChange]);

  return (
    <Styled.Container>
      <Styled.DetailSection>
        <Typography variant="overline">
          {t('pages.habits.header.name.title')}
        </Typography>
      </Styled.DetailSection>

      {!!weeks?.length && (
        <Styled.ResizeContainer ref={resizeContainerRef}>
          {!!onPrevious && scrollDate > visibleDates && (
            <Styled.ButtonContainer>
              <Styled.WeekEntry $width={dateWidth}>
                <IconButton
                  icon={ChevronLeft}
                  size={Sizes.Small}
                  onClick={onPrevious}
                />
              </Styled.WeekEntry>
            </Styled.ButtonContainer>
          )}

          <Styled.WeeksContainer
            ref={scrollContainerRef}
            $visibleDates={visibleDates}
            $dateWidth={dateWidth}
          >
            {weeks.map(({ weekNumber, dates }, index) => (
              <Styled.Week key={weekNumber}>
                {/* divider entry for start of the week, except the first week */}
                {!!index && (
                  <Styled.WeekEntry $width={dateWidth}>
                    <Styled.Divider />
                  </Styled.WeekEntry>
                )}

                {dates.map(({ date }) => (
                  <Styled.WeekEntry
                    key={date.getTime()}
                    $width={dateWidth}
                    $highlight={isSameDay(date, today)}
                  >
                    {format(date, 'EEEEEE')}
                  </Styled.WeekEntry>
                ))}

                {/* empty entry for percentage */}
                <Styled.WeekEntry $width={dateWidth} />
              </Styled.Week>
            ))}
          </Styled.WeeksContainer>

          {!!onNext && scrollDate < maxDates && (
            <Styled.ButtonContainer $isRight>
              <Styled.WeekEntry $width={dateWidth}>
                <IconButton
                  icon={ChevronLeft}
                  size={Sizes.Small}
                  rotation={180}
                  onClick={onNext}
                />
              </Styled.WeekEntry>
            </Styled.ButtonContainer>
          )}
        </Styled.ResizeContainer>
      )}
    </Styled.Container>
  );
};
