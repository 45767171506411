import { createContext } from 'react';
import { GoalMetric } from 'shared/types/goal-metric';
import { ID } from 'shared/types/id';

export type GoalProgressContextProps = {
  onOpenGoalProgress: (goalId: ID, metric: GoalMetric) => void;
};

export const GoalProgressContext = createContext<GoalProgressContextProps>({
  onOpenGoalProgress: () => {},
});
