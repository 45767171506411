import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { createSubscriptionQuery } from 'shared/lib/@tanstack-query';
import { getTasks } from 'shared/services/backend-api';
import { ID } from 'shared/types/id';
import { chunkArray } from 'shared/utils/chunk-array';

export const queryKey = 'sub-tasks';

export const useSubTaskQuery = (
  uid: ID | undefined,
  mainId: ID | undefined,
  childIds: ID[] | undefined,
) => {
  const query = useQuery({
    queryKey: [queryKey, uid ?? '', mainId ?? ''],
    queryFn: (context) => {
      let queries = [() => getTasks({ ids: childIds })];

      if (!childIds?.length) {
        queries = [];
      }

      if (childIds?.length && childIds.length > 30) {
        // check to max-length of 30 since firebase doesn't accept more
        const chunkedParentIds = chunkArray(childIds.toSorted(), 30);
        queries = chunkedParentIds.map(
          (chunked) => () => getTasks({ ids: chunked }),
        );
      }

      return createSubscriptionQuery(queries)(context);
    },
    enabled: !!uid && !!mainId && !!childIds?.length,
  });

  useEffect(() => {
    if (childIds?.length) {
      query.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on new childIds, otherwise it keeps updating
  }, [childIds]);

  return query;
};
