import { useMutation } from '@tanstack/react-query';
import { Locales } from 'shared/assets/localization';
import { setPushTokenOnUser } from 'shared/services/backend-api';
import { ID } from 'shared/types/id';

export const useCreatePushNotificationMutation = () =>
  useMutation({
    mutationFn: async ({ userId, locale }: { userId: ID; locale: Locales }) =>
      setPushTokenOnUser(userId, locale),
  });
