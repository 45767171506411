import { AppleID } from 'shared/services/apple/types';

export const getAppleId = async (): Promise<AppleID> => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src =
      'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';

    // Voeg een event listener toe voor het 'load' event
    script.addEventListener('load', () => {
      resolve(window.AppleID);
    });

    script.addEventListener('error', () => {
      reject(new Error('Apple script not loaded'));
    });

    document.head.appendChild(script);
  });
};
