import { connectionHelper } from './connection-helper';
import { getAnalytics } from './get-analytics';
import { getPerformanceMonitoring } from './get-performance-monitoring';
import { initApp } from './init-app';

export { addDocumentWithOwnerRole } from './add-document';
export { clearApp } from './clear-app';
export { CollectionOptions } from './collection-options';
export { getAuthentication } from './get-authentication';
export { getCollection } from './get-collection';
export { getDatabase } from './get-database';
export { getFunctions } from './get-functions';
export { getRemoteAppConfig } from './get-remote-app-config';
export { initPushNotifications } from './init-push-notifications';
export {
  getPushNotificationToken,
  setPushNotificationToken,
} from './push-notifications-storage';
export { RemoteAppConfigKeys } from './remote-app-config-types';
export { setDocWithTimestamp } from './set-doc-with-timestamp';
export { updateDocWithTimestamp } from './update-doc-with-timestamp';
export { whereUserHasRole } from './where-user-has-role';

getAnalytics(initApp());
getPerformanceMonitoring(initApp());
connectionHelper();

export { getAnalytics, getPerformanceMonitoring, initApp };
