import styled from 'styled-components';

export const Container = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacing(6)}`};
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(6)};

  margin: ${({ theme }) => `${theme.spacing(8)} auto`};
  max-width: 32rem;
`;

export const LogoContainer = styled.div`
  margin: ${({ theme }) => `${theme.spacing(2)} auto 0`};
`;

export const BodyContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(4)};
`;
