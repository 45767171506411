import { addDays } from 'date-fns';
import React, { useMemo } from 'react';
import { Section } from 'shared/components/ui/section';
import { TaskPreviewCard } from 'shared/components/ui/task-preview-card';
import { useToday } from 'shared/contexts/today';
import {
  GoalTemplateInstance,
  TaskTemplateInstance,
} from 'shared/types/goal-template';
import { ID } from 'shared/types/id';
import { LifeArea } from 'shared/types/life-area';
import { WeekDays } from 'shared/types/week-days';
import { sortItems } from 'shared/utils/sort-items';

import * as Styled from './goal-template-preview-action-plan.style';

export type GoalTemplatePreviewActionPlanProps = {
  goals: GoalTemplateInstance[];
  tasks: TaskTemplateInstance[];
  taskOrder: ID[];

  lifeAreas: LifeArea[];
  weekStartsOn: WeekDays;
};

export const GoalTemplatePreviewActionPlan: React.FC<
  GoalTemplatePreviewActionPlanProps
> = ({ goals, tasks, taskOrder, lifeAreas, weekStartsOn }) => {
  const today = useToday();

  const sortedTasks = useMemo(
    () => sortItems(tasks, taskOrder, 'id'),
    [taskOrder, tasks],
  );

  return (
    <Section title="Action plan">
      <Styled.List>
        {sortedTasks.map(
          ({
            id,
            name,
            description,
            priority,
            daysTillDeadline,
            reminderTime,
            schedule,
          }) => (
            <Styled.ListItem key={id}>
              <TaskPreviewCard
                name={name}
                description={description}
                priority={priority}
                deadline={
                  typeof daysTillDeadline === 'number'
                    ? addDays(today, daysTillDeadline)
                    : null
                }
                reminderTime={reminderTime}
                schedule={schedule}
                today={today}
              />
            </Styled.ListItem>
          ),
        )}
      </Styled.List>
    </Section>
  );
};
