import { Components, Theme } from '@mui/material';

export const MuiCssBaseline: Components<
  Omit<Theme, 'components'>
>['MuiCssBaseline'] = {
  styleOverrides: {
    html: {
      fill: 'transparent',
      fontSize: '62.5%',

      userSelect: 'none',
      msUserSelect: 'none',
      MozUserSelect: 'none',
      WebkitUserSelect: 'none',

      'input, textarea, select, button': {
        userSelect: 'auto',
        msUserSelect: 'auto',
        MozUserSelect: 'auto',
        WebkitUserSelect: 'auto',
      },
    },
  },
};
