import React from 'react';

import * as Styled from './tab-panel.style';

export type TabPanelProps = {
  children: React.ReactNode;
};

export const TabPanel: React.FC<TabPanelProps> = ({ children }) => (
  <Styled.Container>{children}</Styled.Container>
);
