import { CircularProgress } from '@mui/material';
import React from 'react';

import * as Styled from './unsplash-image-picker.style';

export const Loader: React.FC = () => (
  <Styled.LoaderContainer>
    <CircularProgress />
  </Styled.LoaderContainer>
);
