import { SortingMode } from 'shared/types/sorting-mode';

import { getUser, getUserRef } from './get-user';
import { updateDocWithTimestamp } from './helpers';

export const updateUserTodoSortMode = async (sort: SortingMode) => {
  const user = await getUser();

  if (!user) {
    return;
  }

  const userRef = await getUserRef();

  if (!userRef) {
    return;
  }

  updateDocWithTimestamp(userRef, {
    'settings.todoSortingMode': sort,
  });
};
