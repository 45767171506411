import { ColumnType, RowData } from './types';

export const columnTypeOrderAscendingMap: Record<
  ColumnType,
  ((goalA: RowData, goalB: RowData) => number) | undefined
> = {
  [ColumnType.Name]: (goalA, goalB) => goalA.name.localeCompare(goalB.name),
  [ColumnType.Deadline]: (goalA, goalB) =>
    !goalB.deadline
      ? -1
      : !goalA.deadline
        ? 1
        : goalB.deadline.getTime() - goalA.deadline.getTime(),
  [ColumnType.Completion]: (goalA, goalB) =>
    goalB.completion === null
      ? -1
      : goalA.completion === null
        ? 1
        : goalB.completion - goalA.completion,
  [ColumnType.Tasks]: (goalA, goalB) =>
    !goalB.uncompletedTasks
      ? -1
      : !goalA.uncompletedTasks
        ? 1
        : goalB.uncompletedTasks - goalA.uncompletedTasks,
  [ColumnType.LifeArea]: (goalA, goalB) =>
    !goalB.lifeArea
      ? -1
      : !goalA.lifeArea
        ? 1
        : goalA.lifeArea.localeCompare(goalB.lifeArea),
  [ColumnType.Empty]: () => 0,
};

export const columnTypeOrderDescendingMap: Record<
  ColumnType,
  ((goalA: RowData, goalB: RowData) => number) | undefined
> = {
  [ColumnType.Name]: (goalA, goalB) => goalB.name.localeCompare(goalA.name),
  [ColumnType.Deadline]: (goalA, goalB) =>
    !goalB.deadline
      ? -1
      : !goalA.deadline
        ? 1
        : goalA.deadline.getTime() - goalB.deadline.getTime(),
  [ColumnType.Completion]: (goalA, goalB) =>
    goalB.completion === null
      ? -1
      : goalA.completion === null
        ? 1
        : goalA.completion - goalB.completion,
  [ColumnType.Tasks]: (goalA, goalB) =>
    !goalB.uncompletedTasks
      ? -1
      : !goalA.uncompletedTasks
        ? 1
        : goalA.uncompletedTasks - goalB.uncompletedTasks,
  [ColumnType.LifeArea]: (goalA, goalB) =>
    !goalB.lifeArea
      ? -1
      : !goalA.lifeArea
        ? 1
        : goalB.lifeArea.localeCompare(goalA.lifeArea),
  [ColumnType.Empty]: () => 0,
};
