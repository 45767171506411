import { useCallback } from 'react';
import { useUpdateTaskMutation } from 'shared/hooks/queries/use-update-task-mutation';
import { ID } from 'shared/types/id';
import { Task } from 'shared/types/task';

export const useUpdateSubtaskOrder = (task: Task) => {
  const { mutate } = useUpdateTaskMutation();

  const updateOrder = useCallback(
    (newOrder: ID[]) => {
      mutate({ id: task.id, subtaskOrder: newOrder });
    },
    [mutate, task.id],
  );

  return {
    updateOrder,
  };
};
