import { useCallback } from 'react';
import { useUpdatePasswordMutation } from 'shared/hooks/queries/use-update-password-mutation';

export const useUpdatePassword = () => {
  const { mutate, isPending, error, isSuccess } = useUpdatePasswordMutation();

  const updatePassword = useCallback(
    (oldPassword: string, newPassword: string) =>
      mutate({ oldPassword, newPassword }),
    [mutate],
  );

  return {
    updatePassword,
    isLoading: isPending,
    error,
    isSuccess,
  };
};
