import styled, { css } from 'styled-components';

export const Container = styled.span<{
  $overdue?: boolean;
  $lastWeek?: boolean;
}>`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
  width: 100%;

  overflow: hidden;
  transition: color 200ms;

  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ $lastWeek, theme }) =>
    $lastWeek &&
    css`
      color: ${theme.palette.warning.main};
    `};

  ${({ $overdue, theme }) =>
    $overdue &&
    css`
      color: ${theme.palette.error.main};
    `};
`;

export const IconContainer = styled.span`
  font-size: 114.3%;
  line-height: 62.5%;
`;

export const Label = styled.span`
  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;
`;
