import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Column, ColumnType } from './types';

const allColumnTypes = Object.values(ColumnType);

const columnTypeTranslationsMap: Record<ColumnType, string | undefined> = {
  [ColumnType.Name]: 'general.labels.life-area-table.headers.name.title',
  [ColumnType.Vision]: 'general.labels.life-area-table.headers.vision.title',
  [ColumnType.ActiveGoals]:
    'general.labels.life-area-table.headers.active-goals.title',
  [ColumnType.CompletedGoals]:
    'general.labels.life-area-table.headers.completed-goals.title',
  [ColumnType.Score]: 'general.labels.life-area-table.headers.life-score.title',
  [ColumnType.Empty]: undefined,
};

const columnTypeSortableMap: Record<ColumnType, boolean> = {
  [ColumnType.Name]: true,
  [ColumnType.Vision]: true,
  [ColumnType.ActiveGoals]: true,
  [ColumnType.CompletedGoals]: true,
  [ColumnType.Score]: true,
  [ColumnType.Empty]: false,
};

export const useColumns = (
  columnTypes: ColumnType[] = allColumnTypes,
): Column[] => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      columnTypes.map((columnType) => {
        const translationKey = columnTypeTranslationsMap[columnType];
        return {
          type: columnType,
          label: translationKey ? t(translationKey) : undefined,
          sortable: columnTypeSortableMap[columnType],
        };
      }),
    [columnTypes, t],
  );
};
