import React, { useMemo, useRef } from 'react';
import { Check, Target } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import {
  PopupMenu,
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
} from 'shared/components/ui/popup-menu';
import { useClickOutside } from 'shared/hooks/use-click-outside';
import { Goal } from 'shared/types/goal';
import { ID } from 'shared/types/id';

export type MoveGoalContextMenuProps = Omit<
  React.ComponentProps<typeof PopupMenu>,
  'children'
> & {
  goals: Goal[];
  onGoal: (parentIds: ID[]) => void;
  onClose: () => void;
  selectedGoal?: ID;
};

export const MoveGoalContextMenu: React.FC<MoveGoalContextMenuProps> = ({
  goals,
  onGoal,
  onClose,
  position,
  location,
  referenceElement,
  selectedGoal,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const filteredGoals = useMemo(
    () => goals.filter(({ parentIds }) => !parentIds),
    [goals],
  );

  useClickOutside(containerRef, onClose);

  return (
    <PopupMenu
      ref={containerRef}
      location={location}
      position={position}
      referenceElement={referenceElement}
    >
      <PopupMenuList>
        {filteredGoals.map((goal) => {
          const onItem = () => onGoal([...(goal.parentIds ?? []), goal.id]);

          return (
            <PopupMenuListItem key={goal.id}>
              <PopupMenuButton
                onClick={onItem}
                isSelected={goal.id === selectedGoal}
                start={<Icon icon={Target} />}
                end={
                  goal.id === selectedGoal ? <Icon icon={Check} /> : undefined
                }
              >
                {goal.name}
              </PopupMenuButton>
            </PopupMenuListItem>
          );
        })}
      </PopupMenuList>
    </PopupMenu>
  );
};
