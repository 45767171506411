import { Timestamp } from 'firebase/firestore';

export const dateToFirestoreTimestamp = (obj: any): any => {
  if (obj instanceof Date) {
    return Timestamp.fromDate(obj);
  } else if (Array.isArray(obj)) {
    return obj.map((item) => dateToFirestoreTimestamp(item));
  } else if (obj !== null && typeof obj === 'object') {
    const transformedObj: { [key: string]: any } = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        transformedObj[key] = dateToFirestoreTimestamp(obj[key]);
      }
    }
    return transformedObj;
  } else {
    return obj;
  }
};
