import MuiTab from '@mui/material/Tab';
import MuiTabs from '@mui/material/Tabs';
import { useHabits } from 'features/tasks';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryNavLink } from 'shared/components/connected/query-nav-link';
import { useActivePath } from 'shared/hooks/use-active-path';
import { Paths } from 'shared/routes';

import * as Styled from './navigation-tabs.style';

const tabs = [
  {
    label: 'pages.habits.navigation.active',
    path: Paths.Habits,
  },
  {
    label: 'pages.habits.navigation.completed',
    path: Paths.HabitsCompleted,
  },
];

export const NavigationTabs: React.FC = () => {
  const { t } = useTranslation();
  const activePath = useActivePath();
  const completedHabits = useHabits({ completedOnly: true });

  const activeIndex = useMemo(
    () => tabs.findIndex(({ path }) => path === activePath),
    [activePath],
  );

  if (!completedHabits.length) {
    return null;
  }

  return (
    <Styled.Container>
      <MuiTabs value={activeIndex}>
        {tabs.map(({ label, path }) => (
          <MuiTab
            key={path}
            label={t(label)}
            component={QueryNavLink}
            to={path}
            icon={
              path === Paths.HabitsCompleted ? (
                <Styled.Badge $active={activeIndex === 1}>
                  {completedHabits.length}
                </Styled.Badge>
              ) : undefined
            }
            iconPosition="end"
            replace
          />
        ))}
      </MuiTabs>
    </Styled.Container>
  );
};
