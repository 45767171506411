import { useUserHadTrial } from 'features/user';
import React, { useMemo } from 'react';
import { PremiumPurchased } from 'shared/dialogs/premium-purchased';
import { PurchasePremium } from 'shared/dialogs/purchase-premium';
import { usePremiumCurrencies } from 'shared/hooks/use-premium-currencies';
import { useRedirectToStripeUrl } from 'shared/hooks/use-redirect-to-stripe-url';
import { useToggle } from 'shared/hooks/use-toggle';
import { QueryParamOptions, useQueryParam } from 'shared/lib/react-router-dom';

import {
  PremiumDialogContext,
  PremiumDialogContextProps,
} from './premium-dialog-context';

export type PremiumDialogProviderProps = {
  children: React.ReactNode;
};

export const PremiumDialogProvider: React.FC<PremiumDialogProviderProps> = ({
  children,
}) => {
  const [premiumQueryParam, setPremiumQueryParam] = useQueryParam(
    QueryParamOptions.Premium,
  );
  const [dialogOpen, toggleDialog] = useToggle(false);
  const userHadTrial = useUserHadTrial();
  const currencies = usePremiumCurrencies();
  const { redirect: redirectToStripeUrl, isLoading } = useRedirectToStripeUrl();

  const clearPremiumQueryParam = () => setPremiumQueryParam();

  const value = useMemo<PremiumDialogContextProps>(
    () => ({ openPremiumDialog: toggleDialog }),
    [toggleDialog],
  );

  return (
    <PremiumDialogContext.Provider value={value}>
      {children}
      <PurchasePremium
        open={dialogOpen}
        onClose={toggleDialog}
        trialExpired={userHadTrial}
        currencies={currencies}
        onSubmit={redirectToStripeUrl}
        isLoading={isLoading}
      />
      <PremiumPurchased
        open={premiumQueryParam === 'true'}
        onClose={clearPremiumQueryParam}
      />
    </PremiumDialogContext.Provider>
  );
};
