import { addDays } from 'date-fns';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ProgressChartSection } from 'shared/components/ui/progress-chart-section';
import { ProgressCountSection } from 'shared/components/ui/progress-count-section';
import { Section } from 'shared/components/ui/section';
import { useToday } from 'shared/contexts/today';
import { GoalMetric } from 'shared/types/goal-metric';
import {
  countMetricOptions,
  DefaultMetricOptions,
  metricUnitFormatBase,
} from 'shared/types/metric-template';
import { getLocalizedStringValue } from 'shared/utils/get-localized-string-value';

import * as Styled from './goal-template-preview-progress.style';

export type GoalTemplatePreviewProgressProps = {
  metric: GoalMetric | null;
  totalTaskCount: number;
  totalSubGoalCount: number;
  daysTillDeadline: number | null;
};

export const GoalTemplatePreviewProgress: React.FC<
  GoalTemplatePreviewProgressProps
> = ({ metric, totalTaskCount, totalSubGoalCount, daysTillDeadline }) => {
  const today = useToday();
  const { t, i18n } = useTranslation();

  const unitFormat = useMemo(() => {
    if (metric?.unit?.format) {
      return (
        metric.unit.format ??
        `${metricUnitFormatBase} ${getLocalizedStringValue(metric.unit.name, i18n.language)}`
      );
    }

    if (metric?.id === DefaultMetricOptions.CompletedTasks) {
      return `${metricUnitFormatBase} ${t('general.labels.goal-progress.unit-format.tasks.label')}`;
    }

    if (metric?.id === DefaultMetricOptions.SubGoalProgress) {
      return `${metricUnitFormatBase} ${t('general.labels.goal-progress.unit-format.sub-goals.label')}`;
    }

    return undefined;
  }, [metric?.id, metric?.unit, i18n.language, t]);

  const deadline = useMemo(
    () =>
      typeof daysTillDeadline === 'number'
        ? addDays(today, daysTillDeadline)
        : undefined,
    [daysTillDeadline, today],
  );

  if (!metric) {
    return null;
  }

  return (
    <Section
      title={t('pages.goal-detail.progress.title')}
      canCollapse
      initialOpen
    >
      <Styled.Container>
        {countMetricOptions.includes(metric.id) ? (
          <ProgressCountSection
            target={
              metric?.id === DefaultMetricOptions.CompletedTasks
                ? totalTaskCount
                : totalSubGoalCount
            }
            targetFormat={
              unitFormat
                ? getLocalizedStringValue(unitFormat, i18n.language)
                : undefined
            }
            total={100}
            completed={0}
          />
        ) : (
          <ProgressChartSection
            data={[]}
            startDate={today}
            endDate={deadline}
            targetFormat={
              unitFormat
                ? getLocalizedStringValue(unitFormat, i18n.language)
                : undefined
            }
            startValue={metric?.startValue}
            target={metric?.targetValue ?? undefined}
          />
        )}
      </Styled.Container>
    </Section>
  );
};
