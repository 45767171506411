import { closestCenter, DndContext } from '@dnd-kit/core';
import React from 'react';
import { OverlayComponentWrapper } from 'shared/components/ui/overlay-component-wrapper';
import { SortableOverlay } from 'shared/components/ui/sortable-overlay';
import { useSortableNestedSectionsControls } from 'shared/hooks/use-sortable-nested-sections-controls';
import { useSortableSensors } from 'shared/hooks/use-sortable-sensors';
import { Goal } from 'shared/types/goal';
import { ID } from 'shared/types/id';
import { TaskCardTask } from 'shared/types/task-card-task';

import { TaskAndGoalList } from './task-and-goal-list';
import { TaskGoalItem } from './task-goal-item';

export type SelfSortingTaskAndGoalListProps = Omit<
  React.ComponentProps<typeof TaskAndGoalList>,
  'hasDnd'
> & {
  id: ID;
  onReorder?: (items: (Goal | TaskCardTask)[]) => void;
};

export const SelfSortingTaskAndGoalList: React.FC<
  SelfSortingTaskAndGoalListProps
> = ({ id, items, goals, selectedItem, onReorder, ...rest }) => {
  const sensors = useSortableSensors();
  const {
    items: changedItems,
    activeItem,
    handleDragStart,
    handleDragOver,
    handleDragCancel,
    handleDragEnd,
  } = useSortableNestedSectionsControls({
    items,
    onDragEnd: onReorder,
  });

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
      sensors={sensors}
      collisionDetection={closestCenter}
    >
      <TaskAndGoalList
        id={id}
        items={changedItems}
        selectedItem={activeItem?.id ?? selectedItem}
        goals={goals}
        {...rest}
        hasDnd={!!onReorder}
      />

      <SortableOverlay>
        {!!activeItem && (
          <OverlayComponentWrapper>
            <TaskGoalItem
              item={activeItem}
              items={changedItems}
              goals={goals}
              isMoving
              {...rest}
            />
          </OverlayComponentWrapper>
        )}
      </SortableOverlay>
    </DndContext>
  );
};
