import Typography from '@mui/material/Typography';
import React from 'react';

import * as Styled from './progress-bar.style';

export type ProgressBarProps = {
  total: number;
  completed: number;
  hidePercentage?: boolean;
};

export const ProgressBar: React.FC<ProgressBarProps> = ({
  total,
  completed,
  hidePercentage,
}) => {
  const percentage = Math.min(
    100,
    Math.max(0, Math.floor((100 / total) * completed) || 0),
  );

  return (
    <Styled.Container>
      <Styled.Bar $percentage={percentage} />

      {!hidePercentage && (
        <Typography variant="subtitle2" component="span">
          {percentage}%
        </Typography>
      )}
    </Styled.Container>
  );
};
