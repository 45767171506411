import { useInfiniteQuery } from '@tanstack/react-query';
import { getUnsplashImages } from 'shared/services/backend-api';

export const queryKey = 'unsplash-images';

export const useUnsplashImagesQuery = (searchText: string = '', size = 30) =>
  useInfiniteQuery({
    queryKey: [queryKey, searchText],
    queryFn: ({ pageParam }) => getUnsplashImages(searchText, pageParam, size),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => pages.length,
    retry: false,
  });
