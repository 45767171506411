import { useCallback } from 'react';
import { useUpdateLifeAreaMutation } from 'shared/hooks/queries/use-update-life-area-mutation';
import { ID } from 'shared/types/id';

export const useUpdateLifeAreaTaskOrder = (id?: ID) => {
  const { mutate } = useUpdateLifeAreaMutation();

  const updateOrder = useCallback(
    (ids: ID[]) => {
      if (!id) {
        return;
      }

      mutate({ id, taskSorting: ids });
    },
    [id, mutate],
  );

  return {
    updateOrder,
  };
};
