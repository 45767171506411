import { OAuthProvider, signInWithCredential } from 'firebase/auth';
import { fallbackLocale } from 'shared/assets/localization';
import { getUser } from 'shared/services/backend-api/get-user';
import { setPushTokenOnUser } from 'shared/services/backend-api/set-push-token-on-user';
import { getNonce } from 'shared/services/local-storage';

import { getAuthentication } from './helpers';
import { setUserCountry } from './set-user-country';

export const signInWithToken = async () => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('idToken');

  if (!token) {
    return;
  }

  const nonce = getNonce();
  const rawNonce = nonce?.raw;

  const provider = new OAuthProvider('apple.com');
  const credential = provider.credential({
    idToken: token,
    rawNonce,
  });

  const auth = await getAuthentication();

  const signedIn = await signInWithCredential(auth, credential);

  const user = await getUser();

  if (!user) {
    return;
  }

  await setPushTokenOnUser(user.uid, user.settings?.language || fallbackLocale);

  try {
    await setUserCountry();
  } catch (e) {
    // do nothing, eventually send error to sentry or something like that
  }

  return signedIn;
};
