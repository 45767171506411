import { useQuery } from '@tanstack/react-query';
import { createSubscriptionQuery } from 'shared/lib/@tanstack-query';
import { GetTaskOptions, getTasks } from 'shared/services/backend-api';
import { ID } from 'shared/types/id';
import { chunkArray } from 'shared/utils/chunk-array';

export const queryKey = 'tasks';

export const useTasksQuery = (
  uid: ID | undefined,
  options: GetTaskOptions,
  enabled = true,
) =>
  useQuery({
    queryKey: [
      queryKey,
      uid ?? '',
      ...Object.entries(options)
        .filter(([, value]) => value !== undefined)
        .map(
          ([key, value]) =>
            `${key}: ${
              value instanceof Date
                ? value.getTime().toString()
                : Array.isArray(value)
                  ? value.toSorted().join(',')
                  : value?.toString()
            }`,
        ),
    ],
    queryFn: (context) => {
      const { goals, ids, ...rest } = options;
      let queries = [() => getTasks(options)];

      if (goals?.length && goals.length > 30) {
        // chunk to max-length of 30 since firebase doesn't accept more
        const chunkedGoals = chunkArray(goals.toSorted(), 30);
        queries = chunkedGoals.map(
          (chunked) => () => getTasks({ ...rest, ids, goals: chunked }),
        );
      }

      if (ids?.length && ids.length > 30) {
        // check to max-length of 30 since firebase doesn't accept more
        const chunkedParentIds = chunkArray(ids.toSorted(), 30);
        queries = chunkedParentIds.map(
          (chunked) => () => getTasks({ ...rest, goals, ids: chunked }),
        );
      }

      return createSubscriptionQuery(queries)(context);
    },
    enabled: !!uid && enabled,
  });
