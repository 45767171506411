import { httpsCallable } from 'firebase/functions';
import { CreateGoalTemplate } from 'shared/types/create-goal-template';

import { getFunctions } from './helpers';

export const createGoalTemplate = async (template: CreateGoalTemplate) => {
  const functions = getFunctions();
  const createGoalTemplate = httpsCallable<CreateGoalTemplate>(
    functions,
    'templates-createTemplateByGoals',
  );

  const { data } = await createGoalTemplate(template);
  return data;
};
