import { useCallback } from 'react';
import { useUser } from 'shared/hooks/use-user';
import { ID } from 'shared/types/id';
import { FavoriteReference } from 'shared/types/user-settings';

import { useUpdateUser } from './use-update-user';

export const useReorderFavorites = () => {
  const user = useUser();
  const { submit } = useUpdateUser();

  return useCallback(
    (ids: ID[]) => {
      const newFavorites = ids.reduce<FavoriteReference[]>((acc, id) => {
        const favorite = user?.settings?.favorites?.find(
          (fav) => fav.id === id,
        );

        if (favorite) {
          acc.push(favorite);
        }

        return acc;
      }, []);

      submit({ favorites: newFavorites });
    },
    [user?.settings?.favorites, submit],
  );
};
