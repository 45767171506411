import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

export const Form = styled.form``;

export const Header = styled.div`
  padding: ${({ theme }) => `${theme.spacing(5)} ${theme.spacing(6)}`};
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(8)};
`;

export const Section = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: ${({ theme }) => theme.spacing(2)};
  grid-row-gap: ${({ theme }) => theme.spacing(2)};
  align-items: center;

  padding: ${({ theme }) => `0 ${theme.spacing(6)}`};
  width: 100%;
`;

export const Label = styled.label<{ $disabled?: boolean; $alignTop?: boolean }>`
  ${({ theme }) => typographyToCss(theme.typography.body2)};
  min-width: 9.6rem;

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.45;
    `};

  ${({ $alignTop }) =>
    $alignTop &&
    css`
      align-self: flex-start;
    `};
`;

export const SectionTitle = styled.span`
  grid-column: span 2;
`;

export const SectionDescription = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.overline)};

  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const Interactions = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  justify-content: flex-end;

  padding: ${({ theme }) => `${theme.spacing(5)} ${theme.spacing(6)}`};
`;

export const SelectedColorContainer = styled.span`
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const ColorCircle = styled.span<{ $color?: string }>`
  display: block;
  width: 1rem;
  height: 1rem;
  background: ${({ $color }) => $color ?? 'transparent'};
  border-radius: 50%;
`;
