export const combineSearchParams = (
  ...searchParams: URLSearchParams[]
): URLSearchParams => {
  const combinedParams = new URLSearchParams();

  for (const params of searchParams) {
    for (const [key, value] of params.entries()) {
      if (combinedParams.has(key)) {
        const existingValue = combinedParams.getAll(key);
        existingValue.push(value);
        combinedParams.delete(key);
        existingValue.forEach((v) => combinedParams.append(key, v));
      } else {
        combinedParams.append(key, value);
      }
    }
  }

  return combinedParams;
};
