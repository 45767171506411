import { CSS, Transform } from '@dnd-kit/utilities';
import styled from 'styled-components';

export const SortableGoalTaskContainer = styled.div<{
  $transform: Transform | null;
  $transition?: string;
}>`
  position: relative;
  transform: ${({ $transform }) => CSS.Translate.toString($transform)};
  transition: ${({ $transition }) => $transition};
`;
