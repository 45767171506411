import { useMutation } from '@tanstack/react-query';
import {
  LifeAreaOrder,
  updateLifeAreaGoalOrder,
} from 'shared/services/backend-api';

export const useUpdateLifeAreaGoalOrderMutation = () =>
  useMutation({
    mutationFn: async (lifeAreasOrder: LifeAreaOrder[]) =>
      updateLifeAreaGoalOrder(lifeAreasOrder),
  });

export { type LifeAreaOrder };
