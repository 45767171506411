import React from 'react';
import { Check, Repeat } from 'shared/assets/icons';
import { Checkbox, Colors } from 'shared/components/ui/checkbox';
import { Icon } from 'shared/components/ui/icon';
import { TaskCheckOptions } from 'shared/types/task';
import { TaskPriorityOptions } from 'shared/types/task-base';

import * as Styled from './task-card-header.style';

const priorityColorMap: Record<TaskPriorityOptions, Colors> = {
  [TaskPriorityOptions.Low]: Colors.Info,
  [TaskPriorityOptions.Medium]: Colors.Warning,
  [TaskPriorityOptions.High]: Colors.Error,
  [TaskPriorityOptions.None]: Colors.Default,
};

export type TaskCardCheckboxProps = {
  onChange: () => void;
  checked: TaskCheckOptions;
  priority?: TaskPriorityOptions | null;
  isFrozen?: boolean;
  isDisabled?: boolean;
};

export const TaskCardCheckbox: React.FC<TaskCardCheckboxProps> = ({
  onChange,
  checked,
  priority,
  isFrozen,
  isDisabled,
}) => {
  const change = () => {
    if (isFrozen) {
      return;
    }
    onChange();
  };

  const preventPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <Styled.CheckboxContainer
      $isFaded={isFrozen || isDisabled}
      onClick={preventPropagation}
    >
      {checked === TaskCheckOptions.RepeatChecked && (
        <Styled.CheckboxCheckContainer>
          <Icon icon={Check} />
        </Styled.CheckboxCheckContainer>
      )}
      {[TaskCheckOptions.Unchecked, TaskCheckOptions.Checked].includes(
        checked,
      ) ? (
        <Checkbox
          checked={checked !== TaskCheckOptions.Unchecked}
          color={priority ? priorityColorMap[priority] : undefined}
          onChange={change}
          disabled={isDisabled}
        />
      ) : (
        <Icon icon={Repeat} />
      )}
    </Styled.CheckboxContainer>
  );
};
