import React from 'react';

export enum IconColors {
  Primary = 'primary',
  Secondary = 'secondary',
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
  Success = 'success',
}

export type Item = {
  title: string;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  iconColor?: IconColors;
  description?: string;
};
