import { isSameDay } from 'date-fns';
import { TFunction } from 'i18next';
import { Goal } from 'shared/types/goal';
import { Task } from 'shared/types/task';
import { TaskCardTask } from 'shared/types/task-card-task';
import { Timestamp } from 'shared/types/timestamp';
import { initialWeekStartsOn, WeekDays } from 'shared/types/week-days';
import { getScheduleTranslationKey } from 'shared/utils/get-schedule-translation-key';
import { getTaskCheckedState } from 'shared/utils/get-task-checked-state';
import { getTaskCompletedState } from 'shared/utils/get-task-completed-state';
import { getDueLabel, getTaskDueDate } from 'shared/utils/get-task-due';
import { getTaskOverdue } from 'shared/utils/get-task-overdue';
import { parseReminderTime } from 'shared/utils/parse-reminder-time';

type Options = {
  goals: Goal[];
  today: Timestamp;
  weekStartsOn: WeekDays;
  showRepeatingAsTodo?: boolean;
  t: TFunction;
  showDue?: boolean | ((task: Task) => boolean);
  hideFutureDue?: boolean;
  hideTodayDue?: boolean;
  hidePastDue?: boolean;
  hideGoal?: boolean;
};

export const mapTaskToTaskCard = (
  task: Task,
  {
    goals,
    weekStartsOn,
    today,
    showRepeatingAsTodo,
    t,
    showDue: showDueFn,
    hideFutureDue,
    hideTodayDue,
    hidePastDue,
    hideGoal,
  }: Options,
): TaskCardTask => {
  weekStartsOn = weekStartsOn ?? initialWeekStartsOn;

  const activeSchedule = task.schedules?.find(({ endDate }) => !endDate);
  const scheduleString = activeSchedule
    ? getScheduleTranslationKey(activeSchedule, t)
    : undefined;

  const showDue =
    typeof showDueFn === 'function' ? showDueFn(task) : !!showDueFn;
  const dueDate = showDue
    ? getTaskDueDate(task, {
        today,
        weekStartsOn,
      })
    : undefined;
  const due = getDueLabel(dueDate, {
    t,
    today,
    hideFutureDue,
    hideTodayDue,
    hidePastDue,
  });
  const overdue = showDue
    ? getTaskOverdue(task, { today, weekStartsOn })
    : undefined;
  const dueToday = !!(dueDate && isSameDay(dueDate, today));

  return {
    id: task.id,
    checked: getTaskCheckedState(task, {
      today,
      showRepeatingAsTodo,
      weekStartsOn,
    }),
    strikeThrough: getTaskCompletedState(task, today, showRepeatingAsTodo),
    meta: {
      goal: !hideGoal
        ? goals.find(({ id }) => id === task.goalId)?.name
        : undefined,
      reminderTime: task.reminderTime
        ? parseReminderTime(task.reminderTime)
        : undefined,
      schedule: scheduleString,
      due,
      overdue,
      dueToday,
    },
    raw: {
      dueDate,
      task,
    },
    fields: {
      name: task.name,
      type: task.type,
      schedules: task.schedules ?? undefined,
      reminderTime: task.reminderTime ?? undefined,
      goalId: task.goalId ?? undefined,
      priority: task.priority ?? undefined,
      endStrategy: {
        deadline: task.endStrategy?.deadline ?? undefined,
        completionCount: task.endStrategy?.completionCount ?? undefined,
      },
    },
    isFrozen: !!task.frozenAt,
  };
};
