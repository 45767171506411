import Dialog from '@mui/material/Dialog';
import React, { useMemo } from 'react';
import { DialogVariantOptions } from 'shared/assets/styles';
import { GoalTemplate } from 'shared/types/goal-template';
import { LifeArea } from 'shared/types/life-area';
import { WeekDays } from 'shared/types/week-days';

import { GoalTemplatePreview } from './goal-template-preview';
import { GoalTemplatePreviewError } from './goal-template-preview-error';
import { GoalTemplatePreviewLoading } from './goal-template-preview-loading';

export type GoalTemplatePreviewDialogProps = {
  template: GoalTemplate;
  onClose: () => void;
  onSubmit: (template: GoalTemplate) => void;
  retry: () => void;
  isLoading: boolean;
  isError: boolean;

  lifeAreas: LifeArea[];
  weekStartsOn: WeekDays;
};

export const GoalTemplatePreviewDialog: React.FC<
  GoalTemplatePreviewDialogProps
> = ({
  template,
  onSubmit,
  retry,
  onClose,
  isLoading,
  isError,
  lifeAreas,
  weekStartsOn,
}) => {
  const content = useMemo(() => {
    if (isError) {
      return <GoalTemplatePreviewError onRetry={retry} onCancel={onClose} />;
    }

    if (isLoading) {
      return <GoalTemplatePreviewLoading />;
    }

    return (
      <GoalTemplatePreview
        template={template}
        onSubmit={onSubmit}
        onCancel={onClose}
        lifeAreas={lifeAreas}
        weekStartsOn={weekStartsOn}
      />
    );
  }, [
    isError,
    isLoading,
    lifeAreas,
    onClose,
    onSubmit,
    retry,
    template,
    weekStartsOn,
  ]);

  return (
    <Dialog
      open
      fullWidth
      variant={DialogVariantOptions.Large}
      onClose={onClose}
    >
      {content}
    </Dialog>
  );
};
