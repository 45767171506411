import React, { Suspense, useCallback, useMemo, useRef, useState } from 'react';
import { TaskDetailColumn } from 'shared/components/connected/task-detail-column';
import { TaskMoreButton } from 'shared/components/connected/task-more-button';
import { DetailColumnContainer } from 'shared/components/ui/detail-column-container';
import { useClickOutside } from 'shared/hooks/use-click-outside';
import { DataType } from 'shared/types/data-type';
import { ID } from 'shared/types/id';
import { elementHasAttribute } from 'shared/utils/element-has-attribute';

import * as Styled from './task-detail.style';
import {
  TaskDetailContext,
  TaskDetailContextProps,
} from './task-detail-context';

export type TaskDetailProviderProps = {
  children: React.ReactNode;
};

export const TaskDetailProvider: React.FC<TaskDetailProviderProps> = ({
  children,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const closeTimerRef = useRef<number>();
  const [activeTask, setActiveTask] = useState<ID>();

  const openTask = useCallback((taskId: ID | undefined) => {
    closeTimerRef.current && window.clearTimeout(closeTimerRef.current);
    setActiveTask(taskId);
  }, []);

  const closeTask = useCallback(() => setActiveTask(undefined), []);

  const value = useMemo<TaskDetailContextProps>(
    () => ({
      openTaskDetail: openTask,
      activeTaskId: activeTask,
    }),
    [activeTask, openTask],
  );

  useClickOutside(containerRef, (event) => {
    if (
      !!event.target &&
      elementHasAttribute(
        event.target as Element,
        'data-component-type',
        DataType.Task,
      )
    ) {
      return;
    }

    closeTimerRef.current = window.setTimeout(
      () => setActiveTask(undefined),
      200,
    );
  });

  return (
    <TaskDetailContext.Provider value={value}>
      <Styled.Container>
        {children}

        <DetailColumnContainer
          ref={containerRef}
          open={!!activeTask}
          onClose={closeTask}
          actions={[<TaskMoreButton taskId={activeTask} />]}
        >
          {activeTask && (
            <Suspense>
              <TaskDetailColumn taskId={activeTask} onClose={closeTask} />
            </Suspense>
          )}
        </DetailColumnContainer>
      </Styled.Container>
    </TaskDetailContext.Provider>
  );
};
