import { CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) =>
    `${theme.spacing(4)} ${theme.spacing(4)}
    ${theme.spacing(4)} ${theme.spacing(6)}`};
`;

export const Body = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(8)};

  padding: ${({ theme }) => `${theme.spacing(2)} 0 ${theme.spacing(6)}`};
  margin: ${({ theme }) => `0 ${theme.spacing(6)}`};

  list-style: none;
`;

export const Section = styled.li`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};
`;

export const SubSection = styled.div``;

export const SplitSectionRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const SecondaryTextTypography = styled(Typography)<{
  $canCopy?: boolean;
}>`
  color: ${({ theme }) => theme.palette.text.secondary};

  ${({ $canCopy }) =>
    $canCopy &&
    css`
      user-select: text;
      -ms-user-select: text;
      -moz-user-select: text;
      -webkit-user-select: text;
    `};
`;

export const Anchor = styled.a`
  color: inherit;
`;

export const ButtonLoader = styled(CircularProgress)``;
