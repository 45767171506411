import { useMemo } from 'react';
import { useUnsplashImagesQuery } from 'shared/hooks/queries/use-unsplash-images-query';

export const useUnsplashImages = (searchText: string, amount: number = 30) => {
  const { data, isLoading, error } = useUnsplashImagesQuery(searchText, amount);
  const images = useMemo(() => data?.pages.flat(1) ?? [], [data?.pages]);

  return {
    images,
    isLoading,
    error,
  };
};
