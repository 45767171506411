import { CircularProgress } from '@mui/material';
import React from 'react';

import * as Styled from './habit-form-loading.style';

export const HabitFormLoading: React.FC = () => (
  <Styled.Container>
    <CircularProgress />
  </Styled.Container>
);
