import { typographyToCss } from 'shared/lib/mui';
import styled from 'styled-components';

export const NavigationSectionContainer = styled.div``;

export const NavigationSectionHeader = styled.div<{ $isFirst: boolean }>`
  padding: ${({ $isFirst, theme }) =>
    $isFirst
      ? `0 ${theme.spacing(0.5)} 0 ${theme.spacing(3)}`
      : `${theme.spacing(3)} ${theme.spacing(0.5)} 0 ${theme.spacing(3)}`};
`;

export const NavigationSectionButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};

  padding: 0;
  margin: 0;
  width: 100%;

  border: none;
  background: transparent;
  cursor: pointer;

  color: ${({ theme }) => theme.palette.grey['500']};
`;

export const NavigationSectionTitle = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.caption)};
  color: ${({ theme }) => theme.palette.text.primary};
`;
