import React, { useEffect, useState } from 'react';
import { IconFormIcons, IconFormNames, Target } from 'shared/assets/icons';
import { BlurHashImage } from 'shared/components/connected/blur-hash-image';
import { Image } from 'shared/types/image';

export type GoalDetailIllustrationProps = {
  illustration?: Image | IconFormNames | null;
};

// this component is used to prevent rerenders on the uploaded image, since it has some post-processing which returns a new url.
export const GoalDetailIllustration: React.FC<GoalDetailIllustrationProps> = ({
  illustration,
}) => {
  const [activeImage, setActiveImage] = useState(
    !illustration || typeof illustration === 'string'
      ? undefined
      : illustration,
  );

  useEffect(() => {
    if (typeof illustration === 'string') {
      setActiveImage(undefined);
      return;
    }

    if (illustration?.createdAt !== activeImage?.createdAt) {
      setActiveImage(illustration ?? undefined);
    }
  }, [activeImage?.createdAt, illustration]);

  return (
    <BlurHashImage
      hash={activeImage?.blurHash}
      src={activeImage?.url}
      placeholderIcon={
        typeof illustration === 'string' ? IconFormIcons[illustration] : Target
      }
    />
  );
};
