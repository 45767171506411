import { CircularProgress } from '@mui/material';
import { typographyToCss } from 'shared/lib/mui';
import styled from 'styled-components';

export const Container = styled.div`
  overflow: auto;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(6)};
  padding: ${({ theme }) => `${theme.spacing(6)} 0 0`};

  ${({ theme }) => theme.breakpoints.up(theme.breakpoints.values.sm)} {
    flex-direction: row;
    padding: ${({ theme }) => `${theme.spacing(6)} ${theme.spacing(10)}`};
  }
`;

export const UspList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(4)};

  margin: 0;
  padding: ${({ theme }) => `0 ${theme.spacing(6)}`};

  list-style: none;

  ${({ theme }) => theme.breakpoints.up(theme.breakpoints.values.sm)} {
    flex: 1;
    padding: 0;
  }
`;

export const UspListItem = styled.li`
  display: flex;
  gap: ${({ theme }) => theme.spacing(3)};
`;

export const UspListItemIcon = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  min-width: 1.8rem;
  width: 1.8rem;
  min-height: 1.8rem;
  height: 1.8rem;

  background: ${({ theme }) => theme.palette.success.main};
  border-radius: 50%;

  font-size: 1.2rem;
  color: ${({ theme }) => theme.palette.common.white};
`;

export const PaymentForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(6)};
  justify-content: space-between;

  ${({ theme }) => theme.breakpoints.up(theme.breakpoints.values.sm)} {
    flex: 1;
  }
`;

export const PaymentOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};

  padding: ${({ theme }) => `0 ${theme.spacing(6)}`};

  ${({ theme }) => theme.breakpoints.up(theme.breakpoints.values.sm)} {
    flex-direction: column-reverse;
    gap: ${({ theme }) => theme.spacing(2)};
    padding: 0;
  }
`;

export const Currency = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const CurrencyLabel = styled.label`
  ${({ theme }) => typographyToCss(theme.typography.overline)};
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const CurrencySelectContainer = styled.span`
  display: inline-block;
  position: relative;
  line-height: 62.5%;
`;

export const CurrencySelect = styled.select`
  ${({ theme }) => typographyToCss(theme.typography.caption)};
  margin: 0;
  padding: ${({ theme }) => `0 ${theme.spacing(2.5)} 0 0`};

  background: transparent;
  border: none;

  // hide basic arrow
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const ChevronContainer = styled.span`
  position: absolute;
  top: 50%;
  right: 0;

  transform: translateY(-50%) rotate(270deg);
  pointer-events: none;

  font-size: 1rem;
  line-height: 62.5%;
`;

export const Interactions = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};

  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;

  padding: ${({ theme }) => theme.spacing(4)};

  background: ${({ theme }) => theme.palette.background.default};
  border-top: 1px solid ${({ theme }) => theme.palette.divider};

  color: ${({ theme }) => theme.palette.text.secondary};
  text-align: center;

  ${({ theme }) => theme.breakpoints.up(theme.breakpoints.values.sm)} {
    position: initial;
    bottom: initial;
    left: initial;
    right: initial;

    padding: 0;

    border-top: none;
  }
`;

export const Loader = styled(CircularProgress)``;
