import Typography from '@mui/material/Typography';
import React from 'react';
import { ArrowLeft } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { SortMode } from 'shared/hooks/use-data-grid-sorting';

import * as Styled from './life-area-data-grid.style';
import { Column, ColumnType } from './types';

export type DataHeaderRowProps = {
  columns: Column[];
  sortMode: SortMode;
  columnToSort?: ColumnType;
  onSortChange: (columnType: ColumnType) => void;
};

export const DataHeaderRow: React.FC<DataHeaderRowProps> = ({
  columns,
  sortMode,
  columnToSort,
  onSortChange,
}) => (
  <Styled.Header>
    {columns.map(({ type, label, sortable }) => (
      <Styled.HeaderItem key={type} $type={type}>
        <Styled.HeaderColumn
          $active={columnToSort === type}
          onClick={() => onSortChange(type)}
        >
          {!!label && (
            <Typography variant="overline" component="span">
              {label}
            </Typography>
          )}
          {sortable && (
            <Styled.OrderIcon
              $active={columnToSort === type}
              $sortMode={sortMode}
            >
              <Icon icon={ArrowLeft} />
            </Styled.OrderIcon>
          )}
        </Styled.HeaderColumn>
      </Styled.HeaderItem>
    ))}
  </Styled.Header>
);
