import { useMemo } from 'react';
import { Option } from 'shared/components/ui/form-select';
import {
  DateFormatOptions,
  dateFormatOptionToLabelMap,
} from 'shared/types/date-format-options';

export const useDateFormatOptions = () =>
  useMemo<Option[]>(
    () =>
      Object.values(DateFormatOptions).map((format) => ({
        value: format,
        label: dateFormatOptionToLabelMap[format],
      })),
    [],
  );
