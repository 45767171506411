export enum WeekDays {
  Monday = 'MO',
  Tuesday = 'TU',
  Wednesday = 'WE',
  Thursday = 'TH',
  Friday = 'FR',
  Saturday = 'SA',
  Sunday = 'SU',
}

export const workWeek = [
  WeekDays.Monday,
  WeekDays.Tuesday,
  WeekDays.Wednesday,
  WeekDays.Thursday,
  WeekDays.Friday,
];

export const initialWeekStartsOn = WeekDays.Monday;

export const weekendDays = [WeekDays.Saturday, WeekDays.Sunday];
