import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GoalTemplatePreviewActionPlan } from 'shared/components/ui/goal-template-preview-action-plan';
import { GoalTemplatePreviewDialogFooter } from 'shared/components/ui/goal-template-preview-dialog-footer';
import { GoalTemplatePreviewDialogHeader } from 'shared/components/ui/goal-template-preview-dialog-header';
import { GoalTemplatePreviewHeader } from 'shared/components/ui/goal-template-preview-header';
import { GoalTemplatePreviewProgress } from 'shared/components/ui/goal-template-preview-progress';
import { GoalTemplate } from 'shared/types/goal-template';
import { LifeArea } from 'shared/types/life-area';
import { WeekDays } from 'shared/types/week-days';
import { getLocalizedStringValue } from 'shared/utils/get-localized-string-value';

import * as Styled from './goal-template-preview.style';

export type GoalTemplatePreviewProps = {
  template: GoalTemplate;
  onSubmit: (template: GoalTemplate) => void;
  onCancel: () => void;

  lifeAreas: LifeArea[];
  weekStartsOn: WeekDays;
};

export const GoalTemplatePreview: React.FC<GoalTemplatePreviewProps> = ({
  template,
  onSubmit,
  onCancel,

  lifeAreas,
  weekStartsOn,
}) => {
  const { i18n } = useTranslation();

  const submit = () => onSubmit(template);

  const goal = useMemo(
    () => template.goals.find(({ isMain }) => isMain) ?? template.goals[0],
    [template],
  );

  const taskSorting = useMemo(() => goal.taskSorting ?? [], [goal.taskSorting]);

  return (
    <>
      <GoalTemplatePreviewDialogHeader
        name={getLocalizedStringValue(template.name, i18n.language)}
      />
      <Styled.Content>
        <GoalTemplatePreviewHeader
          name={getLocalizedStringValue(goal.name, i18n.language)}
          description={
            goal.description
              ? getLocalizedStringValue(goal.description, i18n.language)
              : undefined
          }
          iconName={goal.iconName}
          lifeAreaTemplateId={goal.lifeAreaTemplateId}
          daysTillDeadline={goal.daysTillDeadline}
          lifeAreas={lifeAreas}
        />

        <GoalTemplatePreviewProgress
          metric={goal.metric}
          daysTillDeadline={goal.daysTillDeadline}
          totalTaskCount={template.tasks.length}
          // todo: figure out how we will do this in the future, as we can support multiple main-goals in one template
          totalSubGoalCount={template.goals.length - 1}
        />

        <GoalTemplatePreviewActionPlan
          goals={template.goals}
          tasks={template.tasks}
          lifeAreas={lifeAreas}
          weekStartsOn={weekStartsOn}
          taskOrder={taskSorting}
        />
      </Styled.Content>
      <GoalTemplatePreviewDialogFooter onCancel={onCancel} onSubmit={submit} />
    </>
  );
};
