import styled from 'styled-components';

export const List = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  min-width: 0;
  padding: 0;
  margin: 0;

  list-style: none;
`;

export const ListItem = styled.li<{ $hide: boolean; $hasValue: boolean }>`
  max-width: ${({ $hide, $hasValue }) =>
    $hide ? 0 : $hasValue ? '100%' : '2.4rem'};

  transition: max-width 200ms;
  transition-delay: ${({ $hide }) => ($hide ? '0ms' : '100ms')};
  overflow: hidden;

  line-height: 62.5%;
`;
