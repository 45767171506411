import { useMemo } from 'react';
import { MetricEntry } from 'shared/types/goal-metric';
import { ID } from 'shared/types/id';
import { formatDateKey } from 'shared/utils/format-date-key';

import { useGoalById } from './use-goal-by-id';

export const useGoalMetricProgress = (id?: ID) => {
  const goal = useGoalById(id);

  return useMemo(() => {
    const uniqueEntries = new Map<string, MetricEntry>();

    goal?.metric?.entries?.forEach((entry) => {
      const dateKey = formatDateKey(entry.date);
      const existingUniqueEntry = uniqueEntries.get(dateKey);

      if (
        existingUniqueEntry &&
        existingUniqueEntry.date.getTime() > entry.date.getTime()
      ) {
        return;
      }

      uniqueEntries.set(dateKey, entry);
    });

    return Array.from(uniqueEntries.values()).sort(
      (entryA, entryB) => entryA.date.getTime() - entryB.date.getTime(),
    );
  }, [goal?.metric?.entries]);
};
