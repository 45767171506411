import { NewGoal } from 'shared/types/goal';

import { dateToFirestoreTimestamp } from './converters/date-to-firestore-timestamp';
import { newGoalConverter } from './converters/goal';
import { addDocumentWithOwnerRole, CollectionOptions } from './helpers';

export const createGoal = async (goal: NewGoal) => {
  return addDocumentWithOwnerRole(
    CollectionOptions.Goals,
    dateToFirestoreTimestamp(newGoalConverter(goal)),
  );
};
