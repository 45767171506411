import { initialWeekStartsOn, WeekDays } from 'shared/types/week-days';

const weekDaysList = Object.values(WeekDays);

export const getWeekDays = (
  startDay: WeekDays = initialWeekStartsOn,
): WeekDays[] => {
  const startIndex = weekDaysList.indexOf(startDay);

  if (startIndex === -1) {
    return weekDaysList;
  }

  return [
    ...weekDaysList.slice(startIndex),
    ...weekDaysList.slice(0, startIndex),
  ];
};
