import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IconFormIcons, IconFormNames } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { IconMenu } from 'shared/components/ui/icon-menu';
import { useClickOutside } from 'shared/hooks/use-click-outside';
import { useOpenMenu } from 'shared/hooks/use-open-menu';

import * as Styled from './form-icon.style';

export type FormIconProps = {
  value: IconFormNames;
  id?: string;
  onChange?: (icon: IconFormNames) => void;
  isPremium?: boolean;
  onPremium?: () => void;
};

export const FormIcon: React.FC<FormIconProps> = ({
  id = 'form-icon',
  value,
  onChange,
  isPremium,
  onPremium,
}) => {
  const { t } = useTranslation();

  const containerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const selectedIcon = IconFormIcons[value];
  const { menuOpen, openMenu, closeMenu } = useOpenMenu();
  const onIcon = (icon: IconFormNames) => {
    onChange?.(icon);
    closeMenu();
  };

  useClickOutside(containerRef, closeMenu);

  return (
    <Styled.Container ref={containerRef}>
      <Styled.Button
        ref={buttonRef}
        id={id}
        $selected={menuOpen}
        onClick={isPremium ? onPremium : openMenu}
        type="button"
      >
        <Icon icon={selectedIcon} />
      </Styled.Button>

      {isPremium && (
        <Styled.PremiumLabel>{t('general.labels.premium')}</Styled.PremiumLabel>
      )}

      {menuOpen && (
        <IconMenu
          referenceElement={buttonRef}
          position="right"
          onChange={onIcon}
          selectedIcon={value}
        />
      )}
    </Styled.Container>
  );
};
