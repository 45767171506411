import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLifeAreasQuery } from 'shared/hooks/queries/use-life-areas-query';
import { useUser } from 'shared/hooks/use-user';
import { LifeArea } from 'shared/types/life-area';
import { getLocalizedStringValue } from 'shared/utils/get-localized-string-value';

export const useLocalizedLifeAreas = (enabled = true) => {
  const { i18n } = useTranslation();
  const user = useUser();

  const { data } = useLifeAreasQuery(user?.uid, {}, enabled);

  return useMemo<LifeArea[]>(
    () =>
      data?.map(({ name, ...lifeArea }) => ({
        ...lifeArea,
        name: getLocalizedStringValue(name, i18n.language),
      })) ?? [],
    [data, i18n.language],
  );
};
