import { Paths } from 'shared/routes';

import { Goals } from './goals';
import { Tasks } from './tasks';
import { Vision } from './vision';

export const LifeAreaDetailRoutes = [
  {
    path: Paths.LifeAreaDetail,
    element: <Vision />,
  },
  {
    path: Paths.LifeAreaDetailGoals,
    element: <Goals />,
  },
  {
    path: Paths.LifeAreaDetailTasks,
    element: <Tasks />,
  },
];
