import { useContext } from 'react';
import { ThemeInfoContext } from 'shared/lib/mui/theme-info-context';
import { ThemeModes } from 'shared/types/theme-modes';

export const useActiveTheme = (): [
  themeMode: ThemeModes,
  setThemeMode: (themeMode: ThemeModes) => void,
  resetThemeMode: () => void,
] => {
  const { activeTheme, setThemeMode, resetThemeMode } =
    useContext(ThemeInfoContext);

  return [activeTheme, setThemeMode, resetThemeMode];
};
