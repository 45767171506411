import React, { useEffect, useMemo, useState } from 'react';
import { version } from 'shared/constants';

import { NewVersionMessage } from './new-version-message';
import { Version } from './types';
import { VersionContext, VersionContextProps } from './version-context';

const checkVersion = async (): Promise<Version> => {
  const versionFile = await fetch(`/version.json?${new Date().getTime()}`);

  return versionFile.json();
};

const isVersionGreater = (newVersion: string, currentVersion: string) => {
  const v1 = currentVersion.split('.').map(Number);
  const v2 = newVersion.split('.').map(Number);

  for (let i = 0; i < v1.length; i++) {
    if (v1[i] > v2[i]) {
      return true;
    }
    if (v1[i] < v2[i]) {
      return false;
    }
  }

  return false;
};

export type VersionProviderProps = {
  children: React.ReactNode;
};

export const VersionProvider: React.FC<VersionProviderProps> = ({
  children,
}) => {
  const [askedVersion, setAskedVersion] = useState(version);
  const [messageContent, setMessageContent] = useState<Version>();

  const closeMessage = () => setMessageContent(undefined);

  // @ts-ignore -- reload(true) should forceGet: https://developer.mozilla.org/en-US/docs/Web/API/Location/reload#forceget
  const onSubmit = () => window.location.reload(true);

  useEffect(() => {
    const check = async () => {
      const newVersion = await checkVersion();
      if (isVersionGreater(askedVersion, newVersion.version)) {
        setAskedVersion(newVersion.version);
        setMessageContent(newVersion);
      }
    };

    // check every 10 minutes on an update
    const intervalRef = window.setInterval(check, 10 * 60 * 1000);

    check();

    return () => window.clearInterval(intervalRef);
  }, [askedVersion]);

  const value = useMemo<VersionContextProps>(
    () => ({
      newVersionAvailable: askedVersion !== version,
    }),
    [askedVersion],
  );

  return (
    <VersionContext.Provider value={value}>
      {children}
      {messageContent && (
        <NewVersionMessage
          version={messageContent}
          onCancel={closeMessage}
          onSubmit={onSubmit}
        />
      )}
    </VersionContext.Provider>
  );
};
