import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppStore, Logo } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { iosDownloadLink } from 'shared/constants';

import * as Styled from './download-ios-banner.style';

export const DownloadIosBanner: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Styled.Container>
      <Styled.Content>
        <Styled.IconContainer>
          <Icon icon={Logo} />
        </Styled.IconContainer>

        <Styled.Title>{t('general.ios-banner.title')}</Styled.Title>

        <Styled.Description>
          {t('general.ios-banner.description')}
        </Styled.Description>

        <Styled.AppleLink href={iosDownloadLink}>
          <AppStore />
        </Styled.AppleLink>
      </Styled.Content>

      <Styled.ImageContainer>
        <Styled.ImageLink href={iosDownloadLink}>
          <Styled.Image
            src="/images/iphone/iphone.png"
            srcSet="/images/iphone/iphone@2x.png 2x, /images/iphone/iphone@3x.png 3x"
          />
        </Styled.ImageLink>
      </Styled.ImageContainer>
    </Styled.Container>
  );
};
