import * as React from 'react';

export enum NetworkStatus {
  Online = 'online',
  Offline = 'offline',
}

export const useNetworkStatus = () => {
  const [status, setStatus] = React.useState(
    window.navigator.onLine ? NetworkStatus.Online : NetworkStatus.Offline,
  );

  React.useEffect(() => {
    const setOnline = () => setStatus(NetworkStatus.Online);
    const setOffline = () => setStatus(NetworkStatus.Offline);

    window.addEventListener('online', setOnline);
    window.addEventListener('offline', setOffline);

    return () => {
      window.removeEventListener('online', setOnline);
      window.removeEventListener('offline', setOffline);
    };
  }, []);

  return {
    status,
    isOnline: status === NetworkStatus.Online,
    isOffline: status === NetworkStatus.Offline,
  };
};
