import { useSensor, useSensors } from '@dnd-kit/core';
import { MouseSensor, TouchSensor } from 'shared/lib/dnd-kit';

export const useSortableSensors = () => {
  const mouseSensor = useSensor(MouseSensor, {
    // Require the mouse to move by 10 pixels before activating
    activationConstraint: {
      distance: 10,
    },
  });
  const touchSensor = useSensor(TouchSensor, {
    // Press delay of 250ms, with tolerance of 5px of movement
    activationConstraint: {
      delay: 250,
      tolerance: 5,
    },
  });

  return useSensors(mouseSensor, touchSensor);
};
