import { useMutation } from '@tanstack/react-query';
import { ImageLocations, uploadImage } from 'shared/services/backend-api';
import { ID } from 'shared/types/id';

export const useUploadImageMutation = () =>
  useMutation({
    mutationFn: async ({
      id,
      location,
      image,
    }: {
      id: ID;
      location: ImageLocations;
      image: File;
    }) => uploadImage(location, image, id),
  });

export { ImageLocations };
