import { CircularProgress } from '@mui/material';
import React from 'react';
import { BlurHashImage } from 'shared/components/connected/blur-hash-image';

import * as Styled from './image-preview.style';

export type ImagePreviewProps = {
  image: { url: string; blurHash: string | null };
  isLoading?: boolean;
};

export const ImagePreview: React.FC<ImagePreviewProps> = ({
  image,
  isLoading,
}) => {
  return (
    <Styled.Container>
      <BlurHashImage hash={image.blurHash} src={image.url} />

      {!!isLoading && (
        <Styled.LoaderContainer>
          <CircularProgress size={20} />
        </Styled.LoaderContainer>
      )}
    </Styled.Container>
  );
};
