import { AuthError, signInWithEmailAndPassword } from 'firebase/auth';
import { LoginErrorTypes } from 'shared/types/login-form';

import { getUser } from './get-user';
import { getAuthentication } from './helpers';
import { setUserCountry } from './set-user-country';

export const signInUser = async (email: string, password: string) => {
  const auth = await getAuthentication();

  try {
    await signInWithEmailAndPassword(auth, email, password);
    const user = await getUser();

    try {
      if (!user?.settings?.country) {
        await setUserCountry();
      }
    } catch (e) {
      // do nothing, eventually send error to sentry or something like that
    }

    return user;
  } catch (e) {
    switch ((e as AuthError)?.code) {
      case 'auth/invalid-login-credentials':
      case 'auth/invalid-credential':
        throw LoginErrorTypes.WrongCombination;
      default:
        throw LoginErrorTypes.Unknown;
    }
  }
};
