import { Timestamp } from 'firebase/firestore';

export const firebaseTimestampToDate = (
  timestamp?: Timestamp | null,
): Date | null => {
  if (!timestamp) {
    return null;
  }

  return new Timestamp(timestamp.seconds, timestamp.nanoseconds).toDate();
};
