import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoginUserWithAppleMutation } from 'shared/hooks/queries/use-login-user-with-apple-mutation';
import { Paths } from 'shared/routes';

export const useLoginWithApple = () => {
  const navigate = useNavigate();
  const { mutateAsync, error, reset, isPending } =
    useLoginUserWithAppleMutation();

  const login = useCallback(async () => {
    await mutateAsync();
    navigate(Paths.Home);
  }, [mutateAsync, navigate]);

  return { login, error, reset, isLoading: isPending };
};
