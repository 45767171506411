import React from 'react';
import { Target } from 'shared/assets/icons';
import { Collapse } from 'shared/components/ui/collapse';
import { SectionGoalHeader } from 'shared/components/ui/section-goal-header';
import { TaskAndGoalList } from 'shared/components/ui/task-and-goal-list';
import { useToggle } from 'shared/hooks/use-toggle';

import * as Styled from './goal-task-section.style';

export enum OverwriteCollapseOptions {
  No = 'no',
  Open = 'open',
  Close = 'close',
}

export type GoalTaskSectionProps = React.ComponentProps<
  typeof TaskAndGoalList
> &
  Omit<React.ComponentProps<typeof SectionGoalHeader>, 'onOpen' | 'isOpen'> & {
    overwriteCollapse?: OverwriteCollapseOptions;
  };

export const GoalTaskSection: React.FC<GoalTaskSectionProps> = ({
  id,
  goals,
  onCompleteGoal,
  imagePlaceholderIcon = Target,
  onDeleteGoal,
  onEditGoal,
  onUpdateGoal,
  onUpdateGoalProgress,
  onArchiveGoal,
  onFavoriteGoal,
  onPremium,

  name,
  image,
  iconName,
  color,
  progress,
  deadline,
  metric,
  parentIds,
  isMainGoal,
  isArchived,
  isFavorite,
  isCompleted,
  isDragging,
  isMoving,
  isHovered,
  isFrozen,

  items,
  onTask,
  onUnfocus,
  favorites,
  lifeAreas,
  selectedItem,
  canCollapse,
  initialOpen,
  canCreateTask,
  newTaskInputRef,
  hasDnd,
  isSortingDisabled,
  hideGoal,
  showRepeatingAsTodo,

  overwriteCollapse = OverwriteCollapseOptions.No,
}) => {
  const [collapseOpen, toggleCollapse] = useToggle(initialOpen);

  const CollapseContainer = canCollapse ? Collapse : React.Fragment;
  const collapseProps = canCollapse
    ? {
        isOpen:
          overwriteCollapse === OverwriteCollapseOptions.No
            ? collapseOpen
            : overwriteCollapse === OverwriteCollapseOptions.Open,
      }
    : {};

  return (
    <>
      <SectionGoalHeader
        id={id}
        image={image}
        iconName={iconName}
        imagePlaceholderIcon={imagePlaceholderIcon}
        color={color}
        name={name}
        progress={progress}
        deadline={deadline}
        parentIds={parentIds}
        metric={metric}
        isMainGoal={isMainGoal}
        isCompleted={isCompleted}
        isArchived={isArchived}
        isFavorite={isFavorite}
        isFrozen={isFrozen}
        onEditGoal={onEditGoal}
        onUpdateGoal={onUpdateGoal}
        onUpdateGoalProgress={onUpdateGoalProgress}
        onCompleteGoal={onCompleteGoal}
        onArchiveGoal={onArchiveGoal}
        onDeleteGoal={onDeleteGoal}
        onFavoriteGoal={onFavoriteGoal}
        onPremium={onPremium}
        isHovered={isHovered}
        isDragging={isDragging}
        isMoving={isMoving}
        goals={goals}
        isOpen={collapseOpen}
        onOpen={toggleCollapse}
      />
      <CollapseContainer {...collapseProps}>
        <Styled.SublistContainer>
          <TaskAndGoalList
            id={id}
            items={items}
            goals={goals}
            favorites={favorites}
            lifeAreas={lifeAreas}
            onTask={onTask}
            onCompleteGoal={onCompleteGoal}
            onUnfocus={onUnfocus}
            onEditGoal={onEditGoal}
            onUpdateGoalProgress={onUpdateGoalProgress}
            onUpdateGoal={onUpdateGoal}
            onDeleteGoal={onDeleteGoal}
            onFavoriteGoal={onFavoriteGoal}
            onArchiveGoal={onArchiveGoal}
            onPremium={onPremium}
            selectedItem={selectedItem}
            hasDnd={hasDnd}
            hideGoal={hideGoal}
            showRepeatingAsTodo={showRepeatingAsTodo}
            canCreateTask={canCreateTask}
            canCollapse={canCollapse}
            newTaskInputRef={newTaskInputRef}
            isSortingDisabled={
              isSortingDisabled || (canCollapse && !collapseOpen)
            }
          />
        </Styled.SublistContainer>
      </CollapseContainer>
    </>
  );
};
