import { useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import {
  useOverdueHabits,
  useOverdueTasks,
  useTodaysHabits,
  useTodaysTasks,
} from 'features/tasks';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useToday } from 'shared/contexts/today';
import { useUser } from 'shared/hooks/use-user';
import { lifeAreaOtherID } from 'shared/types/orderable-section';
import { initialWeekStartsOn } from 'shared/types/week-days';
import { mapTaskToTaskCard } from 'shared/utils/mappers/map-task-to-task-card';
import { sortBySortingMode } from 'shared/utils/sort-by-sorting-mode';
import { sortItems } from 'shared/utils/sort-items';

export const useLifeAreaTaskSections = () => {
  const { t } = useTranslation();
  const today = useToday();

  const user = useUser();

  const todos = useTodaysTasks();
  const habits = useTodaysHabits();
  const overdueTodos = useOverdueTasks();
  const overdueHabits = useOverdueHabits();
  const goals = useGoals();
  const lifeAreas = useLocalizedLifeAreas();

  const tasks = useMemo(
    () => [...overdueTodos, ...overdueHabits, ...todos, ...habits],
    [overdueTodos, overdueHabits, todos, habits],
  );
  const taskCardTasks = useMemo(
    () =>
      tasks.map((task) =>
        mapTaskToTaskCard(task, {
          today,
          goals,
          t,
          showDue: true,
          hideTodayDue: true,
          weekStartsOn: user?.settings?.startOfWeek ?? initialWeekStartsOn,
        }),
      ),
    [goals, t, tasks, today, user?.settings?.startOfWeek],
  );

  const sections = useMemo(() => {
    const filteredGoals = goals.filter(
      ({ id }) => !!taskCardTasks.find(({ raw }) => raw.task.goalId === id),
    );
    const otherGoalIds = goals
      .filter(({ lifeAreaId }) => !lifeAreaId)
      .map(({ id }) => id);

    return [
      ...sortItems(lifeAreas, user?.settings?.lifeAreaSorting ?? [], 'id')
        .filter(({ id }) => {
          return (
            !!filteredGoals.find(({ lifeAreaId }) => lifeAreaId === id) ||
            !!taskCardTasks.find(({ raw }) => raw.task.lifeAreaId === id)
          );
        })
        .map((lifeArea) => {
          const lifeAreaGoalIds = filteredGoals
            .filter(({ lifeAreaId }) => lifeAreaId === lifeArea.id)
            .map(({ id }) => id);

          return {
            id: lifeArea.id,
            lifeArea,
            items: sortBySortingMode(
              taskCardTasks.filter(
                ({ raw }) =>
                  (!!raw.task.goalId &&
                    lifeAreaGoalIds.includes(raw.task.goalId)) ||
                  raw.task.lifeAreaId === lifeArea.id,
              ),
              {
                sortingMode: user?.settings?.todoSortingMode,
                customOrder:
                  user?.settings?.todayGroupSorting?.lifeArea?.sorting?.[
                    lifeArea.id
                  ] ?? [],
              },
            ),
          };
        }),
      {
        id: lifeAreaOtherID,
        items: sortBySortingMode(
          taskCardTasks.filter(
            ({ raw }) =>
              (!raw.task.goalId || otherGoalIds.includes(raw.task.goalId)) &&
              !raw.task.lifeAreaId,
          ),
          {
            sortingMode: user?.settings?.todoSortingMode,
            customOrder:
              user?.settings?.todayGroupSorting?.lifeArea?.sorting?.['null'] ??
              [],
          },
        ),
      },
    ];
  }, [
    goals,
    lifeAreas,
    taskCardTasks,
    user?.settings?.lifeAreaSorting,
    user?.settings?.todayGroupSorting?.lifeArea?.sorting,
    user?.settings?.todoSortingMode,
  ]);

  return {
    sections,
    taskCardTasks,
  };
};
