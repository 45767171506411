import Typography from '@mui/material/Typography';
import React from 'react';
import { ChevronLeft } from 'shared/assets/icons';
import { Collapse } from 'shared/components/ui/collapse';
import { Icon } from 'shared/components/ui/icon';

import * as Styled from './information-box.style';
import { Item } from './types';

export type InformationBoxItemProps = {
  header: string;
  open?: boolean;
  onClick: () => void;
  items: Item[];
};

export const InformationBoxBullets: React.FC<InformationBoxItemProps> = ({
  header,
  open,
  onClick,
  items,
}) => (
  <Styled.BulletsContainer>
    <Styled.Header onClick={onClick}>
      <Typography variant="caption" component="span">
        {header}
      </Typography>

      <Styled.HeaderIconContainer $open={!!open}>
        <Icon icon={ChevronLeft} />
      </Styled.HeaderIconContainer>
    </Styled.Header>

    <Collapse isOpen={!!open}>
      <Styled.BulletsList>
        {items.map(({ icon, iconColor, title, description }) => (
          <Styled.BulletsListItem key={`${title} ${description}`}>
            {!!icon && (
              <Styled.BulletsListItemIcon $color={iconColor}>
                <Icon icon={icon} />
              </Styled.BulletsListItemIcon>
            )}

            <Typography variant="overline" component="p">
              <Styled.BulletsListItemTitle>
                {title}
                {description ? ':' : ''}
              </Styled.BulletsListItemTitle>
              {description ? ` ${description}` : ''}
            </Typography>
          </Styled.BulletsListItem>
        ))}
      </Styled.BulletsList>
    </Collapse>
  </Styled.BulletsContainer>
);
