import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Locales } from 'shared/assets/localization';
import { useCreatePushNotificationMutation } from 'shared/hooks/queries/use-create-push-notification-mutation';
import { useLoginUserMutation } from 'shared/hooks/queries/use-login-user-mutation';
import { useNotificationPermission } from 'shared/hooks/use-notification-permission';
import { useTrackEvents } from 'shared/hooks/use-track-events';
import { Paths } from 'shared/routes';
import { ActionEvents } from 'shared/services/tracking';

export const useLogin = () => {
  const { i18n } = useTranslation();
  const track = useTrackEvents();
  const navigate = useNavigate();
  const [permission] = useNotificationPermission();
  const { mutateAsync, isError, error, reset, isPending } =
    useLoginUserMutation();
  const { mutate: createPushToken } = useCreatePushNotificationMutation();

  const login = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      const user = await mutateAsync({ email, password });
      track(ActionEvents.OnboardingLogin, undefined);

      // if the browser already gave permission, we will set a push token for the user at login
      if (permission === 'granted' && user) {
        createPushToken({
          userId: user.uid,
          locale: user?.settings?.language ?? (i18n.language as Locales),
        });
      }
      navigate(Paths.Home);
    },
    [createPushToken, i18n.language, mutateAsync, navigate, permission, track],
  );

  return { login, isError, retry: reset, isLoading: isPending, error };
};
