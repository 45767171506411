import React from 'react';

import * as Styled from './toggle.style';

export type ToggleProps = Omit<React.ComponentProps<'input'>, 'value'> & {
  value: string | number;
};

export const Toggle: React.FC<ToggleProps> = ({ id, ...rest }) => (
  <Styled.Container htmlFor={id}>
    <Styled.Input type="checkbox" {...rest} id={id} />
    <Styled.ToggleLabel role="checkbox" htmlFor={id} />
  </Styled.Container>
);
