import Typography from '@mui/material/Typography';
import { useGoalById, useGoalCompletion, useUpdateGoal } from 'features/goals';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { LifeAreaRocket } from 'shared/assets/icons';
import { Alert, Colors as AlertColors } from 'shared/components/ui/alert';
import { Button, Colors, Sizes, Variants } from 'shared/components/ui/button';
import { Section } from 'shared/components/ui/section';
import { useCompleteGoal } from 'shared/contexts/complete-goal';
import { countMetricOptions } from 'shared/types/metric-template';

import { CountChart } from '../count-chart';
import { EmptyChart } from '../empty-chart';
import { ProgressChart } from '../progress-chart';
import * as Styled from './progress.style';

export const Progress: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const goal = useGoalById(id);
  const goalProgress = useGoalCompletion(id);
  const completeGoal = useCompleteGoal();
  const { submit } = useUpdateGoal();

  const onComplete = () => !!goal && completeGoal(goal);
  const onCloseAlert = () => {
    if (!goal) {
      return;
    }

    submit({
      id: goal.id,
      metadata: { ...(goal.metadata ?? {}), hideCompletionBanner: true },
    });
  };

  return (
    <Section
      title={t('pages.goal-detail.progress.title')}
      canCollapse
      initialOpen
    >
      {goalProgress.completed >= goalProgress.end &&
        !goal?.completedAt &&
        !goal?.metadata?.hideCompletionBanner && (
          <Styled.CompletionBanner>
            <Alert
              startIcon={LifeAreaRocket}
              color={AlertColors.Info}
              onClose={onCloseAlert}
              action={
                <Button
                  variant={Variants.Outlined}
                  color={Colors.Info}
                  size={Sizes.Small}
                  onClick={onComplete}
                >
                  {t('pages.goal-detail.progress.complete-goal.button')}
                </Button>
              }
            >
              <Typography variant="overline" component="span">
                {t('pages.goal-detail.progress.complete-goal.description')}
              </Typography>
            </Alert>
          </Styled.CompletionBanner>
        )}
      <Styled.ChartContainer>
        {!goal?.metric?.id && <EmptyChart />}
        {!!goal?.metric?.id &&
          (countMetricOptions.includes(goal?.metric?.id) ? (
            <CountChart />
          ) : (
            <ProgressChart />
          ))}
      </Styled.ChartContainer>
    </Section>
  );
};
