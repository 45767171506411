import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import React from 'react';
import { Timestamp } from 'shared/types/timestamp';

import * as Styled from './month-header.style';

export type MonthHeaderProps = {
  date: Timestamp;
};

export const MonthHeader: React.FC<MonthHeaderProps> = ({ date }) => (
  <Styled.Container>
    <Typography variant="h6" component="span">
      {format(date, 'LLLL')}
    </Typography>
  </Styled.Container>
);
