import React from 'react';

import * as Styled from './popup-menu.style';

export type PopupMenuListItemProps = {
  children: React.ReactNode;
};

export const PopupMenuListItem: React.FC<PopupMenuListItemProps> = ({
  children,
}) => <Styled.ListItem>{children}</Styled.ListItem>;
