import styled from 'styled-components';

export const Container = styled.div<{ $maxHeight: number | 'auto' }>`
  overflow: hidden;
  max-height: ${({ $maxHeight }) =>
    typeof $maxHeight === 'string' ? $maxHeight : `${$maxHeight}px`};
  transition: max-height 0.2s ease;
`;

export const Content = styled.div`
  display: flow-root;
`;
