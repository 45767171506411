export type Options = {
  start?: number;
  end: number;
  current: number;
};

export const getCompletionPercentage = ({
  start = 0,
  end,
  current,
}: Options) => {
  const range = end - start;
  const progress = current - start;
  return Math.max(
    0,
    Math.min(100, start === end ? 0 : Math.floor((progress / range) * 100)),
  );
};
