import React, { forwardRef } from 'react';

import * as Styled from './radio-input.style';

export type RadioInputProps = Omit<React.ComponentProps<'input'>, 'type'> & {};

export const RadioInput = forwardRef<HTMLInputElement, RadioInputProps>(
  ({ children, disabled, ...rest }, ref) => (
    <Styled.Container>
      <Styled.Input {...rest} ref={ref} disabled={disabled} type="radio" />

      <Styled.Checkbox $isDisabled={!!disabled}>
        <Styled.Checkmark />
      </Styled.Checkbox>

      {!!children && <Styled.Label>{children}</Styled.Label>}
    </Styled.Container>
  ),
);
