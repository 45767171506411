import { useCallback, useEffect, useRef } from 'react';
import {
  ActionEvents,
  AppTypes,
  EventProps,
  MixpanelService,
  PageEvents,
} from 'shared/services/tracking';
import { ID } from 'shared/types/id';

import { useUser } from './use-user';

export const useTrackEvents = () => {
  const user = useUser();
  const mixpanelRef = useRef<MixpanelService>();
  const userIdRef = useRef<ID>();

  useEffect(() => {
    if (mixpanelRef.current && user?.uid && user?.uid !== userIdRef.current) {
      mixpanelRef.current.reset();
      mixpanelRef.current.identifyUser(user.uid);
      userIdRef.current = user.uid;
    }
  }, [user?.uid]);

  return useCallback(
    <TrackEvent extends ActionEvents | PageEvents>(
      event: TrackEvent,
      eventProps: EventProps<TrackEvent>,
    ) => {
      if (!mixpanelRef.current) {
        mixpanelRef.current = new MixpanelService({
          app_type: window.electron
            ? window.electron.isMac
              ? AppTypes.ElectronMacApp
              : AppTypes.ElectronWinApp
            : AppTypes.WebApp,
        });
      }

      mixpanelRef.current.track(event, eventProps);
    },
    [],
  );
};
