import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IconFormNames } from 'shared/assets/icons';
import { Button, Sizes, Variants } from 'shared/components/ui/button';
import { FormIcon } from 'shared/components/ui/form-icon';
import { FormInput } from 'shared/components/ui/form-input';
import { Toggle } from 'shared/components/ui/toggle';
import { useForwardedRef } from 'shared/hooks/use-forwarded-ref';
import { GoalTemplateFormFields } from 'shared/types/goal-template-form';

import * as Styled from './goal-template-form.style';
import { useGoalTemplateForm } from './use-goal-template-form';

const defaultInitialValues: Omit<GoalTemplateFormFields, 'goalId'> = {
  name: '',
  description: '',
  iconName: IconFormNames.EducationAndLearning,
  isPublic: false,
};

export type GoalFormProps = {
  initialValues: Partial<GoalTemplateFormFields> &
    Pick<GoalTemplateFormFields, 'goalId'>;
  onSubmit: (goal: GoalTemplateFormFields) => void;
  onCancel: () => void;
};

export const GoalTemplateForm: React.FC<GoalFormProps> = ({
  initialValues,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation();
  const {
    register,
    onSubmit: submitForm,
    errors,
    isValid,
    watch,
    setValue,
  } = useGoalTemplateForm(onSubmit, {
    ...defaultInitialValues,
    ...initialValues,
    iconName: initialValues?.iconName ?? defaultInitialValues.iconName,
  });

  const { ref: nameRefCallback, ...nameProps } = register('name');
  const nameRef = useForwardedRef(nameRefCallback);

  const iconValue = watch<'iconName'>('iconName');
  const onChangeIcon = (iconName: IconFormNames) =>
    setValue<'iconName'>('iconName', iconName);

  const isPublic = watch<'isPublic'>('isPublic');
  const onChangeFavorite = () => setValue<'isPublic'>('isPublic', !isPublic);

  useEffect(() => {
    if (nameRef.current) {
      nameRef.current.focus();
    }
  }, [nameRef]);

  return (
    <Styled.Form noValidate onSubmit={submitForm}>
      <Styled.Header>
        <Typography variant="h5" component="h1">
          {t('forms.goal-template.create.title')}
        </Typography>
      </Styled.Header>

      <Styled.Body>
        <Styled.Section>
          <Styled.Label>
            {t('forms.goal-template.fields.name.label')}
          </Styled.Label>
          <FormInput
            {...nameProps}
            ref={nameRef}
            placeholder={t('forms.goal-template.fields.name.placeholder')}
            error={errors.name?.message}
            autoComplete="off"
          />

          <Styled.Label>
            {t('forms.goal-template.fields.description.label')}
          </Styled.Label>
          <FormInput
            {...register('description')}
            placeholder={t(
              'forms.goal-template.fields.description.placeholder',
            )}
            error={errors.description?.message}
            autoComplete="off"
          />
        </Styled.Section>

        <Styled.Section>
          <Styled.Label>
            {t('forms.goal-template.fields.icon.label')}
          </Styled.Label>
          <FormIcon onChange={onChangeIcon} value={iconValue!} />

          <Styled.Label>
            {t('forms.goal-template.fields.is-public.label')}
          </Styled.Label>
          <Toggle
            value="isPublic"
            checked={!!isPublic}
            onChange={onChangeFavorite}
          />
        </Styled.Section>
      </Styled.Body>

      <Styled.Interactions>
        <Button
          variant={Variants.Outlined}
          size={Sizes.Medium}
          onClick={onCancel}
          type="button"
        >
          {t('forms.goal-template.buttons.cancel.label')}
        </Button>
        <Button size={Sizes.Medium} type="submit" disabled={!isValid}>
          {t('forms.goal-template.buttons.save.label')}
        </Button>
      </Styled.Interactions>
    </Styled.Form>
  );
};
