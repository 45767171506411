import { ID } from './id';
import { LocalizedString } from './localized-string';

export const metricUnitFormatBase = '{{value}}';

export enum DefaultMetricOptions {
  CompletedTasks = 'completedTasks',
  SubGoalProgress = 'subgoalProgress',
  CompletedSubGoals = 'completedSubgoals',
  Custom = 'custom',
}

export enum MeasurementSystems {
  Metric = 'metric',
  Us = 'us',
  Uk = 'uk',
}

export enum UnitClasses {
  Measurement = 'measurement',
  Currency = 'currency',
}

export const countMetricOptions = [
  DefaultMetricOptions.CompletedTasks as string,
  DefaultMetricOptions.SubGoalProgress as string,
];

export type MetricTemplate = {
  id: DefaultMetricOptions | ID; // completedTasks, completedSubgoals, (money / weight / whatever), custom
  name: LocalizedString; // { en: "Money" }
  description: LocalizedString; // { en: "Used for tracking money" }
  descriptionShort: LocalizedString; // { en: "E.g.: 0k → 100k" }
  unitName: LocalizedString; // { en: "Currency" }
  unitOptions?: UnitOption[] | null;
  unitClass?: UnitClasses;
};

export type UnitOption = {
  id: ID;
  name: LocalizedString; // { en: 'Rotations' }
  format: LocalizedString | null;
  symbol: LocalizedString | null;
  measurementSystem: MeasurementSystems | null;
};
