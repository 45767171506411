import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Option } from 'shared/components/ui/form-select';
import { getWeekDays } from 'shared/utils/get-week-days';

export const useWeekDaysOptions = () => {
  const { t } = useTranslation();
  return useMemo<Option[]>(
    () =>
      getWeekDays().map((day) => ({
        label: t(`general.labels.habit.repeat.specific-days.days.${day}.label`),
        value: day,
      })),
    [t],
  );
};
