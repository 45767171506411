import { useCallback, useEffect, useState } from 'react';
import { useTrackEvents } from 'shared/hooks/use-track-events';
import { ActionEvents } from 'shared/services/tracking';

export const useNotificationPermission = (): [
  permission: PermissionState,
  requestPermission: () => Promise<NotificationPermission>,
] => {
  const [permission, setPermission] = useState<PermissionState>('prompt');
  const track = useTrackEvents();

  const requestPermission = useCallback(() => {
    track(ActionEvents.InitialPushNotificationsQuestion, undefined);
    return Notification.requestPermission();
  }, [track]);

  useEffect(() => {
    (async function () {
      const permission = await navigator.permissions.query({
        name: 'notifications',
      });
      setPermission(permission.state);
      permission.onchange = function () {
        setPermission(this.state);
      };
    })();
  }, []);

  return [permission, requestPermission];
};
