import React, { useMemo } from 'react';
import { IconFormIcons, Target } from 'shared/assets/icons';
import { CircleProgress } from 'shared/components/ui/circle-progress';
import { Icon } from 'shared/components/ui/icon';
import { TimeLeft } from 'shared/components/ui/time-left';
import { Paths } from 'shared/routes';
import { Goal } from 'shared/types/goal';
import { getCompletionPercentage } from 'shared/utils/get-completion-percentage';
import { getGoalCompletion } from 'shared/utils/get-goal-completion';
import { replaceUrlParams } from 'shared/utils/replace-url-params';

import * as Styled from './favorite-goal.style';

export type FavoriteGoalProps = {
  goal: Goal;
  goals: Goal[];
  currentPath?: string;
  isMoving?: boolean;
};

export const FavoriteGoal: React.FC<FavoriteGoalProps> = ({
  goal,
  goals,
  currentPath,
  isMoving,
}) => {
  const url = replaceUrlParams(Paths.GoalDetail, { id: goal.id });
  const isActive = !!currentPath && currentPath.includes(url);

  const { end, percentage } = useMemo(() => {
    const { start, current, end } = getGoalCompletion(goal, {
      goals,
    });
    const percentage = getCompletionPercentage({ start, current, end });

    return {
      start,
      current,
      end,
      percentage,
    };
  }, [goal, goals]);

  return (
    <Styled.Link
      to={url}
      $active={isActive}
      as={goal.frozenAt ? 'span' : undefined}
      $isMoving={!!isMoving}
      $isFrozen={!!goal.frozenAt}
    >
      <Styled.IconContainer $active={isActive}>
        {end === Infinity || !!goal.completedAt ? (
          <Icon icon={goal.iconName ? IconFormIcons[goal.iconName] : Target} />
        ) : (
          <CircleProgress percentage={percentage} hidePercentage />
        )}
      </Styled.IconContainer>

      <Styled.Title $completed={!!goal.completedAt}>{goal.name}</Styled.Title>

      {!!goal.deadline && !goal.completedAt && (
        <Styled.TimeLeftContainer $active={isActive}>
          <TimeLeft date={goal.deadline!} isShort />
        </Styled.TimeLeftContainer>
      )}
    </Styled.Link>
  );
};
