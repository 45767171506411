import { IconFormNames } from 'shared/assets/icons';
import { ID } from 'shared/types/id';
import * as yup from 'yup';

export type GoalTemplate = {
  name: string;
  description: string;
  iconName: IconFormNames;
  goalId: ID;
  isPublic: boolean;
};

export const validationSchema: yup.ObjectSchema<GoalTemplate> = yup.object({
  name: yup.string().required(),
  description: yup.string().required(),
  iconName: yup
    .string<IconFormNames>()
    .oneOf(Object.values(IconFormNames))
    .required(),
  goalId: yup.string().required(),
  isPublic: yup.boolean().required(),
});

export type GoalTemplateFormFields = yup.InferType<typeof validationSchema>;
