import styled, { css } from 'styled-components';

export const List = styled.ul`
  list-style: none;

  margin: 0;
  padding: ${({ theme }) => `0 ${theme.spacing(1)}`};
`;

export const ListItem = styled.li<{ $isTask?: boolean }>`
  ${({ $isTask, theme }) =>
    $isTask &&
    css`
      padding-left: ${theme.spacing(4)};
    `};
`;

export const DroppableArea = styled.div``;

export const NewTaskContainer = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacing(4)}`};
`;
