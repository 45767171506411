import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Colors, Sizes, Variants } from 'shared/components/ui/button';

import * as Styled from './delete-habit.style';

export type DeleteHabitErrorProps = {
  name: string;
  onRetry: () => void;
  onCancel: () => void;
};

export const DeleteHabitError: React.FC<DeleteHabitErrorProps> = ({
  name,
  onRetry,
  onCancel,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Styled.Header>
        <Typography variant="h5" component="h1">
          {t('forms.delete-habit.error.title')}
        </Typography>
      </Styled.Header>

      <Styled.Body>
        <Typography variant="body2" component="span">
          {t('forms.delete-habit.error.description.1')}
          <Styled.HabitName>{name}</Styled.HabitName>
          {t('forms.delete-habit.error.description.2')}
        </Typography>
      </Styled.Body>

      <Styled.Interactions>
        <Button
          variant={Variants.Outlined}
          size={Sizes.Medium}
          onClick={onCancel}
        >
          {t('forms.delete-habit.error.buttons.cancel.label')}
        </Button>
        <Button size={Sizes.Medium} color={Colors.Error} onClick={onRetry}>
          {t('forms.delete-habit.error.buttons.retry.label')}
        </Button>
      </Styled.Interactions>
    </>
  );
};
