import { ID } from 'shared/types/id';
import { SortingMode } from 'shared/types/sorting-mode';
import { TaskPriorityOptions } from 'shared/types/task-base';

import { getUser, getUserRef } from './get-user';
import { updateDocWithTimestamp } from './helpers';

export const updateTodayPriorityTaskOrder = async (
  priorities: { priority: TaskPriorityOptions; taskIds: ID[] }[],
) => {
  const user = await getUser();
  // without a user we cannot update anything.
  if (!user) {
    return;
  }

  const userRef = await getUserRef();

  if (!userRef) {
    return;
  }

  const settingsToUpdate = priorities.reduce<
    Partial<
      Record<
        `settings.todayGroupSorting.priority.sorting.${TaskPriorityOptions}`,
        ID[]
      >
    > & { 'settings.todoSortingMode': SortingMode }
  >(
    (acc, { priority, taskIds }) => {
      acc[`settings.todayGroupSorting.priority.sorting.${priority}`] = taskIds;
      return acc;
    },
    { 'settings.todoSortingMode': SortingMode.Custom },
  );

  updateDocWithTimestamp(userRef, settingsToUpdate);
};
