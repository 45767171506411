import React, { forwardRef } from 'react';
import { Check } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';

import * as Styled from './checkbox.style';
import { Colors } from './types';

export type CheckboxProps = React.ComponentProps<'input'> & {
  color?: Colors;
  isSmall?: boolean;
  isPlaceholder?: boolean;
  isRecommended?: boolean;
};

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      children,
      color = Colors.Default,
      isSmall,
      isPlaceholder,
      isRecommended,
      disabled,
      ...rest
    },
    ref,
  ) => (
    <Styled.Container>
      <Styled.Input {...rest} ref={ref} disabled={disabled} type="checkbox" />

      <Styled.Checkbox
        $color={color}
        $isPlaceholder={!!isPlaceholder}
        $isRecommended={!!isRecommended}
        $isDisabled={!!disabled}
      >
        <Styled.Checkmark $isSmall={!!isSmall}>
          <Icon icon={Check} />
        </Styled.Checkmark>
      </Styled.Checkbox>

      {!!children && <Styled.Label>{children}</Styled.Label>}
    </Styled.Container>
  ),
);
