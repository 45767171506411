export const replaceUrlParams = (
  url: string,
  urlParams: Record<string, string | number | boolean> = {},
  queryParams: Record<string, string | string[]> = {},
) => {
  const searchParams = Object.entries(queryParams).reduce(
    (acc, [key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((val) => acc.append(key, val));
        return acc;
      }

      acc.set(key, value);
      return acc;
    },
    new URLSearchParams(),
  );

  const replacedUrl = Object.entries(urlParams).reduce(
    (acc, [key, value]) => acc.replaceAll(`:${key}`, String(value)),
    url,
  );

  return searchParams.toString()
    ? `${replacedUrl}?${searchParams.toString()}`
    : replacedUrl;
};
