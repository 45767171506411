import { endOfDay } from 'date-fns';
import { useGoals } from 'features/goals';
import {
  useOverdueHabits,
  useOverdueTasks,
  useTodaysHabits,
  useTodaysTasks,
} from 'features/tasks';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useToday } from 'shared/contexts/today';
import { useUser } from 'shared/hooks/use-user';
import { isBetween } from 'shared/lib/date-fns/is-between';
import {
  DateSection,
  DateSectionOptions,
} from 'shared/types/orderable-section';
import { TaskType } from 'shared/types/task-base';
import { initialWeekStartsOn } from 'shared/types/week-days';
import { formatDateKey } from 'shared/utils/format-date-key';
import { getCustomOrderBetweenDates } from 'shared/utils/get-custom-order-between-dates';
import { getTaskDueDate } from 'shared/utils/get-task-due';
import { mapTaskToTaskCard } from 'shared/utils/mappers/map-task-to-task-card';
import { sortBySortingMode } from 'shared/utils/sort-by-sorting-mode';

import { useTodaySections } from './use-today-sections';

export const useTodayTaskSections = () => {
  const { t } = useTranslation();
  const today = useToday();

  const todos = useTodaysTasks();
  const habits = useTodaysHabits();
  const overdueTodos = useOverdueTasks();
  const overdueHabits = useOverdueHabits();
  const goals = useGoals();
  const sections = useTodaySections();
  const user = useUser();

  const tasks = useMemo(
    () => [...overdueTodos, ...overdueHabits, ...todos, ...habits],
    [overdueTodos, overdueHabits, todos, habits],
  );

  const taskCardTasks = useMemo(
    () =>
      tasks.map((task) =>
        mapTaskToTaskCard(task, {
          today,
          goals,
          t,
          showDue: true,
          hideTodayDue: true,
          weekStartsOn: user?.settings?.startOfWeek ?? initialWeekStartsOn,
        }),
      ),
    [goals, t, tasks, today, user?.settings?.startOfWeek],
  );

  const filledSections = useMemo(() => {
    // fill the sections with the tasks we got
    const filled = tasks
      .reduce<DateSection[]>((acc, task) => {
        const dueDate = getTaskDueDate(task, {
          today,
          weekStartsOn: user?.settings?.startOfWeek ?? initialWeekStartsOn,
        });

        if (!dueDate) {
          return acc;
        }

        const sectionIndex = acc.findIndex(({ startDate, endDate }) =>
          isBetween(dueDate, startDate, endDate),
        );
        if (sectionIndex < 0) {
          return acc;
        }

        acc[sectionIndex].items.push(
          mapTaskToTaskCard(task, {
            today,
            goals,
            t,
            showDue: true,
            hideTodayDue: true,
            hideFutureDue:
              acc[sectionIndex].type === DateSectionOptions.Day ||
              task.type !== TaskType.Task,
            weekStartsOn: user?.settings?.startOfWeek ?? initialWeekStartsOn,
          }),
        );

        return acc;
      }, structuredClone(sections))
      // filter out overdue if it doesn't have any items
      .filter(
        ({ id, items }) =>
          id === formatDateKey(endOfDay(today)) || !!items.length,
      );

    filled.forEach((section) => {
      const customOrder = getCustomOrderBetweenDates(
        user?.settings?.todoSorting ?? {},
        section.startDate,
        section.endDate,
      );
      section.items = sortBySortingMode(section.items, {
        sortingMode: user?.settings?.todoSortingMode,
        customOrder,
      });
    });

    return filled;
  }, [
    tasks,
    sections,
    user?.settings?.todoSortingMode,
    user?.settings?.todoSorting,
    user?.settings?.startOfWeek,
    today,
    goals,
    t,
  ]);

  return {
    sections: filledSections,
    taskCardTasks,
  };
};
