import { DndContext } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import React, { useMemo } from 'react';
import { FormIllustration } from 'shared/components/connected/form-illustration';
import { OverlayComponentWrapper } from 'shared/components/ui/overlay-component-wrapper';
import { SortableOverlay } from 'shared/components/ui/sortable-overlay';
import { useSortableItemsControls } from 'shared/hooks/use-sortable-items-controls';
import { useSortableSensors } from 'shared/hooks/use-sortable-sensors';
import { ID } from 'shared/types/id';
import { Image } from 'shared/types/image';

import { createImageId } from './create-image-id';
import { MultiImageUpload } from './multi-image-upload';

export type SortableMultiImageUploadProps = Omit<
  React.ComponentProps<typeof MultiImageUpload>,
  'hasDnd'
> & {};
export const SortableMultiImageUpload: React.FC<
  SortableMultiImageUploadProps
> = ({
  onChange,
  images,
  imageLocation,
  sources,
  premiumSources,
  onPremium,
}) => {
  const sensors = useSortableSensors();
  const imageItems = useMemo(
    () =>
      images.map((image) => ({
        ...image,
        id: createImageId(image),
      })),
    [images],
  );
  const onDragEnd = (ids: ID[]) =>
    onChange(
      ids.map((id) =>
        images.find((image) => createImageId(image) === id),
      ) as Image[],
    );

  const {
    items,
    activeItem,
    handleDragStart,
    handleDragOver,
    handleDragEnd,
    handleDragCancel,
  } = useSortableItemsControls({ items: imageItems, onDragEnd });

  return (
    <DndContext
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext items={items}>
        <MultiImageUpload
          images={items}
          onChange={onChange}
          imageLocation={imageLocation}
          sources={sources}
          premiumSources={premiumSources}
          onPremium={onPremium}
          hasDnd
        />
      </SortableContext>

      <SortableOverlay>
        {!!activeItem && (
          <OverlayComponentWrapper>
            <FormIllustration
              // dragging image cannot change anything
              onChange={() => {}}
              uploadLocation={imageLocation}
              value={activeItem}
              sources={sources}
              premiumSources={premiumSources}
              onPremium={onPremium}
            />
          </OverlayComponentWrapper>
        )}
      </SortableOverlay>
    </DndContext>
  );
};
