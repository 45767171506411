import styled, { css } from 'styled-components';

export const HeaderContainer = styled.div<{ $canCollapse: boolean }>`
  padding-left: ${({ theme }) => theme.spacing(6)};
  padding-right: ${({ theme }) => theme.spacing(3)};

  ${({ $canCollapse, theme }) =>
    $canCollapse &&
    css`
      display: flex;
      align-items: center;
      gap: ${theme.spacing(1)};

      padding-left: ${theme.spacing(0.5)};
    `};
`;

export const IconButtonContainer = styled.span`
  color: ${({ theme }) => theme.palette.text.secondary};
  line-height: 62.5%;
`;

export const ChildrenContainer = styled.div<{ $hasExtraSpacing: boolean }>`
  padding-left: ${({ theme, $hasExtraSpacing }) =>
    $hasExtraSpacing ? theme.spacing(1) : 0};
`;

export const NewTaskContainer = styled.div<{ $hasExtraSpacing: boolean }>`
  padding-left: ${({ theme, $hasExtraSpacing }) =>
    theme.spacing($hasExtraSpacing ? 5 : 4)};
`;
