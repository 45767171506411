import { isAfter, isBefore, isEqual } from 'date-fns';

export enum InclusivityOption {
  All = '[]',
  Start = '[)',
  End = '(]',
  None = '()',
}

export const isBetween = (
  date: Date,
  from: Date,
  to: Date,
  inclusivity = InclusivityOption.All,
) => {
  if (!Object.values(InclusivityOption).includes(inclusivity)) {
    throw new Error('Inclusivity parameter must be one of (), [], (], [)');
  }

  const isBeforeEqual = inclusivity[0] === '[';
  const isAfterEqual = inclusivity[1] === ']';

  return (
    (isBeforeEqual
      ? isEqual(from, date) || isBefore(from, date)
      : isBefore(from, date)) &&
    (isAfterEqual ? isEqual(to, date) || isAfter(to, date) : isAfter(to, date))
  );
};
