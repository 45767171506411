import { useQuery } from '@tanstack/react-query';
import { createSubscriptionQuery } from 'shared/lib/@tanstack-query';
import { getVisions } from 'shared/services/backend-api';
import { ID } from 'shared/types/id';

export const queryKey = 'vision-by-life-area-id';

export const useVisionByLifeAreaIdQuery = (uid: ID = '', id: ID = '') => {
  const results = useQuery({
    queryKey: [queryKey, uid, id],
    queryFn: createSubscriptionQuery([
      () => getVisions({ lifeAreaIds: [id], limit: 1 }),
    ]),
    enabled: !!uid && !!id,
  });

  return {
    ...results,
    data: results.data?.[0] ?? undefined,
  };
};
