import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(10)};

  height: 100%;
  padding: ${({ theme }) => `${theme.spacing(4)} ${theme.spacing(3)}`};

  ${({ theme }) => theme.breakpoints.up(theme.breakpoints.values.md)} {
    flex-direction: row;
  }
`;

export const InputContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Section = styled.div`
  padding: ${({ theme }) => `${theme.spacing(5.5)} 0 ${theme.spacing(1.5)}`};
`;

export const TipsAndImages = styled.div`
  width: 100%;

  ${({ theme }) => theme.breakpoints.up(theme.breakpoints.values.md)} {
    width: 27.2rem;
  }
`;

export const ImageCarouselContainer = styled.div<{ $show: boolean }>`
  display: none;
  padding: ${({ theme }) => ` 0 0 ${theme.spacing(3)}`};
  height: ${({ theme }) => `calc(27.2rem + ${theme.spacing(3)})`};

  transition:
    height 300ms,
    padding 300ms;

  ${({ theme }) => theme.breakpoints.up(theme.breakpoints.values.md)} {
    display: flex;
  }

  ${({ $show }) =>
    !$show &&
    css`
      height: 0;
      padding: 0;
    `};
`;

export const TipsContainer = styled.div``;
