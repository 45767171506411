import React, { useState } from 'react';
import { Resizable, ResizeCallbackData } from 'react-resizable';

import * as Styled from './resizable-container.style';

interface ResizableContainerProps {
  open: boolean;
  mobileOpen: boolean;
  children: React.ReactNode;
}

export const ResizableContainer: React.FC<ResizableContainerProps> = ({
  open,
  mobileOpen,
  children,
}) => {
  const [isResizing, setIsResizing] = useState(false);
  const [width, setWidth] = useState(Styled.initialContainerWidth);

  const handleResizeStart = () => setIsResizing(true);

  const handleResizeMove = (
    event: React.SyntheticEvent,
    { size }: ResizeCallbackData,
  ) => setWidth(size.width);

  const handleResizeEnd = () => setIsResizing(false);

  return (
    <Resizable
      width={width}
      axis="x"
      onResizeStart={handleResizeStart}
      onResize={handleResizeMove}
      onResizeStop={handleResizeEnd}
      handle={(_, ref) => (
        <Styled.ResizeHandle $isResizing={isResizing} ref={ref} />
      )}
      minConstraints={[Styled.minContainerWidth, 0]}
      maxConstraints={[Styled.maxContainerWidth, 0]}
    >
      <Styled.ResizableContainer
        $mobileOpen={mobileOpen}
        $width={open ? width : 0}
        $isResizing={isResizing}
      >
        {children}
      </Styled.ResizableContainer>
    </Resizable>
  );
};
