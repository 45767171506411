import React from 'react';
import { useTranslation } from 'react-i18next';
import { Apple } from 'shared/assets/icons';
import { Button, Colors, Sizes, Variants } from 'shared/components/ui/button';

export type AppleButtonProps = {
  isLoading?: boolean;
  onClick: () => void;
};

export const AppleButton: React.FC<AppleButtonProps> = ({
  isLoading,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <Button
      variant={Variants.Outlined}
      size={Sizes.Large}
      color={Colors.Inherit}
      onClick={onClick}
      startIcon={Apple}
      disabled={isLoading}
      fullWidth
      alignCenter
    >
      {t('forms.login.third-party.apple.label')}
    </Button>
  );
};
