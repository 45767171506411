import { typographyToCss } from 'shared/lib/mui';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
  overflow: hidden;
  min-width: 0;

  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 1.2rem;
`;

export const Label = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.overline)};

  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
`;
