import React, { useEffect } from 'react';

import { getItemScrollPosition } from '../utils/get-item-scroll-position';

export type Options = {
  ref: React.RefObject<HTMLElement>;
  scrollPosition?: number;
  itemWidth: number;
  totalAmount: number;
  visibleAmount: number;
};

export const useScrollSmoothToPosition = ({
  ref,
  scrollPosition,
  itemWidth,
  totalAmount,
  visibleAmount,
}: Options) => {
  useEffect(() => {
    if (typeof scrollPosition === 'number' && ref.current) {
      ref.current.scrollLeft = getItemScrollPosition({
        visibleAmount,
        itemWidth,
        itemPosition: scrollPosition ?? totalAmount,
      });
    }
  });
};
