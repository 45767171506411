import { isSameDay } from 'date-fns';
import { useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import { useSubTasks, useUpdateSubtaskOrder } from 'features/tasks';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SelfSortingTaskSection } from 'shared/components/ui/task-section';
import { useToday } from 'shared/contexts/today';
import { useUser } from 'shared/hooks/use-user';
import { Task } from 'shared/types/task';
import { TaskType } from 'shared/types/task-base';
import { initialWeekStartsOn } from 'shared/types/week-days';
import { mapTaskToTaskCard } from 'shared/utils/mappers/map-task-to-task-card';
import { orderTaskCardTasksByDate } from 'shared/utils/order-task-card-tasks-by-date';

export type SubTasksProps = {
  task: Task;
};

export const SubTasks: React.FC<SubTasksProps> = ({ task }) => {
  const subTasks = useSubTasks(task);
  const { updateOrder } = useUpdateSubtaskOrder(task);

  const parentIds = useMemo(
    () => (task ? [...(task.parentIds ?? []), task.id] : undefined),
    [task],
  );

  const today = useToday();
  const { t } = useTranslation();
  const user = useUser();
  const goals = useGoals();
  const lifeAreas = useLocalizedLifeAreas();

  const isCompletedHabit = useMemo(() => {
    if (![TaskType.Habit, TaskType.Repeating].includes(task.type)) {
      return false;
    }

    const activeSchedule = task.schedules?.find(({ endDate }) => !endDate);
    if (!activeSchedule) {
      return false;
    }

    return !!activeSchedule.completions.find((completion) =>
      isSameDay(completion, today),
    );
  }, [task.schedules, task.type, today]);

  const subTaskTaskCardTasks = useMemo(() => {
    const mappedSubTasks = subTasks.map((subTask) =>
      mapTaskToTaskCard(
        {
          ...subTask,
          // if the main task is a completed habit, all sub-tasks should be displayed as completed as well,
          // whether they are completed or not
          completedAt: isCompletedHabit ? new Date() : subTask.completedAt,
        },
        {
          goals,
          today,
          weekStartsOn: user?.settings?.startOfWeek ?? initialWeekStartsOn,
          t,
        },
      ),
    );

    return orderTaskCardTasksByDate(mappedSubTasks, {
      null: task?.subtaskOrder ?? [],
    });
  }, [
    goals,
    isCompletedHabit,
    subTasks,
    t,
    task?.subtaskOrder,
    today,
    user?.settings?.startOfWeek,
  ]);

  return (
    <>
      <SelfSortingTaskSection
        goals={goals}
        lifeAreas={lifeAreas}
        today={today}
        tasks={subTaskTaskCardTasks}
        parentIds={parentIds}
        parentChildIds={task.childIds}
        showDetailOnSelect={false}
        onReorder={updateOrder}
        tasksDisabled={isCompletedHabit}
        hasOwnSelectedState
        canCreateTask
        hideMeta
      />
    </>
  );
};
