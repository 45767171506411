import { isAfter, isBefore, isSameDay } from 'date-fns';
import { DueDisplayOptions } from 'shared/types/task-base';
import { Timestamp } from 'shared/types/timestamp';

const allDueDisplayOptions = Object.values(DueDisplayOptions);

const isPastDueHidden = (
  due: Timestamp,
  today: Timestamp,
  displayOptions: DueDisplayOptions[],
) => !displayOptions.includes(DueDisplayOptions.Past) && isBefore(due, today);

const isTodayDueHidden = (
  due: Timestamp,
  today: Timestamp,
  displayOptions: DueDisplayOptions[],
) => !displayOptions.includes(DueDisplayOptions.Today) && isSameDay(due, today);

const isFutureDueHidden = (
  due: Timestamp,
  today: Timestamp,
  displayOptions: DueDisplayOptions[],
) => !displayOptions.includes(DueDisplayOptions.Future) && isAfter(due, today);

export const shouldDueBeShown = (
  due: Timestamp,
  displayOptions = allDueDisplayOptions,
  today: Timestamp,
): boolean =>
  !!due &&
  !(
    isPastDueHidden(due, today, displayOptions) ||
    isTodayDueHidden(due, today, displayOptions) ||
    isFutureDueHidden(due, today, displayOptions)
  );
