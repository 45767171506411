import { useGoalById, useSubGoals } from 'features/goals';
import { useMemo } from 'react';
import { ID } from 'shared/types/id';
import { getCompletionPercentage } from 'shared/utils/get-completion-percentage';
import { getGoalCompletion } from 'shared/utils/get-goal-completion';

export type Progress = {
  end: number;
  completed: number;
};

export const useGoalCompletion = (id?: ID) => {
  const goal = useGoalById(id);
  const subGoals = useSubGoals(id);

  return useMemo<Progress>(() => {
    if (!goal) {
      return { completed: 0, end: 0 };
    }

    const completionPercentage = getCompletionPercentage(
      getGoalCompletion(goal, {
        goals: subGoals,
      }),
    );

    return {
      completed: completionPercentage,
      end: 100,
    };
  }, [goal, subGoals]);
};
