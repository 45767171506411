import { Paths } from 'shared/routes';

import { Active } from './active';
import { Completed } from './completed';

export const HabitsOverviewRoutes = [
  {
    path: Paths.Habits,
    element: <Active />,
  },
  {
    path: Paths.HabitsCompleted,
    element: <Completed />,
  },
];
