import { useMemo } from 'react';
import { FreeAppLimits } from 'shared/constants';
import { useUser } from 'shared/hooks/use-user';
import { Entitlements } from 'shared/types/entitlements';
import { PremiumFeature } from 'shared/types/premium-feature';
import { User } from 'shared/types/user';

type FeatureCheckFunctionParams = {
  [PremiumFeature.GoalUserPhoto]: { user?: User };
  [PremiumFeature.UnlimitedGoals]: {
    uncompletedAmountOfGoals: number;
    user?: User;
  };
  [PremiumFeature.SubGoals]: { user?: User };
  [PremiumFeature.UnlimitedHabits]: {
    totalAmountOfHabits: number;
    user?: User;
  };
  [PremiumFeature.UnlimitedLifeAreas]: {
    totalAmountOfCustomLifeAreas: number;
    user?: User;
  };
  [PremiumFeature.HabitIcons]: { user?: User };
  [PremiumFeature.LifeAreaIcons]: { user?: User };
  [PremiumFeature.VisionUserPhoto]: { user?: User };
  [PremiumFeature.CustomizeTemplateLifeAreas]: { user?: User };
};

// goal images
const goalUserPhoto: (
  options: FeatureCheckFunctionParams[PremiumFeature.GoalUserPhoto],
) => boolean = ({ user }) =>
  !!user?.entitlements?.includes(Entitlements.Premium);

// goals
const checkGoalsAllowed: (
  options: FeatureCheckFunctionParams[PremiumFeature.UnlimitedGoals],
) => boolean = ({ uncompletedAmountOfGoals, user }) =>
  uncompletedAmountOfGoals < FreeAppLimits.uncompletedAmountOfGoals ||
  !!user?.entitlements?.includes(Entitlements.Premium);

// Sub goals
const checkSubGoalsAllowed: (
  options: FeatureCheckFunctionParams[PremiumFeature.SubGoals],
) => boolean = ({ user }) =>
  !!user?.entitlements?.includes(Entitlements.Premium);

// habits
const checkHabitsAllowed: (
  options: FeatureCheckFunctionParams[PremiumFeature.UnlimitedHabits],
) => boolean = ({ totalAmountOfHabits, user }) =>
  totalAmountOfHabits < FreeAppLimits.totalAmountOfHabits ||
  !!user?.entitlements?.includes(Entitlements.Premium);

// life areas
const checkUnlimitedLifeAreas: (
  options: FeatureCheckFunctionParams[PremiumFeature.UnlimitedLifeAreas],
) => boolean = ({ totalAmountOfCustomLifeAreas, user }) =>
  totalAmountOfCustomLifeAreas < FreeAppLimits.TotalAmountOfCustomLifeAreas ||
  !!user?.entitlements?.includes(Entitlements.Premium);

// habit icons
const habitIcons: (
  options: FeatureCheckFunctionParams[PremiumFeature.HabitIcons],
) => boolean = ({ user }) =>
  !!user?.entitlements?.includes(Entitlements.Premium);

// life-area icons
const lifeAreaIcons: (
  options: FeatureCheckFunctionParams[PremiumFeature.LifeAreaIcons],
) => boolean = ({ user }) =>
  !!user?.entitlements?.includes(Entitlements.Premium);

// vision images
const visionUserPhoto: (
  options: FeatureCheckFunctionParams[PremiumFeature.VisionUserPhoto],
) => boolean = ({ user }) =>
  !!user?.entitlements?.includes(Entitlements.Premium);

// customize predefined life areas
const customizeTemplateLifeAreas: (
  options: FeatureCheckFunctionParams[PremiumFeature.CustomizeTemplateLifeAreas],
) => boolean = ({ user }) =>
  !!user?.entitlements?.includes(Entitlements.Premium);

const checkFeatureAllowedMap = {
  [PremiumFeature.GoalUserPhoto]: goalUserPhoto,
  [PremiumFeature.UnlimitedGoals]: checkGoalsAllowed,
  [PremiumFeature.SubGoals]: checkSubGoalsAllowed,
  [PremiumFeature.UnlimitedHabits]: checkHabitsAllowed,
  [PremiumFeature.UnlimitedLifeAreas]: checkUnlimitedLifeAreas,
  [PremiumFeature.HabitIcons]: habitIcons,
  [PremiumFeature.LifeAreaIcons]: lifeAreaIcons,
  [PremiumFeature.VisionUserPhoto]: visionUserPhoto,
  [PremiumFeature.CustomizeTemplateLifeAreas]: customizeTemplateLifeAreas,
};

export const usePremiumFeatureAllowed = <Feature extends PremiumFeature>(
  feature: Feature,
  options: Omit<FeatureCheckFunctionParams[Feature], 'user'>,
) => {
  const user = useUser();

  return useMemo(() => {
    const checkFunction = checkFeatureAllowedMap[feature] as (
      options: FeatureCheckFunctionParams[Feature],
    ) => boolean;
    return checkFunction({
      ...options,
      user,
    } as FeatureCheckFunctionParams[Feature]);
  }, [feature, options, user]);
};
