import { httpsCallable } from 'firebase/functions';
import { ForgotPasswordErrorTypes } from 'shared/types/forgot-password-form';

import { getFunctions } from './helpers';

export const forgotPassword = async (email: string) => {
  const functions = getFunctions();
  const reset = httpsCallable(functions, 'auth0Migration-resetPassword');

  try {
    await reset({ email });
  } catch (e) {
    throw ForgotPasswordErrorTypes.Unknown;
  }
};
