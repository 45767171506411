import { useCallback, useRef } from 'react';
import { useCreateVisionMutation } from 'shared/hooks/queries/use-create-vision-mutation';
import { useUpdateVisionMutation } from 'shared/hooks/queries/use-update-vision-mutation';
import { useDelayedCallback } from 'shared/hooks/use-delayed-callback';
import { useTrackEvents } from 'shared/hooks/use-track-events';
import {
  ActionEvents,
  lifeAreaToTrackingLifeAreaType,
} from 'shared/services/tracking';
import { ID } from 'shared/types/id';
import { LifeArea } from 'shared/types/life-area';
import { NewVision, Vision } from 'shared/types/vision';

export const useUpdateVision = (lifeArea?: LifeArea) => {
  const visionRef = useRef<ID>();
  const updatedRef = useRef(false);
  const { mutateAsync: updateVision } = useUpdateVisionMutation();
  const { mutateAsync: createVision } = useCreateVisionMutation();
  const track = useTrackEvents();

  return useDelayedCallback(
    useCallback(
      async (vision: Vision | NewVision) => {
        if (!updatedRef.current) {
          track(
            'id' in vision
              ? ActionEvents.VisionEdit
              : ActionEvents.VisionCreate,
            'id' in vision
              ? undefined
              : {
                  life_area: lifeAreaToTrackingLifeAreaType(lifeArea)!,
                  dream_life_examples_added: false,
                  images: vision.images?.length ?? 0,
                },
          );

          updatedRef.current = true;
        }

        if (!visionRef.current || !('id' in vision)) {
          const newVision = await createVision(vision);
          visionRef.current = newVision.id;
          return;
        }

        updateVision({ ...vision, id: visionRef.current });
      },
      [createVision, lifeArea, track, updateVision],
    ),
  );
};
