import { doc } from 'firebase/firestore';
import { ID } from 'shared/types/id';

import { goalConverter } from './converters/goal';
import { CollectionOptions, getDatabase } from './helpers';

export const getGoalById = async (id: ID) => {
  return doc(getDatabase(), CollectionOptions.Goals, id).withConverter(
    goalConverter,
  );
};
