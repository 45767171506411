import { Active, Over } from '@dnd-kit/core';
import { DraggableOptions } from 'shared/types/draggable-types';
import { Goal } from 'shared/types/goal';
import { TaskCardTask } from 'shared/types/task-card-task';

type Item = TaskCardTask | Goal;

export const handleNestedSectionsMovement = (
  items: Item[],
  active: Active,
  over: Over,
): { items: Item[]; changedItems: Item[] } => {
  const sortedItemsClone = structuredClone(items);
  const actItem = sortedItemsClone.find(
    ({ id }) => id === active.id.toString(),
  );
  const ovrItem = sortedItemsClone.find(({ id }) => id === over.id.toString());

  // early return when there is no over or active task, or the over and active task are the same. We leave everything as is
  if (!actItem || !ovrItem || actItem.id === ovrItem.id) {
    return { items, changedItems: [] };
  }

  // Task movement
  if (active.data.current?.type === DraggableOptions.Task) {
    const activeItem = actItem as TaskCardTask;
    // moving task from goal to goal
    if (over.data.current?.type === DraggableOptions.Goal) {
      const overItem = ovrItem as Goal;
      const activeItemContainer = sortedItemsClone.find(
        ({ id }) => id === (activeItem as TaskCardTask).fields?.goalId,
      ) as Goal | undefined;

      if (!activeItemContainer) {
        return { items, changedItems: [] };
      }

      const newSorting = activeItemContainer.taskSorting?.filter(
        (id) => id !== activeItem.id,
      );
      activeItemContainer.taskSorting = newSorting?.length ? newSorting : null;

      activeItem.fields.goalId = overItem.id;
      overItem.taskSorting = [...(overItem.taskSorting ?? []), activeItem.id];

      return {
        items: sortedItemsClone,
        changedItems: [
          ...(activeItemContainer ? [activeItemContainer] : []),
          activeItem,
          overItem,
        ],
      };
    }

    // moving task from task to task
    if (over.data.current?.type === DraggableOptions.Task) {
      const overItem = ovrItem as TaskCardTask;
      const activeItemContainer = sortedItemsClone.find(
        ({ id }) => id === activeItem.fields?.goalId,
      ) as Goal | undefined;
      const overItemContainer = sortedItemsClone.find(
        ({ id }) => id === overItem.fields?.goalId,
      ) as Goal | undefined;

      if (!activeItemContainer || !overItemContainer) {
        return { items, changedItems: [] };
      }

      if (activeItemContainer.id === overItemContainer.id) {
        const newSorting =
          overItemContainer.taskSorting?.filter((id) => id !== activeItem.id) ??
          [];
        const overIndex =
          overItemContainer.taskSorting?.findIndex(
            (id) => id === overItem.id,
          ) ?? -1;

        const newIndex = overIndex >= 0 ? overIndex : newSorting.length + 1;
        overItemContainer.taskSorting = [
          ...newSorting.slice(0, newIndex),
          activeItem.id,
          ...newSorting.slice(newIndex, Infinity),
        ];

        return {
          items: sortedItemsClone,
          changedItems: [activeItemContainer],
        };
      }

      if (!overItemContainer.taskSorting) {
        overItemContainer.taskSorting = [];
      }

      const overItemIndex = overItemContainer.taskSorting.findIndex(
        (id) => id === overItem.id,
      );
      const newIndex =
        overItemIndex >= 0
          ? overItemIndex
          : overItemContainer.taskSorting.length + 1;

      activeItemContainer.taskSorting =
        activeItemContainer.taskSorting?.filter((id) => id !== activeItem.id) ??
        null;
      overItemContainer.taskSorting = [
        ...overItemContainer.taskSorting.slice(0, newIndex),
        activeItem.id,
        ...overItemContainer.taskSorting.slice(
          newIndex,
          overItemContainer.taskSorting.length,
        ),
      ];

      activeItem.fields.goalId = overItemContainer.id;

      return {
        items: sortedItemsClone,
        changedItems: [
          ...(activeItemContainer ? [activeItemContainer] : []),
          ...(overItemContainer.id !== activeItemContainer?.id
            ? [overItemContainer]
            : []),
          activeItem,
        ],
      };
    }
  }

  // Goal movement
  if (active.data.current?.type === DraggableOptions.Goal) {
    const activeItem = actItem as Goal;
    // ordering goals
    if (over.data.current?.type === DraggableOptions.Goal) {
      const overItem = ovrItem as Goal;
      const activeParentId =
        activeItem.parentIds?.[activeItem.parentIds.length - 1];
      const overParentId = overItem.parentIds?.[overItem.parentIds.length - 1];
      const activeItemContainer = sortedItemsClone.find(
        ({ id }) => activeParentId === id,
      ) as Goal | undefined;
      const overItemContainer = sortedItemsClone.find(
        ({ id }) => id === overParentId,
      ) as Goal | undefined;

      if (!overItemContainer || !activeItemContainer) {
        return { items, changedItems: [] };
      }

      if (activeItemContainer.id === overItemContainer.id) {
        const newSorting =
          overItemContainer.goalSorting?.filter((id) => id !== activeItem.id) ??
          [];
        const overIndex =
          overItemContainer.goalSorting?.findIndex(
            (id) => id === overItem.id,
          ) ?? -1;

        const newIndex = overIndex >= 0 ? overIndex : newSorting.length + 1;
        overItemContainer.goalSorting = [
          ...newSorting.slice(0, newIndex),
          activeItem.id,
          ...newSorting.slice(newIndex, Infinity),
        ];

        return {
          items: sortedItemsClone,
          changedItems: [activeItemContainer, activeItem],
        };
      }

      const newSorting = activeItemContainer.goalSorting?.filter(
        (id) => id !== activeItem.id,
      );
      activeItemContainer.goalSorting = newSorting?.length ? newSorting : null;

      const overItemIndex = overItemContainer.goalSorting?.findIndex(
        (id) => id === overItem.id,
      );
      const newIndex =
        overItemIndex && overItemIndex >= 0
          ? overItemIndex
          : (overItemContainer.goalSorting?.length ?? 0) + 1;

      overItemContainer.goalSorting = [
        ...(overItemContainer.goalSorting ?? []).slice(0, newIndex),
        activeItem.id,
        ...(overItemContainer.goalSorting ?? []).slice(
          newIndex,
          overItem.goalSorting?.length ?? 0,
        ),
      ];

      activeItem.parentIds = [
        ...(overItemContainer.parentIds ?? []),
        overItemContainer.id,
      ];

      return {
        items: sortedItemsClone,
        changedItems: [
          activeItemContainer,
          ...(overItemContainer.id !== activeItemContainer?.id
            ? [overItemContainer]
            : []),
          activeItem,
        ],
      };
    }
  }

  return { items, changedItems: [] };
};
