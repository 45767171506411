import {
  differenceInDays,
  endOfDay,
  format,
  isAfter,
  isBefore,
  isSameDay,
} from 'date-fns';
import { TFunction } from 'i18next';
import { Task } from 'shared/types/task';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';
import { getScheduleDue } from 'shared/utils/get-schedule-due';

export type Options = {
  today: Timestamp;
  weekStartsOn: WeekDays;
};

export const getTaskDueDate = (
  task: Task,
  { today, weekStartsOn }: Options,
) => {
  const activeSchedule = task.schedules?.find(({ endDate }) => !endDate);
  return activeSchedule
    ? getScheduleDue(activeSchedule, { today, weekStartsOn })
    : task.endStrategy?.deadline ?? undefined;
};

type LabelOptions = {
  t: TFunction;
  today: Timestamp;
  hideFutureDue?: boolean;
  hideTodayDue?: boolean;
  hidePastDue?: boolean;
};

export const getDueLabel = (
  date: Timestamp | undefined,
  { t, today, hideTodayDue, hidePastDue, hideFutureDue }: LabelOptions,
) => {
  if (!date) {
    return;
  }
  if (hidePastDue && isBefore(date, today)) {
    return;
  }

  if (hideTodayDue && isSameDay(date, today)) {
    return;
  }

  if (hideFutureDue && isAfter(date, today)) {
    return;
  }

  if (isSameDay(date, today)) {
    return t('general.labels.dates.today');
  }

  if (isBefore(date, today)) {
    return t('general.labels.days-ago', {
      count: differenceInDays(endOfDay(today), endOfDay(date)),
    });
  }

  return format(date, 'd MMM');
};
