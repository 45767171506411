export const generateNonce = async () => {
  const nonce = window.crypto.randomUUID();
  const msgUint8 = new TextEncoder().encode(nonce);
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashNonce = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');

  return {
    nonce,
    hashNonce,
  };
};
