import React, { useEffect, useState } from 'react';
import { FormInput } from 'shared/components/ui/form-input';

export type UserNameProps = Omit<
  React.ComponentProps<typeof FormInput>,
  'onChange' | 'value'
> & {
  value: string;
  onChange: (value: string) => void;
};

export const UserName: React.FC<UserNameProps> = ({
  value,
  onChange,
  ...rest
}) => {
  const [textValue, setTextValue] = useState(value);

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTextValue(e.target.value);
    onChange(e.target.value);
  };

  useEffect(() => {
    setTextValue(value);
  }, [value]);

  return <FormInput value={textValue} onChange={onChangeInput} {...rest} />;
};
