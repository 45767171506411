export enum Variants {
  Outlined = 'outlined',
  Text = 'text',
  Contained = 'contained',
}

export enum Sizes {
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
}

export enum Colors {
  Primary = 'primary',
  Secondary = 'secondary',
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
  Success = 'success',
  White = 'white',
  Inherit = 'inherit',
}
