import { Placement } from '@floating-ui/react';
import React from 'react';
import { IconFormIcons, IconFormNames } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { PopupMenu } from 'shared/components/ui/popup-menu';

import * as Styled from './icon-menu.style';

const iconsList = Object.entries(IconFormIcons);

export type IconMenuProps = {
  referenceElement: React.RefObject<HTMLElement>;
  position: Placement;
  onChange: (icon: IconFormNames) => void;
  selectedIcon?: IconFormNames;
};

export const IconMenu: React.FC<IconMenuProps> = ({
  referenceElement,
  position,
  onChange,
  selectedIcon,
}) => (
  <PopupMenu referenceElement={referenceElement} position={position}>
    <Styled.List>
      {iconsList.map(([iconName, icon]) => {
        const onClick = () => onChange(iconName as IconFormNames);

        return (
          <Styled.ListItem key={iconName}>
            <Styled.Button
              onClick={onClick}
              $selected={iconName === selectedIcon}
            >
              <Icon icon={icon} />
            </Styled.Button>
          </Styled.ListItem>
        );
      })}
    </Styled.List>
  </PopupMenu>
);
