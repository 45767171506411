import { useForgotPasswordMutation } from 'shared/hooks/queries/use-forgot-password-mutation';

export const useForgotPassword = () => {
  const { mutate, isPending, isSuccess, error } = useForgotPasswordMutation();
  return {
    forgotPassword: mutate,
    isLoading: isPending,
    isSuccess,
    error,
  };
};
