import { CircularProgress } from '@mui/material';
import React from 'react';

import * as Styled from './loader.style';

export const Loader: React.FC = () => (
  <Styled.Container>
    <Styled.IconContainer>
      <CircularProgress />
    </Styled.IconContainer>
  </Styled.Container>
);
