import { DndContext } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import React, { useState } from 'react';
import { TaskCard } from 'shared/components/connected/task-card';
import { OverlayComponentWrapper } from 'shared/components/ui/overlay-component-wrapper';
import { useSortableItemsControls } from 'shared/hooks/use-sortable-items-controls';
import { useSortableSensors } from 'shared/hooks/use-sortable-sensors';
import { ID } from 'shared/types/id';

import { SortableOverlay } from '../sortable-overlay';
import { TasksListView } from './tasks-list-view';

export type SelfSortingTasksListProps = Omit<
  React.ComponentProps<typeof TasksListView>,
  'onUnfocus' | 'onTask' | 'hasDnd'
> & {
  onReorder?: (ids: ID[]) => void;
  hasOwnSelectedState?: boolean;
};

export const SelfSortingTasksList: React.FC<SelfSortingTasksListProps> = ({
  tasks,
  selectedTask,
  displayDue,
  showRepeatingAsTodo,
  onReorder,
  hasOwnSelectedState,
  showDetailOnSelect,
  hideMeta,
  hideGoal,
  hideLifeArea,
  tasksDisabled,
}) => {
  const [activeTask, setActiveTask] = useState<ID>();
  const {
    items,
    activeItem,
    handleDragStart,
    handleDragOver,
    handleDragCancel,
    handleDragEnd,
  } = useSortableItemsControls({ items: tasks, onDragEnd: onReorder });
  const sensors = useSortableSensors();

  const clearSelectedTask = () => setActiveTask(undefined);

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
      sensors={sensors}
    >
      <SortableContext
        items={items.map(({ id }) => id)}
        strategy={verticalListSortingStrategy}
        disabled={!onReorder}
      >
        <TasksListView
          tasks={items}
          onTask={setActiveTask}
          onUnfocus={clearSelectedTask}
          selectedTask={hasOwnSelectedState ? activeTask : selectedTask}
          displayDue={displayDue}
          showRepeatingAsTodo={showRepeatingAsTodo}
          showDetailOnSelect={showDetailOnSelect}
          hideMeta={hideMeta}
          hideGoal={hideGoal}
          hideLifeArea={hideLifeArea}
          tasksDisabled={tasksDisabled}
          taskIsMoving={!!activeItem}
          hasDnd
        />

        <SortableOverlay>
          {!!activeItem && (
            <OverlayComponentWrapper>
              <TaskCard
                task={activeItem.raw.task}
                onUnfocus={clearSelectedTask}
                onClick={setActiveTask}
                isSelected={
                  hasOwnSelectedState
                    ? activeTask === activeItem.id
                    : selectedTask === activeItem.id
                }
                displayDue={displayDue}
                showRepeatingAsTodo={showRepeatingAsTodo}
                showDetailOnSelect={showDetailOnSelect}
                hideMeta={hideMeta}
                hideGoal={hideGoal}
                hideLifeArea={hideLifeArea}
                isDisabled={tasksDisabled}
                noHover
              />
            </OverlayComponentWrapper>
          )}
        </SortableOverlay>
      </SortableContext>
    </DndContext>
  );
};
