import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

export const Button = styled.button`
  padding: ${({ theme }) => `${theme.spacing(1.5)} ${theme.spacing(3)} 0`};
  margin: 0;

  width: 100%;

  cursor: pointer;
  border: none;
  background: transparent;
  color: inherit;
`;

export const ButtonContent = styled.span<{
  $underlined: boolean;
  $highlighted: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: ${({ theme }) => theme.palette.text.secondary};

  ${({ $underlined, theme }) =>
    $underlined &&
    css`
      border-bottom: 1px solid ${theme.palette.divider};
    `};

  ${({ $highlighted, theme }) =>
    $highlighted &&
    css`
      color: ${theme.palette.text.primary};
    `};
`;

export const Title = styled.span`
  display: inline-flex;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const TitleCount = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.overline)};
  color: ${({ theme }) => theme.palette.grey['500']};
`;

export const IconContainer = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 2.8rem;
  height: 2.8rem;

  border-radius: ${({ theme }) => theme.spacing(2)};
  transition: background 200ms;

  color: ${({ theme }) => theme.palette.grey['500']};

  &:hover {
    background-color: ${({ theme }) => theme.palette.brown['50']};
  }
`;

export const IconRotation = styled.span<{ $rotate: boolean }>`
  transform: rotate(270deg);
  transition: transform 200ms;

  font-size: 1.4rem;
  line-height: 62.5%;

  ${({ $rotate }) =>
    $rotate &&
    css`
      transform: rotate(90deg);
    `}
`;
