import React from 'react';
import { ProgressBar } from 'shared/components/ui/progress-bar';
import { ProgressChartContainer } from 'shared/components/ui/progress-chart-container';

import * as Styled from './progress-count-section.style';

export type ProgressCountChartProps = Omit<
  React.ComponentProps<typeof ProgressChartContainer>,
  'children' | 'onUpdateProgress' | 'onShowHistory'
> &
  Omit<React.ComponentProps<typeof ProgressBar>, 'hidePercentage'>;

export const ProgressCountSection: React.FC<ProgressCountChartProps> = ({
  target,
  lastEntryValue,
  targetFormat,
  onEditTarget,
  onEditStartDate,
  onEditDeadline,
  onComplete,
  isCompleted,
  total,
  completed,
}) => (
  <ProgressChartContainer
    target={target}
    lastEntryValue={lastEntryValue}
    targetFormat={targetFormat}
    onEditTarget={onEditTarget}
    onEditStartDate={onEditStartDate}
    onEditDeadline={onEditDeadline}
    onComplete={onComplete}
    isCompleted={isCompleted}
  >
    <Styled.ProgressBarContainer>
      <ProgressBar total={total} completed={completed} />
    </Styled.ProgressBarContainer>
  </ProgressChartContainer>
);
