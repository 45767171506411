import React from 'react';

import * as Styled from './grid-container.style';

export type GridContainerProps = {
  children: React.ReactNode;
  hasFlex1?: boolean;
};

export const GridContainer: React.FC<GridContainerProps> = ({
  children,
  hasFlex1,
}) => (
  <Styled.Container $hasFlex1={!!hasFlex1}>
    <Styled.Row $hasFlex1={!!hasFlex1}>{children}</Styled.Row>
  </Styled.Container>
);
