import {
  useGoalById,
  useGoalCompletionCount,
  useGoalMetricProgress,
} from 'features/goals';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { ProgressChartSection } from 'shared/components/ui/progress-chart-section';
import { useCompleteGoal } from 'shared/contexts/complete-goal';
import { useOpenEditGoalDialog } from 'shared/contexts/goal-form';
import { useOpenGoalProgress } from 'shared/contexts/goal-progress';
import { metricUnitFormatBase } from 'shared/types/metric-template';
import { getLocalizedStringValue } from 'shared/utils/get-localized-string-value';

export const ProgressChart: React.FC = () => {
  const { i18n } = useTranslation();
  const { id } = useParams();
  const goal = useGoalById(id);
  const goalProgress = useGoalMetricProgress(id);
  const { completed, end } = useGoalCompletionCount(id);

  const openProgressDialog = useOpenGoalProgress();
  const openEditGoalDialog = useOpenEditGoalDialog();
  const completeGoal = useCompleteGoal();

  const unitFormat = goal?.metric?.unit
    ? goal.metric.unit.format ??
      `${metricUnitFormatBase} ${getLocalizedStringValue(goal.metric.unit.name, i18n.language)}`
    : undefined;

  const onCompleteGoal = () => !!goal && completeGoal(goal);

  const onEditGoalProgress = () => {
    if (!goal) {
      return;
    }

    if (!goal.metric) {
      openEditGoalDialog(goal, goal.id);
      return;
    }

    openProgressDialog(goal.id, goal.metric);
  };

  const onEditGoal = () => {
    if (!goal) {
      return;
    }

    openEditGoalDialog(goal, goal.id);
  };

  return (
    <ProgressChartSection
      target={end === Infinity ? undefined : end}
      lastEntryValue={completed}
      targetFormat={
        unitFormat
          ? getLocalizedStringValue(unitFormat, i18n.language)
          : undefined
      }
      onUpdateProgress={onEditGoalProgress}
      onShowHistory={onEditGoalProgress}
      onEditTarget={onEditGoal}
      onEditStartDate={onEditGoal}
      onEditDeadline={onEditGoal}
      onComplete={onCompleteGoal}
      isCompleted={!!goal?.completedAt}
      data={goalProgress}
      startDate={goal?.startDate}
      endDate={goal?.deadline}
      startValue={goal?.metric?.startValue}
    />
  );
};
