import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Colors,
  Sizes as ButtonSizes,
  Variants as ButtonVariants,
} from 'shared/components/ui/button';

import * as Styled from './feedback-dialog.style';

export type OfflineMessageProps = {
  onClose: () => void;
};

export const OfflineMessage: React.FC<OfflineMessageProps> = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <>
      <Styled.Header>
        <Typography variant="h5" component="h2">
          {t('dialogs.feedback.offline-message.title')}
        </Typography>
      </Styled.Header>
      <Styled.Body>
        <Typography variant="body2">
          {t('dialogs.feedback.offline-message.description')}
        </Typography>
      </Styled.Body>
      <Styled.Footer>
        <Button
          size={ButtonSizes.Medium}
          color={Colors.Primary}
          variant={ButtonVariants.Contained}
          onClick={onClose}
        >
          {t('dialogs.feedback.offline-message.close')}
        </Button>
      </Styled.Footer>
    </>
  );
};
