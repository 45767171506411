import React from 'react';
import { ReactDatePicker } from 'shared/lib/react-datepicker';

import * as Styled from './datepicker.style';
import { Header } from './header';

export type DatepickerProps = Omit<
  React.ComponentProps<typeof ReactDatePicker>,
  | 'renderCustomHeader'
  | 'showPopperArrow'
  | 'shouldCloseOnSelect'
  | 'fixedHeight'
>;

export const Datepicker: React.FC<DatepickerProps> = ({
  selected,
  popperPlacement = 'bottom-start',
  weekStartsOn,
  inline,
  ...props
}) => (
  <Styled.Container $inline={!!inline}>
    <Styled.Datepicker
      {...props}
      selected={selected}
      renderCustomHeader={(params) => (
        <Header currentDate={selected ?? undefined} {...params} />
      )}
      popperPlacement={popperPlacement}
      weekStartsOn={weekStartsOn}
      inline={inline}
      showPopperArrow={false}
      shouldCloseOnSelect
      fixedHeight
    />
  </Styled.Container>
);
