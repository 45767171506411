import { usePremiumFeatureAllowed } from 'shared/hooks/use-premium-feature-allowed';
import { PremiumFeature } from 'shared/types/premium-feature';

import { useHabits } from './use-habits';

export const useCreateHabitAllowed = () => {
  const habits = useHabits({
    uncompletedOnly: true,
    unFrozenOnly: true,
    unArchivedOnly: true,
  });

  return usePremiumFeatureAllowed(PremiumFeature.UnlimitedHabits, {
    totalAmountOfHabits: habits.length,
  });
};
