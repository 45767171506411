import { Unsubscribe } from 'firebase/firestore';
import { getMessaging, Messaging, onMessage } from 'firebase/messaging';

import { initApp } from './init-app';

let messaging: Messaging | undefined;
let unsubscribeMessage: Unsubscribe | undefined;

export const initPushNotifications = () => {
  if (!messaging) {
    messaging = getMessaging(initApp());
    unsubscribeMessage = onMessage(messaging, (payload) => {
      const notification = new Notification(
        payload.notification?.title ?? 'Griply',
        {
          body: payload.notification?.body,
          icon: '/favicon/mstile-150x150.png',
        },
      );

      notification.addEventListener('click', () => notification.close());
    });
  }

  return messaging;
};

export const clearPushNotificationsSubscriptions = () => {
  unsubscribeMessage?.();
  messaging = undefined;
};
