import { typographyToCss } from 'shared/lib/mui';
import styled from 'styled-components';

export const IconContainer = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 2.4rem;
  height: 2.4rem;

  border-radius: ${({ theme }) => theme.spacing(2)};
  background: ${({ theme }) =>
    theme.palette.primaryExtended.opacityBackgroundBackground};
  transition:
    color 200ms,
    background 200ms;

  font-size: 1.6rem;
  line-height: 62.5%;
  color: inherit;
`;

export const Container = styled.button`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};

  padding: ${({ theme }) => `0 ${theme.spacing(3)}`};
  height: 4.8rem;

  border: none;
  border-radius: ${({ theme }) => theme.spacing(2)};
  background: transparent;
  cursor: pointer;
  outline: none;
  transition: color 200ms;

  color: ${({ theme }) => theme.palette.primaryExtended.opacityBackgroundText};

  &:hover {
    ${IconContainer} {
      color: ${({ theme }) => theme.palette.primary.contrastText};
      background: ${({ theme }) => theme.palette.primary.main};
    }
  }
`;

export const PremiumLabel = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.overline)};
  display: block;

  padding: ${({ theme }) => `${theme.spacing(0.5)} ${theme.spacing(2)}`};

  background: ${({ theme }) => theme.palette.info.light};
  border-radius: ${({ theme }) => theme.spacing(6)};

  color: ${({ theme }) => theme.palette.info.main};
`;
