import { useDeleteTask } from 'features/tasks';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DeleteHabitDialog } from 'shared/dialogs/delete-habit';
import { Habit } from 'shared/types/habit';
import { RepeatingTask } from 'shared/types/repeating-task';

import {
  DeleteHabitContext,
  DeleteHabitContextProps,
} from './delete-habit-context';

export type DeleteHabitProviderProps = {
  children: React.ReactNode;
};

export const DeleteHabitProvider: React.FC<DeleteHabitProviderProps> = ({
  children,
}) => {
  const navigate = useNavigate();
  const [habit, setHabit] = useState<Habit | RepeatingTask>();
  const [redirectPath, setRedirectPath] = useState<string>();
  const {
    submit: deleteGoal,
    error,
    isSuccess,
    isLoading,
    retry,
    reset,
  } = useDeleteTask(habit?.parentIds?.[habit.parentIds.length - 1]);

  const openDialog = useCallback(
    (habit: Habit | RepeatingTask, path?: string) => {
      setHabit(habit);
      setRedirectPath(path);
    },
    [],
  );

  const closeDialog = useCallback(() => {
    reset();
    setHabit(undefined);
    setRedirectPath(undefined);
  }, [reset]);

  const submit = useCallback(() => {
    !!habit && deleteGoal(habit);
  }, [deleteGoal, habit]);

  const value = useMemo<DeleteHabitContextProps>(
    () => ({ onDeleteHabit: openDialog }),
    [openDialog],
  );

  useEffect(() => {
    if (isSuccess) {
      redirectPath && navigate(redirectPath);
      closeDialog();
    }
  }, [closeDialog, isSuccess, navigate, redirectPath]);

  return (
    <DeleteHabitContext.Provider value={value}>
      {children}
      <DeleteHabitDialog
        open={!!habit}
        name={habit?.name!}
        onClose={closeDialog}
        onSubmit={submit}
        onRetry={retry}
        isLoading={isLoading}
        isError={!!error}
      />
    </DeleteHabitContext.Provider>
  );
};
