import { useCallback, useState } from 'react';
import {
  ImageLocations,
  useUploadImageMutation,
} from 'shared/hooks/queries/use-upload-image-mutation';
import { Image } from 'shared/types/image';

export const useUploadImage = (
  location: ImageLocations,
  onFinish?: (image: Image) => void,
) => {
  const { mutateAsync, data, isPending, isError, reset } =
    useUploadImageMutation();
  const [temporaryUrl, setTemporaryUrl] = useState<string>();

  const uploadImage = useCallback(
    async (image: File) => {
      try {
        const id = window.crypto.randomUUID();
        setTemporaryUrl(URL.createObjectURL(image));
        const url = await mutateAsync({ location, image, id });
        onFinish?.({
          id,
          url,
          blurHash: null,
          metadata: null,
          createdAt: new Date(),
        });
      } catch (e) {
        // do nothing special, the error is also given by useUploadImageMutation
      }

      setTemporaryUrl(undefined);
    },
    [mutateAsync, location, onFinish],
  );

  return {
    uploadImage,
    imageUrl: data,
    temporaryUrl,
    isLoading: isPending,
    reset,
    isError,
  };
};

export { ImageLocations };
