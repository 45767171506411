import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import {
  LifeAreaFormFields,
  validationSchema,
} from 'shared/types/life-area-form';

export const useLifeAreaForm = (
  submit: (lifeAreaData: LifeAreaFormFields) => void,
  initialValues: LifeAreaFormFields,
) => {
  const { handleSubmit, ...formProps } = useForm<LifeAreaFormFields>({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
    defaultValues: initialValues,
  });

  const onSubmit = handleSubmit((data) => submit(data));

  return {
    ...formProps,
    onSubmit,
    errors: formProps.formState.errors,
    isValid: formProps.formState.isValid,
  };
};
