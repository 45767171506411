import { ID } from 'shared/types/id';
import { TodayTypeSections } from 'shared/types/page-sections';
import { SortingMode } from 'shared/types/sorting-mode';

import { getUser, getUserRef } from './get-user';
import { updateDocWithTimestamp } from './helpers';

export const updateTodayTypeTaskOrder = async (
  typeSection: TodayTypeSections,
  taskIds: ID[],
) => {
  const user = await getUser();
  // without a user we cannot update anything.
  if (!user) {
    return;
  }

  const userRef = await getUserRef();

  if (!userRef) {
    return;
  }

  updateDocWithTimestamp(userRef, {
    [`settings.todayGroupSorting.type.sorting.${typeSection}`]: taskIds,
    'settings.todoSortingMode': SortingMode.Custom,
  });
};
