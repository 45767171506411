import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing(2)};

  padding: ${({ theme }) => `${theme.spacing(5)} ${theme.spacing(6)}`};
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
`;
