import { useCallback } from 'react';
import { useStripeSessionUrlMutation } from 'shared/hooks/queries/use-stripe-session-url-mutation';

export const useRedirectToStripeUrl = () => {
  const { mutateAsync, isPending } = useStripeSessionUrlMutation();

  const redirect = useCallback(
    async ({ currency, type }: { currency: string; type: string }) => {
      window.location.href = await mutateAsync({ currency, type });
      // prevent loading-state to change when the browser is slow
      return new Promise(() => {});
    },
    [mutateAsync],
  );

  return {
    redirect,
    isLoading: isPending,
  };
};
