import { setDefaultOptions } from 'date-fns';
import { enUS } from 'date-fns/locale/en-US';
import { nl } from 'date-fns/locale/nl';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const useLocale = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    setDefaultOptions({ locale: i18n.language === 'nl-NL' ? nl : enUS });
  }, [i18n]);
};
