import { useVisionByLifeAreaIdQuery } from 'shared/hooks/queries/use-vision-by-life-area-id-query';
import { useUser } from 'shared/hooks/use-user';
import { ID } from 'shared/types/id';

export const useVisionByLifeAreaId = (id?: ID) => {
  const user = useUser();
  const { data } = useVisionByLifeAreaIdQuery(user?.uid, id);

  return data;
};
