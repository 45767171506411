import { createTheme, ThemeOptions } from '@mui/material';

import { breakpoints } from './breakpoints';
import { MuiBackdrop } from './components/mui-backdrop';
import { MuiCssBaseline } from './components/mui-baseline';
import { MuiDialog } from './components/mui-dialog';
import { MuiTab, MuiTabs } from './components/mui-tabs';
import { darkGradients, lightGradients } from './gradients';
import { darkPalette, lightPalette } from './palette';
import { darkShadowBox, lightShadowBox } from './shadow-box';
import { spacing } from './spacing';
import { typography } from './typography';

const baseTheme: ThemeOptions = {
  breakpoints,
  spacing,
  typography,
  components: {
    MuiCssBaseline,
    MuiDialog,
    MuiBackdrop,
    MuiTabs,
    MuiTab,
  },
};

export const darkTheme = createTheme({
  ...baseTheme,
  palette: darkPalette,
  shadowBox: darkShadowBox,
  gradients: darkGradients,
});

export const lightTheme = createTheme({
  ...baseTheme,
  palette: lightPalette,
  shadowBox: lightShadowBox,
  gradients: lightGradients,
});
