import { useCallback, useEffect, useRef } from 'react';
import { useDeleteTaskMutation } from 'shared/hooks/queries/use-delete-task-mutation';
import { useUpdateGoalTaskCountMutation } from 'shared/hooks/queries/use-update-goal-task-count-mutation';
import { useUpdateTaskMutation } from 'shared/hooks/queries/use-update-task-mutation';
import { useTrackEvents } from 'shared/hooks/use-track-events';
import {
  ActionEvents,
  taskTypeToTrackingTaskType,
} from 'shared/services/tracking';
import { ID } from 'shared/types/id';
import { Task } from 'shared/types/task';

import { useTaskById } from './use-task-by-id';

export const useDeleteTask = (parentTaskId?: ID) => {
  const deleteTaskRef = useRef<Task>();
  const parentTask = useTaskById(parentTaskId);

  const track = useTrackEvents();
  const {
    mutate: deleteTask,
    isSuccess,
    reset,
    error,
    isPending,
  } = useDeleteTaskMutation();
  const { mutate: updateGoalTaskCount } = useUpdateGoalTaskCountMutation();
  const { mutate: updateTask } = useUpdateTaskMutation();

  const submit = useCallback(
    (task: Task) => {
      deleteTaskRef.current = task;

      // filter the task that will be deleted from the parent task.
      if (parentTask) {
        updateTask({
          id: parentTask.id,
          childIds: parentTask.childIds?.filter((taskId) => taskId !== task.id),
        });
      }

      if (task.goalId) {
        updateGoalTaskCount({
          goalId: task.goalId,
          completedTaskCount: task.completedAt ? -1 : 0,
          taskCount: -1,
        });
      }

      task.childIds?.forEach((id) => deleteTask(id));

      deleteTask(task.id);
    },
    [deleteTask, parentTask, updateGoalTaskCount, updateTask],
  );

  const retry = useCallback(() => {
    const task = deleteTaskRef.current;
    if (task) {
      deleteTask(task.id);
    }
  }, [deleteTask]);

  const resetAll = useCallback(() => {
    reset();
    deleteTaskRef.current = undefined;
  }, [reset]);

  useEffect(() => {
    if (isSuccess && deleteTaskRef.current) {
      track(ActionEvents.TaskDelete, {
        type: taskTypeToTrackingTaskType[deleteTaskRef.current.type],
      });
    }
  }, [isSuccess, track]);

  return {
    submit,
    retry,
    reset: resetAll,
    error,
    isLoading: isPending,
    isSuccess,
  };
};
