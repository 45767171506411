import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Paths } from 'shared/routes';

import { HistoryContext } from './context';

export const useBackFunction = (fallbackPath: Paths) => {
  const { historyStack } = useContext(HistoryContext);
  const navigate = useNavigate();

  return useCallback(
    () =>
      historyStack.length > 1 ? window.history.back() : navigate(fallbackPath),
    [fallbackPath, historyStack.length, navigate],
  );
};
