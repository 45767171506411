import Typography from '@mui/material/Typography';
import { useLogin, useLoginWithApple } from 'features/user';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ThirdPartySignupButtons } from 'shared/components/connected/third-party-signup-buttons';
import { LoginHeader } from 'shared/components/ui/login-header';
import { useTrackPageVisitEvent } from 'shared/hooks/use-track-page-visit-event';
import { Paths } from 'shared/routes';
import { PageEvents } from 'shared/services/tracking';

import { LoginForm } from '../login-form';
import * as Styled from './login-view.style';

export const LoginView: React.FC = () => {
  const { t } = useTranslation();
  useTrackPageVisitEvent(PageEvents.Login, undefined);

  const {
    login: loginMailPassword,
    isLoading: isLoadingMailPassword,
    error: errorMailPassword,
  } = useLogin();
  const { login: loginApple, isLoading: isLoadingApple } = useLoginWithApple();

  const isLoading = isLoadingMailPassword || isLoadingApple;

  return (
    <>
      <LoginHeader />
      <Styled.Container>
        <Styled.Body>
          <Typography variant="h1" textAlign="center">
            {t('forms.login.title')}
          </Typography>

          <ThirdPartySignupButtons
            isLoading={isLoading}
            onAppleLogin={loginApple}
          />

          <Styled.Divider />

          <LoginForm
            onSubmit={loginMailPassword}
            isLoading={isLoading}
            error={errorMailPassword ?? undefined}
          />

          <Typography variant="overline" component="span" textAlign="center">
            {t('forms.login.no-account.question.label')}{' '}
            <Styled.Anchor to={Paths.Signup}>
              {t('forms.login.no-account.link.label')}
            </Styled.Anchor>
          </Typography>
        </Styled.Body>
      </Styled.Container>
    </>
  );
};
