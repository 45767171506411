import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LifeAreaRocket } from 'shared/assets/icons';
import { Button, Colors, Sizes, Variants } from 'shared/components/ui/button';
import { Icon } from 'shared/components/ui/icon';

import * as Styled from './premium-banner.style';

export type PremiumBannerProps = {
  title: string;
  description: string;
  isTrial?: boolean;
  onClick: () => void;
};

export const PremiumBanner: React.FC<PremiumBannerProps> = ({
  title,
  description,
  isTrial,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <Styled.Container>
      <Styled.Body onClick={onClick}>
        <Styled.IconContainer>
          <Icon icon={LifeAreaRocket} />
        </Styled.IconContainer>
        <Styled.Content>
          <Typography variant="h6" component="span">
            {title}
          </Typography>
          {description && (
            <Styled.Description>{description}</Styled.Description>
          )}
        </Styled.Content>

        <Button
          variant={Variants.Contained}
          size={Sizes.Medium}
          color={Colors.White}
          as="span"
        >
          {t(
            isTrial
              ? 'general.premium-banner.trial.label'
              : 'general.premium-banner.upgrade.label',
          )}
        </Button>
      </Styled.Body>
    </Styled.Container>
  );
};
