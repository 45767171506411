// import { AllTasks } from 'pages/all-tasks';
import { CompleteSignup } from 'pages/complete-signup';
import { ForgotPassword } from 'pages/forgot-password';
import { GoalDetail } from 'pages/goal-detail';
import { GoalTemplatesOverview } from 'pages/goal-templates-overview';
import { GoalsOverview, GoalsOverviewRoutes } from 'pages/goals-overview';
import { HabitsOverview, HabitsOverviewRoutes } from 'pages/habits-overview';
import { Inbox } from 'pages/inbox';
import { LifeAreaDetail, LifeAreaDetailRoutes } from 'pages/life-area-detail';
import { LifeAreaOverview } from 'pages/life-area-overview';
import { Loading } from 'pages/loading';
import { Login } from 'pages/login';
import { ResetPassword } from 'pages/reset-password';
import { Root } from 'pages/root';
import { Signup } from 'pages/signup';
import { Today } from 'pages/today';
import { Upcoming } from 'pages/upcoming';
import { VerifyEmail } from 'pages/verify-email';
import * as React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { loginLoader, userLoader } from './loaders/user-loader';
import { Paths } from './paths';

const router = createBrowserRouter([
  {
    path: Paths.Login,
    element: <Login />,
    loader: loginLoader,
  },
  {
    path: Paths.Signup,
    element: <Signup />,
    loader: loginLoader,
  },
  {
    path: Paths.ForgotPassword,
    element: <ForgotPassword />,
    loader: loginLoader,
  },
  {
    path: Paths.ResetPassword,
    element: <ResetPassword />,
    loader: loginLoader,
  },
  {
    path: Paths.CompleteSignup,
    element: <CompleteSignup />,
    loader: loginLoader,
  },
  {
    path: Paths.VerifyEmail,
    element: <VerifyEmail />,
  },
  {
    path: Paths.Home,
    element: <Root />,
    children: [
      {
        index: true,
        element: <Today />,
      },
      {
        path: Paths.Inbox,
        element: <Inbox />,
      },
      {
        path: Paths.Upcoming,
        element: <Upcoming />,
      },
      // {
      //   path: Paths.AllTasks,
      //   element: <AllTasks />,
      // },
      {
        path: Paths.GoalDetail,
        element: <GoalDetail />,
      },
      {
        path: Paths.Goals,
        element: <GoalsOverview />,
        children: GoalsOverviewRoutes,
      },
      {
        path: Paths.GoalTemplates,
        element: <GoalTemplatesOverview />,
      },
      {
        path: Paths.LifeAreas,
        element: <LifeAreaOverview />,
      },
      {
        path: Paths.LifeAreaDetail,
        element: <LifeAreaDetail />,
        children: LifeAreaDetailRoutes,
      },
      {
        path: Paths.Habits,
        element: <HabitsOverview />,
        children: HabitsOverviewRoutes,
      },
    ],
    loader: userLoader,
  },
]);

export const Routes: React.FC = () => (
  <RouterProvider router={router} fallbackElement={<Loading />} />
);
