import { useForceUpdateTask } from 'features/tasks';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RemoveHabitScheduleDialog } from 'shared/dialogs/remove-habit-schedule';
import { Habit } from 'shared/types/habit';
import { TaskType } from 'shared/types/task-base';

import {
  RemoveHabitScheduleContext,
  RemoveHabitScheduleContextProps,
} from './remove-habit-schedule-context';

export type RemoveHabitScheduleProviderProps = {
  children: React.ReactNode;
};

export const RemoveHabitScheduleProvider: React.FC<
  RemoveHabitScheduleProviderProps
> = ({ children }) => {
  const [habit, setHabit] = useState<Habit>();
  const {
    submit: forceUpdateTask,
    error,
    isLoading,
    isSuccess,
    retry,
    reset,
  } = useForceUpdateTask();

  const openDialog = useCallback((habit: Habit) => {
    setHabit(habit);
  }, []);

  const closeDialog = useCallback(() => {
    reset();
    setHabit(undefined);
  }, [reset]);

  const submit = useCallback(() => {
    !!habit &&
      forceUpdateTask({
        ...habit,
        type: TaskType.Task,
        schedules: null,
        hideOnTodo: null,
      });
  }, [forceUpdateTask, habit]);

  const value = useMemo<RemoveHabitScheduleContextProps>(
    () => ({ onRemoveHabitSchedule: openDialog }),
    [openDialog],
  );

  useEffect(() => {
    if (isSuccess) {
      setHabit(undefined);
      reset();
    }
  }, [isSuccess, reset]);

  return (
    <RemoveHabitScheduleContext.Provider value={value}>
      {children}
      <RemoveHabitScheduleDialog
        open={!!habit}
        name={habit?.name!}
        onClose={closeDialog}
        onSubmit={submit}
        onRetry={retry}
        isLoading={isLoading}
        isError={!!error}
      />
    </RemoveHabitScheduleContext.Provider>
  );
};
