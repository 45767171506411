import { useSortable } from '@dnd-kit/sortable';
import React from 'react';
import { DraggableOptions } from 'shared/types/draggable-types';

import { GoalTaskSection, OverwriteCollapseOptions } from './goal-task-section';
import * as Styled from './sortable-goal-task-section.style';

export type SortableGoalTaskSectionProps = Omit<
  React.ComponentProps<typeof GoalTaskSection>,
  'overwriteCollapse' | 'isDragging' | 'isHovered'
> & {
  isDisabled?: boolean;
};

export const SortableGoalTaskSection: React.FC<
  SortableGoalTaskSectionProps
> = ({ id, isDisabled, ...rest }) => {
  const {
    setNodeRef,
    attributes,
    listeners,
    isDragging,
    transform,
    transition,
    isOver,
  } = useSortable({
    id,
    data: { type: DraggableOptions.Goal },
    disabled: { droppable: isDisabled, draggable: isDisabled },
  });

  return (
    <Styled.SortableGoalTaskContainer
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      role="presentation"
      $transform={transform}
      $transition={transition}
    >
      <GoalTaskSection
        {...rest}
        id={id}
        overwriteCollapse={
          isDragging ? OverwriteCollapseOptions.Close : undefined
        }
        isDragging={isDragging}
        isHovered={isOver}
      />
    </Styled.SortableGoalTaskContainer>
  );
};
