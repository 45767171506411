import { useSortable } from '@dnd-kit/sortable';
import React from 'react';
import { FavoriteGoal } from 'shared/components/ui/favorite-goal';
import { FavoriteLifeArea } from 'shared/components/ui/favorite-life-area';
import { Goal } from 'shared/types/goal';
import { Favorite, FavoriteType } from 'shared/types/user-settings';

import * as Styled from './favorites-list.style';

export type FavoriteItemProps = {
  favoriteItem: Favorite;
  goals: Goal[];
  currentPathname: string;
  isMoving?: boolean;
};

export const FavoriteItem: React.FC<FavoriteItemProps> = ({
  favoriteItem,
  goals,
  currentPathname,
  isMoving,
}) => {
  const {
    setNodeRef,
    attributes,
    listeners,
    isDragging,
    transform,
    transition,
  } = useSortable({ id: favoriteItem.id });

  if (favoriteItem.type === FavoriteType.Task) {
    return null;
  }

  return (
    <Styled.DraggableContainer
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      role="presentation"
      $isDragging={isDragging}
      $transform={transform}
      $transition={transition}
    >
      {favoriteItem.type === FavoriteType.Goal && (
        <FavoriteGoal
          goal={favoriteItem.value}
          goals={goals}
          currentPath={currentPathname}
          isMoving={isMoving}
        />
      )}
      {favoriteItem.type === FavoriteType.LifeArea && (
        <FavoriteLifeArea
          lifeArea={favoriteItem.value}
          currentPath={currentPathname}
          isMoving={isMoving}
        />
      )}
    </Styled.DraggableContainer>
  );
};
