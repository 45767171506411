export enum PremiumFeature {
  UnlimitedGoals = 'unlimited-goals',
  SubGoals = 'sub-goals',
  UnlimitedHabits = 'unlimited-habits',
  UnlimitedLifeAreas = 'unlimited-life-areas',
  GoalUserPhoto = 'goal-user-photo',
  LifeAreaIcons = 'life-area-icons',
  CustomizeTemplateLifeAreas = 'customize-template-life-areas',
  HabitIcons = 'habit-icons',
  VisionUserPhoto = 'vision-user-photo',
}
