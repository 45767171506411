import React from 'react';
import { Goal } from 'shared/types/goal';
import { ID } from 'shared/types/id';
import { LifeArea } from 'shared/types/life-area';

import { GoalDataGridView } from './goal-data-grid-view';
import { useGoalOrdering } from './use-goal-ordering';

export type GoalDataGridProps = Omit<
  React.ComponentProps<typeof GoalDataGridView>,
  'rows' | 'onSortChange' | 'sortMode' | 'columnToSort' | 'hasDndRows'
> & {
  goals: Goal[];
  allGoals: Goal[];
  lifeAreas: LifeArea[];
  favorites: ID[];
};

export const GoalDataGrid: React.FC<GoalDataGridProps> = ({
  goals,
  allGoals,
  lifeAreas,
  columnTypes,
  favorites,
  ...rest
}) => {
  const { rows, sortMode, onSortChange, sortColumn } = useGoalOrdering({
    goals,
    allGoals,
    lifeAreas,
    columnTypes,
    favorites,
  });

  return (
    <GoalDataGridView
      rows={rows}
      columnTypes={columnTypes}
      sortMode={sortMode}
      onSortChange={onSortChange}
      columnToSort={sortColumn}
      {...rest}
    />
  );
};
