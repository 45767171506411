import { useArchiveGoal, useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import {
  useFavoriteIds,
  useGoalSorting,
  useUpdateGoalOrder,
} from 'features/user';
import React, { useMemo } from 'react';
import { SelfSortingGoalDataGrid } from 'shared/components/ui/goal-data-grid';
import { GridContainer } from 'shared/components/ui/grid-container';
import { useCompleteGoal } from 'shared/contexts/complete-goal';
import { useOpenDeleteGoalDialog } from 'shared/contexts/delete-goal';
import { useOpenEditGoalDialog } from 'shared/contexts/goal-form';
import { useToday } from 'shared/contexts/today';
import { ID } from 'shared/types/id';
import { GoalTabSortingKeys } from 'shared/types/user-settings';
import { sortItems } from 'shared/utils/sort-items';

import { EmptyCompleted } from './components/empty-completed';
import { TabPanel } from './components/tab-panel';
import { GoalsFilterOptions, goalsFilters } from './utils/goals-filter';

export const Completed: React.FC = () => {
  const today = useToday();
  const goals = useGoals();
  const lifeAreas = useLocalizedLifeAreas();
  const goalSorting = useGoalSorting(GoalTabSortingKeys.Completed);
  const favoriteIds = useFavoriteIds();

  const updateOrder = useUpdateGoalOrder(GoalTabSortingKeys.Completed);
  const openEditDialog = useOpenEditGoalDialog();
  const openDeleteDialog = useOpenDeleteGoalDialog();
  const completeGoal = useCompleteGoal();
  const archiveGoal = useArchiveGoal();

  const onEdit = (id: ID) => {
    const goal = goals.find((goal) => goal.id === id);
    !!goal && openEditDialog(goal, id);
  };
  const onComplete = (id: ID) => {
    const goal = goals.find((goal) => goal.id === id);
    !!goal && completeGoal(goal);
  };
  const onDelete = (id: ID) => {
    const goal = goals.find((goal) => goal.id === id);
    !!goal && openDeleteDialog(goal);
  };
  const onArchive = (id: ID) => {
    const goal = goals.find((goal) => goal.id === id);
    !!goal && archiveGoal(goal);
  };

  const filteredGoals = useMemo(
    () =>
      sortItems(
        goalsFilters[GoalsFilterOptions.Completed](goals, today).sort(
          (goalA, goalB) =>
            goalB.completedAt!.getTime() - goalA.completedAt!.getTime(),
        ),
        goalSorting,
        'id',
      ),
    [goalSorting, goals, today],
  );

  return (
    <GridContainer hasFlex1={!filteredGoals.length}>
      {!filteredGoals.length ? (
        <EmptyCompleted />
      ) : (
        <TabPanel role="tab-panel">
          <SelfSortingGoalDataGrid
            goals={filteredGoals}
            allGoals={goals}
            lifeAreas={lifeAreas}
            favorites={favoriteIds}
            onReorder={updateOrder}
            onEditGoal={onEdit}
            onCompleteGoal={onComplete}
            onArchiveGoal={onArchive}
            onDeleteGoal={onDelete}
          />
        </TabPanel>
      )}
    </GridContainer>
  );
};
