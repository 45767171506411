import {
  fetchAndActivate,
  getRemoteConfig,
  RemoteConfig,
} from 'firebase/remote-config';

import { initApp } from './init-app';
import { remoteAppConfigDefaults } from './remote-app-config-defaults';

let remoteConfig: RemoteConfig | undefined = undefined;

export const getRemoteAppConfig = async () => {
  if (!remoteConfig) {
    remoteConfig = getRemoteConfig(initApp());

    remoteConfig.defaultConfig = remoteAppConfigDefaults;

    if (import.meta.env.VITE_ENVIRONMENT !== 'production') {
      remoteConfig.settings.minimumFetchIntervalMillis = 1;
    }

    await fetchAndActivate(remoteConfig);
  }

  return remoteConfig;
};

export const clearRemoteAppConfig = () => {
  remoteConfig = undefined;
};
