import { doc } from 'firebase/firestore';
import { version } from 'package.json';
import { Locales } from 'shared/assets/localization';
import { ID } from 'shared/types/id';

import {
  CollectionOptions,
  getCollection,
  setDocWithTimestamp,
} from './helpers';

export const createPushToken = async (
  uid: ID,
  token: string,
  locale: Locales,
) => {
  if (!uid || !token) {
    return;
  }

  const newDoc = doc(getCollection(CollectionOptions.FcmTokens), token);
  const newDocData = {
    token,
    uid,
    type: 'desktop',
    platform: 'web', // todo: when electron is created, change this to electron
    version,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    language: locale,
  };

  setDocWithTimestamp(newDoc, newDocData);
};
