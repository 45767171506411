import { useQuery } from '@tanstack/react-query';
import { getEmailByOobcode } from 'shared/services/backend-api';

export const queryKey = 'get-email-by-oob-code';

export const useEmailByOobCodeQuery = (oobCode?: string) =>
  useQuery({
    queryKey: [queryKey, oobCode],
    queryFn: () => (oobCode ? getEmailByOobcode(oobCode) : null),
    enabled: !!oobCode,
  });
