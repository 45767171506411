import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Toggle } from 'shared/components/ui/toggle';

import * as Styled from './hide-on-todo-toggle.style';

export type HideOnTodoToggleProps = {
  value: boolean;
  onChange: (hideOnTodo: boolean) => void;
  disabled?: boolean;
  fullWidth?: boolean;
};

export const HideOnTodoToggle: React.FC<HideOnTodoToggleProps> = ({
  value,
  onChange,
  disabled,
  fullWidth,
}) => {
  const { t } = useTranslation();

  const onHideChange = () => !disabled && onChange(!value);

  return (
    <Styled.Label
      htmlFor="hide-on-todo-toggle"
      $fullWidth={!!fullWidth}
      $isDisabled={!!disabled}
    >
      <Typography variant="body2">
        {t('general.labels.habit.repeat.hide-on-todo.label')}
      </Typography>

      <Styled.Handle>
        <Toggle
          id="hide-on-todo-toggle"
          value="value"
          onChange={onHideChange}
          checked={!value}
        />
      </Styled.Handle>
    </Styled.Label>
  );
};
