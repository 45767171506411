import Typography from '@mui/material/Typography';
import { useCreateUnlimitedGoalsAllowed } from 'features/goals';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  EmptyCompletedGoalDark,
  EmptyCompletedGoalLight,
  Plus,
} from 'shared/assets/icons';
import { Button, Colors, Sizes, Variants } from 'shared/components/ui/button';
import { Icon } from 'shared/components/ui/icon';
import { useOpenCreateGoalDialog } from 'shared/contexts/goal-form';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { useActiveTheme } from 'shared/lib/mui';
import { ThemeModes } from 'shared/types/theme-modes';

import * as Styled from './empty-completed.style';

export const EmptyCompleted: React.FC = () => {
  const { t } = useTranslation();

  const [themeMode] = useActiveTheme();
  const createGoalAllowed = useCreateUnlimitedGoalsAllowed();

  const openPremiumDialog = useOpenPremiumDialog();
  const openCreateGoalDialog = useOpenCreateGoalDialog();

  const createGoal = () =>
    createGoalAllowed ? openCreateGoalDialog() : openPremiumDialog();

  return (
    <Styled.Container>
      <Styled.Content>
        <Styled.IconContainer>
          <Icon
            icon={
              themeMode === ThemeModes.Light
                ? EmptyCompletedGoalLight
                : EmptyCompletedGoalDark
            }
            width="20rem"
            height="15.4rem"
          />
        </Styled.IconContainer>
        <Typography variant="body2">
          {t('pages.goals.completed.empty.title')}
        </Typography>
        <Button
          onClick={createGoal}
          size={Sizes.Medium}
          variant={Variants.Contained}
          color={Colors.Primary}
          startIcon={Plus}
        >
          {t('pages.goals.completed.empty.add-button.label')}
        </Button>
      </Styled.Content>
    </Styled.Container>
  );
};
