import { useCreateHabitAllowed, useUpdateTask } from 'features/tasks';
import React from 'react';
import { HideOnTodoToggle } from 'shared/components/ui/hide-on-todo-toggle';
import {
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
} from 'shared/components/ui/popup-menu';
import { TaskTypeToggle } from 'shared/components/ui/task-type-toggle';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { Task } from 'shared/types/task';
import { TaskType } from 'shared/types/task-base';

export type HabitListProps = {
  task: Task;
  onClose: () => void;
};

export const HabitList: React.FC<HabitListProps> = ({ task, onClose }) => {
  const { submit: updateTask } = useUpdateTask();
  const isCreateHabitAllowed = useCreateHabitAllowed();
  const openPremiumDialog = useOpenPremiumDialog();

  const isChangeToHabitAllowed =
    isCreateHabitAllowed || task.type === TaskType.Habit;

  const onHabitType = (type: TaskType.Habit | TaskType.Repeating) => {
    if (!isChangeToHabitAllowed && type === TaskType.Habit) {
      openPremiumDialog();
      onClose();
      return;
    }

    updateTask({ id: task.id, type });
    onClose();
  };

  const onChangeHideOnTodo = (hideOnTodo: boolean) => {
    updateTask({ id: task.id, type: task.type as TaskType.Habit, hideOnTodo });
    onClose();
  };

  return (
    <PopupMenuList>
      <PopupMenuListItem>
        <PopupMenuButton as="span">
          <TaskTypeToggle
            type={task.type as TaskType.Habit | TaskType.Repeating}
            onChange={onHabitType}
            isPremium={!isChangeToHabitAllowed}
            fullWidth
          />
        </PopupMenuButton>
      </PopupMenuListItem>
      {task.type === TaskType.Habit && (
        <PopupMenuListItem>
          <PopupMenuButton as="span">
            <HideOnTodoToggle
              value={!!task.hideOnTodo}
              onChange={onChangeHideOnTodo}
              fullWidth
            />
          </PopupMenuButton>
        </PopupMenuListItem>
      )}
    </PopupMenuList>
  );
};
