import {
  useCreateLifeArea,
  useLifeAreaById,
  useUpdateLifeArea,
} from 'features/life-areas';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { LifeAreaFormDialog } from 'shared/dialogs/life-area-form';
import { usePremiumFeatureAllowed } from 'shared/hooks/use-premium-feature-allowed';
import { ID } from 'shared/types/id';
import { LifeArea, NewLifeArea } from 'shared/types/life-area';
import { LifeAreaFormFields } from 'shared/types/life-area-form';
import { PremiumFeature } from 'shared/types/premium-feature';

import {
  CreateLifeAreaContextProps,
  LifeAreaFormContext,
} from './life-area-form-context';

export type LifeAreaFormProviderProps = {
  children: React.ReactNode;
};

export const LifeAreaFormProvider: React.FC<LifeAreaFormProviderProps> = ({
  children,
}) => {
  // useOpenPremiumDialog is part of a higher level context which should be kept in mind (probably different setup needed)
  const openPremiumDialog = useOpenPremiumDialog();
  const isCustomIconAllowed = usePremiumFeatureAllowed(
    PremiumFeature.LifeAreaIcons,
    {},
  );

  const [dialogOpen, setDialogOpen] = useState(false);
  const [initialValues, setInitialValues] = useState<LifeAreaFormFields>();
  const [lifeAreaId, setLifeAreaId] = useState<ID>();
  const lifeArea = useLifeAreaById(lifeAreaId);
  const create = useCreateLifeArea();
  const update = useUpdateLifeArea();
  const { retry, isLoading, error, isSuccess, reset } = useMemo(
    () => (lifeAreaId ? update : create),
    [create, lifeAreaId, update],
  );

  const openDialog = useCallback(
    (initialValues?: LifeAreaFormFields, id?: ID) => {
      setLifeAreaId(id);
      setInitialValues(initialValues);
      setDialogOpen(true);
    },
    [setDialogOpen],
  );

  const closeDialog = useCallback(() => {
    reset();
    setInitialValues(undefined);
    setLifeAreaId(undefined);
    setDialogOpen(false);
  }, [setDialogOpen, reset]);

  const submitForm = useCallback(
    (values: LifeAreaFormFields) => {
      if (!values.name) {
        return;
      }

      if (lifeArea?.templateId) {
        create.submit({ ...lifeArea, archivedAt: new Date() });
      }

      if (lifeArea) {
        update.submit({
          ...values,
          id: lifeArea.id,
          templateId: null,
        } as LifeArea);
        return;
      }
      create.submit(values as NewLifeArea);
    },
    [create, lifeArea, update],
  );

  useEffect(() => {
    if (isSuccess) {
      closeDialog();
    }
  }, [closeDialog, isSuccess]);

  const value = useMemo<CreateLifeAreaContextProps>(
    () => ({ onCreateLifeArea: openDialog, onEditLifeArea: openDialog }),
    [openDialog],
  );

  return (
    <LifeAreaFormContext.Provider value={value}>
      {children}
      <LifeAreaFormDialog
        initialValues={initialValues}
        open={dialogOpen}
        onClose={closeDialog}
        onSubmit={submitForm}
        onRetry={retry}
        isLoading={isLoading}
        isError={!!error}
        isEdit={!!lifeAreaId}
        isCustomIconAllowed={isCustomIconAllowed}
        onPremium={openPremiumDialog}
      />
    </LifeAreaFormContext.Provider>
  );
};
