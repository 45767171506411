import {
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
} from '@floating-ui/react';
import React, { forwardRef, useEffect, useMemo } from 'react';
import { useForwardedRef } from 'shared/hooks/use-forwarded-ref';

import * as Styled from './tooltip.style';
import { Positions } from './types';

const positions = Object.values(Positions);

const getPositionsList = (position: Positions) => {
  const index = positions.indexOf(position);
  return index === -1
    ? positions
    : [...positions.slice(index + 1), ...positions.slice(0, index)];
};

export type TooltipProps = {
  label: string;
  position?: Positions;
  referenceElement: React.RefObject<HTMLElement>;
};

export const Tooltip: React.FC<TooltipProps> = forwardRef<
  HTMLDivElement,
  TooltipProps
>(({ label, position = Positions.Top, referenceElement }, ref) => {
  const containerRef = useForwardedRef(ref);
  const [visible, setVisible] = React.useState(false);

  const fallbackPositions = useMemo(
    () => getPositionsList(position),
    [position],
  );

  const { refs, floatingStyles, placement } = useFloating({
    elements: {
      reference: referenceElement.current,
    },
    placement: position,
    whileElementsMounted: autoUpdate,
    strategy: 'fixed',
    middleware: [
      offset({ mainAxis: 8, alignmentAxis: 8 }),
      flip({
        fallbackPlacements: fallbackPositions,
      }),
      shift({ padding: Styled.positioningOffset }),
    ],
  });

  useEffect(() => {
    refs.setFloating(containerRef.current);
  }, [containerRef, refs]);

  useEffect(() => {
    if (referenceElement.current) {
      referenceElement.current.onmouseenter = () => setVisible(true);
      referenceElement.current.onmouseleave = () => setVisible(false);
    }
  }, [referenceElement]);

  return (
    <Styled.Container
      ref={containerRef}
      style={floatingStyles}
      $placement={placement}
      $isVisible={visible}
    >
      <Styled.Label>{label}</Styled.Label>
    </Styled.Container>
  );
});
