import { FirestoreDataConverter } from 'firebase/firestore';
import { IconFormNames } from 'shared/assets/icons';
import {
  LifeArea,
  LocalizedLifeArea,
  NewLifeArea,
} from 'shared/types/life-area';

import { firebaseTimestampToDate } from './firebase-timestamp-to-date';

export const lifeAreaConverter: FirestoreDataConverter<
  LifeArea | LocalizedLifeArea
> = {
  fromFirestore: (snapshot) => {
    const data = snapshot.data();
    return {
      ...data,
      createdAt: firebaseTimestampToDate(data.createdAt),
      iconName: Object.values(IconFormNames).includes(data.iconName)
        ? data.iconName
        : IconFormNames.PlaceholderIcon,
    } as LifeArea | LocalizedLifeArea;
  },
  toFirestore: (lifeArea) => lifeArea,
};

export const newLifeAreaConverter = (lifeArea: NewLifeArea) => ({
  ...lifeArea,
  createdAt: new Date(),
  frozenAt: null,
  iconName: lifeArea.iconName ?? IconFormNames.PlaceholderIcon,
});
