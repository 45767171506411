import { deleteField, FieldValue } from 'firebase/firestore';
import { ID } from 'shared/types/id';
import { SortingMode } from 'shared/types/sorting-mode';
import { Timestamp } from 'shared/types/timestamp';
import { formatDateKey } from 'shared/utils/format-date-key';

import { getUser, getUserRef } from './get-user';
import { updateDocWithTimestamp } from './helpers';

export const updateTodoDaysOrder = async (
  datesOrder: { date: Timestamp; ids: ID[] }[],
) => {
  const user = await getUser();
  // without a user we cannot update anything.
  if (!user) {
    return;
  }

  const userRef = await getUserRef();

  if (!userRef) {
    return;
  }

  // todo: extract to a helper function and test
  const todoSorting = datesOrder.reduce<Record<string, ID[] | FieldValue>>(
    (acc, { date, ids }) => {
      // filter out the new ids of other entries, since tasks cannot be present in multiple days
      Object.keys(acc).forEach((key) => {
        acc[key] = Array.isArray(acc[key])
          ? (acc[key] as string[]).filter((oldIds) => !ids.includes(oldIds))
          : acc[key];

        // if there is nothing left in the array, set the key to be deleted
        if (Array.isArray(acc[key]) && !(acc[key] as string[]).length) {
          acc[key] = deleteField();
        }
      });

      // add the ids under the date that is given as parameter
      const dayKey = formatDateKey(date);

      if (!ids.length) {
        acc[dayKey] = deleteField();
        return acc;
      }

      acc[dayKey] = Array.from(new Set(ids));
      return acc;
    },
    structuredClone(user.settings?.todoSorting ?? {}),
  );

  Object.keys(todoSorting).forEach((key) => {
    const prefixedKey = `settings.todoSorting.${key}`;
    todoSorting[prefixedKey] = todoSorting[key];
    delete todoSorting[key];
  });

  updateDocWithTimestamp(userRef, {
    ...todoSorting,
    'settings.todoSortingMode': SortingMode.Custom,
  });
};
