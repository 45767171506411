import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

export const Container = styled.span<{ $fullWidth: boolean }>`
  position: relative;
  display: inline-block;
  max-width: 100%;
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};
`;

const ContentBase = css<{ $isStrikeThrough: boolean }>`
  ${({ theme }) => typographyToCss(theme.typography.body2)};
  padding: 0;
  max-width: 100%;

  border: none;
  background: transparent;
  outline: none;

  ${({ $isStrikeThrough }) =>
    $isStrikeThrough &&
    css`
      text-decoration: line-through;
    `};
`;

export const NameInput = styled.textarea<{
  $isStrikeThrough: boolean;
  $enabled: boolean;
}>`
  ${ContentBase};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  resize: none;
  overflow: hidden;

  background: transparent;
  color: inherit;

  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  &::-moz-placeholder {
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  &:-ms-input-placeholder {
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  ${({ $enabled }) =>
    !$enabled &&
    css`
      pointer-events: none;
    `};
`;

export const SizingSpan = styled.span<{ $isStrikeThrough: boolean }>`
  ${ContentBase};
  display: inline-block;
  visibility: hidden;
  white-space: pre-wrap;
`;
