import { useUpdateGoal } from 'features/goals';
import React, { useCallback, useMemo } from 'react';
import { GoalProgressDialog } from 'shared/dialogs/goal-progress';
import { useUser } from 'shared/hooks/use-user';
import { initialDateFormat } from 'shared/types/date-format-options';
import { GoalMetric } from 'shared/types/goal-metric';
import { GoalProgressFormFields } from 'shared/types/goal-progress-form';
import { ID } from 'shared/types/id';
import { initialWeekStartsOn } from 'shared/types/week-days';

import {
  GoalProgressContext,
  GoalProgressContextProps,
} from './goal-progress-context';

export type GoalProgressProviderProps = {
  children: React.ReactNode;
};

export const GoalProgressProvider: React.FC<GoalProgressProviderProps> = ({
  children,
}) => {
  const user = useUser();

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [metric, setMetric] = React.useState<GoalMetric>();
  const [goalId, setGoalId] = React.useState<ID>();

  const { submit, isLoading, error, retry } = useUpdateGoal();

  const openDialog = useCallback((goalId: ID, metric: GoalMetric) => {
    setMetric(metric);
    setGoalId(goalId);
    setDialogOpen(true);
  }, []);

  const closeDialog = useCallback(() => {
    setMetric(undefined);
    setGoalId(undefined);
    setDialogOpen(false);
  }, []);

  const submitForm = useCallback(
    (entries: GoalProgressFormFields['entries']) => {
      if (!goalId || !metric) {
        return;
      }

      submit({
        id: goalId,
        metric: {
          ...metric,
          entries,
        },
      });
      closeDialog();
    },
    [closeDialog, goalId, metric, submit],
  );

  const value = useMemo<GoalProgressContextProps>(
    () => ({
      onOpenGoalProgress: openDialog,
    }),
    [openDialog],
  );

  return (
    <GoalProgressContext.Provider value={value}>
      {children}
      <GoalProgressDialog
        open={dialogOpen}
        initialValues={{ entries: metric?.entries ?? [] }}
        unit={metric?.unit ?? undefined}
        target={metric?.targetValue ?? undefined}
        dateFormat={user?.settings?.dateFormat ?? initialDateFormat}
        weekStartsOn={user?.settings?.startOfWeek ?? initialWeekStartsOn}
        onClose={closeDialog}
        onSubmit={submitForm}
        onRetry={retry}
        isLoading={isLoading}
        isError={!!error}
      />
    </GoalProgressContext.Provider>
  );
};
