import { doc } from 'firebase/firestore';
import { ID } from 'shared/types/id';

import { lifeAreaConverter } from './converters/life-area';
import { CollectionOptions, getDatabase } from './helpers';

export const getLifeAreaById = async (id: ID) => {
  return doc(getDatabase(), CollectionOptions.LifeAreas, id).withConverter(
    lifeAreaConverter,
  );
};
