import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1.5)};

  width: 100%;
  padding: ${({ theme }) =>
    `${theme.spacing(1)} ${theme.spacing(1.5)} ${theme.spacing(1)} 0`};

  border: none;
  background: transparent;
  cursor: ${({ onClick }) => (!!onClick ? 'pointer' : 'inherit')};

  text-align: left;
  color: inherit;
`;

export const IconContainer = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 2.8rem;
  height: 2.8rem;

  border-radius: ${({ theme }) => theme.spacing(2)};
  transition: background 200ms;

  font-size: 1.4rem;

  color: ${({ theme }) => theme.palette.grey['500']};

  &:hover {
    background-color: ${({ theme }) => theme.palette.brown['50']};
  }
`;
