import { useMemo } from 'react';
import { usePremiumFeatureAllowed } from 'shared/hooks/use-premium-feature-allowed';
import { PremiumFeature } from 'shared/types/premium-feature';

import { useGoals } from './use-goals';

export const useCreateUnlimitedGoalsAllowed = () => {
  const goals = useGoals();

  const filteredGoals = useMemo(
    () => goals.filter(({ completedAt }) => !completedAt),
    [goals],
  );

  return usePremiumFeatureAllowed(PremiumFeature.UnlimitedGoals, {
    uncompletedAmountOfGoals: filteredGoals.length,
  });
};
