import { typographyToCss } from 'shared/lib/mui';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  margin-top: ${({ theme }) => theme.spacing(6)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => `0 ${theme.spacing(3)} 0 ${theme.spacing(6)}`};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};

  color: ${({ theme }) => theme.palette.text.primary};
`;

export const Description = styled.p`
  ${({ theme }) => typographyToCss(theme.typography.body2)};

  margin: 0;
  padding: 0;

  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const Interactions = styled.div``;
