import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormSelect } from 'shared/components/ui/form-select';
import { RadioInput } from 'shared/components/ui/radio-input';
import { WeekDays } from 'shared/types/week-days';
import { getWeekDays } from 'shared/utils/get-week-days';

import { getMonthDayOrdinals } from './get-month-day-ordinals';
import {
  parseMonthDaysFromRrule,
  parseMonthWeekDayFromRrule,
  setMonthDaysInRrule,
  setMonthSpecificDayInRrule,
} from './parse-rrule';
import * as Styled from './repeat-rrule-form.style';
import { DayType } from './types';

enum RadioOptions {
  SpecificDays = 'specific-days',
  SpecificDay = 'specific-day',
}

const monthDays: number[] = Array.from({ length: 31 }, (_, i) => i + 1);
const ordinals = [1, 2, 3, 4, 5, -1];

export type MonthsFormProps = {
  rrule: string;
  onChange: (monthValues: { value: string }) => void;
  weekStartsOn: WeekDays;
};

export const MonthsForm: React.FC<MonthsFormProps> = ({
  rrule,
  weekStartsOn,
  onChange,
}) => {
  const { t } = useTranslation();
  const selectedMonthDays = useMemo(
    () => parseMonthDaysFromRrule(rrule),
    [rrule],
  );
  const selectedWeekDay = useMemo(
    () => parseMonthWeekDayFromRrule(rrule),
    [rrule],
  );
  const initialOrdinalWeekDay = useMemo(
    () => getWeekDays(weekStartsOn)[0],
    [weekStartsOn],
  );

  const onChangeOption = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      value:
        e.target.value === RadioOptions.SpecificDay
          ? setMonthSpecificDayInRrule({
              weekDay: initialOrdinalWeekDay,
              ordinal: ordinals[0],
            })
          : setMonthDaysInRrule([]),
    });
  };

  const onChangeMonthDay = (e: React.MouseEvent) => {
    e.preventDefault();
    const dayValue = e.currentTarget.getAttribute('data-value');
    const dayNumber = parseInt(dayValue ?? '');

    const newSelectedMonthDays = selectedMonthDays.filter(
      (monthDay) => monthDay !== dayNumber,
    );
    if (newSelectedMonthDays.length === selectedMonthDays.length) {
      newSelectedMonthDays.push(dayNumber);
    }

    onChange({
      value: setMonthDaysInRrule(newSelectedMonthDays),
    });
  };

  const onChangeOrdinalDay = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!selectedWeekDay) {
      return;
    }

    onChange({
      value: setMonthSpecificDayInRrule({
        weekDay: e.target.value as DayType | WeekDays,
        ordinal: selectedWeekDay.ordinal,
      }),
    });
  };

  const onChangeOrdinalNumber = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!selectedWeekDay) {
      return;
    }

    onChange({
      value: setMonthSpecificDayInRrule({
        weekDay: selectedWeekDay.weekDay,
        ordinal: parseInt(e.target.value),
      }),
    });
  };

  const ordinalOptions = useMemo(
    () =>
      ordinals.map((ordinal) => ({
        label: t(
          `general.labels.habit.repeat.value.ordinal.specific-day.${ordinal}.label`,
        ),
        value: ordinal,
      })),
    [t],
  );

  const ordinalDayOptions = useMemo(
    () =>
      getMonthDayOrdinals(weekStartsOn).map((weekDay) => ({
        label: t(
          `general.labels.habit.repeat.specific-days.days.${weekDay}.label`,
        ),
        value: weekDay,
      })),
    [t, weekStartsOn],
  );

  const daysDescription = useMemo(() => {
    const lastSelectedDays = [29, 30, 31].filter((day) =>
      selectedMonthDays.includes(day),
    );

    if (!lastSelectedDays.length) {
      return;
    }

    if (lastSelectedDays.length > 1) {
      const lastEntry = lastSelectedDays[lastSelectedDays.length - 1];
      const firstEntries = lastSelectedDays.slice(0, -1);

      return t(
        'forms.habit.fields.repeat.months.specific-days.last-day.label',
        {
          days: [
            firstEntries
              .map((day) => t(`general.labels.${day}.ordinal`))
              .join(', '),
            t('general.labels.and'),
            t(`general.labels.${lastEntry}.ordinal`),
          ].join(' '),
          amount: lastEntry,
          count: lastSelectedDays.length,
        },
      );
    }

    return t('forms.habit.fields.repeat.months.specific-days.last-day.label', {
      days: t(`general.labels.${lastSelectedDays[0]}.ordinal`),
      amount: lastSelectedDays[0],
      count: 1,
    });
  }, [selectedMonthDays, t]);

  return (
    <Styled.FormContainer>
      <Styled.RadioContainer>
        <RadioInput
          id={RadioOptions.SpecificDays}
          name="month-options"
          checked={!selectedWeekDay}
          onChange={onChangeOption}
          value={RadioOptions.SpecificDays}
        >
          <Typography
            variant="body2"
            component="label"
            htmlFor={RadioOptions.SpecificDays}
          >
            {t('forms.habit.fields.repeat.months.specific-days.label')}
          </Typography>
        </RadioInput>

        <Styled.RadioBody $disabled={!!selectedWeekDay}>
          <Styled.MonthDayList>
            {monthDays.map((monthDay) => (
              <Styled.MonthDayListItem key={monthDay}>
                <Styled.DayButton
                  onClick={onChangeMonthDay}
                  data-value={monthDay}
                  $selected={selectedMonthDays.includes(monthDay)}
                  disabled={!!selectedWeekDay}
                  type="button"
                >
                  {monthDay}
                </Styled.DayButton>
              </Styled.MonthDayListItem>
            ))}
          </Styled.MonthDayList>
          {!!daysDescription && (
            <Styled.MonthDescription>{daysDescription}</Styled.MonthDescription>
          )}
        </Styled.RadioBody>
      </Styled.RadioContainer>

      <Styled.RadioContainer>
        <RadioInput
          id={RadioOptions.SpecificDay}
          name="month-options"
          checked={!!selectedWeekDay}
          onChange={onChangeOption}
          value={RadioOptions.SpecificDay}
        >
          <Typography
            variant="body2"
            component="label"
            htmlFor={RadioOptions.SpecificDay}
          >
            {t('forms.habit.fields.repeat.months.specific-day.label')}
          </Typography>
        </RadioInput>

        <Styled.RadioBody $disabled={false}>
          <Styled.TimesPerContainer>
            <FormSelect
              value={selectedWeekDay?.ordinal}
              options={ordinalOptions}
              onChange={onChangeOrdinalNumber}
              disabled={!selectedWeekDay}
              isSmall
            />

            <FormSelect
              value={selectedWeekDay?.weekDay}
              options={ordinalDayOptions}
              onChange={onChangeOrdinalDay}
              disabled={!selectedWeekDay}
              isSmall
            />
          </Styled.TimesPerContainer>
        </Styled.RadioBody>
      </Styled.RadioContainer>
    </Styled.FormContainer>
  );
};
