import { useQuery } from '@tanstack/react-query';
import { getPremiumCurrencies } from 'shared/services/backend-api';

export const queryKey = 'premium-currencies';

export const usePremiumCurrenciesQuery = () =>
  useQuery({
    queryKey: [queryKey],
    queryFn: async () => getPremiumCurrencies(),
  });
