import React from 'react';
import { IconFormNames } from 'shared/assets/icons';
import {
  FormIllustration,
  Sources,
} from 'shared/components/connected/form-illustration';
import { SortableContainer } from 'shared/components/ui/sortable-container';
import { ImageLocations } from 'shared/services/backend-api';
import { Image } from 'shared/types/image';

import { createImageId } from './create-image-id';
import * as Styled from './multi-image-upload.style';

export type MultiImageUploadProps = {
  images: Image[];
  onChange: (images: Image[]) => void;
  imageLocation: ImageLocations;
  sources?: Sources[];
  premiumSources?: Sources[];
  onPremium: () => void;
  hasDnd?: boolean;
};

export const MultiImageUpload: React.FC<MultiImageUploadProps> = ({
  images,
  onChange,
  imageLocation,
  sources = [Sources.Unsplash],
  premiumSources = [],
  onPremium,
  hasDnd,
}) => {
  const addImage = (image: Image | IconFormNames | null) =>
    !!image && typeof image !== 'string' && onChange([...images, image]);

  const FormImageContainer = hasDnd ? SortableContainer : React.Fragment;

  return (
    <Styled.Container>
      {images.map((image, index) => {
        const onChangeImage = (newImage: Image | IconFormNames | null) => {
          if (!newImage || typeof newImage === 'string') {
            onChange(images.toSpliced(index, 1));
            return;
          }
          onChange(images.toSpliced(index, 1, newImage));
        };
        const imageId = createImageId(image);
        const formImageContainerProps = hasDnd ? { id: imageId } : {};

        return (
          // @ts-ignore
          <FormImageContainer key={imageId} {...formImageContainerProps}>
            <FormIllustration
              onChange={onChangeImage}
              uploadLocation={imageLocation}
              value={image}
              sources={sources}
              premiumSources={premiumSources}
              onPremium={onPremium}
            />
          </FormImageContainer>
        );
      })}

      <FormIllustration
        value={null}
        onChange={addImage}
        uploadLocation={imageLocation}
        sources={sources}
        premiumSources={premiumSources}
        onPremium={onPremium}
        clearOnUploadFinish
      />
    </Styled.Container>
  );
};
