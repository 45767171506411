export const focusOnEndOfInput = (
  element: HTMLInputElement | HTMLTextAreaElement,
) => {
  element.focus();
  element.setSelectionRange(element.value.length, element.value.length);
};

export const focusOnEndOfEditableContent = (element: HTMLElement) => {
  element.focus();
  const selection = window.getSelection();
  selection?.getRangeAt(0).setStart(element, element.childNodes.length);
  selection?.getRangeAt(0).setEnd(element, element.childNodes.length);
};
