import { Components, Theme } from '@mui/material';

export const MuiBackdrop: Components<Omit<Theme, 'components'>>['MuiBackdrop'] =
  {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.backdrop.default,
      }),
    },
  };
