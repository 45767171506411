import {
  and,
  limit,
  orderBy,
  query,
  QueryFieldFilterConstraint,
  where,
} from 'firebase/firestore';
import { ID } from 'shared/types/id';

import { visionConverter } from './converters/vision';
import { CollectionOptions, getAuthentication, getCollection } from './helpers';

export type Options = {
  lifeAreaIds?: ID[];
  limit?: number;
};

export const getVisions = async (options: Options = {}) => {
  const auth = await getAuthentication();

  const collection = getCollection(CollectionOptions.Visions).withConverter(
    visionConverter,
  );

  const conditions = [
    where('roles.all', 'array-contains', auth.currentUser?.uid),

    !!options.lifeAreaIds?.length &&
      where('lifeAreaId', 'in', options.lifeAreaIds),
  ].filter(Boolean) as QueryFieldFilterConstraint[];

  const order = orderBy('createdAt', 'desc');

  if (options.limit) {
    return query(collection, and(...conditions), order, limit(options.limit));
  }

  return query(collection, and(...conditions), order);
};
