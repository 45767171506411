import {
  DocumentReference,
  serverTimestamp,
  UpdateData,
  updateDoc,
} from 'firebase/firestore';

export const updateDocWithTimestamp = <Data extends {}>(
  ref: DocumentReference,
  data: UpdateData<Data>,
) =>
  updateDoc(ref, {
    ...data,
    updatedAt: serverTimestamp(),
  });
