import { CircularProgress } from '@mui/material';
import { QueryNavLink } from 'shared/components/connected/query-nav-link';
import { typographyToCss } from 'shared/lib/mui';
import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const Fields = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const Label = styled.label`
  ${({ theme }) => typographyToCss(theme.typography.caption)};
`;

export const ErrorMessage = styled.p`
  ${({ theme }) => typographyToCss(theme.typography.overline)};

  margin: 0;
  padding: 0;

  color: ${({ theme }) => theme.palette.error.dark};
`;

export const Interactions = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const Anchor = styled(QueryNavLink)`
  ${({ theme }) => typographyToCss(theme.typography.overline)};
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const Loader = styled(CircularProgress)``;

export const TermsOfUse = styled.p`
  ${({ theme }) => typographyToCss(theme.typography.overline)};

  margin: 0;
  padding: 0;

  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const ExternalAnchor = styled.a`
  color: inherit;
`;
