import React, { useEffect, useRef } from 'react';

export const useForwardedRef = <T>(ref?: React.ForwardedRef<T>) => {
  const innerRef = useRef<T | null>(null);

  useEffect(() => {
    if (!ref) {
      return;
    }
    if (typeof ref === 'function') {
      ref(innerRef.current);
    } else {
      ref.current = innerRef.current;
    }
  }, [ref]);

  return innerRef;
};
