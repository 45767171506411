type Gradients = {
  premium: string;
  premiumSideways: string;
};

export const darkGradients: Gradients = {
  premium: 'linear-gradient(352.71deg, #543783 -5.01%, #634CA5 94.1%)',
  premiumSideways: 'linear-gradient(90deg, #543783 0%, #634CA5 100%)',
};

export const lightGradients: Gradients = {
  premium: 'linear-gradient(332.24deg, #DD9AAA 16.54%, #6245B6 86.59%)',
  premiumSideways: 'linear-gradient(90deg, #6245B6 0%, #DD9AAA 100%)',
};

declare module '@mui/material/styles' {
  interface Theme {
    gradients: Gradients;
  }

  interface ThemeOptions {
    gradients?: Gradients;
  }
}
