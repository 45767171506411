import { IconFormNames } from 'shared/assets/icons';
import { ID } from 'shared/types/id';
import { Image } from 'shared/types/image';
import { LifeArea } from 'shared/types/life-area';
import { Timestamp } from 'shared/types/timestamp';

export enum ColumnType {
  Name = 'name',
  Vision = 'vision',
  Score = 'life-score',
  ActiveGoals = 'active-goals',
  CompletedGoals = 'completed-goals',
  Empty = 'empty',
}

export type Column = {
  type: ColumnType;
  label?: string;
  sortable: boolean;
};

export type RowData = {
  id: ID;
  name: string;
  image: Image | null;
  visionDescription: string | null;
  color: string | null;
  iconName: IconFormNames;
  isTemplate: boolean;
  lifeScore: number;
  activeGoals: number;
  completedGoals: number;
  isDeletable: boolean;
  archivedAt: Timestamp | null;
  raw: LifeArea;
};
