import { getValue } from 'firebase/remote-config';
import { MetricTemplate } from 'shared/types/metric-template';

import { getRemoteAppConfig, RemoteAppConfigKeys } from './helpers';

export const getGoalMetricTemplates = async () => {
  const remoteAppConfig = await getRemoteAppConfig();

  try {
    const templates = JSON.parse(
      getValue(
        remoteAppConfig,
        RemoteAppConfigKeys.GoalMetricTemplates,
      ).asString(),
    ) as MetricTemplate[];
    return templates;
  } catch (e) {
    return [];
  }
};
