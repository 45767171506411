export enum SortingMode {
  DueDate = 'dueDate',
  CreatedAt = 'createdAt',
  Custom = 'custom',
  Priority = 'priority',
}

export enum GoalSortingMode {
  DueDate = 'dueDate',
  Custom = 'custom',
}

export enum TodayGroupBy {
  None = 'none',
  Goal = 'goal',
  LifeArea = 'life-area',
  Priority = 'priority',
  Type = 'type',
}

export enum OrderDirection {
  Asc = 'ascending',
  Desc = 'descending',
}
