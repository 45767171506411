import Typography from '@mui/material/Typography';
import React from 'react';

import * as Styled from './popup-menu.style';

export type PopupMenuTitleProps = {
  title: string;
};

export const PopupMenuTitle: React.FC<PopupMenuTitleProps> = ({ title }) => (
  <Styled.TitleContainer>
    <Typography variant="subtitle2">{title}</Typography>
  </Styled.TitleContainer>
);
