import { useGoals } from 'features/goals';
import {
  useOverdueHabits,
  useOverdueTasks,
  useTodaysHabits,
  useTodaysTasks,
} from 'features/tasks';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useToday } from 'shared/contexts/today';
import { useUser } from 'shared/hooks/use-user';
import { goalInboxID } from 'shared/types/orderable-section';
import { initialWeekStartsOn } from 'shared/types/week-days';
import { mapTaskToTaskCard } from 'shared/utils/mappers/map-task-to-task-card';
import { sortBySortingMode } from 'shared/utils/sort-by-sorting-mode';
import { sortItems } from 'shared/utils/sort-items';

export const useGoalTaskSections = () => {
  const { t } = useTranslation();
  const today = useToday();
  const user = useUser();

  const todos = useTodaysTasks();
  const habits = useTodaysHabits();
  const overdueTodos = useOverdueTasks();
  const overdueHabits = useOverdueHabits();
  const goals = useGoals();

  const tasks = useMemo(
    () => [...overdueTodos, ...overdueHabits, ...todos, ...habits],
    [overdueTodos, overdueHabits, todos, habits],
  );

  const taskCardTasks = useMemo(
    () =>
      tasks.map((task) =>
        mapTaskToTaskCard(task, {
          today,
          goals,
          t,
          showDue: true,
          hideTodayDue: true,
          weekStartsOn: user?.settings?.startOfWeek ?? initialWeekStartsOn,
        }),
      ),
    [goals, t, tasks, today, user?.settings?.startOfWeek],
  );

  const sections = useMemo(
    () =>
      [
        // sort goals before mapping to sections
        ...sortItems(
          goals,
          user?.settings?.goalTabSorting?.all.sorting ?? [],
          'id',
          // map to sections
        ).map(({ id }) => ({
          id,
          items: sortBySortingMode(
            taskCardTasks.filter(({ fields }) => fields.goalId === id),
            {
              sortingMode: user?.settings?.todoSortingMode,
              customOrder:
                user?.settings?.todayGroupSorting?.goal?.sorting?.[id] ?? [],
            },
          ),
        })),
        // add inbox as group as well
        {
          id: goalInboxID,
          items: sortBySortingMode(
            taskCardTasks.filter(({ fields }) => !fields.goalId),
            {
              sortingMode: user?.settings?.todoSortingMode,
              customOrder:
                user?.settings?.todayGroupSorting?.goal?.sorting?.['null'] ??
                [],
            },
          ),
        },
        // filter out the empty sections, except inbox
      ].filter(({ id, items }) => id === goalInboxID || !!items.length),
    [
      goals,
      taskCardTasks,
      user?.settings?.goalTabSorting?.all.sorting,
      user?.settings?.todayGroupSorting?.goal?.sorting,
      user?.settings?.todoSortingMode,
    ],
  );

  return {
    sections,
    taskCardTasks,
  };
};
