import { useGoals } from 'features/goals';
import {
  useLocalizedLifeAreas,
  useUpdateLifeAreaTaskOrder,
} from 'features/life-areas';
import React from 'react';
import { useParams } from 'react-router';
import { SelfSortingTaskSection } from 'shared/components/ui/task-section';
import { useActiveTaskId } from 'shared/contexts/task-detail';
import { useToday } from 'shared/contexts/today';

import { useLifeAreaTasks } from '../../hooks/use-life-area-tasks';

export const TasksOverview: React.FC = () => {
  const today = useToday();
  const { id } = useParams();

  const { taskCardTasks } = useLifeAreaTasks(id);

  const { updateOrder } = useUpdateLifeAreaTaskOrder(id);

  const selectedTask = useActiveTaskId();

  const goals = useGoals();
  const lifeAreas = useLocalizedLifeAreas();

  return (
    <SelfSortingTaskSection
      goals={goals}
      lifeAreas={lifeAreas}
      today={today}
      tasks={taskCardTasks}
      selectedTask={selectedTask}
      lifeAreaId={id}
      onReorder={updateOrder}
      hideLifeArea
      canCreateTask
    />
  );
};
