import { useCallback, useEffect, useRef } from 'react';

export const useDelayedCallback = <Callback extends (...args: any[]) => any>(
  callback: Callback,
  delay = 500,
) => {
  const timeoutRef = useRef<number>();

  const execute = useCallback(
    (...args: Parameters<Callback>) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = window.setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay],
  );

  useEffect(
    () => () => {
      timeoutRef.current && window.clearTimeout(timeoutRef.current);
    },
    [callback, delay],
  );

  return execute;
};
