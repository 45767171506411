import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import {
  GoalProgressFormFields,
  validationSchema,
} from 'shared/types/goal-progress-form';
import { isNumber } from 'shared/utils/is-number';

export const useGoalProgressForm = (
  submit: (entries: GoalProgressFormFields['entries']) => void,
  initialValues: GoalProgressFormFields,
) => {
  const { handleSubmit, ...formProps } = useForm<GoalProgressFormFields>({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues: initialValues,
  });

  const onSubmit = handleSubmit(({ entries, newEntry }) => {
    const updatedEntries = [...entries];
    if (newEntry && isNumber(newEntry.value) && newEntry.date) {
      const date = new Date(newEntry.date);
      // set the current time on the date, as it is missing from the calendar output
      date.setHours(
        new Date().getHours(),
        new Date().getMinutes(),
        new Date().getSeconds(),
        new Date().getMilliseconds(),
      );

      updatedEntries.push({
        id: window.crypto.randomUUID(),
        value: newEntry.value!,
        date,
      });
    }

    submit(
      updatedEntries.sort(
        (entryA, entryB) => entryB.date.getTime() - entryA.date.getTime(),
      ),
    );
  });

  return {
    ...formProps,
    onSubmit,
    errors: formProps.formState.errors,
    isValid: formProps.formState.isValid,
  };
};
