import { Locales } from 'shared/assets/localization';
import { TodayTypeSections } from 'shared/types/page-sections';
import { TaskPriorityOptions } from 'shared/types/task-base';
import { WeekDays } from 'shared/types/week-days';

import { DateFormatOptions } from './date-format-options';
import { Goal } from './goal';
import { ID } from './id';
import { LifeArea } from './life-area';
import { SortingMode, TodayGroupBy } from './sorting-mode';
import { Task } from './task';
import { ThemeModes } from './theme-modes';

export type Notifications = {
  taskReminder: {
    desktop?: boolean;
    mobile?: boolean;
  };
};

export enum FavoriteType {
  Task = 'task',
  Goal = 'goal',
  LifeArea = 'lifeArea',
}

export type FavoriteReference = {
  type: FavoriteType;
  id: ID;
};

export type Favorite =
  | {
      id: ID;
      type: FavoriteType.Task;
      value: Task;
    }
  | {
      id: ID;
      type: FavoriteType.Goal;
      value: Goal;
    }
  | {
      id: ID;
      type: FavoriteType.LifeArea;
      value: LifeArea;
    };

export enum GoalTabSortingKeys {
  Active = 'active',
  All = 'all',
  Completed = 'completed',
  Archived = 'archived',
}

export type Year = string;

export type GoalTabSortingOptions = GoalTabSortingKeys | Year;

export enum GoalTabSortingModes {
  Custom = 'custom',
}

export type GoalTabSorting = {
  mode: GoalTabSortingModes;
  sorting: ID[];
};

export type UserSettings = {
  language?: Locales;
  country?: string;
  dateFormat?: DateFormatOptions;
  startOfWeek?: WeekDays;
  notifications?: Notifications;
  themePreference?: {
    desktop?: ThemeModes;
    mobile?: ThemeModes;
  };
  favorites?: FavoriteReference[];
  isTaskNotificationEnabled?: boolean;
  isCheckInNotificationEnabled?: boolean;
  isHabitNotificationEnabled?: boolean;
  isAppLockEnabled?: boolean;
  todoSortingMode?: SortingMode;
  todayGroupBy?: TodayGroupBy;
  todayGroupSorting?: {
    goal?: {
      // goalId: [task.id]
      sorting: Record<ID, ID[]>;
    };
    lifeArea?: {
      // lifeAreaId: [task.id]
      sorting: Record<ID, ID[]>;
    };
    priority?: {
      // high: [task.id]
      sorting: Record<TaskPriorityOptions, ID[]>;
    };
    type?: {
      // high: [task.id]
      sorting: Record<TodayTypeSections, ID[]>;
    };
  };
  todoSorting?: Record<string, ID[]>; // { '2024-12-31': ['task-id-1'], ... }
  inboxSorting?: ID[];
  goalSorting?: ID[];
  habitSorting?: ID[];
  lifeAreaSorting?: ID[];
  goalTabSorting?: Record<GoalTabSortingOptions, GoalTabSorting>;
};
