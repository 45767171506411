import { useQuery } from '@tanstack/react-query';
import { downloadImage } from 'shared/services/backend-api';
import { ID } from 'shared/types/id';

export const queryKey = 'download-bucket-image';

export const useDownloadBucketImageQuery = (uid: ID = '', src?: string) => {
  const isBucketLink = !!src?.startsWith('gs://');

  const result = useQuery({
    queryKey: [queryKey, uid, src],
    queryFn: () => downloadImage(src!),
    enabled: !!uid && isBucketLink,
  });

  return {
    ...result,
    isBucketLink,
  };
};
