import styled, { css } from 'styled-components';

export const Container = styled.header<{ $isElectronMac: boolean }>`
  ${({ theme, $isElectronMac }) =>
    $isElectronMac &&
    css`
      -webkit-app-region: drag;
      height: ${theme.spacing(7)};
    `};
`;

export const Content = styled.div`
  display: flex;
  align-items: center;

  height: 8rem;
  padding: ${({ theme }) => `0 ${theme.spacing(6)}`};
`;
