import i18next from 'i18next';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from 'shared/hooks/use-user';
import { useLocale } from 'shared/lib/date-fns';
import { useThemeMode } from 'shared/lib/mui';

export const useUpdateAppToUserSettings = () => {
  useLocale();
  const { i18n } = useTranslation();
  const user = useUser();
  const [, setThemeMode, resetThemeMode] = useThemeMode();

  useEffect(() => {
    const userLanguage = user?.settings?.language;

    if (!userLanguage) {
      i18next.changeLanguage();
      return;
    }

    const userLanguageSupported = Object.keys(
      i18n.options.resources ?? {},
    ).find((supportedLanguage) => supportedLanguage === userLanguage);

    i18next.changeLanguage(
      userLanguageSupported
        ? userLanguage
        : (i18next.options.fallbackLng as string),
    );
  }, [i18n.options.resources, user]);

  // set user theme
  useEffect(() => {
    if (user?.settings?.themePreference?.desktop) {
      setThemeMode(user?.settings?.themePreference?.desktop);

      return () => resetThemeMode();
    }
  }, [resetThemeMode, setThemeMode, user?.settings?.themePreference?.desktop]);
};
