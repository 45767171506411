import React from 'react';
import { GridContainer } from 'shared/components/ui/grid-container';

import { CompletedHabitsList } from './components/completed-habits-list';
import { TabPanel } from './components/tab-panel';

export const Completed: React.FC = () => (
  <GridContainer hasFlex1>
    <TabPanel>
      <CompletedHabitsList />
    </TabPanel>
  </GridContainer>
);
