export enum TrackingMetricTemplates {
  CompletedTasks = 'completedTasks',
  SubgoalProgress = 'subgoalProgress',
  Money = 'money',
  Percentage = 'percentage',
  Weight = 'weight',
  Distance = 'distance',
  Time = 'time',
  Height = 'height',
  Pages = 'pages',
  Chapters = 'chapters',
  Custom = 'custom',
}
