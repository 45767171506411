import { rgba } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 5.6rem;
  height: 5.6rem;
`;

export const Button = styled.button<{ $rounded: boolean; $hasImage: boolean }>`
  margin: 0;
  padding: 0;

  width: 100%;
  height: 100%;

  border-radius: ${({ theme, $rounded }) =>
    $rounded ? '50%' : theme.spacing(2)};
  border: 1px solid ${({ theme }) => theme.palette.divider};
  background: ${({ theme }) => theme.palette.background.default};
  cursor: pointer;
  transition: background 200ms;

  &:hover {
    background: ${({ theme }) => theme.palette.brown['50']};
  }

  ${({ $hasImage }) =>
    $hasImage &&
    css`
      border: none;
    `};
`;

export const IllustrationContainer = styled.div<{ $rounded: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  border-radius: ${({ theme, $rounded }) =>
    $rounded ? '50%' : theme.spacing(2)};

  overflow: hidden;
`;

export const IconContainer = styled.span`
  color: ${({ theme }) => theme.palette.brown['200']};
  font-size: 2.4rem;
  line-height: 62.5%;
`;

export const ClearButton = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 1.8rem;
  height: 1.8rem;

  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  background: ${({ theme }) => theme.palette.background.default};
  transform: ${({ theme }) =>
    `translate(${theme.spacing(1.5)}, ${theme.spacing(-1.5)})`};
  box-shadow: ${({ theme }) =>
    `0 ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(-0.5)}
    ${rgba(theme.palette.common.black, 0.25)}`};
  cursor: pointer;

  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 1rem;
  line-height: 62.5%;
`;
