import { redirect } from 'react-router-dom';
import { Paths } from 'shared/routes';
import { getUser, signInWithToken } from 'shared/services/backend-api';

export const userLoader = async () => {
  const user = await getUser();

  if (!user) {
    throw redirect(Paths.Login);
  }

  return user;
};

export const loginLoader = async () => {
  await signInWithToken();
  const user = await getUser();

  if (user) {
    throw redirect(Paths.Home);
  }

  return user;
};
