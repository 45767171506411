import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  PushNotificationMessageRequestState,
  setPushNotificationMessage,
} from 'shared/services/local-storage';

import { queryKey as pushNotificationMessageQueryKey } from './use-push-notification-message-query';

export const useSetPushNotificationMessageMutation = () => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (state: PushNotificationMessageRequestState) => {
      setPushNotificationMessage(state);
      await client.invalidateQueries({
        queryKey: [pushNotificationMessageQueryKey],
      });
    },
  });
};
