import { ColumnType, RowData } from './types';

export const columnTypeOrderAscendingMap: Record<
  ColumnType,
  ((lifeAreaA: RowData, lifeAreaB: RowData) => number) | undefined
> = {
  [ColumnType.Name]: (lifeAreaA, lifeAreaB) =>
    lifeAreaA.name.localeCompare(lifeAreaB.name),
  [ColumnType.ActiveGoals]: (lifeAreaA, lifeAreaB) =>
    !lifeAreaB.activeGoals
      ? -1
      : !lifeAreaA.activeGoals
        ? 1
        : lifeAreaA.activeGoals - lifeAreaB.activeGoals,
  [ColumnType.Vision]: (lifeAreaA, lifeAreaB) =>
    !lifeAreaB.visionDescription
      ? -1
      : !lifeAreaA.visionDescription
        ? 1
        : lifeAreaA.visionDescription.localeCompare(
            lifeAreaB.visionDescription,
          ),
  [ColumnType.CompletedGoals]: (lifeAreaA, lifeAreaB) =>
    !lifeAreaB.completedGoals
      ? -1
      : !lifeAreaA.completedGoals
        ? 1
        : lifeAreaA.completedGoals - lifeAreaB.completedGoals,
  [ColumnType.Score]: (lifeAreaA, lifeAreaB) =>
    !lifeAreaB.lifeScore
      ? -1
      : !lifeAreaA.lifeScore
        ? 1
        : lifeAreaA.lifeScore - lifeAreaB.lifeScore,
  [ColumnType.Empty]: () => 0,
};

export const columnTypeOrderDescendingMap: Record<
  ColumnType,
  ((lifeAreaA: RowData, lifeAreaB: RowData) => number) | undefined
> = {
  [ColumnType.Name]: (lifeAreaA, lifeAreaB) =>
    lifeAreaB.name.localeCompare(lifeAreaA.name),
  [ColumnType.Vision]: (lifeAreaA, lifeAreaB) =>
    !lifeAreaB.visionDescription
      ? -1
      : !lifeAreaA.visionDescription
        ? 1
        : lifeAreaB.visionDescription.localeCompare(
            lifeAreaA.visionDescription,
          ),
  [ColumnType.ActiveGoals]: (lifeAreaA, lifeAreaB) =>
    !lifeAreaB.activeGoals
      ? -1
      : !lifeAreaA.activeGoals
        ? 1
        : lifeAreaB.activeGoals - lifeAreaA.activeGoals,
  [ColumnType.CompletedGoals]: (lifeAreaA, lifeAreaB) =>
    !lifeAreaB.completedGoals
      ? -1
      : !lifeAreaA.completedGoals
        ? 1
        : lifeAreaB.completedGoals - lifeAreaA.completedGoals,
  [ColumnType.Score]: (lifeAreaA, lifeAreaB) =>
    !lifeAreaB.lifeScore
      ? -1
      : !lifeAreaA.lifeScore
        ? 1
        : lifeAreaB.lifeScore - lifeAreaA.lifeScore,
  [ColumnType.Empty]: () => 0,
};
