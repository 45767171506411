import { useQuery } from '@tanstack/react-query';
import { getPushNotificationMessage } from 'shared/services/local-storage';

export const queryKey = 'push-notification-message';

export const usePushNotificationMessageQuery = () =>
  useQuery({
    queryKey: [queryKey],
    queryFn: () => getPushNotificationMessage(),
  });
