import { IconFormNames } from 'shared/assets/icons';
import { ID } from 'shared/types/id';
import { Image } from 'shared/types/image';
import { Timestamp } from 'shared/types/timestamp';

export enum ColumnType {
  Name = 'name',
  Deadline = 'deadline',
  Completion = 'completion',
  Tasks = 'tasks',
  LifeArea = 'life-area',
  Empty = 'empty',
}

export type Column = {
  type: ColumnType;
  label?: string;
  sortable: boolean;
};

export type RowData = {
  id: ID;
  name: string;
  iconName: IconFormNames | null;
  image: Image | null;
  deadline: Timestamp | null;
  completedTasks: number;
  tasks: number;
  uncompletedTasks: number;
  completion: number | null;
  lifeArea?: string;
  color?: string | null;
  completedAt?: Timestamp | null;
  archivedAt?: Timestamp | null;
  isFavorite: boolean;
};
