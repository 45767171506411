import { Task } from 'shared/types/task';
import { TaskType } from 'shared/types/task-base';
import { TaskCardTask } from 'shared/types/task-card-task';

export const mapTaskCardTaskToTask = (taskCardTask: TaskCardTask): Task => {
  const base = {
    ...taskCardTask.raw.task,
    type: taskCardTask.fields.type,
    name: taskCardTask.fields.name,
    endStrategy: {
      deadline: taskCardTask.fields.schedules?.length
        ? null
        : taskCardTask.fields.endStrategy?.deadline ?? null,
      completionCount:
        taskCardTask.fields.endStrategy?.completionCount ??
        taskCardTask.raw.task.endStrategy?.completionCount ??
        null,
    },
    reminderTime: taskCardTask.fields.reminderTime ?? null,
    priority: taskCardTask.fields.priority ?? null,
    goalId: taskCardTask.fields.goalId ?? null,
  };

  // todos
  if (base.type === TaskType.Task) {
    return {
      ...base,
      type: TaskType.Task,
      schedules: null,
      hideOnTodo: null,
    };
  }

  // repeating tasks
  if (base.type === TaskType.Repeating) {
    return {
      ...base,
      type: TaskType.Repeating,
      schedules: taskCardTask.fields.schedules!,
      hideOnTodo: null,
    };
  }

  // habits tasks
  return {
    ...base,
    type: TaskType.Habit,
    schedules: taskCardTask.fields.schedules!,
    hideOnTodo: base.hideOnTodo,
  };
};
