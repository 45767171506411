import { Goal } from 'shared/types/goal';
import { LifeArea } from 'shared/types/life-area';

import { RowData } from './types';

export const mapLifeAreaToRowData = (
  lifeArea: LifeArea,
  goals: Goal[],
): RowData => {
  const lifeAreaGoals = goals.filter(
    ({ lifeAreaId }) => lifeAreaId === lifeArea.id,
  );
  const completedGoals = lifeAreaGoals.filter(
    ({ completedAt }) => !!completedAt,
  ).length;
  const activeGoals = lifeAreaGoals.length - completedGoals;

  return {
    id: lifeArea.id,
    name: lifeArea.name,
    visionDescription: lifeArea.visionPreview?.description || null,
    color: lifeArea.color ?? null,
    iconName: lifeArea.iconName,
    isTemplate: !!lifeArea.templateId,
    archivedAt: lifeArea.archivedAt,
    image: lifeArea.visionPreview?.image || null,
    activeGoals: activeGoals,
    completedGoals: completedGoals,
    lifeScore: lifeArea.lifescanValue ?? 0,
    isDeletable: !lifeArea.templateId,
    raw: lifeArea,
  };
};
