import { parse } from 'date-fns';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  PopupMenu,
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
} from 'shared/components/ui/popup-menu';
import { ResizableInput } from 'shared/components/ui/resizable-input';
import { useClickOutside } from 'shared/hooks/use-click-outside';
import { isValidTime } from 'shared/utils/is-valid-time';
import { parseReminderTime } from 'shared/utils/parse-reminder-time';
import { processStringToHoursAndMinutes } from 'shared/utils/process-string-to-hours-and-minutes';

import * as Styled from './time-picker.style';

interface TimePickerProps {
  id?: string;
  options: string[];
  onChange: (value?: number) => void;
  value?: number | null;
  placeholder?: string;
  icon?: React.FC;
  showAsFormInput?: boolean;
}

export const TimePicker: React.FC<TimePickerProps> = ({
  id,
  options,
  onChange,
  value,
  placeholder,
  icon,
  showAsFormInput,
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [open, setOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleInputChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (!target.value.length) {
      // if empty, the user removed the time, so it doesn't want a Date
      onChange();
      setInputValue('');
      return;
    }

    // Process textual input to the format we expect
    const hoursAndMinutes = processStringToHoursAndMinutes(target.value);

    // if we have a date we execute the onChange
    if (isValidTime(hoursAndMinutes)) {
      const date = parse(hoursAndMinutes, 'HH:mm', new Date());
      onChange((date.getHours() * 60 + date.getMinutes()) * 60 * 1000);
      return;
    }

    // Update the input if it isn't a valid time format yet
    setInputValue(hoursAndMinutes);
  };

  const openMenu = () => setOpen(true);

  const closeMenu = () => setOpen(false);

  useEffect(() => {
    let valString = '';

    if (value) {
      valString = parseReminderTime(value);
    }

    setInputValue(valString);
  }, [value]);

  const onBlur = ({
    relatedTarget,
  }:
    | React.FocusEvent<HTMLInputElement>
    | React.FocusEvent<HTMLButtonElement>) => {
    if (
      !relatedTarget ||
      !containerRef.current?.contains(relatedTarget as Node)
    ) {
      closeMenu();
    }
  };

  useClickOutside(containerRef, closeMenu);

  return (
    <Styled.Container
      ref={containerRef}
      $hasValue={!!inputValue}
      $fullWidth={!!showAsFormInput}
    >
      <ResizableInput
        id={id}
        value={inputValue}
        onChange={handleInputChange}
        onFocus={openMenu}
        placeholder={placeholder}
        onBlur={onBlur}
        icon={icon}
        showAsFormInput={showAsFormInput}
        canClearEntry
      />

      {open && (
        <PopupMenu referenceElement={containerRef} position="bottom-start">
          <PopupMenuList>
            {options.map((option) => {
              const onItem = () => {
                const date = parse(option, 'HH:mm', new Date());
                onChange(
                  (date.getHours() * 60 + date.getMinutes()) * 60 * 1000,
                );
                closeMenu();
              };

              return (
                <PopupMenuListItem key={option}>
                  <PopupMenuButton
                    onClick={onItem}
                    onBlur={onBlur}
                    tabIndex={0}
                    type="button"
                  >
                    {option}
                  </PopupMenuButton>
                </PopupMenuListItem>
              );
            })}
          </PopupMenuList>
        </PopupMenu>
      )}
    </Styled.Container>
  );
};
