import { deleteDoc, doc, getDocs, writeBatch } from 'firebase/firestore';
import { ID } from 'shared/types/id';

import { getTasks } from './get-tasks';
import { CollectionOptions, getCollection, getDatabase } from './helpers';

export const deleteGoals = async (ids: ID[]) => {
  const goalCollection = getCollection(CollectionOptions.Goals);
  const batch = writeBatch(getDatabase());

  for (const id of ids) {
    const docRef = doc(goalCollection, id);

    const tasks = await getDocs(await getTasks({ goals: [id] }));

    tasks.forEach((task) => {
      batch.delete(task.ref);
    });

    deleteDoc(docRef);
  }

  batch.commit();
};
