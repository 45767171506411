import { Paths } from 'shared/routes';

const paths = Object.values(Paths).sort(
  (pathA, pathB) => pathA.length - pathB.length,
);

export type Options = {
  level?: number;
};

export const getPathByString = (str: string, { level }: Options = {}) => {
  const leveledPath = str
    .toLowerCase()
    .split('/')
    .slice(0, level !== undefined ? level + 1 : undefined);
  const exactPath = paths.find((path) => path === leveledPath.join('/'));

  return (
    exactPath ||
    paths.find((path) => {
      const splicedPath = path
        .split('/')
        .slice(0, level !== undefined ? level + 1 : undefined);

      if (splicedPath.length < leveledPath.length) {
        return false;
      }

      return splicedPath
        .slice(0)
        .every((part, i) => part.startsWith(':') || part === leveledPath[i]);
    })
  );
};
