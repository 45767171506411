import styled, { css } from 'styled-components';

export const Container = styled.span<{
  $hasValue: boolean;
  $fullWidth: boolean;
}>`
  position: relative;
  display: inline-block;
  box-sizing: border-box;

  line-height: 62.5%;

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
    `};
`;
