import { DndContext } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useGoals } from 'features/goals';
import { useFavorites, useReorderFavorites } from 'features/user';
import React from 'react';
import { useLocation } from 'react-router';
import { OverlayComponentWrapper } from 'shared/components/ui/overlay-component-wrapper';
import { SortableOverlay } from 'shared/components/ui/sortable-overlay';
import { useSortableItemsControls } from 'shared/hooks/use-sortable-items-controls';
import { useSortableSensors } from 'shared/hooks/use-sortable-sensors';

import { FavoriteItem } from './favorite-item';
import * as Styled from './favorites-list.style';

export const FavoritesList: React.FC = () => {
  const { pathname: currentPathname } = useLocation();
  const goals = useGoals();
  const favorites = useFavorites();

  const onReorder = useReorderFavorites();
  const sensors = useSortableSensors();
  const {
    items,
    activeItem,
    handleDragStart,
    handleDragOver,
    handleDragCancel,
    handleDragEnd,
  } = useSortableItemsControls({
    items: favorites,
    onDragEnd: onReorder,
  });

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
      sensors={sensors}
    >
      <SortableContext
        items={items.map(({ id }) => id)}
        strategy={verticalListSortingStrategy}
      >
        <Styled.List>
          {items.map((favorite) => (
            <Styled.ListItem key={favorite.id}>
              <FavoriteItem
                favoriteItem={favorite}
                goals={goals}
                currentPathname={currentPathname}
              />
            </Styled.ListItem>
          ))}
        </Styled.List>

        <SortableOverlay>
          {!!activeItem && (
            <OverlayComponentWrapper>
              <FavoriteItem
                isMoving
                favoriteItem={activeItem}
                goals={goals}
                currentPathname={currentPathname}
              />
            </OverlayComponentWrapper>
          )}
        </SortableOverlay>
      </SortableContext>
    </DndContext>
  );
};
