import styled, { css } from 'styled-components';

export const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: ${({ theme }) => theme.spacing(2)};
  list-style: none;

  margin: 0;
  padding: ${({ theme }) => theme.spacing(2)};
`;

export const ListItem = styled.li``;

export const Button = styled.button<{ $selected: boolean }>`
  display: block;

  margin: 0;
  padding: ${({ theme }) => theme.spacing(1.5)};

  cursor: pointer;
  border: none;
  border-radius: ${({ theme }) => theme.spacing(2)};
  background: transparent;

  color: inherit;
  font-size: 2rem;
  line-height: 62.5%;

  ${({ $selected, theme }) =>
    $selected &&
    css`
      background: ${theme.palette.primary.light};
      color: ${theme.palette.primary.main};
    `}
`;
