import { createContext } from 'react';
import { Goal } from 'shared/types/goal';

export type DeleteGoalContextProps = {
  onDeleteGoal: (goal: Goal, redirectPath?: string) => void;
};

export const DeleteGoalContext = createContext<DeleteGoalContextProps>({
  onDeleteGoal: () => {},
});
