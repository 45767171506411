import { CircularProgress } from '@mui/material';
import { typographyToCss } from 'shared/lib/mui';
import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(5)};
`;

export const Fields = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(4)};
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const Label = styled.label`
  ${({ theme }) => typographyToCss(theme.typography.subtitle2)};
`;

export const ErrorMessage = styled.p`
  ${({ theme }) => typographyToCss(theme.typography.overline)};

  margin: 0;
  padding: 0;

  color: ${({ theme }) => theme.palette.error.dark};
`;

export const Interactions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const Loader = styled(CircularProgress)``;
