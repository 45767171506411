import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import {
  CompleteSignupFormFields,
  validationSchema,
} from 'shared/types/complete-signup-form';

export const useCompleteSignupForm = (
  submit: (forgotPasswordData: CompleteSignupFormFields) => void,
) => {
  const { handleSubmit, ...formProps } = useForm<CompleteSignupFormFields>({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
  });

  const onSubmit = handleSubmit((data) => submit(data));

  return {
    ...formProps,
    onSubmit,
    errors: formProps.formState.errors,
    isValid: formProps.formState.isValid,
  };
};
