import styled from 'styled-components';

export const Container = styled.span`
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};

  width: 100%;

  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const Bar = styled.span<{ $percentage: number }>`
  position: relative;
  flex: 1;
  display: inline-block;
  height: 0.8rem;

  border-radius: ${({ theme }) => theme.spacing(4)};
  background: ${({ theme }) => theme.palette.primary.light};

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: ${({ $percentage }) => `${100 - $percentage}%`};

    transition: right 200ms;

    background: ${({ theme }) => theme.palette.primary.main};
    border-radius: ${({ theme }) => theme.spacing(4)};
  }
`;
