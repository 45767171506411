import { useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import { useArchiveTask, useCompleteTask, useHabits } from 'features/tasks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TitledCollapse } from 'shared/components/ui/titled-collapse';
import { useOpenDeleteHabitDialog } from 'shared/contexts/delete-habit';
import { useOpenEditHabitDialog } from 'shared/contexts/habit-form';
import { useUser } from 'shared/hooks/use-user';
import { ID } from 'shared/types/id';
import { initialWeekStartsOn } from 'shared/types/week-days';

import { HabitDetailCard } from '../habit-detail-card';
import * as Styled from './archived-habits-list.style';

export const ArchivedHabitsList: React.FC = () => {
  const { t } = useTranslation();
  const user = useUser();
  const habits = useHabits({ archivedOnly: true });
  const completeHabit = useCompleteTask();
  const deleteHabit = useOpenDeleteHabitDialog();
  const archiveHabit = useArchiveTask();
  const editHabit = useOpenEditHabitDialog();
  const goals = useGoals();
  const lifeAreas = useLocalizedLifeAreas();

  const onComplete = (id: ID) => {
    const habit = habits.find((habit) => habit.id === id);
    if (habit) {
      completeHabit(habit);
    }
  };

  const onDelete = (id: ID) => {
    const habit = habits.find((habit) => habit.id === id);
    if (habit) {
      deleteHabit(habit);
    }
  };

  const onArchive = (id: ID) => {
    const habit = habits.find((habit) => habit.id === id);
    if (habit) {
      archiveHabit(habit);
    }
  };

  const onEdit = (id: ID) => {
    const habit = habits.find((habit) => habit.id === id);
    if (habit && !habit.frozenAt) {
      editHabit(habit, id);
    }
  };

  if (!habits.length) {
    return null;
  }

  return (
    <Styled.Container>
      <TitledCollapse
        title={t('pages.habits.archived-section.title')}
        titleCount={habits.length}
      >
        {habits.map(
          ({ id, iconName, name, schedules, goalId, completedAt }) => (
            <HabitDetailCard
              key={id}
              id={id}
              icon={iconName}
              name={name}
              schedules={schedules}
              goalId={goalId}
              goals={goals}
              lifeAreas={lifeAreas}
              weeks={[]}
              weekStartsOn={user?.settings?.startOfWeek ?? initialWeekStartsOn}
              onCompleteHabit={onComplete}
              onArchiveHabit={onArchive}
              onDeleteHabit={onDelete}
              onEditHabit={onEdit}
              isCompleted={!!completedAt}
              isArchived
            />
          ),
        )}
      </TitledCollapse>
    </Styled.Container>
  );
};
