import React, { useEffect, useMemo, useState } from 'react';
import { useActivePath } from 'shared/hooks/use-active-path';
import { Paths } from 'shared/routes';

import { HistoryContext, HistoryContextProps } from './context';

type HistoryProviderProps = {
  children: React.ReactNode;
};

export const HistoryProvider: React.FC<HistoryProviderProps> = ({
  children,
}) => {
  const [stack, setStack] = useState<Paths[]>([]);

  const activePath = useActivePath();

  useEffect(() => {
    if (activePath) {
      // strict mode has influence on this; first render of the app will add the path twice
      setStack((oldStack) => [...oldStack, activePath]);
    }
  }, [activePath]);

  const value = useMemo<HistoryContextProps>(
    () => ({ historyStack: stack }),
    [stack],
  );

  return (
    <HistoryContext.Provider value={value}>{children}</HistoryContext.Provider>
  );
};
