import Typography from '@mui/material/Typography';
import { useEmailByOobCode, useResetPasswordAndLogin } from 'features/user';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FullLogoDark, FullLogoLight } from 'shared/assets/icons';
import { useTrackPageVisitEvent } from 'shared/hooks/use-track-page-visit-event';
import { useBrowserPrefersDarkMode } from 'shared/lib/mui';
import { QueryParamOptions, useQueryParam } from 'shared/lib/react-router-dom';
import { PageEvents } from 'shared/services/tracking';
import { CompleteSignupFormFields } from 'shared/types/complete-signup-form';

import * as Styled from './complete-signup.style';
import { CompleteSignupForm } from './components/complete-signup-form';

export const CompleteSignup: React.FC = () => {
  useTrackPageVisitEvent(PageEvents.CompleteSignup, undefined);
  const { t } = useTranslation();
  const prefersDarkMode = useBrowserPrefersDarkMode();

  const [code] = useQueryParam(QueryParamOptions.OobCode);
  const email = useEmailByOobCode(code);

  const { resetPasswordAndLogin, isLoading, error } =
    useResetPasswordAndLogin();

  const submitForm = ({ password, name }: CompleteSignupFormFields) =>
    !!code && resetPasswordAndLogin(code, { password, name });

  return (
    <>
      <Styled.Container>
        <Styled.Body>
          <Styled.LogoContainer>
            {prefersDarkMode ? <FullLogoDark /> : <FullLogoLight />}
          </Styled.LogoContainer>

          <Typography variant="h1" textAlign="center">
            {t('forms.complete-signup.title')}
          </Typography>

          <Styled.BodyContent>
            <CompleteSignupForm
              email={email ?? ''}
              onSubmit={submitForm}
              isLoading={isLoading}
              error={error ?? undefined}
            />
          </Styled.BodyContent>
        </Styled.Body>
      </Styled.Container>
    </>
  );
};
