import { ID } from 'shared/types/id';

export type ItemBase = { id: ID; parentIds?: ID[] | null };

export const groupItems = <Item extends ItemBase>(items: Item[]): Item[] => {
  const idToItem = new Map(items.map((item) => [item.id, item]));
  const reordered: Item[] = [];
  const visited = new Set<string>();

  items.forEach((item) => {
    const place = (item: Item) => {
      if (visited.has(item.id)) {
        return;
      }
      visited.add(item.id);

      const parentId = item.parentIds?.slice(-1)[0];
      if (parentId && idToItem.has(parentId)) {
        place(idToItem.get(parentId)!);
      }

      reordered.push(item);
    };
    place(item);
  });

  return reordered;
};
