import { useMemo } from 'react';
import { useTasksQuery } from 'shared/hooks/queries/use-tasks-query';
import { useUser } from 'shared/hooks/use-user';

export const useInboxTasks = () => {
  const user = useUser();
  const { data } = useTasksQuery(user?.uid, {
    uncompletedOnly: true,
    noGoalsOnly: true,
    noScheduleOnly: true,
  });

  return useMemo(
    () => data?.filter(({ parentIds }) => !parentIds?.length) ?? [],
    [data],
  );
};
