import React, { useEffect, useRef, useState } from 'react';

import * as Styled from './collapse.style';

export type CollapseProps = {
  isOpen?: boolean;
  children: React.ReactNode;
};

export const Collapse: React.FC<CollapseProps> = ({
  isOpen = false,
  children,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [renderChildren, setRenderChildren] = useState(isOpen);
  const [contentHeight, setContentHeight] = useState<number | 'auto'>(
    isOpen ? 'auto' : 0,
  );

  useEffect(() => {
    if (isOpen) {
      setRenderChildren(true);
      // after animation set to auto
      setTimeout(() => {
        setContentHeight('auto');
      }, 250);
    } else {
      setContentHeight(contentRef.current?.scrollHeight ?? 0);
      setTimeout(() => {
        setContentHeight(0);
      }, 10);
      setTimeout(() => {
        setRenderChildren(false);
      }, 250);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && renderChildren) {
      setContentHeight(contentRef.current?.scrollHeight ?? 0);
    }
  }, [isOpen, renderChildren]);

  return (
    <Styled.Container $maxHeight={contentHeight}>
      {renderChildren && (
        <Styled.Content ref={contentRef}>{children}</Styled.Content>
      )}
    </Styled.Container>
  );
};
