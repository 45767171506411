import { MetricEntry } from 'shared/types/goal-metric';
import { Timestamp } from 'shared/types/timestamp';
import * as yup from 'yup';

export type ProgressForm = {
  newEntry?: { value?: number | null; date?: Timestamp | null } | null;
  entries: MetricEntry[];
};

export const validationSchema: yup.ObjectSchema<ProgressForm> = yup.object({
  newEntry: yup
    .object({
      value: yup
        .number()
        .nullable()
        .transform((value, originalValue) =>
          String(originalValue).trim() === '' ? null : value,
        ),
      date: yup
        .date()
        .nullable()
        .transform((value, originalValue) =>
          String(originalValue).trim() === '' ? null : value,
        ),
    })
    .nullable(),
  entries: yup
    .array()
    .of(
      yup
        .object({
          id: yup.string().required(),
          value: yup.number().required(),
          date: yup.date().required(),
        })
        .required(),
    )
    .required(),
});

export type GoalProgressFormFields = yup.InferType<typeof validationSchema>;
