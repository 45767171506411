import { getDownloadURL, getStorage, ref } from 'firebase/storage';

import { initApp } from './init-app';

export const downloadFile = (bucketPath: string) => {
  const storage = getStorage(initApp());
  const fileRef = ref(storage, bucketPath);

  return getDownloadURL(fileRef);
};
