import { useGoals } from 'features/goals';
import { useHabits, useRepeatingTasks } from 'features/tasks';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useToday } from 'shared/contexts/today';
import { useUser } from 'shared/hooks/use-user';
import { isBetween } from 'shared/lib/date-fns';
import { ID } from 'shared/types/id';
import {
  DateSection,
  DateSectionOptions,
} from 'shared/types/orderable-section';
import { SortingMode } from 'shared/types/sorting-mode';
import { Task } from 'shared/types/task';
import { TaskType } from 'shared/types/task-base';
import { initialWeekStartsOn } from 'shared/types/week-days';
import { getTaskDueDate } from 'shared/utils/get-task-due';
import { mapTaskToTaskCard } from 'shared/utils/mappers/map-task-to-task-card';
import { orderTaskCardTasksByDate } from 'shared/utils/order-task-card-tasks-by-date';

import { useUpcomingSections } from './use-upcoming-sections';
import { useUpcomingTasks } from './use-upcoming-tasks';

export const useUpcomingTaskSections = (weekStartsOn = initialWeekStartsOn) => {
  const { t } = useTranslation();
  const today = useToday();

  const todos = useUpcomingTasks();
  const repeatingTasks = useRepeatingTasks({
    uncompletedOnly: true,
    unArchivedOnly: true,
    unFrozenOnly: true,
  });
  const habits = useHabits({
    uncompletedOnly: true,
    unArchivedOnly: true,
    unFrozenOnly: true,
  });
  const goals = useGoals();
  const sections = useUpcomingSections();
  const user = useUser();

  const tasks = useMemo(
    () =>
      [...todos, ...habits, ...repeatingTasks].filter(
        (task) => task.type !== TaskType.Habit || !task.hideOnTodo,
      ),
    [todos, habits, repeatingTasks],
  );

  const taskCardTasks = useMemo(
    () =>
      tasks.map((task) =>
        mapTaskToTaskCard(task, {
          today,
          goals,
          t,
          showDue: true,
          hideTodayDue: true,
          weekStartsOn,
        }),
      ),
    [goals, t, tasks, today, weekStartsOn],
  );

  const tasksMap = useMemo(
    () =>
      tasks.reduce<Record<ID, Task>>((acc, task) => {
        acc[task.id] = task;
        return acc;
      }, {}),
    [tasks],
  );

  const filledSections = useMemo(() => {
    // fill the sections with the tasks we got
    const filled = tasks.reduce<DateSection[]>((acc, task) => {
      const dueDate = getTaskDueDate(task, { today, weekStartsOn });

      if (!dueDate) {
        return acc;
      }

      const sectionIndex = acc.findIndex(({ startDate, endDate }) =>
        isBetween(dueDate, startDate, endDate),
      );
      if (sectionIndex < 0) {
        return acc;
      }

      acc[sectionIndex].items.push(
        mapTaskToTaskCard(task, {
          today,
          goals,
          t,
          showDue: true,
          hideTodayDue: true,
          hideFutureDue:
            acc[sectionIndex].type === DateSectionOptions.Day ||
            task.type !== TaskType.Task,
          weekStartsOn,
        }),
      );

      return acc;
    }, structuredClone(sections));

    // correct the order that the tasks are in
    const order =
      user?.settings?.todoSortingMode === SortingMode.Custom
        ? user?.settings?.todoSorting ?? {}
        : {};

    filled.forEach((section) => {
      section.items = orderTaskCardTasksByDate(section.items, order);
    });

    return filled;
  }, [
    tasks,
    sections,
    user?.settings?.todoSortingMode,
    user?.settings?.todoSorting,
    today,
    weekStartsOn,
    goals,
    t,
  ]);

  return {
    sections: filledSections,
    tasksMap,
    taskCardTasks,
  };
};
