import { doc } from 'firebase/firestore';
import { UpdateTask } from 'shared/types/task';

import { dateToFirestoreTimestamp } from './converters/date-to-firestore-timestamp';
import {
  CollectionOptions,
  getCollection,
  updateDocWithTimestamp,
} from './helpers';

export const updateTask = (task: UpdateTask) => {
  const taskCollection = getCollection(CollectionOptions.Tasks);
  const docRef = doc(taskCollection, task.id);
  updateDocWithTimestamp(docRef, dateToFirestoreTimestamp(task));
};
