import { useCallback } from 'react';
import { useUpdateUserMutation } from 'shared/hooks/queries/use-update-user-mutation';
import { useTrackEvents } from 'shared/hooks/use-track-events';
import { UserFields } from 'shared/services/backend-api';
import {
  ActionEvents,
  notificationsToTrackingNotificationType,
} from 'shared/services/tracking';
import { User } from 'shared/types/user';
import { Notifications } from 'shared/types/user-settings';

export const useUpdateUser = () => {
  const { mutate } = useUpdateUserMutation();
  const track = useTrackEvents();

  const submit = useCallback(
    (userFields: Partial<UserFields>, initialUser?: User | null) => {
      if (userFields.notifications) {
        Object.entries(userFields.notifications).forEach(([key, value]) => {
          const notificationSettings =
            initialUser?.settings?.notifications?.[key as keyof Notifications];
          if (
            notificationSettings?.desktop !== value.desktop ||
            notificationSettings?.mobile !== value.mobile
          ) {
            const type = notificationsToTrackingNotificationType(key);
            type &&
              track(ActionEvents.PushNotificationUpdated, {
                type,
                is_enabled:
                  notificationSettings?.desktop !== value.desktop
                    ? !!value.desktop
                    : !!value.mobile,
              });
          }
        });
      }

      mutate(userFields);
    },
    [mutate, track],
  );

  return {
    submit,
  };
};
