import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Bell } from 'shared/assets/icons';
import { Button, Colors, Sizes, Variants } from 'shared/components/ui/button';
import { Icon } from 'shared/components/ui/icon';

import * as Styled from './request-notification-message.style';

export type RequestNotificationMessageProps = {
  onCancel: () => void;
  onSubmit: () => void;
};

export const RequestNotificationMessage: React.FC<
  RequestNotificationMessageProps
> = ({ onCancel, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <Styled.Container>
      <Styled.IconContainer>
        <Icon icon={Bell} />
      </Styled.IconContainer>
      <Styled.Body>
        <Styled.Content>
          <Typography variant="h6" component="span">
            {t('messages.push-notifications.title')}
          </Typography>
          <Styled.Description>
            {t('messages.push-notifications.description')}
          </Styled.Description>
        </Styled.Content>

        <Styled.Interactions>
          <Button
            variant={Variants.Text}
            color={Colors.Inherit}
            size={Sizes.Small}
            onClick={onCancel}
          >
            {t('messages.push-notifications.buttons.deny.label')}
          </Button>
          <Button
            variant={Variants.Contained}
            color={Colors.Primary}
            size={Sizes.Small}
            onClick={onSubmit}
          >
            {t('messages.push-notifications.buttons.allow.label')}
          </Button>
        </Styled.Interactions>
      </Styled.Body>
    </Styled.Container>
  );
};
