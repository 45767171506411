import { deleteDoc, doc } from 'firebase/firestore';
import { ID } from 'shared/types/id';

import { CollectionOptions, getCollection } from './helpers';

export const deleteLifeArea = async (id: ID) => {
  const lifeAreaCollection = getCollection(CollectionOptions.LifeAreas);
  const docRef = doc(lifeAreaCollection, id);

  // todo: delete all underlying goals tasks if user agrees to it
  // const batch = writeBatch(getDatabase());
  //
  // const tasks = await getDocs(await getTasks({ goals: [id] }));
  //
  // tasks.forEach((task) => {
  //   batch.delete(task.ref);
  // });
  //
  // batch.commit();

  deleteDoc(docRef);
};
