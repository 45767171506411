import {
  fallbackLanguage,
  LocalizedString,
  LocalizedStringOptions,
} from 'shared/types/localized-string';

export const getLocalizedStringValue = (
  localizedString: string | LocalizedString,
  languageRegion: string,
) => {
  // if it is a string or undefined, we just return it
  if (typeof localizedString === 'string' || !localizedString) {
    return localizedString;
  }

  if (localizedString[languageRegion as LocalizedStringOptions]) {
    return localizedString[languageRegion as LocalizedStringOptions];
  }

  // if not region specific, we check if the language is supported
  const language = languageRegion.split('-')[0] as LocalizedStringOptions;

  if (localizedString[language]) {
    return localizedString[language];
  }

  // if user language is not supported, we take the app fallback language
  return localizedString[fallbackLanguage];
};
