import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoaderLogo } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';

import * as Styled from './loading.style';

export const Loading: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Styled.Container>
      <Styled.Content>
        <Styled.ImageContainer>
          <Icon icon={LoaderLogo} />
        </Styled.ImageContainer>
        <Typography variant="h5">{t('pages.loading.title')}</Typography>
      </Styled.Content>

      <CircularProgress size="2.4rem" />
    </Styled.Container>
  );
};
