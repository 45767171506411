import Typography from '@mui/material/Typography';
import { useLoginWithApple, useSignup } from 'features/user';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Locales } from 'shared/assets/localization';
import { ThirdPartySignupButtons } from 'shared/components/connected/third-party-signup-buttons';
import { LoginHeader } from 'shared/components/ui/login-header';
import { useTrackPageVisitEvent } from 'shared/hooks/use-track-page-visit-event';
import { Paths } from 'shared/routes';
import { PageEvents } from 'shared/services/tracking';

import { SignupForm } from './components/signup-form';
import * as Styled from './signup.style';

export const Signup: React.FC = () => {
  const { t, i18n } = useTranslation();
  useTrackPageVisitEvent(PageEvents.Signup, undefined);

  const {
    signup: signupMailPassword,
    isLoading: isLoadingMailPassword,
    error: errorMailPassword,
  } = useSignup();
  const { login: loginApple, isLoading: isLoadingApple } = useLoginWithApple();

  const onSignupWithApple = () => loginApple();

  const onSignupWithMailPassword = ({
    name,
    email,
    password,
  }: {
    name: string;
    email: string;
    password: string;
  }) =>
    signupMailPassword({
      name,
      email,
      password,
      locale: i18n.language as Locales,
    });

  const isLoading = isLoadingMailPassword || isLoadingApple;

  return (
    <>
      <LoginHeader />
      <Styled.Container>
        <Styled.Body>
          <Typography variant="h1" textAlign="center">
            {t('forms.signup.title')}
          </Typography>

          <ThirdPartySignupButtons
            isLoading={isLoading}
            onAppleLogin={onSignupWithApple}
          />

          <Styled.Divider />

          <SignupForm
            onSubmit={onSignupWithMailPassword}
            isLoading={isLoading}
            error={errorMailPassword ?? undefined}
          />

          <Typography variant="overline" component="span" textAlign="center">
            {t('forms.signup.has-account.question.label')}{' '}
            <Styled.Anchor to={Paths.Login}>
              {t('forms.signup.has-account.link.label')}
            </Styled.Anchor>
          </Typography>
        </Styled.Body>
      </Styled.Container>
    </>
  );
};
