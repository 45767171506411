import {
  useArchiveGoal,
  useCreateUnlimitedGoalsAllowed,
  useGoals,
} from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import {
  useFavoriteIds,
  useGoalSorting,
  useToggleFavorite,
  useUpdateGoalOrder,
} from 'features/user';
import React, { useMemo } from 'react';
import { SelfSortingGoalDataGrid } from 'shared/components/ui/goal-data-grid';
import { GridContainer } from 'shared/components/ui/grid-container';
import { useCompleteGoal } from 'shared/contexts/complete-goal';
import { useOpenDeleteGoalDialog } from 'shared/contexts/delete-goal';
import {
  useOpenCreateGoalDialog,
  useOpenEditGoalDialog,
} from 'shared/contexts/goal-form';
import { useToday } from 'shared/contexts/today';
import { ID } from 'shared/types/id';
import { FavoriteType, GoalTabSortingKeys } from 'shared/types/user-settings';
import { sortItems } from 'shared/utils/sort-items';

import { TabPanel } from './components/tab-panel';
import { GoalsFilterOptions, goalsFilters } from './utils/goals-filter';

export const Archived: React.FC = () => {
  const today = useToday();
  const goals = useGoals();
  const lifeAreas = useLocalizedLifeAreas();
  const goalSorting = useGoalSorting(GoalTabSortingKeys.Active);
  const favoriteIds = useFavoriteIds();

  const updateOrder = useUpdateGoalOrder(GoalTabSortingKeys.Archived);
  const openEditDialog = useOpenEditGoalDialog();
  const openCreateDialog = useOpenCreateGoalDialog();
  const openDeleteDialog = useOpenDeleteGoalDialog();
  const completeGoal = useCompleteGoal();
  const archiveGoal = useArchiveGoal();
  const toggleFavorite = useToggleFavorite();

  const createGoalAllowed = useCreateUnlimitedGoalsAllowed();

  const onEdit = (id: ID) => {
    const goal = goals.find((goal) => goal.id === id);
    !!goal && openEditDialog(goal, id);
  };
  const onComplete = (id: ID) => {
    const goal = goals.find((goal) => goal.id === id);
    !!goal && completeGoal(goal);
  };
  const onDelete = (id: ID) => {
    const goal = goals.find((goal) => goal.id === id);
    !!goal && openDeleteDialog(goal);
  };
  const onArchive = (id: ID) => {
    const goal = goals.find((goal) => goal.id === id);
    !!goal && archiveGoal(goal);
  };

  const onFavorite = (id: ID) => {
    toggleFavorite(id, FavoriteType.Goal);
  };

  const filteredGoals = useMemo(
    () =>
      sortItems(
        goalsFilters[GoalsFilterOptions.Archived](goals, today)
          .filter(({ parentIds }) => !parentIds?.length)
          .sort(
            (goalA, goalB) =>
              goalB.archivedAt!.getTime() - goalA.archivedAt!.getTime(),
          ),
        goalSorting,
        'id',
      ),
    [goalSorting, goals, today],
  );

  return (
    <GridContainer>
      <TabPanel role="tab-panel">
        <SelfSortingGoalDataGrid
          goals={filteredGoals}
          allGoals={goals}
          lifeAreas={lifeAreas}
          favorites={favoriteIds}
          onReorder={updateOrder}
          onEditGoal={onEdit}
          onCompleteGoal={onComplete}
          onArchiveGoal={onArchive}
          onDeleteGoal={onDelete}
          onFavoriteGoal={onFavorite}
          onCreateGoal={createGoalAllowed ? openCreateDialog : undefined}
          isArchived
        />
      </TabPanel>
    </GridContainer>
  );
};
