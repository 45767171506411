import React, { forwardRef } from 'react';

import * as Styled from './task-card-title.style';

export type TaskCardTitleProps = {
  value: string;
  placeholder?: string;
  onClick?: (e: React.MouseEvent) => void;
  onChange: (name: string) => void;
  onFocus?: (e: React.FocusEvent) => void;
  fullWidth?: boolean;
  isDisabled?: boolean;
};

export const TaskCardTitle = forwardRef<
  HTMLTextAreaElement,
  TaskCardTitleProps
>(
  (
    { value, placeholder, onClick, onChange, onFocus, fullWidth, isDisabled },
    ref,
  ) => {
    const onChangeTitle = ({
      target,
    }: React.ChangeEvent<HTMLTextAreaElement>) => onChange(target.value);

    return (
      <Styled.Container $fullWidth={!!fullWidth} $isDisabled={!!isDisabled}>
        <Styled.SizingSpan>{value || placeholder}</Styled.SizingSpan>
        <Styled.NameInput
          ref={ref}
          value={value}
          placeholder={placeholder}
          onChange={onChangeTitle}
          onClick={onClick}
          onFocus={onFocus}
          disabled={isDisabled}
        ></Styled.NameInput>
      </Styled.Container>
    );
  },
);
