import styled from 'styled-components';

export const Container = styled.main`
  flex: 1;
  display: flex;
  overflow: hidden;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: ${({ theme }) => theme.spacing(12)};

  overflow: auto;
`;

export const Content = styled.div`
  flex: 1;
`;
