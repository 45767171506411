import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Frequency, RRule } from 'rrule';
import { FormSelect } from 'shared/components/ui/form-select';

import * as Styled from './repeat-rrule-form.style';
import {
  defaultRrule,
  FormUnit,
  formUnits,
  formUnitToRruleFreqMap,
} from './types';

export type HeaderProps = {
  unit: FormUnit;
  onChange: (rrule: string) => void;
};

export const Header: React.FC<HeaderProps> = ({ unit, onChange }) => {
  const { t } = useTranslation();

  const _onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const freq = formUnitToRruleFreqMap[e.target.value as FormUnit];
    const rrule =
      freq === Frequency.WEEKLY
        ? defaultRrule
        : new RRule({
            freq: freq,
          });

    onChange(rrule.toString());
  };

  const formUnitOptions = useMemo(
    () =>
      formUnits.map((formUnit) => ({
        value: formUnit,
        label: t(`forms.habit.fields.repeat.header.units.${formUnit}`),
      })),
    [t],
  );

  return (
    <Styled.HeaderContainer>
      <Typography variant="body2" component="span">
        {t('forms.habit.fields.repeat.header.repeat-every')}
      </Typography>

      <FormSelect
        value={unit}
        onChange={_onChange}
        options={formUnitOptions}
        isSmall
      />
    </Styled.HeaderContainer>
  );
};
