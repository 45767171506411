import { doc } from 'firebase/firestore';
import { LifeArea, LocalizedLifeArea } from 'shared/types/life-area';

import { dateToFirestoreTimestamp } from './converters/date-to-firestore-timestamp';
// import { createLifeArea } from './create-life-area';
// import { getLifeAreaById } from './get-life-area-by-id';
import {
  CollectionOptions,
  getCollection,
  updateDocWithTimestamp,
} from './helpers';

export const updateLifeArea = async (
  lifeArea: Partial<LifeArea | LocalizedLifeArea>,
) => {
  // const lifeAreaToUpdate = (
  //   await getDoc(await getLifeAreaById(lifeArea.id))
  // ).data();
  // if a public life-area is updated, and it is not archiving it, we need to duplicate the life-area
  // if (
  //   lifeArea.templateId &&
  //   lifeAreaToUpdate?.archivedAt === lifeArea.archivedAt
  // ) {
  //   // get the old version of the life-area
  //   const lifeAreaToUpdate = await getDoc(await getLifeAreaById(lifeArea.id));
  //   // create a new version with the old information
  //   await createLifeArea(lifeAreaToUpdate.data() as LifeArea);
  //
  //   // remove the template-id from the current life-area, since the user wants to use it differently then intended.
  //   lifeArea.templateId = null;
  // }

  const lifeAreaCollection = getCollection(CollectionOptions.LifeAreas);
  const docRef = doc(lifeAreaCollection, lifeArea.id);
  updateDocWithTimestamp(docRef, dateToFirestoreTimestamp(lifeArea));
};
