import React, { useMemo, useState } from 'react';
import { FeedbackDialog } from 'shared/dialogs/feedback';
import { useSendFeedbackMutation } from 'shared/hooks/queries/use-send-feedback-mutation';
import { useTrackEvents } from 'shared/hooks/use-track-events';
import { useUser } from 'shared/hooks/use-user';
import { ActionEvents } from 'shared/services/tracking';

import {
  FeedbackDialogContext,
  FeedbackDialogContextProps,
} from './feedback-dialog-context';

export type FeedbackDialogProviderProps = {
  children: React.ReactNode;
};

export const FeedbackDialogProvider: React.FC<FeedbackDialogProviderProps> = ({
  children,
}) => {
  const [open, setOpen] = useState(false);
  const track = useTrackEvents();
  const user = useUser();

  const { mutate } = useSendFeedbackMutation();

  const onSubmit = ({ message }: { message: string }) => mutate(message);

  const onClose = () => setOpen(false);

  const value = useMemo<FeedbackDialogContextProps>(
    () => ({
      openFeedbackDialog: () => {
        track(ActionEvents.ShareFeedback, undefined);
        setOpen(true);
      },
    }),
    [track],
  );

  return (
    <FeedbackDialogContext.Provider value={value}>
      {children}
      <FeedbackDialog
        open={open}
        onClose={onClose}
        userName={user?.name}
        onSubmit={onSubmit}
      />
    </FeedbackDialogContext.Provider>
  );
};
