import { MetricTemplate } from 'shared/types/metric-template';

import {
  RemoteAppConfig,
  RemoteAppConfigKeys,
} from './remote-app-config-types';

const metricTemplates: MetricTemplate[] = [
  {
    id: 'completedTasks',
    name: {
      en: 'Completed tasks',
      nl: 'Voltooide taken',
    },
    description: {
      en: 'E.g 5/10 tasks completed = 50%',
      nl: 'Bijvoorbeeld 5/10 taken voltooid = 50%',
    },
    descriptionShort: {
      en: '5/10 tasks = 50%',
      nl: '5/10 taken = 50%',
    },
    unitName: {
      en: 'Tasks',
      nl: 'Taken',
    },
  },
  {
    id: 'subgoalProgress',
    name: {
      en: 'Subgoal progress',
      nl: 'Subdoel voortgang',
    },
    description: {
      en: 'E.g 1/4 subgoals at 100% = 25%',
      nl: 'Bijvoorbeeld 1/4 subdoelen op 100% = 25%',
    },
    descriptionShort: {
      en: '1/4 subgoals = 25%',
      nl: '1/4 subdoelen = 25%',
    },
    unitName: {
      en: 'Subgoals',
      nl: 'Subdoelen',
    },
  },
  {
    id: 'custom',
    name: {
      en: 'Custom',
      nl: 'Custom',
    },
    description: {
      en: 'Create your own metric',
      nl: 'Creëer je eigen metriek',
    },
    descriptionShort: {
      en: 'Create your own metric',
      nl: 'Creëer je eigen metric',
    },
    unitName: {
      en: 'Unit',
      nl: 'Eenheid',
    },
  },
];

export const remoteAppConfigDefaults: RemoteAppConfig = {
  [RemoteAppConfigKeys.GoalMetricTemplates]: JSON.stringify(metricTemplates),
};
