import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigationSectionLayout } from 'shared/components/ui/navigation-section-layout';
import { useUser } from 'shared/hooks/use-user';

import { FavoritesList } from './favorites-list';

export const Favorites: React.FC = () => {
  const { t } = useTranslation();
  const user = useUser();
  const hasFavorites = !!user?.settings?.favorites?.length;

  return hasFavorites ? (
    <NavigationSectionLayout
      label={t('general.menu.navigation.favorites')}
      initialOpen={true}
    >
      <Suspense>
        <FavoritesList />
      </Suspense>
    </NavigationSectionLayout>
  ) : null;
};
