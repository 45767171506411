import { useCallback } from 'react';
import { useUpdateUserMutation } from 'shared/hooks/queries/use-update-user-mutation';
import { useUser } from 'shared/hooks/use-user';
import { ID } from 'shared/types/id';

export const useDeleteFavorites = () => {
  const { mutate } = useUpdateUserMutation();
  const user = useUser();

  return useCallback(
    (ids: ID[]) => {
      const favorites = user?.settings?.favorites ?? [];
      const newFavorites = favorites.filter(
        (favorite) => !ids.includes(favorite.id),
      );

      // no changes means no update is needed
      if (favorites.length === newFavorites.length) {
        return;
      }

      mutate({ favorites: newFavorites });
    },
    [mutate, user?.settings?.favorites],
  );
};
