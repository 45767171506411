import { ID } from 'shared/types/id';
import {
  GoalTabSortingKeys,
  GoalTabSortingModes,
  GoalTabSortingOptions,
} from 'shared/types/user-settings';

import { getUser, getUserRef } from './get-user';
import { updateDocWithTimestamp } from './helpers';

const settingsBase = 'settings.goalTabSorting';

const goalOrderKeysSettingsMap: Record<GoalTabSortingKeys, string> = {
  [GoalTabSortingKeys.Active]: `${settingsBase}.active`,
  [GoalTabSortingKeys.All]: `${settingsBase}.all`,
  [GoalTabSortingKeys.Completed]: `${settingsBase}.completed`,
  [GoalTabSortingKeys.Archived]: `${settingsBase}.active`,
};

export const updateGoalOrder = async (
  goalOrder: ID[],
  goalOrderOption: GoalTabSortingOptions,
) => {
  const user = await getUser();

  // without a user we cannot update anything.
  if (!user) {
    return;
  }

  const userRef = await getUserRef();

  if (!userRef) {
    return null;
  }

  const settingsToUpdate =
    goalOrderKeysSettingsMap[goalOrderOption as GoalTabSortingKeys] ??
    `${settingsBase}.${goalOrderOption}`;

  updateDocWithTimestamp(userRef, {
    [`${settingsToUpdate}.sorting`]: Array.from(new Set(goalOrder)),
    [`${settingsToUpdate}.mode`]: GoalTabSortingModes.Custom,
  });
};
