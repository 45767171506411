import { useDeleteLifeArea } from 'features/life-areas';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DeleteLifeAreaDialog } from 'shared/dialogs/delete-life-area';
import { LifeArea } from 'shared/types/life-area';

import {
  DeleteLifeAreaContext,
  DeleteLifeAreaContextProps,
} from './delete-life-area-context';

export type DeleteLifeAreaProviderProps = {
  children: React.ReactNode;
};

export const DeleteLifeAreaProvider: React.FC<DeleteLifeAreaProviderProps> = ({
  children,
}) => {
  const navigate = useNavigate();
  const [lifeArea, setLifeArea] = useState<LifeArea>();
  const [redirectPath, setRedirectPath] = useState<string>();
  const {
    submit: deleteLifeArea,
    error,
    isSuccess,
    isLoading,
    retry,
    reset,
  } = useDeleteLifeArea();

  const openDialog = useCallback((lifeArea: LifeArea, path?: string) => {
    setLifeArea(lifeArea);
    setRedirectPath(path);
  }, []);

  const closeDialog = useCallback(() => {
    reset();
    setLifeArea(undefined);
    setRedirectPath(undefined);
  }, [reset]);

  const submit = useCallback(() => {
    !!lifeArea && deleteLifeArea(lifeArea);
  }, [deleteLifeArea, lifeArea]);

  const value = useMemo<DeleteLifeAreaContextProps>(
    () => ({ onDeleteLifeArea: openDialog }),
    [openDialog],
  );

  useEffect(() => {
    if (isSuccess) {
      redirectPath && navigate(redirectPath);
      closeDialog();
    }
  }, [closeDialog, isSuccess, navigate, redirectPath]);

  return (
    <DeleteLifeAreaContext.Provider value={value}>
      {children}
      <DeleteLifeAreaDialog
        open={!!lifeArea}
        name={lifeArea?.name!}
        onClose={closeDialog}
        onSubmit={submit}
        onRetry={retry}
        isLoading={isLoading}
        isError={!!error}
      />
    </DeleteLifeAreaContext.Provider>
  );
};
