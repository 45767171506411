import Typography from '@mui/material/Typography';
import React from 'react';
import { ChevronLeft } from 'shared/assets/icons';
import { Collapse } from 'shared/components/ui/collapse';
import { Icon } from 'shared/components/ui/icon';

import * as Styled from './information-box.style';

export type InformationBoxProps = {
  header: string;
  description?: string;
  open?: boolean;
  onClick: () => void;
  children: React.ReactNode;
};

export const InformationBox: React.FC<InformationBoxProps> = ({
  header,
  description,
  open,
  onClick,
  children,
}) => (
  <Styled.Container $open={!!open}>
    <Styled.Header onClick={onClick}>
      <Typography variant="h6" component="span">
        {header}
      </Typography>

      <Styled.HeaderIconContainer $open={!!open}>
        <Icon icon={ChevronLeft} />
      </Styled.HeaderIconContainer>
    </Styled.Header>

    <Collapse isOpen={open}>
      {description && (
        <Styled.Description>
          <Typography variant="overline" component="p">
            {description}
          </Typography>
        </Styled.Description>
      )}

      {children}
    </Collapse>
  </Styled.Container>
);
