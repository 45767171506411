import React, { useEffect, useState } from 'react';

import * as Styled from './vision-description.style';

export type VisionDescriptionProps = Omit<
  React.ComponentProps<'textarea'>,
  'onChange' | 'value'
> & {
  value?: string;
  onChange: (value: string) => void;
};

export const VisionDescription: React.FC<VisionDescriptionProps> = ({
  value = '',
  onChange,
  ...rest
}) => {
  const [textValue, setTextValue] = useState(value);

  const onChangeTextarea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextValue(e.target.value);
    onChange(e.target.value);
  };

  useEffect(() => {
    setTextValue(value);
  }, [value]);

  return (
    <Styled.Textarea value={textValue} onChange={onChangeTextarea} {...rest} />
  );
};
