import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Sizes, Variants } from 'shared/components/ui/button';

import * as Styled from './goal-template-preview-error.style';

export type GoalTemplateFormErrorProps = {
  onRetry: () => void;
  onCancel: () => void;
};

export const GoalTemplatePreviewError: React.FC<GoalTemplateFormErrorProps> = ({
  onRetry,
  onCancel,
}) => {
  const { t } = useTranslation();
  return (
    <Styled.Container>
      <Styled.Body>
        <Typography variant="h2">
          {t('forms.goal-template.retry.create.title')}
        </Typography>
        <Typography variant="overline">
          {t('forms.goal-template.retry.create.description')}
        </Typography>
      </Styled.Body>
      <Styled.Interactions>
        <Button
          variant={Variants.Outlined}
          size={Sizes.Medium}
          onClick={onCancel}
          type="button"
        >
          {t('forms.goal-template.retry.cancel.label')}
        </Button>
        <Button size={Sizes.Medium} type="submit" onClick={onRetry}>
          {t('forms.goal-template.retry.save.label')}
        </Button>
      </Styled.Interactions>
    </Styled.Container>
  );
};
