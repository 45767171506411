import { CSS, Transform } from '@dnd-kit/utilities';
import styled, { css } from 'styled-components';

export const List = styled.ul`
  list-style: none;

  margin: 0;
  padding: 0;
`;

export const ListItem = styled.li``;

export const DraggableContainer = styled.div<{
  $isDragging?: boolean;
  $transform: Transform | null;
  $transition?: string;
}>`
  position: relative;
  transform: ${({ $transform }) => CSS.Translate.toString($transform)};
  transition: ${({ $transition }) => $transition};

  ${({ $isDragging }) =>
    $isDragging &&
    css`
      > * {
        opacity: 0;
      }

      &:after {
        content: '';

        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        border-radius: ${({ theme }) => theme.spacing(2)};
        background-color: ${({ theme }) => theme.palette.brown['100']};
      }
    `};
`;
