import styled, { css } from 'styled-components';

export const Container = styled.div<{
  $isDragging: boolean;
}>`
  position: relative;

  ${({ $isDragging }) =>
    $isDragging &&
    css`
      > * {
        opacity: 0;
      }

      &:after {
        content: '';

        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        border: 1px solid ${({ theme }) => theme.palette.primary.main};
        border-radius: ${({ theme }) => theme.spacing(2)};
        background-color: ${({ theme }) => theme.palette.primary.light};
      }
    `};
`;
