import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconFormNames } from 'shared/assets/icons';
import { Button, Sizes, Variants } from 'shared/components/ui/button';
import {
  CustomFormSelect,
  Option,
} from 'shared/components/ui/custom-form-select';
import { FormIcon } from 'shared/components/ui/form-icon';
import { FormInput } from 'shared/components/ui/form-input';
import { PredefinedColorOptions } from 'shared/constants';
import { LifeAreaFormFields } from 'shared/types/life-area-form';

import * as Styled from './life-area-form.style';
import { useLifeAreaForm } from './use-life-area-form';

const defaultInitialValues: LifeAreaFormFields = {
  iconName: IconFormNames.PlaceholderIcon,
};

const RenderSelectedColorOption: React.FC<Option> = ({ value, label }) => (
  <Styled.SelectedColorContainer>
    <Styled.ColorCircle $color={value} /> {label}
  </Styled.SelectedColorContainer>
);

export type LifeAreaFormProps = {
  initialValues?: LifeAreaFormFields;
  onSubmit: (lifeArea: LifeAreaFormFields) => void;
  onCancel: () => void;
  isEdit?: boolean;
  isCustomIconAllowed?: boolean;
  onPremium?: () => void;
};

export const LifeAreaForm: React.FC<LifeAreaFormProps> = ({
  initialValues,
  onSubmit,
  onCancel,
  isEdit,
  isCustomIconAllowed,
  onPremium,
}) => {
  const { t } = useTranslation();
  const {
    register,
    onSubmit: submitForm,
    isValid,
    errors,
    watch,
    setValue,
  } = useLifeAreaForm(onSubmit, {
    ...defaultInitialValues,
    ...(initialValues ?? {}),
  });

  const colorOptions = useMemo(
    () =>
      PredefinedColorOptions.map<Option>((colorOption) => ({
        ...colorOption,
        label: t(`general.labels.colors.${colorOption.label}`),
        start: <Styled.ColorCircle $color={colorOption.value} />,
      })),
    [t],
  );

  const colorValue = watch<'color'>('color');
  const onChangeColor = (color?: string) =>
    setValue<'color'>('color', color ?? null);

  const iconValue = watch<'iconName'>('iconName');
  const onChangeIcon = (iconName: IconFormNames) =>
    setValue<'iconName'>('iconName', iconName);

  return (
    <Styled.Form noValidate onSubmit={submitForm}>
      <Styled.Header>
        <Typography variant="h5" component="h1">
          {t(
            isEdit
              ? 'forms.life-area.edit.title'
              : 'forms.life-area.create.title',
          )}
        </Typography>
      </Styled.Header>

      <Styled.Body>
        <Styled.Section>
          <Styled.Label>{t('forms.life-area.fields.name.label')}</Styled.Label>
          <FormInput
            {...register('name')}
            placeholder={t('forms.life-area.fields.name.placeholder')}
            error={errors.name?.message}
            autoComplete="off"
          />

          <Styled.Label>{t('forms.life-area.fields.color.label')}</Styled.Label>
          <CustomFormSelect
            placeholder={t('forms.life-area.fields.color.placeholder')}
            options={colorOptions}
            value={colorValue ?? undefined}
            renderValue={RenderSelectedColorOption}
            onChange={onChangeColor}
          />

          <Styled.Label>{t('forms.life-area.fields.icon.label')}</Styled.Label>
          <FormIcon
            value={iconValue}
            onChange={onChangeIcon}
            isPremium={!isCustomIconAllowed}
            onPremium={onPremium}
          />
        </Styled.Section>
      </Styled.Body>

      <Styled.Interactions>
        <Button
          variant={Variants.Outlined}
          size={Sizes.Medium}
          onClick={onCancel}
          type="button"
        >
          {t('forms.life-area.buttons.cancel.label')}
        </Button>
        <Button size={Sizes.Medium} type="submit" disabled={!isValid}>
          {t('forms.life-area.buttons.save.label')}
        </Button>
      </Styled.Interactions>
    </Styled.Form>
  );
};
