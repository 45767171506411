import styled, { css } from 'styled-components';

import { Colors } from './types';

const setColors = css<{ $color: Colors }>`
  ${({ $color, theme }) => {
    switch ($color) {
      case Colors.Warning:
        return css`
          background: ${theme.palette.warning.light};
          color: ${theme.palette.warning.main};
        `;
      case Colors.Success:
        return css`
          background: ${theme.palette.success.light};
          color: ${theme.palette.success.main};
        `;
      case Colors.Error:
        return css`
          background: ${theme.palette.error.light};
          color: ${theme.palette.error.main};
        `;
      case Colors.Info:
      default:
        return css`
          background: ${theme.palette.primary.light};
          color: ${theme.palette.primary.main};
        `;
    }
  }}
`;

export const Container = styled.div<{ $color: Colors }>`
  ${setColors};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};

  width: 100%;
  padding: ${({ theme }) => `${theme.spacing(1.5)} ${theme.spacing(2)}`};

  border-radius: ${({ theme }) => theme.spacing(2)};
`;

export const IconContainer = styled.span`
  font-size: 2rem;
  line-height: 62.5%;
`;

export const Content = styled.div`
  flex: 1;
`;

export const ActionContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  align-items: center;
`;
