import Dialog from '@mui/material/Dialog';
import React, { useMemo } from 'react';

import { DeleteHabitError } from './delete-habit-error';
import { DeleteHabitLoading } from './delete-habit-loading';
import { DeleteHabitMessage } from './delete-habit-message';

export type DeleteHabitDialogProps = {
  open: boolean;
  name: string;
  onClose: () => void;
  onSubmit: () => void;
  onRetry: () => void;
  isLoading?: boolean;
  isError?: boolean;
};

export const DeleteHabitDialog: React.FC<DeleteHabitDialogProps> = ({
  open,
  name,
  onClose,
  onRetry,
  onSubmit,
  isLoading,
  isError,
}) => {
  const content = useMemo(() => {
    if (isError) {
      return (
        <DeleteHabitError name={name} onRetry={onRetry} onCancel={onClose} />
      );
    }

    if (isLoading) {
      return <DeleteHabitLoading />;
    }

    return (
      <DeleteHabitMessage name={name} onSubmit={onSubmit} onCancel={onClose} />
    );
  }, [isError, isLoading, name, onClose, onRetry, onSubmit]);

  return (
    <Dialog open={open} fullWidth onClose={!isLoading ? onClose : undefined}>
      {content}
    </Dialog>
  );
};
