import { format, getMonth, getYear } from 'date-fns';
import React from 'react';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { ChevronLeft, CircleSmall } from 'shared/assets/icons';
import { IconButton, Sizes } from 'shared/components/ui/icon-button';
import { Timestamp } from 'shared/types/timestamp';

import * as Styled from './datepicker.style';

export type HeaderProps = ReactDatePickerCustomHeaderProps & {
  currentDate?: Timestamp;
};

export const Header: React.FC<HeaderProps> = ({
  currentDate = new Date(),
  date,
  increaseMonth,
  decreaseMonth,
  changeYear,
  changeMonth,
}) => {
  const onCircle = () => {
    if (currentDate) {
      changeYear(getYear(currentDate));
      changeMonth(getMonth(currentDate));
    }
  };

  return (
    <Styled.Header>
      <Styled.HeaderDate>{format(date, 'LLL yyyy')}</Styled.HeaderDate>

      <Styled.HeaderInteractions>
        <IconButton
          size={Sizes.Small}
          icon={ChevronLeft}
          onClick={decreaseMonth}
          type="button"
        />
        <IconButton
          size={Sizes.Small}
          icon={CircleSmall}
          onClick={onCircle}
          type="button"
        />
        <IconButton
          size={Sizes.Small}
          icon={ChevronLeft}
          rotation={180}
          onClick={increaseMonth}
          type="button"
        />
      </Styled.HeaderInteractions>
    </Styled.Header>
  );
};
