import { useQuery } from '@tanstack/react-query';
import { getGoalTemplates } from 'shared/services/backend-api';

export const queryKey = 'goal-templates';

export const useGoalTemplatesQuery = () =>
  useQuery({
    queryKey: [queryKey],
    queryFn: () => getGoalTemplates(),
  });
