import styled, { css } from 'styled-components';

export const Container = styled.div<{
  $isSelected?: boolean;
}>`
  padding: ${({ theme }) => `0 ${theme.spacing(3)} 0 ${theme.spacing(1)}`};

  border-radius: ${({ theme }) => theme.spacing(2)};
  background: ${({ theme }) => theme.palette.background.default};
  transition: background-color 200ms;
  cursor: pointer;

  ${({ $isSelected, theme }) =>
    $isSelected &&
    css`
      background: ${theme.palette.background.paper};
    `};
`;

export const Body = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1.5)};

  margin: ${({ theme }) => `${theme.spacing(0.5)} 0 0`};
  padding: ${({ theme }) => `0 0 ${theme.spacing(0.5)} ${theme.spacing(6.5)}`};
`;
