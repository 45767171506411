import { httpsCallable } from 'firebase/functions';

import { getFunctions } from './helpers';

export const createStripeSessionUrl = async ({
  currency,
  type,
}: {
  currency: string;
  type: string;
}) => {
  const functions = getFunctions();
  const createSessionUrl = httpsCallable<
    { currency: string; type: string },
    string
  >(functions, 'stripeCreateSession-createSession');

  const { data } = await createSessionUrl({ currency, type });

  return data;
};
