import styled from 'styled-components';

export const List = styled.ul`
  margin: 0;
  padding: 0;

  width: 100%;

  list-style: none;
`;

export const ListItem = styled.li``;
