import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ArrowDown,
  ChevronLeft,
  Dot,
  LogOut,
  Menu,
  MessageCircle,
  Settings,
  Star,
  User,
  Users,
} from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import {
  Colors,
  PopupMenu,
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
} from 'shared/components/ui/popup-menu';
import { sideNavigationHoverClass } from 'shared/constants';
import { useClickOutside } from 'shared/hooks/use-click-outside';
import { useOpenMenu } from 'shared/hooks/use-open-menu';
import { Image } from 'shared/types/image';

import * as Styled from './user-button.style';

export type UserButtonViewProps = {
  name: string;
  image?: Image;
  onLogout: () => void;
  onSettings: () => void;
  onIAP?: () => void;
  whatsappLink?: string;
  onFeedback?: () => void;
  onCloseSideNavigation?: () => void;
  userHadTrial?: boolean;
  newVersionAvailable?: boolean;
};

export const UserButtonView: React.FC<UserButtonViewProps> = ({
  name,
  image,
  onLogout,
  onSettings,
  onIAP,
  whatsappLink,
  onFeedback,
  onCloseSideNavigation,
  userHadTrial,
  newVersionAvailable,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const { menuOpen, openMenu, closeMenu } = useOpenMenu();

  const logout = () => {
    onLogout();
    closeMenu();
  };

  const openSettings = () => {
    onSettings();
    closeMenu();
  };

  const openIAP = () => {
    onIAP?.();
    closeMenu();
  };

  const openWhatsappLink = () => {
    window.open(whatsappLink, '_blank');
    closeMenu();
  };

  const openFeedbackLink = () => {
    onFeedback?.();
    closeMenu();
  };

  const closeSideNav = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onCloseSideNavigation?.();
  };

  // @ts-ignore -- reload(true) should forceGet: https://developer.mozilla.org/en-US/docs/Web/API/Location/reload#forceget
  const onReload = () => window.location.reload(true);

  useClickOutside(containerRef, closeMenu);

  return (
    <Styled.Container ref={containerRef}>
      <Styled.Button
        onClick={openMenu}
        tabIndex={0}
        $hasSideNavButton={!!onCloseSideNavigation}
      >
        <Styled.ButtonContent>
          <Styled.AvatarContainer>
            {image ? (
              <Styled.Image src={image.url} />
            ) : (
              <Styled.IconContainer>
                <Icon icon={User} />
              </Styled.IconContainer>
            )}
            {newVersionAvailable && <Styled.NewVersionBadge />}
          </Styled.AvatarContainer>

          <Styled.Name>{name}</Styled.Name>
        </Styled.ButtonContent>

        <Styled.ChevronContainer $open={menuOpen}>
          <Icon icon={ChevronLeft} />
        </Styled.ChevronContainer>

        {onCloseSideNavigation && (
          <Styled.SideNavigationButton
            className={sideNavigationHoverClass}
            onClick={closeSideNav}
            aria-label={t('general.menu.labels.close')}
          >
            <Icon icon={Menu} />
          </Styled.SideNavigationButton>
        )}
      </Styled.Button>

      {menuOpen && (
        <PopupMenu referenceElement={containerRef} position="bottom-start">
          <PopupMenuList hasBottomBorder>
            <PopupMenuListItem>
              <PopupMenuButton
                onClick={openSettings}
                start={<Icon icon={Settings} />}
                tabIndex={0}
              >
                {t('context-menus.user.labels.settings')}
              </PopupMenuButton>
            </PopupMenuListItem>
            <PopupMenuListItem>
              <PopupMenuButton
                onClick={openFeedbackLink}
                start={<Icon icon={MessageCircle} />}
                tabIndex={0}
              >
                {t('context-menus.user.labels.share-feedback')}
              </PopupMenuButton>
            </PopupMenuListItem>
          </PopupMenuList>

          {newVersionAvailable && (
            <PopupMenuList hasBottomBorder>
              <PopupMenuListItem>
                <PopupMenuButton
                  onClick={onReload}
                  start={<Icon icon={ArrowDown} />}
                  end={<Icon icon={Dot} />}
                  endColor={Colors.Error}
                  tabIndex={0}
                >
                  {t('context-menus.user.labels.reload')}
                </PopupMenuButton>
              </PopupMenuListItem>
            </PopupMenuList>
          )}

          {onIAP && (
            <PopupMenuList hasBottomBorder>
              <PopupMenuListItem>
                <PopupMenuButton
                  onClick={openIAP}
                  start={<Icon icon={Star} />}
                  startColor={Colors.Warning}
                  tabIndex={0}
                >
                  {t(
                    userHadTrial
                      ? 'context-menus.user.labels.upgrade-premium'
                      : 'context-menus.user.labels.try-premium-free',
                  )}
                </PopupMenuButton>
              </PopupMenuListItem>
            </PopupMenuList>
          )}

          {!!whatsappLink && (
            <PopupMenuList hasBottomBorder>
              <PopupMenuListItem>
                <PopupMenuButton
                  onClick={openWhatsappLink}
                  start={<Icon icon={Users} />}
                  tabIndex={0}
                >
                  {t('context-menus.user.labels.whatsapp-community')}
                </PopupMenuButton>
              </PopupMenuListItem>
            </PopupMenuList>
          )}

          <PopupMenuList>
            <PopupMenuListItem>
              <PopupMenuButton
                onClick={logout}
                start={<Icon icon={LogOut} />}
                tabIndex={0}
              >
                {t('context-menus.user.labels.logout')}
              </PopupMenuButton>
            </PopupMenuListItem>
          </PopupMenuList>
        </PopupMenu>
      )}
    </Styled.Container>
  );
};
