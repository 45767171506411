import { useResetPasswordMutation } from 'shared/hooks/queries/use-reset-password-mutation';

export const useResetPassword = () => {
  const {
    mutate: resetPassword,
    error,
    reset,
    isPending,
    isSuccess,
  } = useResetPasswordMutation();

  return {
    resetPassword,
    error,
    isLoading: isPending,
    isSuccess,
    reset,
  };
};
