import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React from 'react';
import { DraggableOptions } from 'shared/types/draggable-types';
import { ID } from 'shared/types/id';

import * as Styled from './sortable-container.style';

export type SortableContainerProps = {
  id: ID;
  type: DraggableOptions;
  children: React.ReactNode;
  isDisabled?: boolean;
  isEditing: boolean;
};

export const SortableContainer: React.FC<SortableContainerProps> = ({
  id,
  type,
  children,
  isDisabled,
  isEditing,
}) => {
  const {
    setNodeRef,
    attributes,
    listeners,
    isDragging,
    transform,
    transition,
  } = useSortable({
    id: id,
    disabled: {
      droppable: isDisabled || isEditing,
      draggable: isDisabled || isEditing,
    },
    data: { type, isDisabled },
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition: transition,
  };

  return (
    <Styled.Container
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      role="presentation"
      style={style}
      $isDragging={isDragging}
    >
      {children}
    </Styled.Container>
  );
};
