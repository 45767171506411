import { rgba } from 'polished';
import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};

  margin: 0;
  padding: 0;

  list-style: none;
`;

export const ListItem = styled.li``;

export const OptionButton = styled.button<{ $selected: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(0.5)};

  padding: ${({ theme }) => theme.spacing(4)};
  margin: 0;
  width: 100%;

  border-radius: ${({ theme }) => theme.spacing(2)};
  border: 1px solid ${({ theme }) => theme.palette.divider};
  background: ${({ theme }) => rgba(theme.palette.common.white, 0.1)};
  cursor: pointer;

  @media (any-hover: hover) {
    transition: box-shadow 200ms;
    &:hover {
      box-shadow: ${({ theme }) => theme.shadowBox.menu};
    }
  }

  ${({ theme, $selected }) =>
    $selected &&
    css`
      border-color: ${theme.palette.primary.main};
      border-width: 2px;
      box-shadow: ${theme.shadowBox.menu};
    `};
`;

export const OptionHeader = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(1)};
  width: 100%;

  color: ${({ theme }) => theme.palette.text.primary};
`;

export const SelectedIconContainer = styled.span`
  display: inline-block;
  font-size: 1.6rem;
  line-height: 62.5%;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const OptionBody = styled.span`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1.5)};

  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const Amount = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.h6)};
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const Discount = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.caption)};
  display: inline-block;
  padding: ${({ theme }) => `${theme.spacing(0.5)} ${theme.spacing(2)}`};

  border-radius: ${({ theme }) => theme.spacing(6)};
  background: ${({ theme }) => theme.palette.info.light};

  color: ${({ theme }) => theme.palette.info.main};
`;
