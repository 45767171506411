import { PaletteColor } from '@mui/material';
import { rgba } from 'polished';
import { typographyToCss } from 'shared/lib/mui';
import styled, { css, DefaultTheme } from 'styled-components';

import { Colors, Sizes, Variants } from './types';

const getColor = (color: Colors, theme: DefaultTheme): PaletteColor => {
  switch (color) {
    case Colors.Secondary:
      return theme.palette.secondary;
    case Colors.Error:
      return theme.palette.error;
    case Colors.Warning:
      return theme.palette.warning;
    case Colors.Info:
      return {
        main: theme.palette.info.dark,
        dark: theme.palette.info.dark,
        light: theme.palette.info.light,
        contrastText: theme.palette.info.contrastText,
      };
    case Colors.Success:
      return theme.palette.success;
    case Colors.White:
      return {
        main: theme.palette.common.white,
        dark: theme.palette.common.white,
        light: theme.palette.common.white,
        contrastText: theme.palette.common.black,
      };
    case Colors.Inherit:
      return {
        main: 'inherit',
        dark: 'inherit',
        light: 'inherit',
        contrastText: 'inherit',
      };
    default:
      return theme.palette.primary;
  }
};

const ContainedButton = css<{
  $color: Colors;
  $disabledBackgroundOnly: boolean;
}>`
  border: none;
  background: ${({ $color, theme }) =>
    $color === Colors.Inherit
      ? theme.palette.brown['100']
      : getColor($color, theme).main};
  transition: background-color 200ms;

  color: ${({ theme, $color }) =>
    $color === Colors.Inherit
      ? 'inherit'
      : getColor($color, theme).contrastText};

  &:hover {
    background: ${({ $color, theme }) =>
      $color === Colors.Inherit
        ? theme.palette.grey['200']
        : getColor($color, theme).dark};
  }

  ${({ $disabledBackgroundOnly, $color, theme }) =>
    $disabledBackgroundOnly &&
    css`
      &:disabled {
        opacity: 1;
        background: ${rgba(getColor($color, theme).main, 0.4)};
      }
    `};
`;

const OutlinedButton = css<{
  $color: Colors;
  $disabledBackgroundOnly: boolean;
}>`
  background: transparent;
  border: 1px solid
    ${({ $color, theme }) =>
      $color === Colors.Inherit
        ? theme.palette.divider
        : getColor($color, theme).main};
  transition: background 200ms;

  color: ${({ theme, $color }) => getColor($color, theme).main};

  &:hover {
    background: ${({ $color, theme }) =>
      rgba(
        $color === Colors.Inherit
          ? theme.palette.common.black
          : getColor($color, theme).main,
        0.04,
      )};
  }

  ${({ $disabledBackgroundOnly, $color, theme }) =>
    $disabledBackgroundOnly &&
    css`
      &:disabled {
        opacity: 1;
        border: 1px solid
          ${rgba(
            $color === Colors.Inherit
              ? theme.palette.divider
              : getColor($color, theme).main,
            0.4,
          )};
        background: ${rgba(getColor($color, theme).main, 0.4)};
      }
    `};
`;

const TextButton = css<{ $color: Colors }>`
  background: transparent;
  border: none;
  transition: background 200ms;

  color: ${({ $color, theme }) => getColor($color, theme).main};

  &:hover {
    background: ${({ $color, theme }) =>
      rgba(
        $color === Colors.Inherit
          ? theme.palette.common.black
          : getColor($color, theme).main,
        0.04,
      )};
  }
`;

const LargeButton = css`
  ${({ theme }) => typographyToCss(theme.typography.buttonLarge)};
  padding: ${({ theme }) => `${theme.spacing(2.5)} ${theme.spacing(4)}`};
`;

const MediumButton = css`
  ${({ theme }) => typographyToCss(theme.typography.button)};
  padding: ${({ theme }) => `${theme.spacing(1.5)} ${theme.spacing(2)}`};
`;

const SmallButton = css`
  ${({ theme }) => typographyToCss(theme.typography.buttonSmall)};
  padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(2)}`};
`;

export const Button = styled.button<{
  $color: Colors;
  $variant: Variants;
  $size: Sizes;
  $fullWidth: boolean;
  $alignCenter: boolean;
  $disabledBackgroundOnly: boolean;
}>`
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1.5)};
  margin: 0;

  border-radius: ${({ theme }) => theme.spacing(2)};
  cursor: pointer;

  text-decoration: none;
  outline: none;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
    `};

  ${({ $alignCenter }) =>
    $alignCenter &&
    css`
      justify-content: center;
    `};

  ${({ $variant }) => {
    switch ($variant) {
      case Variants.Text:
        return TextButton;
      case Variants.Outlined:
        return OutlinedButton;
      default:
        return ContainedButton;
    }
  }};

  ${({ $size }) => {
    switch ($size) {
      case Sizes.Medium:
        return MediumButton;
      case Sizes.Small:
        return SmallButton;
      default:
        return LargeButton;
    }
  }}
`;

export const IconContainer = styled.span<{ $size: Sizes }>`
  color: inherit;
  line-height: 62.5%;

  ${({ $size }) => {
    switch ($size) {
      case Sizes.Small:
        return css`
          font-size: 1.2rem;
        `;
      case Sizes.Medium:
        return css`
          font-size: 1.6rem;
        `;
      default:
        return css`
          font-size: 1.6rem;
        `;
    }
  }}
`;
