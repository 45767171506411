import React from 'react';
import { To } from 'react-router-dom';
import { Paths } from 'shared/routes';

export enum MainNavigationItemId {
  Todo = 'todo',
  Inbox = 'inbox',
  Today = 'today',
  Upcoming = 'upcoming',
  AllTasks = 'all-tasks',
  MyLife = 'my-life',
  LifeAreas = 'life-areas',
  Goals = 'goals',
  Habits = 'habits',
  Journal = 'journal',
}

export type SubNavigationItem = {
  id: MainNavigationItemId;
  translationLabel: string;
  link?: To;
  pathsToMatch?: Paths[];
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  comingSoon?: boolean;
};

export type NavigationItem = {
  id: MainNavigationItemId;
  translationLabel: string;
  link?: To;
  subItems: SubNavigationItem[];
};
