import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import React from 'react';
import { Calendar, ChevronLeft, Lock, Target } from 'shared/assets/icons';
import { BlurHashImage } from 'shared/components/connected/blur-hash-image';
import { Icon } from 'shared/components/ui/icon';
import { TimeLeft } from 'shared/components/ui/time-left';
import { Paths } from 'shared/routes';
import { ID } from 'shared/types/id';
import { Image } from 'shared/types/image';
import { Timestamp } from 'shared/types/timestamp';
import { replaceUrlParams } from 'shared/utils/replace-url-params';

import * as Styled from './goal-summary.style';

export type GoalSummaryProps = {
  id?: ID;
  image?: Image | null;
  name: string;
  deadline?: Timestamp;
  imagePlaceholderIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  color?: string;
  isLink?: boolean;
  isSmall?: boolean;
  isHeading?: boolean;
  isFrozen?: boolean;
};

export const GoalSummary: React.FC<GoalSummaryProps> = ({
  id,
  image,
  name,
  deadline,
  imagePlaceholderIcon = Target,
  color,
  isLink,
  isSmall,
  isHeading,
  isFrozen,
}) => (
  <Styled.Container>
    <Styled.ImageContainer $isFrozen={!!isFrozen}>
      {image ? (
        <Styled.BlurImageContainer>
          <BlurHashImage hash={image.blurHash} src={image.url} />
        </Styled.BlurImageContainer>
      ) : (
        <Styled.ImageIconContainer>
          <Icon icon={imagePlaceholderIcon} />
        </Styled.ImageIconContainer>
      )}
      {!!color && <Styled.ColorDot $color={color} />}
    </Styled.ImageContainer>

    <Styled.HeaderBody>
      <Styled.TitleContainer
        as={isLink ? undefined : 'span'}
        to={
          isLink
            ? !id
              ? Paths.Inbox
              : replaceUrlParams(Paths.GoalDetail, { id })
            : ''
        }
      >
        <Styled.Title
          $isFrozen={!!isFrozen}
          $isSmall={!!isSmall}
          as={isHeading ? 'h2' : undefined}
        >
          {name}
        </Styled.Title>
        <Styled.LinkIconContainer $isFrozen={!!isFrozen}>
          <Icon icon={isFrozen ? Lock : ChevronLeft} />
        </Styled.LinkIconContainer>
      </Styled.TitleContainer>

      {!!deadline && (
        <Styled.DeadlineContainer>
          <Styled.Date>
            <Icon icon={Calendar} />
            <Typography variant="overline" component="span">
              {format(deadline, 'd MMM')}
            </Typography>
          </Styled.Date>
          <Styled.Dot />
          <TimeLeft date={deadline} />
        </Styled.DeadlineContainer>
      )}
    </Styled.HeaderBody>
  </Styled.Container>
);
