import { FirebaseApp } from 'firebase/app';
import {
  AppCheck,
  initializeAppCheck,
  ReCaptchaV3Provider,
} from 'firebase/app-check';

let appCheck: AppCheck | undefined;
let callInitialized = false;

export const initAppCheck = (app: FirebaseApp) => {
  if (!appCheck) {
    const initializeAppCheckWhenOnline = () => {
      if (callInitialized) {
        return;
      }

      if (navigator.onLine) {
        callInitialized = true;

        if (import.meta.env.MODE === 'development') {
          // eslint-disable-next-line no-restricted-globals -- Debug is only turned on locally, which is needed to make it work on localhost
          self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
        }

        appCheck = initializeAppCheck(app, {
          provider: new ReCaptchaV3Provider(
            import.meta.env.VITE_FIREBASE_RECAPTCHA_KEY,
          ),
          isTokenAutoRefreshEnabled: true,
        });
      } else {
        window.addEventListener(
          'online',
          () => {
            initializeAppCheckWhenOnline();
          },
          { once: true },
        );
      }
    };

    initializeAppCheckWhenOnline();
  }

  return appCheck;
};

export const clearAppCheck = () => {
  appCheck = undefined;
  callInitialized = false;
};
