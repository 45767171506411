import { TrackingMetricTemplates } from './models/tracking-metric-templates';

const goalMetricIdToTrackingMetricTemplateMap: Record<
  string,
  TrackingMetricTemplates
> = {
  completedTasks: TrackingMetricTemplates.CompletedTasks,
  subgoalProgress: TrackingMetricTemplates.SubgoalProgress,
  money: TrackingMetricTemplates.Money,
  percentage: TrackingMetricTemplates.Percentage,
  weight: TrackingMetricTemplates.Weight,
  distance: TrackingMetricTemplates.Distance,
  time: TrackingMetricTemplates.Time,
  height: TrackingMetricTemplates.Height,
  pages: TrackingMetricTemplates.Pages,
  chapters: TrackingMetricTemplates.Chapters,
  custom: TrackingMetricTemplates.Custom,
};

export const goalMetricIdToTrackingMetricTemplate = (goalMetricId?: string) => {
  if (!goalMetricId) {
    return;
  }

  return goalMetricIdToTrackingMetricTemplateMap[goalMetricId] ?? goalMetricId;
};
