import { useMemo } from 'react';
import { useSubTaskQuery } from 'shared/hooks/queries/use-sub-task-query';
import { useUser } from 'shared/hooks/use-user';
import { Task } from 'shared/types/task';

export const useSubTasks = (task: Task) => {
  const user = useUser();
  const { data } = useSubTaskQuery(
    user?.uid,
    task.id,
    task.childIds ?? undefined,
  );

  return useMemo(() => data ?? [], [data]);
};
