import { httpsCallable } from 'firebase/functions';
import { UnsplashImage } from 'shared/types/unsplash-image';

import { getFunctions } from './helpers';

export const getUnsplashImages = async (
  searchText: string,
  page: number,
  pageSize: number,
) => {
  const functions = getFunctions();
  const getImages = httpsCallable<
    {
      searchText: string;
      limit: number;
      page: number;
    },
    UnsplashImage[]
  >(functions, 'fetchUnsplashImages-fetchUnsplashImages');

  const { data } = await getImages({ searchText, limit: pageSize, page });
  return data;
};
