import { isBefore } from 'date-fns';
import { useMemo } from 'react';
import { useToday } from 'shared/contexts/today';
import { useUser } from 'shared/hooks/use-user';
import { SubscriptionType } from 'shared/types/user-subscription';

export const useUserHadTrial = () => {
  const user = useUser();
  const today = useToday();

  return useMemo(
    () =>
      !!user?.subscriptions?.filter(
        ({ expiresAt, type }) =>
          type === SubscriptionType.Trial && isBefore(today, expiresAt),
      ).length,
    [today, user?.subscriptions],
  );
};
