import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Check, ChevronLeft } from 'shared/assets/icons';
import { DialogVariantOptions } from 'shared/assets/styles';
import { Button, Colors, Sizes, Variants } from 'shared/components/ui/button';
import { Icon } from 'shared/components/ui/icon';
import { Currency } from 'shared/types/currency';

import { Header } from './header';
import { PaymentOptions } from './payment-options';
import * as Styled from './purchase-premium.style';

const uspTranslations = [
  'dialogs.premium.usp.1',
  'dialogs.premium.usp.2',
  'dialogs.premium.usp.3',
  'dialogs.premium.usp.4',
  'dialogs.premium.usp.5',
  'dialogs.premium.usp.6',
];

export type PurchasePremiumProps = {
  open?: boolean;
  currencies: Currency[];
  trialExpired: boolean;
  onClose: () => void;
  onSubmit: ({ currency, type }: { currency: string; type: string }) => void;
  isLoading?: boolean;
};

export const PurchasePremium: React.FC<PurchasePremiumProps> = ({
  open,
  trialExpired,
  currencies,
  onClose,
  onSubmit,
  isLoading,
}) => {
  const { t } = useTranslation();
  const [currency, setCurrency] = useState(currencies[0]?.id);
  const [selectedPriceType, setSelectedPriceType] = useState(
    currencies[0]?.options[0]?.type,
  );

  const onSelectCurrency = ({
    target,
  }: React.ChangeEvent<HTMLSelectElement>) => {
    const currency = currencies.find(({ id }) => id === target.value)!;
    setCurrency(currency.id);
    setSelectedPriceType(currency.options[0]?.type);
  };

  const submitSelection = () => onSubmit({ currency, type: selectedPriceType });

  const currentCurrency = useMemo(
    () => currencies.find(({ id }) => id === currency)!,
    [currencies, currency],
  );
  const currentOption = useMemo(
    () =>
      currentCurrency.options.find(({ type }) => type === selectedPriceType)!,
    [currentCurrency.options, selectedPriceType],
  );

  return (
    <Dialog
      open={!!open}
      fullWidth
      onClose={onClose}
      variant={DialogVariantOptions.Medium}
    >
      <Styled.Container>
        <Header onClose={onClose} />

        <Styled.Body>
          <Styled.UspList>
            {uspTranslations.map((translationKey) => (
              <Styled.UspListItem key={translationKey}>
                <Styled.UspListItemIcon>
                  <Icon icon={Check} />
                </Styled.UspListItemIcon>
                <Typography variant="subtitle2">{t(translationKey)}</Typography>
              </Styled.UspListItem>
            ))}
          </Styled.UspList>

          <Styled.PaymentForm>
            <Styled.PaymentOptions>
              <Styled.Currency>
                <Styled.CurrencyLabel htmlFor="currency-select">
                  {t('dialogs.premium.currency-selection.label')}
                </Styled.CurrencyLabel>
                <Styled.CurrencySelectContainer>
                  <Styled.CurrencySelect
                    id="currency-select"
                    value={currency}
                    onChange={onSelectCurrency}
                  >
                    {currencies.map(({ id, label }) => (
                      <option key={id} value={id}>
                        {label}
                      </option>
                    ))}
                  </Styled.CurrencySelect>
                  <Styled.ChevronContainer>
                    <Icon icon={ChevronLeft} />
                  </Styled.ChevronContainer>
                </Styled.CurrencySelectContainer>
              </Styled.Currency>

              <PaymentOptions
                currency={currentCurrency.sign}
                options={currentCurrency.options}
                onOption={setSelectedPriceType}
                selectedOption={selectedPriceType}
                disabled={isLoading}
              />
            </Styled.PaymentOptions>

            <Styled.Interactions>
              <Button
                variant={Variants.Contained}
                size={Sizes.Large}
                color={Colors.Primary}
                onClick={submitSelection}
                disabled={isLoading}
                fullWidth
                alignCenter
                disabledBackgroundOnly
              >
                {isLoading ? (
                  <Styled.Loader color="primary" size={20} />
                ) : (
                  t(
                    trialExpired
                      ? 'dialogs.premium.interactions.confirm.premium'
                      : 'dialogs.premium.interactions.confirm.trial',
                  )
                )}
              </Button>
              <Typography variant="overline">
                {t(
                  trialExpired
                    ? 'dialogs.premium.interactions.disclaimer.premium'
                    : 'dialogs.premium.interactions.disclaimer.trial',
                  {
                    currency: currentCurrency.sign,
                    price: currentOption.totalAmount ?? currentOption.amount,
                    period: t(
                      `general.labels.currency.period.${currentOption.type}`,
                    ),
                  },
                )}
              </Typography>
            </Styled.Interactions>
          </Styled.PaymentForm>
        </Styled.Body>
      </Styled.Container>
    </Dialog>
  );
};
