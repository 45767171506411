import { FirestoreDataConverter } from 'firebase/firestore';
import { IconFormNames } from 'shared/assets/icons';
import {
  GoalTemplate,
  GoalTemplateInstance,
  TaskTemplateInstance,
} from 'shared/types/goal-template';

import { firebaseTimestampToDate } from './firebase-timestamp-to-date';

export const goalTemplateConverter: FirestoreDataConverter<GoalTemplate> = {
  fromFirestore: (snapshot) => {
    const data = snapshot.data();

    return {
      ...data,
      id: data.id,
      name: data.name,
      description: data.description ?? null,
      embedding: data.embedding,
      originGoalIds: data.originGoalIds ?? [],
      iconName: Object.values(IconFormNames).includes(data.iconName)
        ? data.iconName
        : IconFormNames.EducationAndLearning,
      isPublic: data.isPublic ?? false,

      goals:
        data.goals?.map((goal: GoalTemplateInstance) => ({
          ...goal,
          color: goal.color ?? null,
          goalSortingMode: goal.goalSortingMode ?? null,
          goalSorting: goal.goalSorting ?? null,
          taskSortingMode: goal.taskSortingMode ?? null,
          taskSorting: goal.taskSorting ?? null,
          metric: goal.metric ?? null,

          lifeAreaTemplateId: goal.lifeAreaTemplateId ?? null,
          daysTillDeadline: goal.daysTillDeadline ?? null,
          isMain: goal.isMain ?? false,
        })) ?? [],
      tasks:
        data.tasks?.map((task: TaskTemplateInstance) => ({
          ...task,
          description: task.description ?? null,
          reminderTime: task.reminderTime ?? null,
          priority: task.priority ?? null,
          iconName: task.iconName ?? null,
          schedule: task.schedule ?? null,
        })) ?? [],

      createdAt: firebaseTimestampToDate(data.createdDate)!,
      updatedAt: firebaseTimestampToDate(data.updatedAt),
    };
  },
  toFirestore: (goalTemplate) => goalTemplate,
};
