import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

export const Container = styled.span<{
  $fullWidth: boolean;
  $isDisabled: boolean;
}>`
  position: relative;
  display: inline-block;
  max-width: 100%;
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      pointer-events: none;
    `};
`;

const ContentBase = css`
  ${({ theme }) => typographyToCss(theme.typography.body2)};
  padding: ${({ theme }) => `0 ${theme.spacing(2)} 0 0`};
  max-width: 100%;

  border: none;
  background: transparent;
  outline: none;
`;

export const NameInput = styled.textarea`
  ${ContentBase};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  resize: none;
  overflow: hidden;

  background: transparent;
  color: inherit;

  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  &::-moz-placeholder {
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  &:-ms-input-placeholder {
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;

export const SizingSpan = styled.span`
  ${ContentBase};
  display: inline-block;
  visibility: hidden;
  white-space: pre-wrap;
`;
