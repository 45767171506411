import { ID } from 'shared/types/id';

const storageKey = 'push-notification-tokens';

const getPushNotificationTokens = (): Record<ID, string> => {
  const tokensStr = window.localStorage.getItem(storageKey);
  try {
    const tokens = tokensStr ? JSON.parse(tokensStr) ?? {} : {};
    return tokens;
  } catch (e) {
    return {};
  }
};

export const getPushNotificationToken = (uid: ID) =>
  getPushNotificationTokens()[uid];

export const setPushNotificationToken = (uid: string, token: string) =>
  window.localStorage.setItem(storageKey, JSON.stringify({ [uid]: token }));

export const clearPushNotificationTokens = () =>
  window.localStorage.removeItem(storageKey);
