import * as yup from 'yup';

export enum ForgotPasswordErrorTypes {
  Unknown = 'unknown',
  UnknownEmail = 'unknown-email',
}

export type ForgotPasswordData = {
  email: string;
};

export const validationSchema: yup.ObjectSchema<ForgotPasswordData> =
  yup.object({
    email: yup.string().email().required(),
  });

export type ForgotPasswordFormFields = yup.InferType<typeof validationSchema>;
