import { useCallback } from 'react';
import { useUpdateInboxOrderMutation } from 'shared/hooks/queries/use-update-inbox-order-mutation';
import { useUser } from 'shared/hooks/use-user';
import { Task } from 'shared/types/task';

export const useUpdateInboxTaskOrder = () => {
  const user = useUser();
  const { mutate: updateOrder } = useUpdateInboxOrderMutation();

  const taskReorder = useCallback(
    (task: Task, options: { delete?: boolean } = {}) => {
      // if the task is completed, we need to remove the entry
      if (task.completedAt || task.endStrategy?.deadline || options.delete) {
        updateOrder(
          user?.settings?.inboxSorting?.filter((id) => id !== task.id) ?? [],
        );
        return;
      }

      updateOrder([...(user?.settings?.inboxSorting ?? []), task.id]);
    },
    [updateOrder, user?.settings?.inboxSorting],
  );

  return {
    taskReorder,
    updateOrder,
  };
};
