import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

export const Header = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  padding-top: ${({ theme }) => theme.spacing(0.75)};
  padding-bottom: ${({ theme }) => theme.spacing(0.75)};
`;

export const CheckboxContainer = styled.span<{ $isFaded?: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  padding-top: ${({ theme }) => theme.spacing(0.25)};
  width: 2.4rem;
  height: 2.4rem;
  min-width: 2.4rem;
  min-height: 2.4rem;

  line-height: 62.5%;
  font-size: 2rem;
  color: ${({ theme }) => theme.palette.grey['400']};

  ${({ $isFaded }) =>
    $isFaded &&
    css`
      opacity: 0.3;
    `};
`;

export const CheckboxCheckContainer = styled.span`
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 1.2rem;
  height: 1.2rem;

  transform: translate(-33%, 33%);
  background: ${({ theme }) => theme.palette.success.main};
  border-radius: 50%;

  font-size: 0.8rem;
  color: ${({ theme }) => theme.palette.success.contrastText};
`;

export const HeaderContent = styled.div<{ $isFaded?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(0.75)};
  padding-top: ${({ theme }) => theme.spacing(0.75)};
  min-width: 0;

  ${({ $isFaded }) =>
    $isFaded &&
    css`
      opacity: 0.5;
    `}
`;

export const TitleContainer = styled.span<{ $isFrozen: boolean }>`
  flex: 1;
  line-height: 1;

  ${({ $isFrozen, theme }) =>
    $isFrozen &&
    css`
      color: ${theme.palette.text.secondary};
    `};
`;

export const Description = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.overline)};
  display: block;

  overflow: hidden;

  color: ${({ theme }) => theme.palette.text.secondary};
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const HeaderMetaContent = styled.div<{ $isFrozen: boolean }>`
  display: flex;
  gap: ${({ theme }) => theme.spacing(3)};
  min-width: 0;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up(theme.breakpoints.values.xs)} {
    padding-top: ${({ theme }) => theme.spacing(0.5)};
  }

  ${({ $isFrozen }) =>
    $isFrozen &&
    css`
      opacity: 0.5;
    `}
`;

export const MetaLabelContainer = styled.span<{
  $isOverdue?: boolean;
  $isToday?: boolean;
  $isCompleted?: boolean;
  $hasOverflowHidden?: boolean;
}>`
  ${({ theme }) => typographyToCss(theme.typography.overline)};

  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};

  min-width: 3.2rem;

  color: ${({ theme }) => theme.palette.text.secondary};

  ${({ $isOverdue, $isCompleted, theme }) =>
    $isOverdue &&
    !$isCompleted &&
    css`
      color: ${theme.palette.error.main};
    `};

  ${({ $isToday, $isCompleted, theme }) =>
    $isToday &&
    !$isCompleted &&
    css`
      color: ${theme.palette.info.main};
    `};
`;

export const Label = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const MetaIcon = styled.span`
  font-size: 1.2rem;
  line-height: 62.5%;
`;

export const GoalColorDot = styled.span<{ $color: string }>`
  display: block;
  width: 0.6rem;
  height: 0.6rem;

  border-radius: 50%;
  background: ${({ $color }) => $color};
`;
