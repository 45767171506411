import { useGoalById, useGoalControls, useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import { useFavoriteIds, useToggleFavorite } from 'features/user';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Plus } from 'shared/assets/icons';
import { IconButton } from 'shared/components/ui/icon-button';
import { Section } from 'shared/components/ui/section';
import { Sizes } from 'shared/components/ui/section/types';
import { SelfSortingTaskAndGoalList } from 'shared/components/ui/task-and-goal-list';
import { SelfSortingTasksList } from 'shared/components/ui/tasks-list';
import { useCompleteGoal } from 'shared/contexts/complete-goal';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import {
  useActiveTaskId,
  useOpenTaskDetail,
} from 'shared/contexts/task-detail';
import { useToday } from 'shared/contexts/today';
import { useHandleTaskAndGoalChanges } from 'shared/hooks/use-handle-task-and-goal-changes';
import { useOpenMenu } from 'shared/hooks/use-open-menu';
import { useUser } from 'shared/hooks/use-user';
import { ID } from 'shared/types/id';
import { FavoriteType } from 'shared/types/user-settings';
import { initialWeekStartsOn } from 'shared/types/week-days';
import { mapTaskToTaskCard } from 'shared/utils/mappers/map-task-to-task-card';

import { useActionPlanItems } from '../../hooks/use-action-plan-items';
import { ActionPlanContextMenu } from '../action-plan-context-menu';
import * as Styled from './action-plan.style';

export const ActionPlan: React.FC = () => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const { t } = useTranslation();
  const today = useToday();
  const selectedTask = useActiveTaskId();

  const { id } = useParams();
  const openTaskDetail = useOpenTaskDetail();
  const actionContextButtonRef = useRef<HTMLButtonElement>(null);
  const {
    menuOpen: actionContextOpen,
    openMenu: openActionContext,
    closeMenu: closeActionContext,
  } = useOpenMenu();

  const user = useUser();
  const favoriteIds = useFavoriteIds();
  const lifeAreas = useLocalizedLifeAreas();
  const openPremiumDialog = useOpenPremiumDialog();
  const toggleFavorite = useToggleFavorite();

  const [selectedItem, setSelectedItem] = useState<ID>();
  const clearSelectedItem = () => setSelectedItem(undefined);

  const goal = useGoalById(id);
  const goals = useGoals();

  const mainParentIds = useMemo(
    () => [...(goal?.parentIds ?? []), goal?.id!],
    [goal],
  );
  const { items, completedTasks, tasks, subTasks, archivedSubGoals } =
    useActionPlanItems();
  const allTasks = useMemo(
    () =>
      [...tasks, ...subTasks].map((task) =>
        mapTaskToTaskCard(task, {
          goals,
          t,
          today,
          showDue: true,
          hideGoal: true,
          weekStartsOn: user?.settings?.startOfWeek ?? initialWeekStartsOn,
        }),
      ),
    [goals, subTasks, t, tasks, today, user?.settings?.startOfWeek],
  );
  const onTask = (id?: ID) => {
    setSelectedItem(id);
    const taskCardTask = allTasks.find(
      (taskCardTask) => taskCardTask.id === id,
    );
    if (taskCardTask) {
      openTaskDetail(taskCardTask.id);
    }
  };

  const onReorder = useHandleTaskAndGoalChanges(items);
  const completeGoal = useCompleteGoal();
  const {
    onCreateSubGoal,
    onEditGoal,
    onUpdateGoal,
    onUpdateGoalProgress,
    onArchiveGoal,
    onDeleteGoal,
  } = useGoalControls(goals, {
    onCreatePresets: { lifeAreaId: goal?.lifeAreaId },
  });

  const onCompleteGoal = useCallback(
    (id: ID) => {
      const goal = goals.find((goal) => goal.id === id);
      !!goal && completeGoal(goal);
    },
    [completeGoal, goals],
  );

  const onFavorite = (id: ID) => {
    toggleFavorite(id, FavoriteType.Goal);
  };

  const _onCreateSubGoal = () => {
    if (!goal) {
      return;
    }

    onCreateSubGoal([...(goal.parentIds ?? []), goal.id]);
  };

  return (
    <Styled.Container>
      <Section
        title={t('pages.goal-detail.action-plan.title')}
        actions={[
          // <Styled.SortIconContainer>
          //   <IconButton icon={Sort} />
          // </Styled.SortIconContainer>,
          <IconButton
            icon={Plus}
            ref={actionContextButtonRef}
            onClick={openActionContext}
          />,
        ]}
      >
        <SelfSortingTaskAndGoalList
          id={goal?.id ?? ''}
          items={items}
          goals={goals}
          favorites={favoriteIds}
          lifeAreas={lifeAreas}
          onPremium={openPremiumDialog}
          onTask={onTask}
          onUnfocus={clearSelectedItem}
          selectedItem={selectedTask ?? selectedItem}
          onCreateSubGoal={
            mainParentIds.length > 1 ? undefined : _onCreateSubGoal
          }
          onCompleteGoal={onCompleteGoal}
          onEditGoal={onEditGoal}
          onUpdateGoal={onUpdateGoal}
          onUpdateGoalProgress={onUpdateGoalProgress}
          onArchiveGoal={onArchiveGoal}
          onDeleteGoal={onDeleteGoal}
          onFavoriteGoal={onFavorite}
          onReorder={onReorder}
          newTaskInputRef={inputRef}
          hideGoal
          canCreateTask
          canCollapse
        />
        {!!completedTasks.length && (
          <Styled.SectionContainer>
            <Section
              title={t('pages.goal-detail.tasks.completed.title')}
              titleCount={completedTasks.length}
              size={Sizes.Small}
              canCollapse
            >
              <SelfSortingTasksList
                tasks={completedTasks}
                selectedTask={selectedTask}
              />
            </Section>
          </Styled.SectionContainer>
        )}
        {!!archivedSubGoals.length && (
          <Styled.SectionContainer>
            <Section
              title={t('pages.goal-detail.tasks.archived.title')}
              titleCount={archivedSubGoals.length}
              size={Sizes.Small}
              canCollapse
            >
              <SelfSortingTaskAndGoalList
                id={goal?.id ?? ''}
                items={archivedSubGoals}
                goals={goals}
                favorites={favoriteIds}
                lifeAreas={lifeAreas}
                onPremium={openPremiumDialog}
                onTask={onTask}
                onUnfocus={clearSelectedItem}
                selectedItem={selectedTask ?? selectedItem}
                onCompleteGoal={onCompleteGoal}
                onEditGoal={onEditGoal}
                onUpdateGoalProgress={onUpdateGoalProgress}
                onUpdateGoal={onUpdateGoal}
                onArchiveGoal={onArchiveGoal}
                onDeleteGoal={onDeleteGoal}
                onFavoriteGoal={onFavorite}
                hideGoal
              />
            </Section>
          </Styled.SectionContainer>
        )}
      </Section>
      {actionContextOpen && (
        <ActionPlanContextMenu
          referenceElement={actionContextButtonRef}
          onClose={closeActionContext}
          newTaskRef={inputRef}
          id={id}
          parentIds={mainParentIds}
          lifeAreaId={goal?.lifeAreaId}
        />
      )}
    </Styled.Container>
  );
};
