import React from 'react';
import { FullLogoDark, FullLogoLight } from 'shared/assets/icons';
import { useElectron } from 'shared/hooks/use-is-mac';
import { useBrowserPrefersDarkMode } from 'shared/lib/mui';

import * as Styled from './login-header.style';

export const LoginHeader: React.FC = () => {
  const prefersDarkMode = useBrowserPrefersDarkMode();
  const { isMac, isElectron } = useElectron();

  return (
    <Styled.Container $isElectronMac={isMac}>
      {!isElectron && (
        <Styled.Content>
          {prefersDarkMode ? <FullLogoDark /> : <FullLogoLight />}
        </Styled.Content>
      )}
    </Styled.Container>
  );
};
