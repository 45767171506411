import { PaletteColor } from '@mui/material';
import styled, { css, DefaultTheme } from 'styled-components';

import { IconColors } from './types';

const getColor = (
  color: IconColors | undefined,
  theme: DefaultTheme,
): PaletteColor['main'] => {
  switch (color) {
    case IconColors.Secondary:
      return theme.palette.secondary.main;
    case IconColors.Error:
      return theme.palette.error.main;
    case IconColors.Warning:
      return theme.palette.warning.main;
    case IconColors.Info:
      return theme.palette.info.main;
    case IconColors.Success:
      return theme.palette.success.main;
    default:
      return theme.palette.primary.main;
  }
};

export const Container = styled.div<{ $open: boolean }>`
  width: 100%;

  border-radius: ${({ theme }) => theme.spacing(2)};
  border: ${({ theme }) => `1px solid ${theme.palette.divider}`};
  box-shadow: ${({ theme }) => theme.shadowBox.card};

  ${({ $open, theme }) =>
    $open &&
    css`
      padding: ${`0 0 ${theme.spacing(4)}`};
    `};
`;

export const Header = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(2)};

  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(6)}`};
  margin: 0;
  width: 100%;
  min-height: 4.4rem;

  border: none;
  background: transparent;
  cursor: pointer;

  color: inherit;
  text-align: left;
`;

export const HeaderIconContainer = styled.span<{ $open: boolean }>`
  transform: rotate(270deg);
  transition: transform 200ms;

  font-size: 1.4rem;
  line-height: 62.5%;
  color: ${({ theme }) => theme.palette.text.secondary};

  ${({ $open }) =>
    $open &&
    css`
      transform: rotate(90deg);
    `};
`;

export const Description = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacing(6)}`};
`;

export const BulletsContainer = styled.div``;

export const BulletsList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};

  padding: ${({ theme }) => `0 ${theme.spacing(6)}`};
  margin: 0;
  list-style: none;
`;

export const BulletsListItem = styled.li`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const BulletsListItemIcon = styled.span<{ $color?: IconColors }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  min-width: 1.6rem;
  width: 1.6rem;
  min-height: 1.6rem;
  height: 1.6rem;

  border: ${({ theme, $color }) => `1px solid ${getColor($color, theme)}`};
  border-radius: 50%;

  font-size: 1rem;
  color: ${({ $color, theme }) => getColor($color, theme)};
`;

export const BulletsListItemTitle = styled.span`
  color: ${({ theme }) => theme.palette.text.primary};
`;
