import { Timestamp } from './timestamp';

export enum SubscriptionType {
  Trial = 'trial',
  Paid = 'paid',
}

export enum Store {
  Stripe = 'stripe',
  Apple = 'apple',
}

export type UserSubscription = {
  type: SubscriptionType;
  store: Store;
  stripeCustomerId?: string;
  managementUrl?: string;
  productId?: string;
  expiresAt: Timestamp;
  unsubscribedAt?: Timestamp;
  refundedAt?: Timestamp;
  purchasedAt: Timestamp;
};
