import React, { useMemo, useState } from 'react';
import { TaskTypeToggle } from 'shared/components/ui/task-type-toggle';
import { useToday } from 'shared/contexts/today';
import { HabitSchedule } from 'shared/types/habit-schedule';
import { TaskType } from 'shared/types/task-base';
import { WeekDays } from 'shared/types/week-days';

import { Footer } from './footer';
import { Header } from './header';
import { MonthsForm } from './months-form';
import { isValidRrule, parseFormUnitFromRrule } from './parse-rrule';
import { parseValueAndCountToSchedule } from './parse-value-and-count-to-schedule';
import * as Styled from './repeat-rrule-form.style';
import { defaultRrule, FormUnit } from './types';
import { WeeksForm } from './weeks-form';

export type RepeatRruleFormProps = {
  initialRrule?: string;
  initialCount: number | null;
  type: TaskType;
  weekStartsOn: WeekDays;
  onChange: (schedule: HabitSchedule | null) => void;
  onChangeType: (value: TaskType.Repeating | TaskType.Habit) => void;
  createHabitPremium: boolean;
};

export const RepeatRruleForm: React.FC<RepeatRruleFormProps> = ({
  initialRrule = defaultRrule,
  initialCount,
  type,
  weekStartsOn,
  onChange,
  onChangeType,
  createHabitPremium,
}) => {
  const today = useToday();
  const [rrule, setRrule] = useState(initialRrule);
  const [count, setCount] = useState(initialCount);
  const typeValue =
    type && type !== TaskType.Task
      ? type
      : createHabitPremium
        ? TaskType.Repeating
        : TaskType.Habit;

  const selectedUnit = useMemo(() => parseFormUnitFromRrule(rrule), [rrule]);
  const isValidValue = useMemo(
    () => isValidRrule(rrule, { hasCount: !!count }),
    [count, rrule],
  );

  const _onChange = ({
    value,
    count,
  }: {
    value: string;
    count?: number | null;
  }) => {
    setCount(count ?? null);
    setRrule(value);
  };

  const onClear = () => onChange(null);

  const onSubmit = () => {
    if (type !== typeValue) {
      onChangeType(typeValue);
    }

    onChange(parseValueAndCountToSchedule(rrule, count, today));
  };

  return (
    <Styled.Container>
      <Header onChange={setRrule} unit={selectedUnit} />

      <Styled.Body>
        {selectedUnit === FormUnit.Weeks && (
          <WeeksForm
            rrule={rrule}
            count={count}
            onChange={_onChange}
            weekStartsOn={weekStartsOn}
          />
        )}
        {selectedUnit === FormUnit.Months && (
          <MonthsForm
            rrule={rrule}
            onChange={_onChange}
            weekStartsOn={weekStartsOn}
          />
        )}

        <TaskTypeToggle
          type={typeValue}
          onChange={onChangeType}
          isPremium={createHabitPremium}
        />
      </Styled.Body>

      <Footer onClear={onClear} onSubmit={onSubmit} isValid={isValidValue} />
    </Styled.Container>
  );
};
