import {
  defaultDropAnimationSideEffects,
  DragOverlay,
  DropAnimation,
} from '@dnd-kit/core';
import React from 'react';

const dropAnimationConfig: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.4',
      },
    },
  }),
};

type SortableOverlayProps = Omit<
  React.ComponentProps<typeof DragOverlay>,
  'wrapperElement' | 'dropAnimation'
> & {
  as?: keyof JSX.IntrinsicElements;
};

export const SortableOverlay: React.FC<SortableOverlayProps> = ({
  children,
  as,
  ...rest
}) => (
  <DragOverlay
    {...rest}
    dropAnimation={dropAnimationConfig}
    wrapperElement={as}
  >
    {children}
  </DragOverlay>
);
