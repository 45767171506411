import * as yup from 'yup';

export enum ResetPasswordErrorTypes {
  Unknown = 'unknown',
}

export type ResetPasswordData = {
  password: string;
  passwordConfirmation: string;
};

export const validationSchema: yup.ObjectSchema<ResetPasswordData> = yup.object(
  {
    password: yup.string().required(),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref('password')])
      .required(),
  },
);

export type ResetPasswordFormFields = yup.InferType<typeof validationSchema>;
