import { DndContext } from '@dnd-kit/core';
import { useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import { usePriorityTodayReorder } from 'features/user';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TaskCard } from 'shared/components/connected/task-card';
import { OverlayComponentWrapper } from 'shared/components/ui/overlay-component-wrapper';
import { SortableOverlay } from 'shared/components/ui/sortable-overlay';
import { TaskSection } from 'shared/components/ui/task-section';
import { useActiveTaskId } from 'shared/contexts/task-detail';
import { useToday } from 'shared/contexts/today';
import { useDroppableSectionControls } from 'shared/hooks/use-droppable-section-controls';
import { useSortableSensors } from 'shared/hooks/use-sortable-sensors';
import { DueDisplayOptions, TaskPriorityOptions } from 'shared/types/task-base';

import { usePriorityTaskSections } from '../../hooks/use-priority-task-sections';
import { SectionHeaderToday } from '../section-header-today';
import * as Styled from './priority-grouping-tasks-list.style';

export const PriorityGroupingTasksList: React.FC = () => {
  const { t } = useTranslation();
  const today = useToday();
  const { taskCardTasks, sections: initialSections } =
    usePriorityTaskSections();
  const onDragEnd = usePriorityTodayReorder(initialSections);

  const {
    handleDragStart,
    handleDragOver,
    handleDragEnd,
    handleDragCancel,
    activeId,
    sections,
  } = useDroppableSectionControls({ initialSections, onDragEnd });
  const sensors = useSortableSensors();

  const selectedTask = useActiveTaskId();

  const goals = useGoals();
  const lifeAreas = useLocalizedLifeAreas();

  const activeItem = useMemo(
    () =>
      activeId ? taskCardTasks.find(({ id }) => id === activeId) : undefined,
    [activeId, taskCardTasks],
  );

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
      sensors={sensors}
    >
      <Styled.Container>
        {sections.map(({ id, items }) => (
          <Styled.Section key={id}>
            <TaskSection
              droppableId={id}
              goals={goals}
              lifeAreas={lifeAreas}
              today={today}
              tasks={items}
              selectedTask={selectedTask}
              date={today}
              priority={id as TaskPriorityOptions}
              displayDue={[DueDisplayOptions.Past, DueDisplayOptions.Future]}
              customHeader={
                <SectionHeaderToday
                  title={t(`general.sections.priority.${id}.title`)}
                  titleCount={items.length}
                />
              }
              taskIsMoving={!!activeItem}
              canCollapse
              canCreateTask
              canReorder
            />
          </Styled.Section>
        ))}
      </Styled.Container>

      <SortableOverlay>
        {!!activeItem && (
          <OverlayComponentWrapper>
            <TaskCard
              task={activeItem.raw.task}
              displayDue={[DueDisplayOptions.Past, DueDisplayOptions.Future]}
              noHover
            />
          </OverlayComponentWrapper>
        )}
      </SortableOverlay>
    </DndContext>
  );
};
