import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Colors, Sizes, Variants } from 'shared/components/ui/button';

import * as Styled from './delete-habit.style';

export type DeleteHabitMessageProps = {
  name: string;
  onSubmit: () => void;
  onCancel: () => void;
};

export const DeleteHabitMessage: React.FC<DeleteHabitMessageProps> = ({
  name,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Styled.Header>
        <Typography variant="h5" component="h1">
          {t('forms.delete-habit.message.title')}
        </Typography>
      </Styled.Header>

      <Styled.Body>
        <Typography variant="body2">
          {t('forms.delete-habit.message.description.1')}
          <Styled.HabitName>{name}</Styled.HabitName>
          {t('forms.delete-habit.message.description.2')}
        </Typography>
        <Typography variant="body2">
          {t('forms.delete-habit.message.warning')}
        </Typography>
      </Styled.Body>

      <Styled.Interactions>
        <Button
          variant={Variants.Outlined}
          size={Sizes.Medium}
          onClick={onCancel}
        >
          {t('forms.delete-habit.message.buttons.cancel.label')}
        </Button>
        <Button size={Sizes.Medium} color={Colors.Error} onClick={onSubmit}>
          {t('forms.delete-habit.message.buttons.submit.label')}
        </Button>
      </Styled.Interactions>
    </>
  );
};
