import React, { forwardRef } from 'react';
import { Icon } from 'shared/components/ui/icon';

import * as Styled from './popup-menu.style';
import { Colors } from './types';

export type PopupMenuInputProps = React.ComponentProps<'input'> & {
  startIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  iconColor?: Colors;
  textColor?: Colors;
  inputHidden?: boolean;
};

export const PopupMenuInput = forwardRef<HTMLInputElement, PopupMenuInputProps>(
  (
    { startIcon, children, iconColor, textColor, inputHidden, id, ...rest },
    ref,
  ) => (
    <Styled.InputLabel htmlFor={id} $color={textColor} $noHover={!inputHidden}>
      {!!startIcon && (
        <Styled.AffixContainer $color={iconColor}>
          <Icon icon={startIcon} />
        </Styled.AffixContainer>
      )}
      {children}
      <Styled.Input
        ref={ref}
        $hasStartIcon={!!startIcon}
        $hidden={!!inputHidden}
        id={id}
        {...rest}
      />
    </Styled.InputLabel>
  ),
);
