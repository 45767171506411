import { QueryNavLink } from 'shared/components/connected/query-nav-link';
import { typographyToCss } from 'shared/lib/mui';
import styled from 'styled-components';

export const Container = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacing(6)}`};
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(6)};

  margin: ${({ theme }) => `${theme.spacing(8)} auto`};
  max-width: 32rem;
`;

export const BodyContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(4)};
`;

export const Anchor = styled(QueryNavLink)`
  display: block;
  ${({ theme }) => typographyToCss(theme.typography.overline)};

  color: ${({ theme }) => theme.palette.primary.main};
  text-align: center;
`;
