import { Paths } from 'shared/routes';

import { Active } from './active';
import { All } from './all';
import { Archived } from './archived';
import { Completed } from './completed';
import { NextYear } from './next-year';
import { ThisYear } from './this-year';

export const GoalsOverviewRoutes = [
  {
    path: Paths.Goals,
    element: <Active />,
  },
  {
    path: Paths.GoalsAll,
    element: <All />,
  },
  {
    path: Paths.GoalsCompleted,
    element: <Completed />,
  },
  {
    path: Paths.GoalsArchived,
    element: <Archived />,
  },
  {
    path: Paths.GoalsThisYear,
    element: <ThisYear />,
  },
  {
    path: Paths.GoalsNextYear,
    element: <NextYear />,
  },
];
