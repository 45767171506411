import { doc, FieldValue, increment } from 'firebase/firestore';
import { Goal } from 'shared/types/goal';

import { dateToFirestoreTimestamp } from './converters/date-to-firestore-timestamp';
import {
  CollectionOptions,
  getCollection,
  updateDocWithTimestamp,
} from './helpers';

export const updateGoal = async (
  goal: Partial<Goal>,
  forceValues?: boolean,
) => {
  if (!goal.id) {
    return;
  }

  Object.entries(goal).forEach(([key, value]) => {
    if (value === undefined) {
      delete goal[key as keyof Goal];
    }
  });
  const goalCollection = getCollection(CollectionOptions.Goals);
  const docRef = doc(goalCollection, goal.id);
  const { taskCount, completedTaskCount, ...rest } = goal;
  const data: typeof rest & {
    taskCount?: FieldValue;
    completedTaskCount?: FieldValue;
  } = dateToFirestoreTimestamp({ ...goal });

  if (!forceValues && taskCount) {
    data.taskCount = increment(taskCount);
  }

  if (!forceValues && completedTaskCount) {
    data.completedTaskCount = increment(completedTaskCount);
  }

  updateDocWithTimestamp(docRef, data);
};
