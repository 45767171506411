import { createContext } from 'react';
import { Goal } from 'shared/types/goal';

export type CompleteGoalContextProps = {
  onCompleteDialog: (goal: Goal) => void;
};

export const CompleteGoalContext = createContext<CompleteGoalContextProps>({
  onCompleteDialog: () => {},
});
