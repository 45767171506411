import { useCallback, useRef } from 'react';
import { useUpdateLifeAreaMutation } from 'shared/hooks/queries/use-update-life-area-mutation';
import { LifeArea } from 'shared/types/life-area';

export const useUpdateLifeArea = () => {
  const updateLifeAreaRef = useRef<LifeArea>();
  const { mutate, error, isPending, isSuccess, reset } =
    useUpdateLifeAreaMutation();

  const submit = useCallback(
    (lifeArea: LifeArea) => {
      updateLifeAreaRef.current = lifeArea;
      mutate(lifeArea);
    },
    [mutate],
  );

  const retry = useCallback(() => {
    if (updateLifeAreaRef.current) {
      mutate(updateLifeAreaRef.current);
    }
  }, [mutate]);

  const resetAll = useCallback(() => {
    reset();
    updateLifeAreaRef.current = undefined;
  }, [reset]);

  return {
    submit,
    retry,
    reset: resetAll,
    error,
    isLoading: isPending,
    isSuccess,
  };
};
