import { isSameDay, startOfDay } from 'date-fns';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { TodayContext } from './context';

export type TodayProviderProps = {
  children: React.ReactNode;
};

export const TodayProvider: React.FC<TodayProviderProps> = ({ children }) => {
  const [today, setToday] = useState(startOfDay(new Date()));
  const previousDayRef = useRef(today);

  useEffect(() => {
    const interval = setInterval(() => {
      const time = startOfDay(new Date());
      // if it is the same day, we don't want a render cycle
      if (isSameDay(previousDayRef.current, time)) {
        return;
      }
      // if not, we update the today state and the previousTimeRef to the current time
      previousDayRef.current = time;
      setToday(time);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const value = useMemo(() => ({ today }), [today]);

  return (
    <TodayContext.Provider value={value}>{children}</TodayContext.Provider>
  );
};
