import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Colors, Sizes, Variants } from 'shared/components/ui/button';

import * as Styled from './goal-template-preview-dialog-footer.style';

export type GoalTemplatePreviewHeaderProps = {
  onCancel: () => void;
  onSubmit: () => void;
};

export const GoalTemplatePreviewDialogFooter: React.FC<
  GoalTemplatePreviewHeaderProps
> = ({ onCancel, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <Styled.Container>
      <Button
        variant={Variants.Outlined}
        size={Sizes.Medium}
        color={Colors.Inherit}
        onClick={onCancel}
        type="button"
        tabIndex={0}
      >
        {t('forms.goal-template-preview.buttons.cancel.label')}
      </Button>
      <Button
        variant={Variants.Contained}
        size={Sizes.Medium}
        color={Colors.Primary}
        onClick={onSubmit}
        tabIndex={0}
      >
        {t('forms.goal-template-preview.buttons.save.label')}
      </Button>
    </Styled.Container>
  );
};
