import { addDays, endOfDay } from 'date-fns';
import { useUpdateTask } from 'features/tasks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Calendar,
  CalendarDeleteDate,
  CalendarTomorrow,
} from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import {
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
} from 'shared/components/ui/popup-menu';
import { Task } from 'shared/types/task';

export type DateListProps = {
  task: Task;
  onClose: () => void;
};

export const DateList: React.FC<DateListProps> = ({ task, onClose }) => {
  const { t } = useTranslation();
  const { submit: updateTask } = useUpdateTask();

  const onDeadlineToday = () => {
    updateTask({
      id: task.id,
      endStrategy: {
        deadline: endOfDay(new Date()),
        completionCount: task.endStrategy?.completionCount ?? null,
      },
    });
    onClose();
  };

  const onDeadlineTomorrow = () => {
    updateTask({
      id: task.id,
      endStrategy: {
        deadline: endOfDay(addDays(new Date(), 1)),
        completionCount: task.endStrategy?.completionCount ?? null,
      },
    });
    onClose();
  };

  const onRemoveDeadline = () => {
    updateTask({
      id: task.id,
      endStrategy: {
        deadline: null,
        completionCount: task.endStrategy?.completionCount ?? null,
      },
    });
    onClose();
  };

  return (
    <PopupMenuList hasBottomBorder>
      <PopupMenuListItem>
        <PopupMenuButton
          onClick={onDeadlineToday}
          start={<Icon icon={Calendar} />}
        >
          {t('context-menus.task.labels.deadline-today')}
        </PopupMenuButton>
      </PopupMenuListItem>

      <PopupMenuListItem>
        <PopupMenuButton
          onClick={onDeadlineTomorrow}
          start={<Icon icon={CalendarTomorrow} />}
        >
          {t('context-menus.task.labels.deadline-tomorrow')}
        </PopupMenuButton>
      </PopupMenuListItem>

      {!!task.endStrategy?.deadline && (
        <PopupMenuListItem>
          <PopupMenuButton
            onClick={onRemoveDeadline}
            start={<Icon icon={CalendarDeleteDate} />}
          >
            {t('context-menus.task.labels.remove-deadline')}
          </PopupMenuButton>
        </PopupMenuListItem>
      )}
    </PopupMenuList>
  );
};
