import { httpsCallable } from 'firebase/functions';

import { getFunctions } from './helpers';

export const setUserCountry = async () => {
  const functions = getFunctions();
  const setCountry = httpsCallable(functions, 'users-setLocationOnSignup');

  await setCountry();
};
