import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Check } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { ID } from 'shared/types/id';
import { PaymentOption } from 'shared/types/payment-option';

import * as Styled from './payment-options.style';

export type PaymentOptionsProps = {
  options: PaymentOption[];
  onOption: (id: ID) => void;
  currency: string;
  selectedOption: string;
  disabled?: boolean;
};

export const PaymentOptions: React.FC<PaymentOptionsProps> = ({
  options,
  onOption,
  currency,
  selectedOption,
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <Styled.List>
      {options.map(({ type, amount, discountPercentage }) => {
        const isSelected = type === selectedOption;
        const onClick = () => onOption(type);

        return (
          <Styled.ListItem key={type}>
            <Styled.OptionButton
              $selected={isSelected}
              onClick={onClick}
              disabled={disabled}
            >
              <Styled.OptionHeader>
                <Typography variant="h6" component="span">
                  {t(`dialogs.premium.payment-options.period.${type}`)}
                </Typography>
                {isSelected && (
                  <Styled.SelectedIconContainer>
                    <Icon icon={Check} />
                  </Styled.SelectedIconContainer>
                )}
              </Styled.OptionHeader>

              <Styled.OptionBody>
                <Typography variant="body2" component="span">
                  <Styled.Amount>
                    {currency} {amount}
                  </Styled.Amount>{' '}
                  {t(`dialogs.premium.payment-options.interval.${type}`)}
                </Typography>

                {!!discountPercentage && (
                  <Styled.Discount>
                    {t('dialogs.premium.payment-options.discount.percentage', {
                      percentage: discountPercentage,
                    })}
                  </Styled.Discount>
                )}
              </Styled.OptionBody>
            </Styled.OptionButton>
          </Styled.ListItem>
        );
      })}
    </Styled.List>
  );
};
