import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Toggle } from 'shared/components/ui/toggle';
import { TaskType } from 'shared/types/task-base';

import * as Styled from './task-type-toggle.style';

export type TaskTypeToggleProps = {
  type: TaskType.Repeating | TaskType.Habit;
  onChange: (value: TaskType.Repeating | TaskType.Habit) => void;
  isPremium?: boolean;
  fullWidth?: boolean;
};

export const TaskTypeToggle: React.FC<TaskTypeToggleProps> = ({
  type,
  onChange,
  isPremium,
  fullWidth,
}) => {
  const { t } = useTranslation();

  const onTypeChange = () => {
    onChange(type === TaskType.Habit ? TaskType.Repeating : TaskType.Habit);
  };

  return (
    <Styled.Label htmlFor="type-toggle" $fullWidth={!!fullWidth}>
      <Typography variant="body2">
        {t('general.labels.habit.repeat.change-type.label')}
      </Typography>

      {isPremium && (
        <Styled.PremiumLabel>{t('general.labels.premium')}</Styled.PremiumLabel>
      )}

      <Styled.Handle>
        <Toggle
          id="type-toggle"
          value="value"
          onChange={onTypeChange}
          checked={type === TaskType.Habit}
        />
      </Styled.Handle>
    </Styled.Label>
  );
};
