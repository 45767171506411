import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Check, Warning } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import {
  PopupMenu,
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
  PopupMenuTitle,
} from 'shared/components/ui/popup-menu';
import { TaskMetaInteraction } from 'shared/components/ui/task-meta-interaction';
import { useClickOutside } from 'shared/hooks/use-click-outside';
import { useOpenMenu } from 'shared/hooks/use-open-menu';
import { TaskPriorityOptions } from 'shared/types/task-base';

import * as Styled from './task-meta-interaction-priority.style';

export type TaskMetaInteractionPriorityProps = Omit<
  React.ComponentProps<typeof TaskMetaInteraction>,
  'start' | 'onClick' | 'label' | 'selected' | 'tooltipLabel'
> & {
  value?: TaskPriorityOptions | null;
  onChange: (value: TaskPriorityOptions | null) => void;
};

export const TaskMetaInteractionPriority: React.FC<
  TaskMetaInteractionPriorityProps
> = ({ value, onChange, ...rest }) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLSpanElement>(null);

  const { menuOpen, openMenu, closeMenu } = useOpenMenu();

  const onClear = () => {
    onChange(null);
    closeMenu();
  };

  const onHigh = () => {
    onChange(TaskPriorityOptions.High);
    closeMenu();
  };

  const onMedium = () => {
    onChange(TaskPriorityOptions.Medium);
    closeMenu();
  };

  const onLow = () => {
    onChange(TaskPriorityOptions.Low);
    closeMenu();
  };

  useClickOutside(containerRef, closeMenu);

  return (
    <Styled.Container ref={containerRef}>
      <TaskMetaInteraction
        start={<Icon icon={Warning} />}
        onClick={openMenu}
        label={value ? t(`task.priority.options.${value}.label`) : undefined}
        tooltipLabel={t(
          value
            ? 'task.priority.tooltip.edit.label'
            : 'task.priority.tooltip.add.label',
        )}
        selected={menuOpen}
        {...rest}
      />

      {menuOpen && (
        <PopupMenu referenceElement={containerRef} position="right">
          <PopupMenuList>
            <PopupMenuTitle title={t('task.priority.options.title')} />
            <PopupMenuListItem>
              <PopupMenuButton
                onClick={onClear}
                start={!value ? <Icon icon={Check} /> : undefined}
                hasIndentation={!!value}
              >
                {t('task.priority.options.none.label')}
              </PopupMenuButton>
            </PopupMenuListItem>
            <PopupMenuListItem>
              <PopupMenuButton
                onClick={onLow}
                start={
                  value === TaskPriorityOptions.Low ? (
                    <Icon icon={Check} />
                  ) : undefined
                }
                hasIndentation={value !== TaskPriorityOptions.Low}
              >
                {t('task.priority.options.low.label')}
              </PopupMenuButton>
            </PopupMenuListItem>
            <PopupMenuListItem>
              <PopupMenuButton
                onClick={onMedium}
                start={
                  value === TaskPriorityOptions.Medium ? (
                    <Icon icon={Check} />
                  ) : undefined
                }
                hasIndentation={value !== TaskPriorityOptions.Medium}
              >
                {t('task.priority.options.medium.label')}
              </PopupMenuButton>
            </PopupMenuListItem>
            <PopupMenuListItem>
              <PopupMenuButton
                onClick={onHigh}
                start={
                  value === TaskPriorityOptions.High ? (
                    <Icon icon={Check} />
                  ) : undefined
                }
                hasIndentation={value !== TaskPriorityOptions.High}
              >
                {t('task.priority.options.high.label')}
              </PopupMenuButton>
            </PopupMenuListItem>
          </PopupMenuList>
        </PopupMenu>
      )}
    </Styled.Container>
  );
};
