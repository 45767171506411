import { getDocs, orderBy, query, where } from 'firebase/firestore';
import { GoalTemplate } from 'shared/types/goal-template';

import { goalTemplateConverter } from './converters/goal-template';
import { CollectionOptions, getCollection } from './helpers';

export const getGoalTemplates = async () => {
  const result: GoalTemplate[] = [];

  const docs = await getDocs(
    query(
      getCollection(CollectionOptions.GoalTemplates).withConverter(
        goalTemplateConverter,
      ),
      where('isPublic', '==', true),
      orderBy('updatedAt', 'asc'),
    ),
  );

  docs.forEach((doc) => {
    result.push(doc.data());
  });

  return result;
};
