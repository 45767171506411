import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  align-items: center;

  color: ${({ theme }) => theme.palette.text.primary};
`;

export const Button = styled.button<{ $selected: boolean }>`
  display: block;

  padding: ${({ theme }) => theme.spacing(1.5)};
  margin: 0;

  border-radius: ${({ theme }) => theme.spacing(2)};
  border: 1px solid
    ${({ theme, $selected }) =>
      $selected ? theme.palette.primary.main : theme.palette.divider};
  background: transparent;
  cursor: pointer;
  transition: border-color 200ms;

  font-size: 2rem;
  line-height: 62.5%;

  color: ${({ theme }) => theme.palette.text.primary};

  &:hover {
    border-color: ${({ theme }) => theme.palette.brown['200']};
  }
`;

export const PremiumLabel = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.overline)};
  display: block;

  padding: ${({ theme }) => `${theme.spacing(0.5)} ${theme.spacing(2)}`};

  background: ${({ theme }) => theme.palette.info.light};
  border-radius: ${({ theme }) => theme.spacing(6)};

  color: ${({ theme }) => theme.palette.info.main};
`;

export const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: ${({ theme }) => theme.spacing(2)};
  list-style: none;

  margin: 0;
  padding: ${({ theme }) => theme.spacing(2)};
`;

export const ListItem = styled.li``;

export const OptionButton = styled.button<{ $selected: boolean }>`
  display: block;

  margin: 0;
  padding: ${({ theme }) => theme.spacing(1.5)};

  cursor: pointer;
  border: none;
  border-radius: ${({ theme }) => theme.spacing(2)};
  background: transparent;

  color: inherit;
  font-size: 2rem;
  line-height: 62.5%;

  ${({ $selected, theme }) =>
    $selected &&
    css`
      background: ${theme.palette.primary.light};
      color: ${theme.palette.primary.main};
    `}
`;
