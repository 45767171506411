import React, { forwardRef } from 'react';
import { Icon } from 'shared/components/ui/icon';

import * as Styled from './button.style';
import { Colors, Sizes, Variants } from './types';

export type ButtonProps = React.ComponentProps<'button'> & {
  startIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  endIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  variant?: Variants;
  color?: Colors;
  size?: Sizes;
  fullWidth?: boolean;
  alignCenter?: boolean;
  disabledBackgroundOnly?: boolean;
  as?: React.FC | string;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      startIcon,
      endIcon,
      size = Sizes.Large,
      variant = Variants.Contained,
      color = Colors.Primary,
      fullWidth,
      alignCenter,
      disabledBackgroundOnly,
      children,
      as,
      ...rest
    },
    ref,
  ) => (
    <Styled.Button
      {...rest}
      ref={ref}
      $size={size}
      $color={color}
      $variant={variant}
      $fullWidth={!!fullWidth}
      $alignCenter={!!alignCenter}
      $disabledBackgroundOnly={!!disabledBackgroundOnly}
      as={as}
    >
      {!!startIcon && (
        <Styled.IconContainer $size={size}>
          <Icon icon={startIcon} />
        </Styled.IconContainer>
      )}
      {children}
      {!!endIcon && (
        <Styled.IconContainer $size={size}>
          <Icon icon={endIcon} />
        </Styled.IconContainer>
      )}
    </Styled.Button>
  ),
);
