import { useGoalByIdQuery } from 'shared/hooks/queries/use-goal-by-id-query';
import { useUser } from 'shared/hooks/use-user';
import { ID } from 'shared/types/id';

export const useGoalById = (id?: ID) => {
  const user = useUser();
  const { data } = useGoalByIdQuery(user?.uid, id);

  return data;
};
