import styled, { css, DefaultTheme } from 'styled-components';

import { Colors, Sizes, Variants } from './types';

const getColor = (
  color: Colors,
  theme: DefaultTheme,
): {
  background: string;
  border: string;
  color: string;
  outlineColor: string;
} => {
  switch (color) {
    case Colors.Primary:
      return {
        background: theme.palette.primaryExtended.opacityBackgroundBackground,
        border: theme.palette.primaryExtended.opacityBackgroundBackground,
        color: theme.palette.primaryExtended.opacityBackgroundText,
        outlineColor: theme.palette.primaryExtended.opacityBackgroundText,
      };
    case Colors.Success:
      return {
        background: theme.palette.success.light,
        border: theme.palette.success.light,
        color: theme.palette.success.dark,
        outlineColor: theme.palette.success.dark,
      };
    case Colors.Info:
      return {
        background: theme.palette.info.light,
        border: theme.palette.info.light,
        color: theme.palette.info.main,
        outlineColor: theme.palette.info.main,
      };
    case Colors.Warning:
      return {
        background: theme.palette.warning.light,
        border: theme.palette.warning.light,
        color: theme.palette.warning.dark,
        outlineColor: theme.palette.warning.dark,
      };
    default:
      return {
        background: theme.palette.grey['100'],
        border: theme.palette.divider,
        color: theme.palette.text.secondary,
        outlineColor: theme.palette.text.primary,
      };
  }
};

const getSize = (size: Sizes, theme: DefaultTheme) => {
  switch (size) {
    case Sizes.Medium:
      return {
        height: '3.2rem',
        borderRadius: '1.6rem',
        horizontalPadding: theme.spacing(3),
      };
    default:
      return {
        height: '2.2rem',
        borderRadius: '1.1rem',
        horizontalPadding: theme.spacing(2),
      };
  }
};

const contained = css<{ $color: Colors }>`
  background: ${({ theme, $color }) => getColor($color, theme).background};
  color: ${({ theme, $color }) => getColor($color, theme).color};
`;

const outlined = css<{ $color: Colors }>`
  background: transparent;
  border: 1px solid ${({ $color, theme }) => getColor($color, theme).border};
  color: ${({ theme, $color }) => getColor($color, theme).outlineColor};
`;

export const Container = styled.div<{
  $color: Colors;
  $size: Sizes;
  $variant: Variants;
}>`
  display: inline-flex;
  align-items: center;
  padding: ${({ $size, theme }) =>
    `0 ${getSize($size, theme).horizontalPadding}`};

  height: ${({ $size, theme }) => getSize($size, theme).height};
  border-radius: ${({ $size, theme }) => getSize($size, theme).borderRadius};

  ${({ $variant }) => {
    switch ($variant) {
      case Variants.Outlined:
        return outlined;
      default:
        return contained;
    }
  }};
`;
